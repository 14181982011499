import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HttpHelper } from '../../helpers/http-helper';
import { Avatar } from '../../models/AvatarDados';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  standalone: true,
  imports: [NgStyle],
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input()
  avatar: Avatar;

  @Output()
  click = new EventEmitter<void>();

  public showInitials = false;
  public initials: string;
  public circleColor: string;
  public srcimage: string;

  private colors = [
    '#EB7181', // red
    '#468547', // green
    '#FFD558', // yellow
    '#3670B2', // blue
  ];

  constructor(private helper: HttpHelper) {}

  ngOnInit() {
    if (!this.avatar.image || this.avatar.image.length === 0) {
      this.showInitials = true;
      this.createInititals();
      const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
      this.circleColor = this.colors[randomIndex];
    } else {
      this.showInitials = false;
      this.srcimage = this.helper.ToURL(this.avatar.image);
    }
  }

  private createInititals(): void {
    const fullName = this.avatar.name.split(' ');
    if (fullName.length > 1) {
      this.initials = (fullName.shift().charAt(0) + fullName.pop().charAt(0)).toUpperCase();
    } else {
      this.initials = this.avatar.name.charAt(0).toUpperCase();
    }
  }

  onClick() {
    this.click.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('SimpleChanges:', changes['avatar'].currentValue);
    this.srcimage = this.helper.ToURL(this.avatar.image);
  }
}
