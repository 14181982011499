import { Component, OnInit } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { NgbConfig, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  host: { class: 'global' },
  imports: [LoadingBarModule, RouterOutlet, NgbModule],
  providers: [NgbTooltipConfig],
})
export class AppComponent implements OnInit {
  title = '7Bov';

  constructor(ngbConfig: NgbConfig, tooltipConfig: NgbTooltipConfig) {
    ngbConfig.animation = false;
    //tooltipConfig.placement = 'right';
    tooltipConfig.container = 'body';
    tooltipConfig.triggers = 'hover';
    tooltipConfig.tooltipClass = 'tpx-tooltip';
  }

  ngOnInit() {}
}
