<form [id]="fieldIdTable['form']" [formGroup]="loginForm" class="w-100">
  <div class="text-center pb-4">
    <div class="t-header-title" l10nTranslate>EsqueceuSenhaComponent.HEADERTITLE</div>
  </div>
  <!-- PASSO 0 -->
  <div [class.d-none]="workflow.passo != 0">
    <div class="mb-3">
      <strong l10nTranslate>EsqueceuSenhaComponent.TITLE-PASSO-1</strong>
    </div>
    <div class="form-group row mb-3">
      <div class="col">
        <input
          type="text"
          id="username"
          formControlName="username"
          autocomplete="username"
          placeholder="Usu&aacute;rio"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.username.invalid && (f.username.dirty || f.username.touched) }"
        />
        @if (f.username.errors) {
          <div class="invalid-feedback">
            @if (f.username.errors.required) {
              <div>Usu&aacute;rio &eacute; requerido</div>
            }
          </div>
        }
      </div>
    </div>
    <div class="form-group row mb-3">
      <div class="d-flex flex-row-reverse">
        <ngx-recaptcha2
          #captchaElem
          [siteKey]="siteKey"
          (reset)="handleReset()"
          (expire)="handleExpire()"
          (load)="handleLoad()"
          (success)="handleSuccess($event)"
          [useGlobalDomain]="false"
          formControlName="recaptcha"
        >
        </ngx-recaptcha2>
      </div>
    </div>
    <div class="form-group mb-3">
      <button type="button" class="btn btn-primary" (click)="toPasso(1)" [disabled]="!passoValido(0)">Continuar</button>
    </div>
  </div>
  <!-- PASSO 1 -->
  <div [class.d-none]="workflow.passo != 1">
    <div class="mb-3">
      Enviaremos um
      <strong> código de verificação</strong>
      para o e-mail cadastrado:
    </div>
    <div class="mb-3">
      <strong>{{ wbag.getTextById('email.fake', '') }}</strong>
    </div>
    <div class="form-group mb-3">
      <button type="button" class="btn btn-primary" (click)="toPasso(2)">Continuar</button>
    </div>
  </div>
  <div [class.d-none]="workflow.passo != 2">
    <div class="mb-3">Digite o código de validação enviado para o e-mail</div>
    <div class="mb-3">
      <strong>{{ wbag.getTextById('email.fake', '') }}</strong>
    </div>
    <div class="row">
      <div class="col-sm-6 mb-3">
        <div class="form-group">
          <input
            type="text"
            formControlName="codigo"
            placeholder="Digite o código"
            class="form-control"
            maxlength="8"
            minlength="8"
            (input)="inputHandleCode($event)"
            numberOnly
            [ngClass]="{ 'is-invalid': f.codigo.invalid && (f.codigo.dirty || f.codigo.touched) }"
          />
          @if (f.codigo.errors) {
            <div class="invalid-feedback">
              @if (f.codigo.errors.required) {
                <div>Por favor insira um código</div>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 mb-3">
        Não recebeu o código? <a href="javascript:;" class="ms-3" (click)="toPasso(2)">Reenviar código</a>
      </div>
    </div>
  </div>
  <!-- TROCA DE SENHA -->
  <div [class.d-none]="workflow.passo != 3">
    <div class="row">
      <div class="col-sm-6 mb-3">
        <label [for]="fieldIdTable['senha']" class="form-label" l10nTranslate>ChangePasswordForm.senha</label>
        <input
          type="{{ showPassword2 ? 'text' : 'password' }}"
          [id]="fieldIdTable['senha']"
          formControlName="senha"
          class="form-control"
          autocomplete="current-password"
          maxlength="50"
          [ngClass]="{ 'is-invalid': f.senha.invalid && (f.senha.dirty || f.senha.touched) }"
        />
        <span class="p-viewer">
          <i
            (click)="showPassword2 = !showPassword2"
            [class]="showPassword2 ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
          ></i>
        </span>
        @if (f.senha.errors) {
          <div class="invalid-feedback">
            @if (f.senha.errors.required) {
              <div l10nTranslate>ChangePasswordForm.senha-REQUIRED</div>
            }
          </div>
        }
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 mb-3">
        <label [for]="fieldIdTable['resenha']" class="form-label" l10nTranslate>ChangePasswordForm.resenha</label>
        <input
          type="{{ showPassword3 ? 'text' : 'password' }}"
          [id]="fieldIdTable['resenha']"
          formControlName="resenha"
          class="form-control"
          autocomplete="new-password"
          maxlength="50"
          [ngClass]="{ 'is-invalid': f.resenha.invalid && (f.resenha.dirty || f.resenha.touched) }"
        />
        <span class="p-viewer">
          <i
            (click)="showPassword3 = !showPassword3"
            [class]="showPassword3 ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
          ></i>
        </span>
        @if (f.resenha.errors) {
          <div class="invalid-feedback">
            @if (f.resenha.errors.required) {
              <div l10nTranslate>ChangePasswordForm.resenha-REQUIRED</div>
            }
          </div>
        }
      </div>
      <div class="form-group mb-3">
        <button type="button" class="btn btn-primary" (click)="toPasso(4)" [disabled]="!passoValido(3)">
          Concluir
        </button>
      </div>
    </div>
  </div>
  <!-- fim -->
  <div>
    <a href="javascript:;" (click)="backToLogin()">Voltar ao início</a>
  </div>
</form>
