<!-- {{NOTGENERATE}} -->
<ng-container>
  <div [id]="fieldIdTable['wrapper']" class="ng-select-wrapper" style="position: relative">
    <div style="overflow: unset">
      <ng-select
        #ngSelect
        name="{{ GetFormControlName() }}"
        [items]="rows"
        bindLabel="name"
        bindValue="id"
        [(ngModel)]="selectRows"
        (change)="onChangeInterno($event)"
        (blur)="onTouch()"
        [searchable]="true"
        [searchFn]="CustomSearchFn"
        [appendTo]="'#' + fieldIdTable['wrapper']"
        [required]="isRequired"
        [multiple]="multiple"
        [placeholder]="placeholder"
      >
        @if (hasListeners()) {
          <ng-template ng-header-tmp>
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-outline-secondary btn-sm" (click)="OnAddElement()">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        }
        <ng-template ng-label-tmp let-item="item">
          <div>{{ item.name }}</div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div [title]="item.id">{{ item.name }}</div>
        </ng-template>
      </ng-select>
    </div>
  </div>
</ng-container>
