// {{GENERATE}}
// @CRUD
// @GRID:card
// @Route:/admin/contato/action
// URL:/admin/contato
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { SelectOptionUtils } from 'src/app/core/helpers/SelectOptionUtils';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SearchBoxComponent } from 'src/app/core/helpers/search-box.component';
import { GridModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService, DialogType } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { ContatoBean } from '../../../models/ContatoBean';
import { AdminContatoActionService } from '../../../services/admin-contato-action.service';
import { DadosContatoBeanFragmentComponent } from '../dados-contato-bean/dados-contato-bean-fragment.component';
import { DadosFiscaisBeanFragmentComponent } from '../dados-fiscais-bean/dados-fiscais-bean-fragment.component';
import { AdminContatoActionAddComponent } from './admin-contato-action-add.component';
import { AdminContatoActionCardComponent } from './admin-contato-action-card.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-admin-contato-action-grid',
  templateUrl: './admin-contato-action-grid.component.html',
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginator,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    SearchBoxComponent,
    DadosContatoBeanFragmentComponent,
    DadosFiscaisBeanFragmentComponent,
    AdminContatoActionCardComponent,
  ],
  providers: [],
})
export class AdminContatoActionGridComponent implements OnInit, AfterViewChecked, GridModelComponent, AfterViewInit {
  // #region #properties#.
  // #endregion

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lastsearchtext = '';
  checkedTodas = true;
  public displayedColumns: string[] = ['nome', 'observacoes', 'action'];
  rows: MatTableDataSource<ContatoBean>;
  rowsCache: ContatoBean[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loadingIndicator: boolean;
  messages = Constants.messages;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: AdminContatoActionService,
    private modalService: NgbModal,
    public translation: L10nTranslationService,
  ) {
    //
    this.rows = new MatTableDataSource<ContatoBean>([]);
  }
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit');
    this.rows.paginator = this.paginator;
    this.rows.sort = this.sort;
  }
  // #endregion

  // #region #ngafterviewchecked.
  // CODGEN {{R}}
  ngAfterViewChecked(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    this.loadingIndicator = true;

    this.crudService.GetAllContato(this.checkedTodas ? 'TODAS' : '').subscribe({
      next: (data) => {
        this.rowsCache = [...data];
        if (this.lastsearchtext && this.lastsearchtext !== '') {
          this.OnSearchChanged(this.lastsearchtext);
        } else {
          this.rows.data = this.rowsCache;
        }
        this.loadingIndicator = false;
      },
      error: (error) => {
        this.loadingIndicator = false;
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #ondeleterow.
  // CODGEN {{R}}
  OnDeleteRow(row: ContatoBean) {
    this.alertService.showDialog(
      this.translation.translate('AdminContatoActionGrid.DELETE-TITLE'),
      Utilities.GetDeleteRowMessage(this, 'AdminContatoActionGrid', row, this.displayedColumns),
      DialogType.confirm,
      () => this.OnDeleteRowHelper(row),
      () => {
        //console.log('Cancelado');
      },
    );
  }
  // #endregion

  // #region #ondeleterowhelper.
  // CODGEN {{R}}
  private OnDeleteRowHelper(row: ContatoBean) {
    this.crudService.RemoveContato(row.idcontato).subscribe({
      next: () => {
        this.rowsCache = this.rowsCache.filter((item) => item !== row);
        this.rows.data = this.rowsCache;
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #onsearchchanged.
  // CODGEN {{R}}
  OnSearchChanged(value: string) {
    this.lastsearchtext = value;
    this.rows.data = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        value,
        false,
        this.DisplayField('nome', r.nome, r),
        this.DisplayField('observacoes', r.observacoes, r),
      ),
    );
  }
  // #endregion

  // #region #displayfield.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DisplayField(fieldname: string, value, _row: ContatoBean) {
    switch (fieldname) {
      case 'nome':
        return value;
      case 'observacoes':
        return value;
      default:
        return value;
    }
  }
  // #endregion

  // #region #oncardmodify.
  // CODGEN {{R}}
  OnCardModify($event: { cmd: string; node: ContatoBean }): void {
    this.OnMenuClickcontato(null, $event.node, $event.cmd);
  }
  // #endregion

  // #region #onaddelement.
  // CODGEN {{R}}
  OnAddElement() {
    const modalRef = Utilities.OpenNewModal(this.modalService, AdminContatoActionAddComponent, 'tpx-custom-modal');
    const view: AdminContatoActionAddComponent = modalRef.componentInstance;
    view.viewType = ViewTypeEnum.ADD;
    modalRef.result.then((result) => {
      if (result?.ok) {
        this.OnInitInvokeService();
        if (result?.repeat) {
          this.OnAddElement();
        }
      }
    });
  }
  // #endregion

  // #region #oneditrow.
  // CODGEN {{R}}
  OnEditRow(row: ContatoBean) {
    const modalRef = Utilities.OpenNewModal(this.modalService, AdminContatoActionAddComponent, 'tpx-custom-modal');
    const view: AdminContatoActionAddComponent = modalRef.componentInstance;
    view.viewType = ViewTypeEnum.READ;
    view.registro = row;
    modalRef.result.then((result) => {
      if (result?.ok) {
        this.OnInitInvokeService();
      }
    });
  }
  // #endregion

  // #region #onactivatedatatable.
  // CODGEN {{R}}
  OnActivateDatatable(event) {
    if (event.type === 'dblclick') {
      event.cellElement.blur();
      this.OnEditRow(event.row);
    }
  }
  // #endregion

  // #region #onincluiopcionalenderecochange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionalenderecoChange(_evt) {}
  // #endregion

  // #region #onincluiopcionaldadoscontatochange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionaldadoscontatoChange(_evt) {}
  // #endregion

  // #region #onincluiopcionaldadosfiscaischange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionaldadosfiscaisChange(_evt) {}
  // #endregion

  // #region #onmostrarativas.
  // CODGEN {{R}}
  OnMostrarAtivas(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    this.checkedTodas = input.checked;
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onmenuclickcontato.
  // CODGEN {{R}}
  OnMenuClickcontato($event: MouseEvent, row: ContatoBean, cmd: string): void {
    if ($event) $event.stopImmediatePropagation();
    //console.log('OnMenuClickcontato:', cmd);
    switch (cmd) {
      case 'editar':
        this.OnEditRow(row);
        break;
      case 'inativar':
        SelectOptionUtils.sendMenuCommand(this, 'menu.clickcontato.inativar', row.idcontato, () => {
          this.OnInitInvokeService();
        });
        break;
      case 'ativar':
        SelectOptionUtils.sendMenuCommand(this, 'menu.clickcontato.ativar', row.idcontato, () => {
          this.OnInitInvokeService();
        });
        break;
      case 'excluir':
        this.OnDeleteRow(row);
        break;
    }
  }
  // #endregion

  // #region #event-preventclose.
  // CODGEN {{R}}
  /**
   *
   * @param event Permite que evento de click funcione em um controle dentro de outro
   */
  preventClose($event: MouseEvent) {
    $event.stopImmediatePropagation();
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
