// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DateMaskDirective } from 'src/app/core/directives/date-mask.directive';
import { NumberOnlyDirective } from 'src/app/core/directives/number-only.directive';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { StatusEnumRepo } from 'src/app/core/models/StatusEnum';
import { TipoPessoaEnum, TipoPessoaEnumRepo } from 'src/app/core/models/TipoPessoaEnum';
import { DadosContatoBeanFragmentComponent } from '../dados-contato-bean/dados-contato-bean-fragment.component';
import { DadosFiscaisBeanFragmentComponent } from '../dados-fiscais-bean/dados-fiscais-bean-fragment.component';
import { EnderecoBeanFragmentComponent } from '../endereco-bean/endereco-bean-fragment.component';
import { TipoContatoEnumRepo, TipoContatoEnum } from '../../../models/TipoContatoEnum';
import { Utilities } from 'src/app/core/services/utilities';
import { CharCountDirective } from 'src/app/core/directives/char-count.directive';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-contato-bean-fragment',
  templateUrl: './contato-bean-fragment.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    DateMaskDirective,
    NumberOnlyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    EnderecoBeanFragmentComponent,
    DadosContatoBeanFragmentComponent,
    DadosFiscaisBeanFragmentComponent,
    CharCountDirective,
  ],
  providers: [provideNgxMask()],
})
export class ContatoBeanFragmentComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  toogle_endereco = false;
  toogle_dados_fiscais = false;
  toogle_dados_contato = false;
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  tipopessoaList: SelectOptionItem[] = TipoPessoaEnumRepo.optionList();
  tipocontatoList: SelectOptionItem[] = TipoContatoEnumRepo.optionList();
  @ViewChild('vendereco', { static: true }) childEndereco: EnderecoBeanFragmentComponent;
  @ViewChild('vdadocontato', { static: true }) childDadocontato: DadosContatoBeanFragmentComponent;
  @ViewChild('vdadofiscal', { static: true }) childDadofiscal: DadosFiscaisBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN
  SetValoresDefault(): void {
    this.f.tipopessoa.patchValue(TipoPessoaEnum.PF);
    this.f.tipocontato.patchValue(TipoContatoEnum.CLIENTE);
    this.RefreshOpcionais();
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      tipopessoa: [null, Validators.required],
      tipocontato: [null, Validators.required],
      nome: [null, [Validators.required, Validators.maxLength(200)]],
      observacoes: [null, Validators.maxLength(500)],
      incluiopcionalendereco: [null],
      endereco: this.formBuilder.group(this.childEndereco.CrudFormValidators(this.rootformid, 'endereco')),
      incluiopcionaldadoscontato: [null],
      dadocontato: this.formBuilder.group(this.childDadocontato.CrudFormValidators(this.rootformid, 'dadocontato')),
      incluiopcionaldadosfiscais: [null],
      dadofiscal: this.formBuilder.group(this.childDadofiscal.CrudFormValidators(this.rootformid, 'dadofiscal')),
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childEndereco.CrudFormGroupValidators(validators);
    this.childDadocontato.CrudFormGroupValidators(validators);
    this.childDadofiscal.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      tipopessoa: this.CrudForm.get('tipopessoa').value,
      tipocontato: this.CrudForm.get('tipocontato').value,
      nome: this.CrudForm.get('nome').value,
      observacoes: this.CrudForm.get('observacoes').value,
      incluiopcionalendereco: this.CrudForm.get('incluiopcionalendereco').value,
      endereco: this.childEndereco.CrudFormToRegistro(),
      incluiopcionaldadoscontato: this.CrudForm.get('incluiopcionaldadoscontato').value,
      dadocontato: this.childDadocontato.CrudFormToRegistro(),
      incluiopcionaldadosfiscais: this.CrudForm.get('incluiopcionaldadosfiscais').value,
      dadofiscal: this.childDadofiscal.CrudFormToRegistro(),
    };
    return reg;
  }
  // #endregion

  // #region #onincluiopcionalenderecochange.
  // CODGEN
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionalenderecoChange(_evt) {
    this.RefreshOpcionais();
  }
  // #endregion

  // #region #onincluiopcionaldadoscontatochange.
  // CODGEN
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionaldadoscontatoChange(_evt) {
    this.RefreshOpcionais();
  }
  // #endregion

  // #region #onincluiopcionaldadosfiscaischange.
  // CODGEN
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionaldadosfiscaisChange(_evt) {
    this.RefreshOpcionais();
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion

  // #region #declarations#.
  toggleSection(name: string): void {
    switch (name) {
      case 'A':
        this.toogle_endereco = !this.toogle_endereco;
        break;
      case 'B':
        this.toogle_dados_contato = !this.toogle_dados_contato;
        break;
      case 'C':
        this.toogle_dados_fiscais = !this.toogle_dados_fiscais;
        break;
    }
    this.RefreshOpcionais();
  }
  RefreshOpcionais(): void {
    if (this.f.incluiopcionalendereco?.value) {
      this.f.endereco.enable();
    } else {
      if (Utilities.hasNonNullOrNonEmptyProperties(this.f.endereco.value)) {
        this.f.incluiopcionalendereco?.patchValue(true);
      } else {
        this.f.endereco.disable();
      }
    }
    if (this.f.incluiopcionaldadoscontato?.value) {
      this.f.dadocontato.enable();
    } else {
      if (Utilities.hasNonNullOrNonEmptyProperties(this.f.dadocontato.value)) {
        this.f.incluiopcionaldadoscontato?.patchValue(true);
      } else {
        this.f.dadocontato.disable();
      }
    }
    if (this.f.incluiopcionaldadosfiscais?.value) {
      this.f.dadofiscal.enable();
    } else {
      if (Utilities.hasNonNullOrNonEmptyProperties(this.f.dadofiscal.value)) {
        this.f.incluiopcionaldadosfiscais?.patchValue(true);
      } else {
        this.f.dadofiscal.disable();
      }
    }
  }
  // #endregion
}
