// {{GENERATE}}
// @Component
// @Modal
// @Route:/extrato/painel
// URL:/financeiro/extrato
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { PageResponse } from 'src/app/core/models/PageResponse';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ChartDataLabel } from '../../models/ChartDataLabel';
import { ExtratoLancamentoBean } from '../../models/ExtratoLancamentoBean';
import { ExtratoRequest } from '../../models/ExtratoRequest';
import { PeriodoExtrato } from '../../models/PeriodoExtrato';
import { SaldosResponse } from '../../models/SaldosResponse';
import { ExtratoContasPainelService } from '../extrato-contas-painel.service';

@Injectable()
export class ExtratoContasPainelImplService implements ExtratoContasPainelService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/financeiro/extrato/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/financeiro/extrato/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region metodo#get-extrato-conta.
  // CODGEN {{R}}
  GetExtratoConta(conta: ExtratoRequest): Observable<PageResponse<ExtratoLancamentoBean>> {
    const url = this.helper.ToURL('/financeiro/extrato/getextratoconta');
    const headers = this.helper.GetHeaders();
    return this.http.post<PageResponse<ExtratoLancamentoBean>>(url, conta, headers);
  }
  // #endregion

  // #region metodo#get-extrato-saldos.
  // CODGEN {{R}}
  GetExtratoSaldos(opcao: ExtratoRequest): Observable<SaldosResponse> {
    const url = this.helper.ToURL('/financeiro/extrato/getextratosaldos');
    const headers = this.helper.GetHeaders();
    return this.http.post<SaldosResponse>(url, opcao, headers);
  }
  // #endregion

  // #region metodo#get-movimento-full.
  // CODGEN {{R}}
  GetMovimentoFull(periodo: PeriodoExtrato): Observable<ExtratoLancamentoBean[]> {
    const url = this.helper.ToURL('/financeiro/extrato/getmovimentofull');
    const headers = this.helper.GetHeaders();
    return this.http.post<ExtratoLancamentoBean[]>(url, periodo, headers);
  }
  // #endregion

  // #region metodo#get-movimento-chart.
  // CODGEN {{R}}
  GetMovimentoChart(opcao: ExtratoRequest): Observable<ChartDataLabel[]> {
    const url = this.helper.ToURL('/financeiro/extrato/getmovimentochart');
    const headers = this.helper.GetHeaders();
    return this.http.post<ChartDataLabel[]>(url, opcao, headers);
  }
  // #endregion

  // #region #declarations#.
  // #endregion
}
