<div class="modal-content boxshadow">
  <div class="modal-header">
    <div class="modal-title t-header-title">{{ title }}</div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    @if (!isImage) {
      <div class="overflow-auto" [innerHTML]="message"></div>
    }
    @if (isImage) {
      <img class="img-fluid" [src]="imagePath" />
    }
  </div>
  <div class="modal-footer">
    @if (IsTemCancel()) {
      <button type="button" ngbAutofocus class="btn btn-secondary px-4" (click)="decline()">
        <i class="fa fa-times"></i> {{ btnCancelText }}
      </button>
    }
    @if (IsTemConfirm()) {
      <button type="button" class="btn btn-primary px-4" (click)="accept()">
        {{ btnOkText }}
      </button>
    }
  </div>
</div>
