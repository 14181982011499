import { SelectOptionItem } from '../models/SelectOptionItem';
import { SelectOptionList } from '../models/SelectOptionList';
import { DialogType } from '../services/alert.service';
import { Utilities } from '../services/utilities';

export class SelectOptionUtils {
  /**
   * Força o ID para numeric
   * @param lista
   * @returns
   */
  public static TransformToNumericId(lista: SelectOptionItem[]): SelectOptionItem[] {
    if (lista) {
      lista.forEach((element) => {
        if (element.id && Utilities.TestIsString(element.id)) {
          element.id = parseInt(element.id.toString(), 10);
        }
      });
    }
    return lista;
  }

  public static SelectOptionItemSearchFn(term: string, item: SelectOptionItem): boolean {
    if (item) {
      return Utilities.searchArray(term, false, item.name);
    }
    return true;
  }

  public static ToSelectOptionItemList(selecteds: any[], name: string): SelectOptionItem[] {
    const items: SelectOptionItem[] = [];
    if (selecteds && selecteds.length > 0) {
      selecteds.forEach((element) => {
        items.push({
          id: element[name].toString(),
        });
      });
    }
    return items;
  }

  public static trataOnRemoveSelecao(componente: any, fieldname: string, serverid: string) {
    if (componente.gridSelectionSize() > 0) {
      componente.alertService.showDialog(
        'Excluir registros',
        'Confirme a exclusão dos registros selecionados?',
        DialogType.confirm,
        () => {
          const bean: SelectOptionList = {
            name: serverid,
            items: SelectOptionUtils.ToSelectOptionItemList(componente.gridSelectionArray(), fieldname),
          };
          componente.crudService.CmdSelectList(bean).subscribe(
            () => {
              componente.alertService.success('Registros excluídos.');
              componente.OnInitInvokeService();
            },
            (error) => {
              componente.alertService.error(error);
            },
          );
        },
        () => {
          console.log('Cancelado');
        },
      );
    }
  }

  public static sendMenuCommand(
    componente: any,
    cmd: string,
    id: string | number,
    completed: (n: SelectOptionList) => any,
    crudService: any = null,
  ) {
    const bean: SelectOptionList = {
      name: cmd,
      items: [{ id: id }],
    };
    const servico = crudService ? crudService : componente.crudService;
    if (typeof servico === 'undefined') {
      throw `Serviço - "crudService" não definido no componente. Comando: ${JSON.stringify(bean)}`;
    }
    servico.CmdSelectList(bean).subscribe({
      next: (data: SelectOptionList) => {
        completed(data);
      },
      error: (error) => {
        componente.alertService.error(error);
      },
    });
  }
}

export declare interface OnGridSelection<T> {
  gridSelectionSize(): number;

  gridSelectionArray(): T[];

  gridSelectionClear(): void;
}
