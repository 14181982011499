<div class="password-strength-wrapper">
  <ul class="password-strength-list">
    <li class="password-strength-item" [style.background-color]="bar0"></li>
    <li class="password-strength-item" [style.background-color]="bar1"></li>
    <li class="password-strength-item" [style.background-color]="bar2"></li>
  </ul>
</div>
@if (passwordToCheck || true) {
  <div class="password-validation-list">
    <ul class="password-validation-items">
      <li class="password-validation-item">
        <i class="icon" [class]="isLengthValid ? 'fa fa-fw fa-check' : 'fa fa-fw fa-times'"></i>
        <div l10nTranslate>PasswordStrengthComponent.MINIMO</div>
      </li>
      <li class="password-validation-item">
        <i class="icon" [class]="hasLetters ? 'fa fa-fw fa-check' : 'fa fa-fw  fa-times'"></i>
        <div l10nTranslate>PasswordStrengthComponent.LETRAS</div>
      </li>
      <li class="password-validation-item">
        <i class="icon" [class]="hasNumbers ? 'fa fa-fw fa-check' : 'fa fa-fw fa-times'"></i>
        <div l10nTranslate>PasswordStrengthComponent.NUMEROS</div>
      </li>
      <li class="password-validation-item">
        <i class="icon" [class]="hasSymbols ? 'fa fa-fw fa-check' : 'fa fa-fw fa-times'"></i>
        <div l10nTranslate>PasswordStrengthComponent.SIMBOLOS</div>
      </li>
    </ul>
  </div>
}
