<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form -->
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-status/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['status']" class="form-label" l10nTranslate>SmtpConfigFormBeanFragment.status</label>
      <ng-select
        [id]="fieldIdTable['status']"
        #status
        formControlName="status"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of statusList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>StatusEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-status -->
    <!-- #I-#field-host/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['host']" class="form-label" l10nTranslate>SmtpConfigFormBeanFragment.host</label>
      <input
        type="text"
        [id]="fieldIdTable['host']"
        formControlName="host"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.host.invalid && (f.host.dirty || f.host.touched) }"
        maxlength="256"
      />
      @if (f.host.errors) {
        <div class="invalid-feedback">
          @if (f.host.errors.required) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.host-REQUIRED</div>
          }
          @if (f.host.errors.maxlength) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.host-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-host -->
    <!-- #I-#field-port/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['port']" class="form-label" l10nTranslate>SmtpConfigFormBeanFragment.port</label>
      <input
        type="text"
        [id]="fieldIdTable['port']"
        formControlName="port"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.port.invalid && (f.port.dirty || f.port.touched) }"
        numberOnly
        maxlength="10"
      />
      @if (f.port.errors) {
        <div class="invalid-feedback">
          @if (f.port.errors.required) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.port-REQUIRED</div>
          }
          @if (f.port.errors.maxlength) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.port-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-port -->
    <!-- #I-#field-user/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['user']" class="form-label" l10nTranslate>SmtpConfigFormBeanFragment.user</label>
      <input
        type="text"
        [id]="fieldIdTable['user']"
        formControlName="user"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.user.invalid && (f.user.dirty || f.user.touched) }"
        maxlength="256"
      />
      @if (f.user.errors) {
        <div class="invalid-feedback">
          @if (f.user.errors.required) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.user-REQUIRED</div>
          }
          @if (f.user.errors.maxlength) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.user-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-user -->
    <!-- #I-#field-password -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['password']" class="form-label" l10nTranslate
        >SmtpConfigFormBeanFragment.password</label
      >
      <input
        type="{{ showPassword ? 'text' : 'password' }}"
        [id]="fieldIdTable['password']"
        formControlName="password"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.password.invalid && (f.password.dirty || f.password.touched) }"
        autocomplete="current-password"
        maxlength="50"
      />
      <span class="p-viewer">
        <i
          (click)="showPassword = !showPassword"
          [class]="showPassword ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
        ></i>
      </span>
      @if (f.password.errors) {
        <div class="invalid-feedback">
          @if (f.password.errors.required) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.password-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-password -->
    <!-- #I-#field-fromname/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['fromname']" class="form-label" l10nTranslate
        >SmtpConfigFormBeanFragment.fromname</label
      >
      <input
        type="text"
        [id]="fieldIdTable['fromname']"
        formControlName="fromname"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.fromname.invalid && (f.fromname.dirty || f.fromname.touched) }"
        maxlength="256"
      />
      @if (f.fromname.errors) {
        <div class="invalid-feedback">
          @if (f.fromname.errors.required) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.fromname-REQUIRED</div>
          }
          @if (f.fromname.errors.maxlength) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.fromname-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-fromname -->
    <!-- #I-#field-contextpath/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['contextpath']" class="form-label" l10nTranslate
        >SmtpConfigFormBeanFragment.contextpath</label
      >
      <input
        type="text"
        [id]="fieldIdTable['contextpath']"
        formControlName="contextpath"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.contextpath.invalid && (f.contextpath.dirty || f.contextpath.touched) }"
        maxlength="256"
      />
      @if (f.contextpath.errors) {
        <div class="invalid-feedback">
          @if (f.contextpath.errors.required) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.contextpath-REQUIRED</div>
          }
          @if (f.contextpath.errors.maxlength) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.contextpath-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-contextpath -->
    <!-- #I-#field-tags/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['tags']" class="form-label" l10nTranslate>SmtpConfigFormBeanFragment.tags</label>
      <input
        type="text"
        [id]="fieldIdTable['tags']"
        formControlName="tags"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.tags.invalid && (f.tags.dirty || f.tags.touched) }"
        maxlength="256"
      />
      @if (f.tags.errors) {
        <div class="invalid-feedback">
          @if (f.tags.errors.maxlength) {
            <div l10nTranslate>SmtpConfigFormBeanFragment.tags-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-tags -->
    <!-- #I-#field-flgstarttls/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          formControlName="flgstarttls"
          class="form-check-input"
          [id]="fieldIdTable['ck-flgstarttls']"
          (change)="OnFlgstarttlsChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-flgstarttls']" l10nTranslate
          >SmtpConfigFormBeanFragment.flgstarttls</label
        >
      </div>
    </div>
    <!-- #E-#field-flgstarttls -->
    <!-- #I-#field-flgauth/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          formControlName="flgauth"
          class="form-check-input"
          [id]="fieldIdTable['ck-flgauth']"
          (change)="OnFlgauthChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-flgauth']" l10nTranslate
          >SmtpConfigFormBeanFragment.flgauth</label
        >
      </div>
    </div>
    <!-- #E-#field-flgauth -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
