// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { AuthorityEnumRepo } from 'src/app/core/models/AuthorityEnum';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { StatusEnumRepo } from 'src/app/core/models/StatusEnum';
import { Utilities } from 'src/app/core/services/utilities';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-tb-user-bean-fragment',
  templateUrl: './tb-user-bean-fragment.component.html',
  imports: [
    CommonModule,
    FormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
})
export class TbUserBeanFragmentComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  protected fieldIdTable = {};
  // #endregion

  private instanceId = Constants.GetId();
  private rootformid: string;
  private myname: string;
  public contexto = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  rolesList: SelectOptionItem[] = AuthorityEnumRepo.optionList();
  statusList: SelectOptionItem[] = StatusEnumRepo.optionList();

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      email: [null, [Validators.email, Validators.required]],
      name: [null, [Validators.required, Validators.maxLength(256)]],
      phone: [null],
      roles: [null, Validators.required],
      status: [null, Validators.required],
      username: [null, [Validators.required, Validators.maxLength(256)]],
      emailnotices: [null],
      whatsappnotice: [null],
      telegrannotice: [null],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      email: this.CrudForm.get('email').value,
      name: this.CrudForm.get('name').value,
      phone: Utilities.unmask(this.CrudForm.get('phone').value),
      roles: this.CrudForm.get('roles').value,
      status: this.CrudForm.get('status').value,
      username: this.CrudForm.get('username').value,
      emailnotices: this.CrudForm.get('emailnotices').value,
      whatsappnotice: this.CrudForm.get('whatsappnotice').value,
      telegrannotice: this.CrudForm.get('telegrannotice').value,
    };
    return reg;
  }
  // #endregion

  // #region #onemailnoticeschange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnEmailnoticesChange(_evt) {}
  // #endregion

  // #region #onwhatsappnoticechange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnWhatsappnoticeChange(_evt) {}
  // #endregion

  // #region #ontelegrannoticechange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnTelegrannoticeChange(_evt) {}
  // #endregion

  // #region #routine-definitions#.
  // #endregion
  // #region #declarations#.
  // #endregion
}
