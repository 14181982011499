// {{GENERATE}}
// @CRUD
// @GRID:card
// @Route:/admin/contato/action
// URL:/admin/contato
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { GridModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { ContatoBean } from '../../../models/ContatoBean';
import { AdminContatoActionService } from '../../../services/admin-contato-action.service';
import { ContatoBeanFragmentComponent } from '../contato-bean/contato-bean-fragment.component';
import { Utilities } from 'src/app/core/services/utilities';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-admin-contato-action-card',
  templateUrl: './admin-contato-action-card.component.html',
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    AdminContatoActionCardComponent,
    ContatoBeanFragmentComponent,
  ],
  providers: [],
})
export class AdminContatoActionCardComponent implements OnInit, GridModelComponent {
  // #region #properties#.
  // #endregion

  @Input() registro: ContatoBean;
  @Output() onOk = new EventEmitter<{ cmd: string; node: ContatoBean }>();
  public displayedColumns: string[] = ['nome', 'observacoes', 'action'];
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: AdminContatoActionService,
    public translation: L10nTranslationService,
  ) {
    //
  }
  // #endregion

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #displayfield.
  // CODGEN
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DisplayField(fieldname: string, value, _row: ContatoBean) {
    switch (fieldname) {
      case 'contato':
        return value;
      case 'observacoes':
        return Utilities.truncateString(value, 120);
      default:
        return value;
    }
  }
  // #endregion

  // #region #oninvokecardevent.
  // CODGEN {{R}}
  OnInvokeCardEvent(cmd: string): void {
    this.onOk.emit({ cmd: cmd, node: this.registro });
  }
  // #endregion

  // #region #onincluiopcionalenderecochange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionalenderecoChange(_evt) {}
  // #endregion

  // #region #onincluiopcionaldadoscontatochange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionaldadoscontatoChange(_evt) {}
  // #endregion

  // #region #onincluiopcionaldadosfiscaischange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnIncluiopcionaldadosfiscaisChange(_evt) {}
  // #endregion

  // #region #onmenuclickcontato.
  // CODGEN {{R}}
  OnMenuClickcontato($event: MouseEvent, cmd: string): void {
    $event.stopImmediatePropagation();
    //console.log('OnMenuClickcontato:', cmd);
    this.OnInvokeCardEvent(cmd);
  }
  // #endregion

  // #region #event-preventclose.
  // CODGEN {{R}}
  /**
   *
   * @param event Permite que evento de click funcione em um controle dentro de outro
   */
  preventClose($event: MouseEvent) {
    $event.stopImmediatePropagation();
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
