import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LRUCache } from 'lru-cache';
import { Observable, catchError, map, of } from 'rxjs';
import { SelectOptionChangeEvent } from 'src/app/core/accessors/select-value.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { EntityDescription, EntityDescriptionItem } from '../models/EntityDescriptionItem';
import { EntityDescriptionRequest } from '../models/EntityDescriptionRequest';
import { EntityKey } from '../models/EntityKey';
import { TipoEntityEnum } from '../models/TipoEntityEnum';
import { TipoEntityRequest } from '../models/TipoEntityRequest';
import { CategoriaActionService } from './categoria-action.service';

@Injectable({
  providedIn: 'root',
})
export class EntityDescriptionService implements OnInit, OnDestroy {
  private cache: LRUCache<string, EntityDescriptionItem>;

  constructor(
    private crudService: CategoriaActionService,
    private alertService: AlertService,
  ) {
    this.cache = new LRUCache<string, EntityDescriptionItem>({
      max: 500,
      ttl: 1000 * 60 * 60, // Time-to-live for cache entries (1 hour)
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.cache.clear();
  }

  private generateCacheKey(key: EntityKey): string {
    return `${key.tipo}-${key.id}`;
  }

  // Adicionar um item ao cache
  addItem(item: EntityDescriptionItem): void {
    if (item.key) {
      const cacheKey = this.generateCacheKey(item.key);
      this.cache.set(cacheKey, item);
    }
  }

  addItemList(items: EntityDescriptionItem[]): void {
    if (items && items.length > 0) {
      items.forEach((item) => {
        this.addItem(item);
      });
    }
  }

  addItemSelect(item: SelectOptionChangeEvent): void {
    if (item && item.table && item.items && item.items.length > 0) {
      const tipo: TipoEntityEnum = EntityDescription.stringToEnum(item.table);
      for (let i = 0; i < item.items.length; i++) {
        const x = item.items[i];
        const val: EntityDescriptionItem = { key: { tipo: tipo, id: Number(x.id) }, descricao: x.name };
        if (tipo == TipoEntityEnum.TAG) {
          try {
            const jsonObject = JSON.parse(x.tag);
            val['cor'] = jsonObject.labelcor;
          } catch (error) {
            console.error('Invalid JSON string:', error);
          }
        }
        this.addItem(val);
      }
    }
  }

  // Obter um item do cache
  getEntityDescription(key: EntityKey): Observable<EntityDescriptionItem | undefined> {
    const cacheKey = this.generateCacheKey(key);
    const cachedItem = this.cache.get(cacheKey);
    if (cachedItem) {
      return of(cachedItem);
    } else {
      const request: EntityDescriptionRequest = { todos: false, opcoes: [{ tipo: key.tipo, ids: [key.id] }] };
      this.crudService.GetEntityDescription(request).pipe(
        map((response) => {
          if (response.items && response.items.length > 0) {
            for (const node in response.items) {
              this.addItem(node as EntityDescriptionItem);
            }
            return response.items[0];
          }
          return of(undefined);
        }),
        catchError((error) => {
          this.alertService.error(error);
          return of(undefined);
        }),
      );
    }
  }

  // Verificar se um item existe no cache
  hasItem(key: EntityKey): boolean {
    const cacheKey = this.generateCacheKey(key);
    return this.cache.has(cacheKey);
  }

  // Remover um item do cache
  removeItem(key: EntityKey): void {
    const cacheKey = this.generateCacheKey(key);
    this.cache.delete(cacheKey);
  }

  // Limpar todo o cache
  clearCache(): void {
    this.cache.clear();
  }

  getCachedItemList(keylist: EntityKey[]): EntityDescriptionItem[] {
    const cachedItems: EntityDescriptionItem[] = [];
    keylist.forEach((key) => {
      const cacheKey = this.generateCacheKey(key);
      const cachedItem = this.cache.get(cacheKey);
      if (cachedItem) {
        cachedItems.push(cachedItem);
      } else {
        cachedItems.push({ key: key, descricao: '...' });
      }
    });
    return cachedItems;
  }

  getItemList(keylist: EntityKey[]): Observable<EntityDescriptionItem[] | undefined> {
    const cachedItems: EntityDescriptionItem[] = [];
    const keysToFetch: EntityKey[] = [];
    keylist.forEach((key) => {
      const cacheKey = this.generateCacheKey(key);
      const cachedItem = this.cache.get(cacheKey);
      if (cachedItem) {
        cachedItems.push(cachedItem);
      } else {
        keysToFetch.push(key);
      }
    });
    if (keysToFetch.length === 0) {
      return of(cachedItems);
    } else {
      const request: EntityDescriptionRequest = { todos: false, opcoes: [] };
      this.agrupa(keysToFetch).forEach((elm) => {
        request.opcoes.push(elm);
      });
      this.crudService.GetEntityDescription(request).pipe(
        map((response) => {
          const fetchedItems: EntityDescriptionItem[] = [];
          if (response.items) {
            response.items.forEach((node) => {
              this.addItem(node as EntityDescriptionItem);
              fetchedItems.push(node);
            });
          }
          return [...cachedItems, ...fetchedItems];
        }),
        catchError((error) => {
          this.alertService.error(error);
          return of(undefined);
        }),
      );
    }
  }

  private agrupa(keys: EntityKey[]): TipoEntityRequest[] {
    const result: TipoEntityRequest[] = [];
    const mapa = {};
    if (keys && keys.length > 0) {
      keys.forEach((key) => {
        if (key) {
          if (!mapa[key.tipo]) {
            mapa[key.tipo] = { tipo: key.tipo, x: { tipo: key.tipo, ids: [] } };
          }
          mapa[key.tipo].x.ids.push(key.id);
        }
      });
      for (const elm of Object.keys(mapa)) {
        result.push(mapa[elm].x);
      }
    }
    return result;
  }
}
