<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form -->
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-email/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['email']" class="form-label" l10nTranslate>TbUserBeanFragment.email</label>
      <input
        type="text"
        [id]="fieldIdTable['email']"
        formControlName="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched) }"
      />
      @if (f.email.errors) {
        <div class="invalid-feedback">
          @if (f.email.errors.email) {
            <div l10nTranslate>TbUserBeanFragment.email-EMAIL</div>
          }
          @if (f.email.errors.required) {
            <div l10nTranslate>TbUserBeanFragment.email-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-email -->
    <!-- #I-#field-name/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['name']" class="form-label" l10nTranslate>TbUserBeanFragment.name</label>
      <input
        type="text"
        [id]="fieldIdTable['name']"
        formControlName="name"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.name.invalid && (f.name.dirty || f.name.touched) }"
        maxlength="256"
      />
      @if (f.name.errors) {
        <div class="invalid-feedback">
          @if (f.name.errors.required) {
            <div l10nTranslate>TbUserBeanFragment.name-REQUIRED</div>
          }
          @if (f.name.errors.maxlength) {
            <div l10nTranslate>TbUserBeanFragment.name-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-name -->
    <!-- #I-#field-phone/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['phone']" class="form-label" l10nTranslate>TbUserBeanFragment.phone</label>
      <input
        type="text"
        [id]="fieldIdTable['phone']"
        formControlName="phone"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.phone.invalid && (f.phone.dirty || f.phone.touched) }"
        mask="{{ masks.ngx.cellphoneMask }}"
      />
    </div>
    <!-- #E-#field-phone -->
    <!-- #I-#field-roles -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['roles']" class="form-label" l10nTranslate>TbUserBeanFragment.roles</label>
      <ng-select
        #roles
        formControlName="roles"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
        [closeOnSelect]="true"
        [multiple]="true"
      >
        @for (item of rolesList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>AuthorityEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-roles -->
    <!-- #I-#field-status/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['status']" class="form-label" l10nTranslate>TbUserBeanFragment.status</label>
      <ng-select
        [id]="fieldIdTable['status']"
        #status
        formControlName="status"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of statusList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>StatusEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-status -->
    <!-- #I-#field-username -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['username']" class="form-label" l10nTranslate>TbUserBeanFragment.username</label>
      <input
        type="text"
        [id]="fieldIdTable['username']"
        formControlName="username"
        autocomplete="username"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.username.invalid && (f.username.dirty || f.username.touched) }"
        maxlength="256"
      />
      @if (f.username.errors) {
        <div class="invalid-feedback">
          @if (f.username.errors.required) {
            <div l10nTranslate>TbUserBeanFragment.username-REQUIRED</div>
          }
          @if (f.username.errors.maxlength) {
            <div l10nTranslate>TbUserBeanFragment.username-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-username -->
    <!-- #I-#field-emailnotices/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          formControlName="emailnotices"
          class="form-check-input"
          [id]="fieldIdTable['ck-emailnotices']"
          (change)="OnEmailnoticesChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-emailnotices']" l10nTranslate
          >TbUserBeanFragment.emailnotices</label
        >
      </div>
    </div>
    <!-- #E-#field-emailnotices -->
    <!-- #I-#field-whatsappnotice/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          formControlName="whatsappnotice"
          class="form-check-input"
          [id]="fieldIdTable['ck-whatsappnotice']"
          (change)="OnWhatsappnoticeChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-whatsappnotice']" l10nTranslate
          >TbUserBeanFragment.whatsappnotice</label
        >
      </div>
    </div>
    <!-- #E-#field-whatsappnotice -->
    <!-- #I-#field-telegrannotice/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          formControlName="telegrannotice"
          class="form-check-input"
          [id]="fieldIdTable['ck-telegrannotice']"
          (change)="OnTelegrannoticeChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-telegrannotice']" l10nTranslate
          >TbUserBeanFragment.telegrannotice</label
        >
      </div>
    </div>
    <!-- #E-#field-telegrannotice -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
