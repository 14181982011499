import { NgClass } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AlertService, DialogType } from 'src/app/core/services/alert.service';
import { AdminUserActionService } from 'src/app/modules/admin/services/admin-user-action.service';
import { NumberOnlyDirective } from '../../directives/number-only.directive';
import { Constants } from '../../helpers/constants';
import { BagParameterObject } from '../../models/BagParameter';
import { RecuperacaoSenhaBean } from '../../models/RecuperacaoSenhaBean';
import { PasswordStrongValidator } from '../../helpers/password-strong.validator';
import { PasswordValidation } from '../../helpers/password-validation';
import { Utilities } from '../../services/utilities';

@Component({
  selector: 'app-esqueceu-senha',
  templateUrl: './esqueceu-senha.component.html',
  styleUrls: ['./esqueceu-senha.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgxCaptchaModule,
    NumberOnlyDirective,
  ],
})
export class EsqueceuSenhaComponent implements OnInit {
  submitted = false;
  loginForm: FormGroup;
  returnUrl: string;
  siteKey = '6LdyhCUTAAAAAI3zcUNKjjo6B8LH2XeuJc06ZNSy';
  catchValid = false;
  masks = Constants;
  private instanceId = Constants.GetId();
  public workflow: RecuperacaoSenhaBean;
  public wbag: BagParameterObject = new BagParameterObject();
  showPassword2 = false;
  showPassword3 = false;
  protected fieldIdTable = {};

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminUserActionService: AdminUserActionService,
    private alertService: AlertService,
    public translation: L10nTranslationService,
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  GetId(id: number | string): string {
    //private instanceId = Constants.GetId();
    return id + '-' + this.instanceId;
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      codigo: ['', [Validators.minLength(7), Validators.required]],
      senha: [null, [Validators.required, PasswordStrongValidator.Strong]],
      resenha: [null, [Validators.required, PasswordValidation.MustMatch('senha')]],
      recaptcha: ['', Validators.required],
    });
    this.fieldIdTable = Utilities.createFieldIdTable(this.loginForm, this.instanceId);
    this.workflow = {
      passo: 0,
    };
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;
    const bean: RecuperacaoSenhaBean = {
      username: this.loginForm.get('username').value,
      //email: this.loginForm.get('email').value,
      //recaptcha: this.loginForm.get('recaptcha').value,
    };
    this.adminUserActionService.RecuperacaoSenha(bean).subscribe({
      next: () => {
        this.finalizaTroca();
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  finalizaTroca() {
    this.alertService.showDialog(
      'Obrigado',
      'A senha foi enviada para o E-mail cadastrado, ...',
      DialogType.confirm,
      () => {
        this.backToLogin();
      },
      () => {
        //
      },
      'Ok',
      null,
    );
  }

  handleExpire() {
    //console.log('handleExpire');
    this.catchValid = false;
  }
  handleLoad() {
    //console.log('handleLoad');
  }

  handleSuccess(event: any) {
    //console.log('handleSuccess:' + event);
    this.catchValid = true;
  }

  handleReset() {
    //console.log('handleReset');
  }

  backToLogin() {
    this.router.navigate([this.returnUrl]);
  }

  inputHandleCode(event): void {
    var number = event.target.value;
    if (number.length >= 8) {
      this.toPasso(3);
    }
  }
  passoValido(passo: number): boolean {
    switch (passo) {
      case 0:
        return this.f.username.valid; // && this.catchValid;
      case 1:
        return true;
      case 2:
        return this.f.codigo.valid;
      case 3:
        return this.f.senha.valid && this.f.resenha.valid;
    }
    return false;
  }

  toPasso(passo: number): void {
    switch (passo) {
      case 1:
        this.workflow.passo = 0;
        this.workflow.username = this.loginForm.get('username').value;
        this.onSendServer();
        break;
      case 2:
        this.workflow.passo = 1;
        this.workflow.username = this.loginForm.get('username').value;
        this.onSendServer();
        break;
      case 3:
        this.workflow.passo = 2;
        this.workflow.username = this.loginForm.get('username').value;
        this.wbag.setTextById('codigo', this.loginForm.get('codigo').value);
        this.workflow.parameter = this.wbag;
        this.onSendServer();
        break;
      case 4:
        this.workflow.passo = 3;
        this.workflow.username = this.loginForm.get('username').value;
        this.wbag.setTextById('codigo', this.loginForm.get('codigo').value);
        this.wbag.setTextById('senha', this.loginForm.get('senha').value);
        this.wbag.setTextById('resenha', this.loginForm.get('resenha').value);
        this.workflow.parameter = this.wbag;
        this.onSendServer();
        break;
    }
  }

  onSendServer(): void {
    this.workflow.username = this.loginForm.get('username').value;
    this.adminUserActionService.RecuperacaoSenha(this.workflow).subscribe({
      next: (data) => {
        if (data.passo >= 9) {
          this.backToLogin();
        }
        this.workflow.passo = data.passo;
        this.workflow.username = data.username;
        this.workflow.parameter = data.parameter;
        this.wbag.nodes = data.parameter.nodes;
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
}
