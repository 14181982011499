import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdItem } from './ad-item';
import { AdComponent } from './ad.component';
import { AdDirective } from './ad.directive';
import { AdDadosElement } from './ad-dados_element';

@Component({
  selector: 'app-painel-ad',
  templateUrl: './painel-ad.component.html',
  styleUrls: ['./painel-ad.component.css'],
  standalone: true,
  imports: [FormsModule],
})
export class PainelAdComponent implements OnInit, OnDestroy {
  @Input() ads: AdItem;

  @Output() onAdChange = new EventEmitter<AdDadosElement>();

  @ViewChild(AdDirective, { static: true }) adHost: AdDirective;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    //console.log('LOAD COMPONENTE', this.ads.data, this.ads.data.card.name);
    this.loadComponent();
  }

  ngOnDestroy(): void {}

  loadComponent() {
    const adItem = this.ads;
    const componentRef = this.viewContainerRef.createComponent(adItem.component);
    (<AdComponent>componentRef.instance).data = adItem.data;
    adItem.data.instance = <AdComponent>componentRef.instance;
    if (this.onAdChange) {
      adItem.data.instance.onAdChange = this.onAdChange;
    }
  }

  getAds() {
    setTimeout(() => {
      this.loadComponent();
    });
  }
}
