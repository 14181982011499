// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { ComplementaDados } from 'src/app/core/models/ComplementaDados';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { TbUserBean } from '../../../models/TbUserBean';
import { AdminUserActionService } from '../../../services/admin-user-action.service';
import { ChangePasswordFormComponent } from '../change-password-form/change-password-form.component';
import { EditarFotoPerfilFormComponent } from '../editar-foto-perfil-form/editar-foto-perfil-form.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgxMaskDirective,
    ChangePasswordFormComponent,
  ],
})
export class UserProfileFormComponent implements OnInit {
  // #region #properties#.
  @Output() onOk = new EventEmitter<void>();
  protected fieldIdTable = {};
  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();
  isModal: boolean = false;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private helper: HttpHelper,
    private currentService: AdminUserActionService,
    private alertService: AlertService,
    public translation: L10nTranslationService,
    private modalService: NgbModal,
  ) {
    //
  }

  OnNovoLaudoEvent(): void {
    throw new Error('Method not implemented.');
  }
  ValidateLaudo(): boolean {
    throw new Error('Method not implemented.');
  }
  ComplementaRequest(): Observable<ComplementaDados> {
    throw new Error('Method not implemented.');
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required, Validators.maxLength(256)]],
      name: [null, [Validators.required, Validators.maxLength(256)]],
      phone: [null],
      emailnotices: [null],
      whatsappnotice: [null],
      telegrannotice: [null],
    });
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.currentService.GetReadUser(this.helper.credentials.user.id).subscribe({
      next: (data) => {
        //console.log('GetReadUser:', JSON.stringify(data));
        this.loadData(data);
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  loadData(bean: TbUserBean): void {
    this.CrudForm.patchValue(bean);
  }

  ngOnDestroy(): void {}

  // #endregion

  // #region #onprocessar#method.
  // CODGEN
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
    const reg: TbUserBean = this.CrudFormToRegistro();
    reg.iduser = this.helper.credentials.user.id;
    this.currentService.UpdateUserProfie(reg).subscribe({
      next: (data) => {
        const nm = Utilities.getTextParameter(data.parameters, 'name');
        this.helper.credentials.user.name = nm;
        this.helper.refreshUser();
        this.OnCloseModal(true);
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.onOk.emit();
    this.activeModal.close({
      ok: status,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      email: this.CrudForm.get('email').value,
      name: this.CrudForm.get('name').value,
      phone: Utilities.unmask(this.CrudForm.get('phone').value),
      emailnotices: this.CrudForm.get('emailnotices').value,
      whatsappnotice: this.CrudForm.get('whatsappnotice').value,
      telegrannotice: this.CrudForm.get('telegrannotice').value,
    };
    return reg;
  }
  // #endregion

  // #region #onemailnoticeschange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnEmailnoticesChange(_evt) {}
  // #endregion

  // #region #onwhatsappnoticechange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnWhatsappnoticeChange(_evt) {}
  // #endregion

  // #region #ontelegrannoticechange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnTelegrannoticeChange(_evt) {}
  // #endregion

  // #region #opennewmodal.
  // CODGEN {{R}}
  static OpenNewModal(modalService: NgbModal): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, UserProfileFormComponent);
    const view: UserProfileFormComponent = modalRef.componentInstance;
    view.isModal = true;
    return modalRef;
  }
  // #endregion

  // #region #routine-definitions#.
  ImagemAvatar() {
    return { type: 'AVATAR', code: this.helper.getUser().id };
  }

  toggleFoto(): void {
    EditarFotoPerfilFormComponent.OpenNewModal(this.modalService);
  }
  // #endregion
}
