// password-strong.validator.ts

import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordStrongValidator {
  static Strong(control: FormControl): ValidationResult {
    const password = control.value;
    if (password) {
      const isLengthValid = password.length >= 8;
      const hasLetters = /[a-zA-Z]/.test(password);
      const hasNumbers = /\d/.test(password);
      const hasSymbols = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
      const valid = isLengthValid && hasLetters && hasNumbers && hasSymbols;
      if (!valid) {
        // return what´s not valid
        return { strong: true };
      }
    }
    return null;
  }
}
