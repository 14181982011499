// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoLimiteEnum {
  TOTAL = 'TOTAL',
  MENSAL = 'MENSAL',
  INDEFINIDO = 'INDEFINIDO',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoLimiteEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'TOTAL', name: 'TOTAL' },
      { id: 'MENSAL', name: 'MENSAL' },
      { id: 'INDEFINIDO', name: 'INDEFINIDO' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
