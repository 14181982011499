<!-- {{NOTGENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-srcdate/R -->
    <div class="col-sm-12">
      <label [for]="fieldIdTable['srcdate']" class="form-label" l10nTranslate>{{ Title }}</label>
      <div class="input-group">
        <input
          type="text"
          [id]="fieldIdTable['srcdate']"
          formControlName="srcdate"
          ngbDatepicker
          #dvisualctrl="ngbDatepicker"
          class="form-control"
          placeholder="dd/mm/aaaa"
          appMaskDate
          [ngClass]="{ 'is-invalid': f.srcdate.invalid && (f.srcdate.dirty || f.srcdate.touched) }"
        />
        <button class="btn btn-outline-secondary" (click)="dvisualctrl.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
      @if (f.srcdate.errors) {
        <div class="invalid-feedback">
          @if (f.srcdate.errors.validateoptionaldate) {
            <div l10nTranslate>TxDatePickerFragment.srcdate-VALIDATEOPTIONALDATE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-srcdate -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
