import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TxDate } from '../models/TxDate';

/**
 */
@Injectable()
export class TxDateJsonAdapter extends NgbDateAdapter<TxDate> {
  fromModel(ts: TxDate | null): NgbDateStruct | null {
    if (ts && ts.year && ts.month && ts.day) {
      const ndf = {
        year: ts.year,
        month: ts.month,
        day: ts.day,
      };
      return ndf;
    }
    return null;
  }

  toModel(ngbDate: NgbDateStruct | null): TxDate | null {
    if (ngbDate) {
      return ngbDate;
    } else {
      return null;
    }
  }
}
