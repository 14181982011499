<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-6">
    <div class="t-header-title mb-3">TERMOS DE USO</div>
    <p class="text-break tbx-justfy-text">
      Antes de prosseguir, leia os 14 principais itens abordados em nossos Termos de Uso: Todas as peças desta
      plataforma tratam-se de MODELOS INICIAIS e básicos que devem ser utilizados POR PROFISSIONAL HABILITADO - ADVOGADO
      para o seu cliente final, devendo ser revisados, adaptados ao caso concreto de acordo com fatos, provas,
      jurisprudência local, base legal atualizada e específica. SOB NENHUMA HIPÓTESE serão prestadas consultorias
      jurídicas. No PLANO GRATUITO, você concorda em conceder seu e-mail e se cadastrar na plataforma. O cadastro
      gratuito é limitado a uma conta por pessoa/entidade - não é permitido mais de um cadastro por pessoa/entidade. Em
      qualquer plano você autoriza o uso do conteúdo das áreas de seu interesse quando você usa nossos Serviços, através
      da COLETA DE DADOS DA SUA PESQUISA e acessos aos modelos e autoriza o envio deste conteúdo ao seu e-mail. No plano
      pago, a RENOVAÇÃO AUTOMÁTICA CONSENTIDA: Todos os planos pagos no cartão de crédito constituem contrato de
      assinatura renovável mensalmente por tempo indeterminado, com COBRANÇAS MENSAIS realizadas automaticamente no
      cartão de crédito indicado, até que ocorra o cancelamento pelo próprio usuário - situação na qual todas as
      cobranças futuras cessarão e o usuário perderá acesso à plataforma. O cadastro pago (PRO) é limitado a uma conta
      por pessoa/entidade - não é permitido mais de um cadastro por pessoa/entidade. Você pode CANCELAR A ASSINATURA a
      qualquer momento, sem qualquer custo adicional ou fidelidade. O cancelamento deverá ser feito pelo próprio usuário
      através do menu "Minha Assinatura". É responsabilidade do usuário certificar-se da VIGÊNCIA DA LEI e
      jurisprudência referida, bem como da adequação da peça à fase processual e riscos de improcedência. Não oferecemos
      garantia de que os modelos estão atualizados, adequados à legislação vigente ou qualquer responsabilidade pelo
      insucesso no uso dos modelos por qualquer razão, seja por se mostrarem inadequados, desatualizados ou impróprios
      ao caso específico. Antes de assinar esta plataforma, verifique se todas as peças de seu interesse estão
      disponíveis no campo de busca. Possíveis sugestões de inclusão de novos modelos serão analisadas, porém sem
      garantia de aceitação ou qualquer compromisso que serão disponibilizados. Você PODE utilizar o conteúdo desta
      plataforma exclusivamente para uso imediato em casos reais e concretos do seu cliente final, para fins
      profissionais no exercício da Advocacia no âmbito processual administrativo ou judicial. Você NÃO PODE baixar,
      armazenar, revender, divulgar, distribuir ou publicar mesmo que gratuitamente o conteúdo desta plataforma para
      fins não diretamente ligados a um caso concreto e imediato ligado ao processo do seu cliente final. Não é
      permitido baixar ou armazenar os conteúdos da plataforma simplesmente para uso futuro, sem a existência de um caso
      real e concreto. LGPD. Nós utilizamos cookies e outras tecnologias para melhorar a experiência do usuário, e
      podemos capturar, armazenar e processar suas informações pessoais. Você possui o direito de acessar, solicitar
      exclusão ou confirmar que nós processamos os seus dados pessoais, exceto quando obrigações legais ou tributárias
      venham a nos impedir. A gestão do seus dados é feita por XXXXXXXXXXXXXXXX., e você pode entrar em contato com a
      pessoa responsável pelos seus dados através do e-mail: XXXXXXXXXXXXXXXXXXXX. Você tem também o direito de contatar
      diretamente a Autoridade de Proteção de Dados brasileira. VEJA TERMO DE CONSENTIMENTO COMPLETO ABAIXO.
      Cancelamento compulsório. É a nós reservado o direito de efetuar o cancelamento ou bloqueio imediato e sem aviso
      prévio da sua conta (ou de acessos ligados direta ou indiretamente a você), seja ela paga ou gratuita, caso você
      não cumpra qualquer das clausulas presentes nestes termos. Nós podemos bloquear o acesso e solicitar informações
      adicionais aos usuários caso identifiquemos atividades suspeitas ou padrões de utilização fora do esperado para a
      proposta desta ferramenta. Qualquer dificuldade, impedimento, solicitação, sugestão ou reclamação pode ser feito
      pelo canal de COMUNICAÇÃO da plataforma, pelo e-mail XXXXXXXXXXX ou pelo telefone XXXXXXXXXXXXXXXXX. Ao prosseguir
      você concorda que ELEGE O FORO de Porto Alegre/RS para a solução de qualquer litígio decorrente desta relação. Por
      favor leia também nossa Política de Privacidade e nossos Termos de Uso completos para ficar ciente de todos os
      detalhes que envolvem o seu uso do sistema. Se você não concorda ou tem dúvidas sobre qualquer dos itens, por
      favor não prossiga, e entre em contato conosco para esclarecimentos. TERMOS DE USO DA PLATAFORMA Bem-vindo ao
      Portal Modelo Inicial! Agradecemos por usar nossa plataforma de compartilhamento de conteúdo. Ao usar a
      plataforma, você está concordando com estes termos. Leia-os com atenção. COMO USAR NOSSOS SERVIÇOS É preciso que
      você siga as políticas disponibilizadas a você dentro dos Portal. Não faça uso indevido de nossos Serviços. Por
      exemplo, não interfira com nossos Serviços nem tente acessá-los por um método diferente da interface e das
      instruções que fornecemos. Você pode usar nossos serviços somente conforme permitido por lei, inclusive
      regulamentos de controle de exportação e reexportação. Podemos suspender ou deixar de fornecer nossos Serviços se
      você descumprir nossos termos ou políticas ou se estivermos investigando casos de suspeita de má conduta. O uso de
      nossos Serviços não lhe confere a propriedade sobre direitos de propriedade intelectual sobre os nossos Serviços
      ou sobre o conteúdo que você acessar. Estes termos não conferem a você o direito de usar quaisquer marcas,
      conteúdo ou logotipos utilizados em nossos Serviços. Não remova, oculte ou altere quaisquer avisos legais exibidos
      em ou junto a nossos Serviços. Nossos Serviços exibem alguns conteúdos que não são elaborados ou fornecidos por
      este portal. Esses conteúdos são de exclusiva responsabilidade da entidade e usuários que os disponibilizam.
      Podemos revisar conteúdo para determinar se é ilegal ou se infringe nossas políticas, e podemos remover ou nos
      recusar a exibir conteúdos que razoavelmente acreditamos violar nossas políticas ou a lei. Mas isso não significa,
      necessariamente, que revisaremos todos os conteúdos, portanto por favor, não presuma que o faremos, não recaindo
      qualquer responsabilidade sobre o portal dos conteúdos compartilhados. Em relação com seu uso dos Serviços,
      podemos enviar-lhe anúncios de serviços, mensagens administrativas e outras informações. Você pode desativar estas
      comunicações a qualquer momento. Alguns dos nossos Serviços estão disponíveis em dispositivos móveis. O usuário
      não deve utilizar tais Serviços de forma que o distraia ou o impeça de cumprir leis de trânsito ou de segurança.
      SUA CONTA Para utilizar qualquer de nossos Serviços você precisa criar uma Conta através de cadastro de conta na
      plataforma, sendo necessário informar, entre outros dados, endereço de e-mail e senha pessoal e intransferível, ou
      através de acesso por meio de redes sociais ou plataformas já logadas no seu computador, como por exemplo
      Facebook, Gmail, etc. O cadastro autoriza o envio de atualizações da plataforma. O USUÁRIO é o único responsável,
      para todos os fins, pelas operações efetuadas em sua conta. Para proteger sua Conta, o usuário deve manter a senha
      em sigilo ou logout após cada sessão. A atividade realizada na Conta ou por seu intermédio é de responsabilidade
      do usuário. Não recomendamos que a senha da Conta seja reutilizada em aplicativos de terceiros ou computadores
      públicos. Caso tome conhecimento de uso não autorizado da sua senha ou Conta, o usuário deve notificar este portal
      para cancelamento imediato. RENOVAÇÃO AUTOMÁTICA CONSENTIDA Para total comodidade do usuário, a assinatura
      realizada por meio do cartão de crédito constitui contrato de assinatura renovável mensalmente por tempo
      indeterminado, com COBRANÇAS MENSAIS realizadas automaticamente no cartão de crédito indicado, até que ocorra o
      cancelamento pelo próprio usuário - situação na qual todas as cobranças futuras cessarão e o usuário perderá
      acesso à plataforma. DIREITOS AUTORAIS Ao utilizar nossos Serviços, você concorda respeitar nossas políticas de
      privacidade. Nós respondemos às notificações de alegação de violação de direitos autorais e encerramos contas de
      infratores reincidentes. Fornecemos informações para ajudar os detentores de direitos autorais a gerenciarem sua
      propriedade intelectual on-line. Caso você entenda que alguém está violando seus direitos autorais e quiser nos
      notificar, você deve notificar este Portal pelo portal de COMUNICAÇÃO ou pelo e-mail XXXXXXXXXXXXXXXX. DO CONTEÚDO
      EM NOSSOS SERVIÇOS - LICENÇA AUTORAL Alguns de nossos Serviços permitem que você faça upload, submeta, armazene,
      envie, publique ou receba conteúdo. Quando você faz upload, submete, armazena, envia, publica ou recebe conteúdo a
      nossos Serviços ou por meio deles, você concede ao Portal (e àqueles com quem trabalhamos) uma licença mundial sem
      direito a remuneração para usar, hospedar, armazenar, reproduzir, modificar, criar obras derivadas (como aquelas
      resultantes de traduções, adaptações ou outras alterações que fazemos para que seu conteúdo se adapte melhor às
      necessidades dos usuários), comunicar, publicar, executar e exibir publicamente e distribuir tal conteúdo. Essa
      licença permite a utilização de seu conteúdo comercialmente pelo Portal para atrair usuários, podendo refletir em
      benefícios financeiros ao Portal através de veiculação de publicidade, venda de assinaturas, cobrança por recursos
      específicos tais como visualização ou download do seu conteúdo em diferentes formatos, entre outras formas de
      remuneração que este Portal julgar pertinente, sem que você tenha direito a qualquer remuneração, e perdura mesmo
      que você deixe de usar nossos Serviços. Alguns Serviços podem oferecer-lhe modos de acessar e remover conteúdos
      que foram fornecidos para aquele Serviço. Além disso, em alguns de nossos Serviços, existem termos ou
      configurações que restringem o escopo de nosso uso do conteúdo enviado nesses Serviços. Certifique-se de que você
      tem os direitos necessários para nos conceder a licença de qualquer conteúdo que você enviar a nossos Serviços. Ao
      disponibilizar seu conteúdo você concorda expressamente com a ampla divulgação e utilização por parte dos usuários
      do Portal, sem qualquer contrapartida financeira, concedendo ao Portal uma licença perpétua, irrestrita e
      irrevogável, com validade internacional, isenta de royalties para usar, distribuir, exibir, modificar, editar,
      executar, vender, reproduzir, publicar, adaptar, traduzir e criar outros textos derivados do seu conteúdo em
      qualquer tipo e formato, mídia ou tecnologia. Nesse aspecto, cabe ao titular da conta cadastrar seus dados
      pessoais corretamente, em especial o de declarar que o conteúdo disponibilizado é de sua Autoria, ficando
      inteiramente responsável por ações e denúncias de plágio. As informações eventualmente disponíveis no Portal são
      extraídas automaticamente de diversas fontes na internet ou são publicadas no portal por seus usuários, que não
      mantém qualquer espécie de vínculo com a plataforma. FUNCIONAMENTO DA PLATAFORMA O Portal não se responsabilizará
      em nenhuma hipótese por quaisquer interrupções causadas pelo serviço de hospedagem do site ou manutenção, quedas
      de energia elétrica ou do sistema de comunicação, caso fortuito ou força maior. UTILIZAÇÃO DESTE CONTEÚDO Todo
      conteúdo disponibilizado no portal, de autoria da equipe Modelo Inicial ou dos usuários, pode ser utilizado única
      e exclusivamente para fins profissionais no exercício da Advocacia (uso no âmbito processual), ficando
      expressamente PROIBIDO a reprodução ou o armazenamento em banco de dados do conteúdo desta página em qualquer meio
      de comunicação, eletrônico ou impresso, sem autorização escrita do autor. O material postado nesta plataforma se
      trata de referências disponibilizadas pela equipe Modelo Inicial ou pelos próprios usuários, não esgotando o
      assunto, ao contrário, serve apenas como rota para que o profissional do direito possa se orientar. Devendo,
      portanto, se certificar sobre a vigência de normas indicadas, adequação da jurisprudência bem como sobre a
      aplicabilidade correta do material ao caso concreto. RESTRIÇÕES DE USO Quem pode utilizar a plataforma. Todo e
      qualquer acesso aos conteúdos desta plataforma deve ser feito exclusivamente por pessoas reais, seja de modo
      gratuito ou pago. É vedado o acesso aos conteúdos por robôs ou qualquer outro método automatizado ou
      semi-automatizado. É vedado o acesso à plataforma através de VPNs anônimas ou de origens não condizentes com
      atividade humana (ex: data-centers, etc). Apenas um cadastro é permitido por pessoa natural ou entidade, devendo
      esse cadastro obrigatoriamente ser feito, pago, acessado e administrado pela própria pessoa ou responsável legal
      pela entidade. Uma pessoa não pode possui um cadastro como pessoa física e outro como pessoa jurídica, mesmo que
      ela seja a responsável legal por tal entidade. Ao aceitar estes termos, você autoriza o contato pela equipe Modelo
      Inicial para certificar estas informações. Contas duplas pertencentes à mesma pessoa, utilização de dados falsos,
      incompletos ou de terceiros, utilização de VPNs, bloqueadores de conteúdo, plugins que alteram as funcionalidades
      básicas da plataforma, comportamentos ou acessos de origens fora do padrão normal humano, ou com acessos
      mecanizados, serão suspensas, podendo acarretar exclusão e banimento do usuário da plataforma. Identificação do
      usuário. Cada pessoa natural cadastrada deve identificar-se unicamente utilizando: O seu nome verdadeiro e
      completo e data de nascimento correta, conforme consta no cadastro do seu CPF. O seu e-mail profissional, conforme
      consta no cadastro da OAB ou que é utilizado para contatos profissionais. Não serão em hipótese alguma aceitos
      e-mails temporários, criados com finalidade específica de cadastro no site ou de uso não pessoal. Também não serão
      aceitos e-mails de domínios cuja propriedade não é identificável ou cujo site não existe ou utilizados unicamente
      para criar caixas de e-mail. O seu endereço comercial ou residencial completo e atual. O não atendimento das
      regras acima coloca em risco a integridade dos nossos serviços e poderá acarretar no bloqueio e/ou banimento do
      usuário da plataforma. Para qual finalidade pode ser utilizada a plataforma. Acessos, consultas, cópias e
      downloads de qualquer conteúdo do site, seja de modo gratuito ou pago, devem ser feitos exclusivamente com o
      intuito de exercer a atividade de advogado ou profissional devidamente habilitado em casos reais e concretos com o
      cliente final ou diretamente com o órgão/entidade/pessoa de destino final dos documentos. É vedado o uso da
      plataforma para criação de bancos de petições paralelos, sejam eles privados, compartilhados, públicos, pagos ou
      gratuitos. Todos os serviços e conteúdos devem ser utilizados exclusivamente para prática direta e imediata de
      cada caso, sendo vedado o armazenamento de longo prazo do conteúdo. Com qual frequência pode ser usada a
      plataforma. Cada usuário possui um limite diário ou semanal de petições que podem ser baixadas. Para garantir a
      segurança, estabilidade, performance e propósito de uso de nossos serviços, nós podemos limitar o número e a
      frequência com que cada usuário pode acessar ou baixar petições por hora, sem com isso prejudicar de modo algum o
      limite total diário contratado pelo usuário. Também podemos limitar a frequência com que cada usuário acessa
      outras páginas do sistema, incluindo mas não limitado a: consultas de jurisprudência, legislação, notícias e
      artigos. Não conformidade. Qualquer atividade realizada não condizente com tais premissas poderá ser bloqueada e o
      usuário banido do sistema. COMO MODIFICAR E CANCELAR NOSSOS SERVIÇOS O contrato firmado com o Modelo Inicial é por
      tempo indeterminado, podendo ser cancelado a qualquer momento sem custos adicionais. Para efetuar o cancelamento,
      basta clicar no link CANCELAR ASSINATURA na página Minha Assinatura, disponível em XXXXXXXXXXXXXXXXXXXX. Lembrando
      que, após o cancelamento, será necessário contratar uma assinatura novamente para acessar qualquer modelo. O valor
      do plano contratado será cobrado independente de quantos dias foram utilizados. ALTERAÇÕES NA PLATAFORMA Estamos
      constantemente alterando e melhorando nossos Serviços. Podemos incluir ou remover funcionalidades ou recursos e
      podemos também suspender ou encerrar um Serviço por completo. Você pode deixar de usar nossos Serviços a qualquer
      momento. O Portal também poderá deixar de prestar os Serviços, incluir ou criar novos limites a nossos Serviços a
      qualquer momento. Acreditamos que você seja o proprietário dos seus dados e que é importante preservar seu acesso
      a esses dados. NOSSAS GARANTIAS E ISENÇÕES DE RESPONSABILIDADE Fornecemos nossos Serviços usando um nível
      comercialmente razoável de capacidade e cuidado e esperamos que você aproveite seu uso. Mas existem algumas coisas
      que não prometemos sobre nossos Serviços. Exceto quando expressamente previsto nestes termos ou em termos
      adicionais, nem o portal, nem seus fornecedores ou distribuidores oferecem quaisquer garantias sobre os serviços
      ou sua continuidade. Por exemplo, não nos responsabilizamos pelos conteúdos nos serviços, por funcionalidades
      específicas do serviço, ou pela confiabilidade, disponibilidade ou capacidade de atender suas necessidades.
      Fornecemos os serviços "na forma em que estão". O portal e seus usuários não serão responsáveis por perda de
      lucros, perda de receita, perda de dados, perdas financeiras ou danos indiretos, especiais, consequenciais,
      exemplares ou punitivos. Os modelos disponibilizados no portal servem apenas como sugestão para o conteúdo a ser
      elaborado pelo profissional habilitado, respeitadas suas peculiaridades. A legislação, jurisprudência e doutrina
      citadas nessa base são usadas apenas a título de exemplo, sendo de total responsabilidade de quem utiliza. POSTURA
      ÉTICA E DE COMPLIANCE Em hipótese alguma os materiais compilados e disponibilizados nesta plataforma por
      profissionais ou estudantes visam ou tem o condão de substituir os profissionais habilitados ao exercício da
      profissão. Portanto não servem para orientar, assessorar ou substituir os atributos exclusivos dos Advogados.
      Nenhum dos dados coletados, disponibilizados ou publicados pelos usuários serão utilizados para captação de
      clientela para a Equipe Modelo Inicial. Toda e qualquer divulgação do material será previamente autorizada pelo
      Autor, revisada pela equipe e divulgada entre os usuários cadastrados. Quaisquer cobranças serão realizadas
      unicamente para manutenção do suporte ao sistema. DOS PLANOS E DO CONSUMO O Portal não se compromete a adequar os
      planos, funcionalidades ou ferramentas de seus serviços para atender objetivos específicos ou atingir resultados
      definidos pelo USUÁRIO. As funcionalidades e ferramentas do Portal disponíveis ao USUÁRIO estão vinculadas à sua
      conta de cadastro no serviço e são intransferíveis. Os modelos existentes na plataforma são de prévio conhecimento
      do USUÁRIO, não podendo ser exigida qualquer outra peça independente do assunto. Não é aplicável qualquer tipo de
      carência aos planos. É facultado ao USUÁRIO alterar o plano ao qual assina a qualquer tempo durante a vigência do
      vínculo. a) Na hipótese em que o USUÁRIO optar por assinar plano de valor superior ao plano vigente ao tempo da
      alteração a disponibilidade das ferramentas e funcionalidades do novo plano é imediata; b) Na hipótese em que o
      USUÁRIO optar por assinar plano de valor inferior ao plano vigente ao tempo da alteração a disponibilidade das
      ferramentas e funcionalidades permanecerá inalterada até o fim do ciclo de cobrança vigente. As funcionalidades e
      ferramentas do novo plano contratado ficarão disponíveis a partir do início do próximo ciclo de cobrança. Uma vez
      realizada a mudança de planos ou pacotes uma nova alteração somente será possível: (i) para um plano de valor mais
      elevado; (ii) quando concluído o ciclo de cobrança vigente. As funcionalidades, ferramentas do Portal e limites de
      consumo disponíveis ao USUÁRIO não são cumulativos e são zerados a cada dia. Os planos e pacotes não são
      cumulativos; apenas um plano pode ser contratado por cada conta cadastrada nas plataformas operadas pelo Portal.
      Os planos de assinaturas são por tempo indeterminado, podendo ser rescindidos unilateralmente sem anuência o
      indenizações por qualquer das partes. Além das modificações ou alterações mencionadas no item anterior, o Portal
      também se reserva o direito de reajustar unilateralmente os valores de novos pacotes ou planos. A prestação do
      serviço está sujeita a aceitação, por parte do USUÁRIO, dos termos de uso do portal. Caso não concorde com
      eventuais alterações ou modificações no presente instrumento ou em qualquer outro termo ou política dos serviços
      operados ou mantidos pelo Portal, o USUÁRIO pode optar por interromper o fornecimento do serviço sem reembolso de
      valores que já tenham sido pagos. COBRANÇA E PAGAMENTOS As disposições desta seção regem os pagamentos, reembolsos
      e demais transações realizadas entre as partes decorrentes da contratação, utilização e pagamento. Somente serão
      aceitos os pagamentos realizados mediante os meios eleitos pelo Portal para este fim, sendo nulas as transações
      realizadas por outros métodos. É facultado ao USUÁRIO eleger qualquer uma dentre as formas de pagamento indicadas
      a qualquer tempo durante a vigência do vínculo. A cobrança por meio de cartão de crédito dar-se-á na forma de
      ciclos indivisíveis de 30 (trinta) dias a contar da data de contratação; o dia de vencimento será sempre o
      coincidente com o aniversário em ciclos de 30 (trinta) dias da contratação. Na hipótese de cancelamento do serviço
      por parte do USUÁRIO a disponibilidade das ferramentas e funcionalidades perdurará até o final do ciclo. Se o
      USUÁRIO fornecer informações de cartão de crédito para o pagamento dos valores pela utilização do Portal, o
      USUÁRIO, afirma e garante que está autorizado a fornecer tais informações e autoriza, aqui, o Portal a utilizar
      esses dados para cobrar pelo respectivo cartão de crédito o pagamento dos valores devidos. DO ARREPENDIMENTO Caso
      a relação entre as partes seja consumerista, fica assegurado ao USUÁRIO o direito de arrependimento por 7 (sete)
      dias, nos termos do art. 49 do Código de Defesa do Consumidor. PEDIDO DE REEMBOLSO Se por alguma razão você não
      utilizou os serviços em um determinado mês (não acessou a plataforma, não visualizou, copiou ou baixou nenhum
      conteúdo), você pode solicitar um pedido de reembolso referente ao mês sem uso. O pedido dever ser feito
      utilizando o formulário de suporte no próprio site, ou para o e-mail XXXXXXXXXXXXXXXX, os seguintes dados para
      depósito: Banco, Agência, Número da Conta, Nome completo e CPF. O reembolso será efetuado na forma de estorno de
      transação caso a última cobrança tenha sido realizada através de cartão de crédito há menos de 15 dias. VALORES E
      REAJUSTES Este Portal poderá exigir cobrança para acesso a recursos específicos (PRO). Estes valores, quando em
      forma de assinaturas com cobrança recorrente, poderão ser reajustados conforme a variação do IGPM sem aviso prévio
      aos usuários. Caso por algum motivo este Portal necessite fazer reajustes de valores em virtude de aumentos nos
      custos operacionais, variações de impostos, implementação de novos recursos e/ou outros motivos relevantes, todos
      os usuários serão notificados por e-mail com pelo menos 30 dias de antecedência, para que fiquem cientes das
      alterações e, se desejarem, possam cancelar suas assinaturas em tempo hábil. Todos os usuários que não cancelarem
      suas assinaturas após a notificação terão o valor de suas assinaturas automaticamente reajustados após 30 dias. DO
      NÃO CUMPRIMENTO DESTES TERMOS Caso qualquer um dos itens presentes nestes termos não seja cumprido por um
      indivíduo, empresa, dispositivo, e-mail, domínio ou IP, é reservado a nós o direito de cancelamento imediato,
      compulsório e sem aviso prévio da prestação de serviços, seja de natureza paga ou gratuita, assim como o bloqueio
      do acesso partindo do individuo, empresa, dispositivo, domínio, e-mail, IP ou qualquer outra origem direta ou
      indiretamente ligada a você, de forma temporária ou permanente. É de responsabilidade do usuário cumprir todas as
      clausulas acordadas nestes termos para evitar cancelamento ou bloqueio do serviço. SOBRE ESTES TERMOS Se você não
      concordar com os termos alterados de um Serviço, deve descontinuar o uso desse Serviço. Em caso de conflito entre
      estes termos e os termos adicionais, os termos adicionais prevalecerão com relação a esse conflito.Estes termos
      regem a relação entre o Portal e você. Eles não criam quaisquer direitos para terceiros. Caso você não cumpra
      estes termos e nós não tomemos providências imediatas, isso não significa que estamos renunciando a quaisquer
      direitos que possamos ter (como tomar providências futuras). Caso uma condição específica destes termos não seja
      executável, isso não prejudicará quaisquer outros termos. DAS VIOLAÇÕES AOS TERMOS DE USO O USUÁRIO apenas pode
      acessar os produtos e serviços para fins lícitos. O USUÁRIO é completamente responsável pelo conhecimento e pela
      adesão a qualquer e toda lei, regulamento e regras pertinentes ao uso dos produtos e serviços sobre os quais
      tratam o presente termo. Caso a rescisão contratual se dê em razão de descumprimento ou violação por parte do
      USUÁRIO a disposições do presente termo, bem como a descumprimento ou violação à legislação aplicável, não caberá
      reembolso de valores já pagos. O USUÁRIO não poderá reproduzir, distribuir, transmitir, exibir, vender, licenciar,
      alienar de qualquer forma ou explorar qualquer conteúdo acessado no Portal para quaisquer fins, onerosos ou não.
      Na hipótese de qualquer medida, seja ela judicial ou não, tomada por terceiros contra o Portal em razão de
      qualquer conteúdo postado, publicizado ou de qualquer outra forma disponibilizado pelo USUÁRIO nos serviços, o
      PORTAL poderá voltar tal medida contra o USUÁRIO. Qualquer tentativa de burlar as regras do presente termo, tais
      como, mas não limitados a, ao uso de informações de cadastro falsas ou incompletas, o uso de plugins ou
      ferramentas de debug ou inspeção de código fonte, tentativa de engenharia reversa ou de alteração do código fonte,
      utilização de ferramentas ou plugins que modifiquem as funcionalidades padrões do navegador ou do site, utilização
      de ferramentas de acesso automatizado ou semi-automatizado, tentativas de copiar, imprimir, acessar, exportar ou
      utilizar os serviços diferentemente das funcionalidades já previstas pelo sistema ou identificação de padrões de
      uso repetitivos que possam sinalizar uso fora do padrão normal da plataforma, configuram ameaças a estes termos de
      uso e motivos suficientes para exclusão definitiva e banimento do usuário da plataforma e/ou implementação de
      limitações de recursos que o usuário tem acesso na plataforma, tanto dos planos pagos quanto gratuitos. A
      utilização da plataforma em padrões distintos da finalidades de peticionamento direto em um caso concreto (tais
      como, mas não limitado a, baixar ou buscar modelos em um padrão não condizente com a existência de um caso
      concreto para uso do mesmo) configuram violação a estes termos de uso e motivos suficientes para exclusão
      definitiva e banimento do usuário da plataforma e/ou implementação de limitações de recursos que o usuário tem
      acesso na plataforma, tanto dos planos pagos quanto gratuitos. Nós podemos entrar em contato para solicitar
      informações adicionais sobre o seu cadastro, com o objetivo de garantir a integridade da plataforma. A não
      disposição em atender tal solicitação ou o fornecimento de informações falsas ou incompletas caracteriza violação
      dos termos de uso e acarretará cancelamento imediato e definitivo do contrato, seja ele grátis ou pago, e
      banimento do usuário da plataforma por tempo indeterminado e sem aviso prévio. O Modelo Inicial registra todas as
      suas obras intelectuais regularmente nos meios de proteção autoral pertinentes. O ato de compartilhar, publicar,
      vender ou distribuir um modelo a terceiros fora das situações previstas nestes Temos de Uso configura crime de
      pirataria conforte Art. 184 do Código Penal e violação da Lei 9.610 de 1998 (Lei do Direito Autoral). DISPOSIÇÕES
      FINAIS O Portal poderá alterar o presente termo a qualquer tempo, sem a necessidade de aviso prévio. O Portal não
      se responsabiliza: (i) pela disponibilidade ou exatidão de modelos ou recursos esperados pelo usuário, e; (ii)
      pelo conteúdo, produtos ou serviços contidos ou disponibilizados em tais sites ou recursos. O Portal não realiza a
      conferência dos dados ou conteúdo inseridos pelo USUÁRIO, cabe ao próprio USUÁRIO a verificação sobre a
      regularidade dessas informações junto aos órgãos competentes, bem como a outras fontes que puderem servir como
      meio de atestar a veracidade e direitos autorais das mesmas. Nesta hipótese, o Portal se reserva ao direito de
      encerrar a assinatura no caso de conhecimento da falsidade ou da má-fé empregadas pelo USUÁRIO, podendo impedir
      seu posterior regresso aos serviços oferecidos, sem direito a qualquer tipo de compensação. A rescisão será
      previamente comunicada ao USUÁRIO. SOB NENHUMA HIPÓTESE serão prestadas consultorias jurídicas, esclarecimentos
      acerca do conteúdo de quaisquer publicações disponibilizadas no Portal. O Portal não prestará quaisquer serviços
      de assessoria e/ou consultoria jurídica. Todo contato entre o USUÁRIO e o Portal mediante os canais para este fim
      destinar-se-á, única e exclusivamente, para atender solicitações ou dúvidas técnicas acerca da utilização e
      funcionamento do serviço em questão. O Portal não utiliza os dados pessoais dos seus Usuários para fins
      comerciais, ou seja, os dados armazenados não são disponibilizados para terceiros, seja de forma gratuita ou
      onerosa. Muito embora o Portal persiga a perfeição e a excelência nos serviços que presta, não pode garantir: (i)
      que irá atender expectativas ou resultados específicos, (ii) que é à prova de interrupções, inviolável ou à prova
      de erros, (iii) que todo erro detectado será resolvido, ou (iv) que a resolução se adequará a quaisquer
      expectativas do USUÁRIO; (v) que os modelos estão atualizados ou de acordo com a jurisprudência dominante. O
      Portal não será responsabilizado por nenhum prejuízo que eventualmente ocorra em decorrência de alteração,
      suspensão ou descontinuação temporária de qualquer dos seus serviços. As partes elegem pelo presente termo o foro
      de Porto Alegre-RS para dirimir qualquer litígio referente ao uso dos serviços desta plataforma. AO SE CADASTRAR
      NA PLATAFORMA O USUÁRIO DECLARA TER LIDO E COMPREENDIDO OS TERMOS E DISPOSIÇÕES DESTE ACORDO DE UTILIZAÇÃO E QUE
      ESTÁ CIENTE DE SEU INTEIRO TEOR, ACEITANDO TODAS AS SUAS CONDIÇÕES. COMO ENTRAR EM CONTATO CONOSCO E-mail:
    </p>
  </div>
</div>
