// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum ConfUtilizacaoEnum {
  DATA_COMPETENCIA = 'DATA_COMPETENCIA',
  CONTATOS = 'CONTATOS',
  CENTROS = 'CENTROS',
  FORMAS_PAGAMENTO = 'FORMAS_PAGAMENTO',
  PROJETOS = 'PROJETOS',
  TAGS = 'TAGS',
  NUMERO_DOCUMENTO = 'NUMERO_DOCUMENTO',
  CAMPO_OBSERVACOES = 'CAMPO_OBSERVACOES',
  SENHA_EXCL_CAD = 'SENHA_EXCL_CAD',
}

// #region optionlist#method.
// CODGEN {{R}}
export class ConfUtilizacaoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'DATA_COMPETENCIA', name: 'DATA_COMPETENCIA' },
      { id: 'CONTATOS', name: 'CONTATOS' },
      { id: 'CENTROS', name: 'CENTROS' },
      { id: 'FORMAS_PAGAMENTO', name: 'FORMAS_PAGAMENTO' },
      { id: 'PROJETOS', name: 'PROJETOS' },
      { id: 'TAGS', name: 'TAGS' },
      { id: 'NUMERO_DOCUMENTO', name: 'NUMERO_DOCUMENTO' },
      { id: 'CAMPO_OBSERVACOES', name: 'CAMPO_OBSERVACOES' },
      { id: 'SENHA_EXCL_CAD', name: 'SENHA_EXCL_CAD' },
    ];
  }
}
// #endregion

// #region #declarations#.
export class ConfUtilizacaoConfiguration {
  static configuracoes = {
    datacompetencia: { e: ConfUtilizacaoEnum.DATA_COMPETENCIA, n: 'datacompetencia' },
    idcontato: { e: ConfUtilizacaoEnum.CONTATOS, n: 'idcontato' },
    idcentrocusto: { e: ConfUtilizacaoEnum.CENTROS, n: 'idcentrocusto' },
    idformapagamento: { e: ConfUtilizacaoEnum.FORMAS_PAGAMENTO, n: 'idformapagamento' },
    idprojeto: { e: ConfUtilizacaoEnum.PROJETOS, n: 'idprojeto' },
    listatags: { e: ConfUtilizacaoEnum.TAGS, n: 'listatags' },
    observacoes: { e: ConfUtilizacaoEnum.CAMPO_OBSERVACOES, n: 'observacoes' },
    x1: { e: ConfUtilizacaoEnum.NUMERO_DOCUMENTO, n: 'x1' },
    x2: { e: ConfUtilizacaoEnum.SENHA_EXCL_CAD, n: 'x2' },
  };
}
// #endregion
