// {{GENERATE}}
// @CRUD
// @GRID:card
// @Route:/admin/contato/action
// URL:/admin/contato
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { AdminContatoActionService } from '../admin-contato-action.service';
import { ContatoBean } from '../../models/ContatoBean';

@Injectable()
export class AdminContatoActionImplService implements AdminContatoActionService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #getallcontato.
  // CODGEN {{R}}
  GetAllContato(par: string): Observable<ContatoBean[]> {
    const url = this.helper.ToURL('/admin/contato/getAllContato');
    const params = new HttpParams().append('par', par);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<ContatoBean[]>(url, headers);
  }
  // #endregion

  // #region #crud#createcontato.
  // CODGEN {{R}}
  CreateContato(bean: ContatoBean): Observable<ContatoBean> {
    const url = this.helper.ToURL('/admin/contato/createContato');
    const headers = this.helper.GetHeaders();
    return this.http.post<ContatoBean>(url, bean, headers);
  }
  // #endregion

  // #region #crud#updatecontato.
  // CODGEN {{R}}
  UpdateContato(bean: ContatoBean): Observable<void> {
    const url = this.helper.ToURL('/admin/contato/updateContato');
    const headers = this.helper.GetHeaders();
    return this.http.put<void>(url, bean, headers);
  }
  // #endregion

  // #region #crud#getreadcontato.
  // CODGEN {{R}}
  GetReadContato(idcontato: number): Observable<ContatoBean> {
    const url = this.helper.ToURL('/admin/contato/readContato');
    const params = new HttpParams().append('idcontato', idcontato.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.get<ContatoBean>(url, headers);
  }
  // #endregion

  // #region #crud#removecontato.
  // CODGEN {{R}}
  RemoveContato(idcontato: number): Observable<void> {
    const url = this.helper.ToURL('/admin/contato/removeContato');
    const params = new HttpParams().append('idcontato', idcontato.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, headers);
  }
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/contato/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/contato/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region #declarations#.
  // #endregion
}
