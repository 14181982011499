<div class="app-content content">
  <div class="d-flex flex-nowrap">
    <div class="d-flex flex-column flex-shrink-0 bg-light" style="width: 6.5rem">
      <div class="d-flex flex-column fixed-on-top w-100">
        <app-month-year-picker
          formControlName="periodo"
          (onEventChange)="OnEventChange($event)"
        ></app-month-year-picker>
        <button
          type="button"
          class="btn btn-sm"
          (click)="onPersonalizarHome()"
          ngbTooltip="personalizar paineis"
          placement="bottom"
        >
          <i class="fa fa-cog fa-2x" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="container">
      <div class="masonry">
        @for (item of adsList; track item.data.id; let i = $index) {
          <div class="item" [style]="getSpanStyle(item.data)">
            <app-painel-ad class="item" [ads]="item" (onAdChange)="onAddChange($event)"> </app-painel-ad>
          </div>
        }
      </div>
    </div>
  </div>
</div>
