import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css'],
  standalone: true,
  imports: [FormsModule],
})
export class SearchBoxComponent {
  @Input()
  placeholder = 'Search...';

  @Output()
  searchChange = new EventEmitter<string>();

  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;

  onValueChange(value: string) {
    setTimeout(() => this.searchChange.emit(value));
  }

  public getSearchText(): string {
    return this.searchInput.nativeElement.value;
  }

  clear() {
    this.searchInput.nativeElement.value = '';
    this.onValueChange(this.searchInput.nativeElement.value);
  }
}
