// {{GENERATE}}
// @CRUD
// @GRID:card
// @Route:/admin/contato/action
// URL:/admin/contato
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ContatoBean } from '../models/ContatoBean';

@Injectable()
export abstract class AdminContatoActionService {
  // #region #getallcontato.
  // CODGEN {{R}}
  abstract GetAllContato(par: string): Observable<ContatoBean[]>;
  // #endregion

  // #region #crud#createcontato.
  // CODGEN {{R}}
  abstract CreateContato(bean: ContatoBean): Observable<ContatoBean>;
  // #endregion

  // #region #crud#updatecontato.
  // CODGEN {{R}}
  abstract UpdateContato(bean: ContatoBean): Observable<void>;
  // #endregion

  // #region #crud#getreadcontato.
  // CODGEN {{R}}
  abstract GetReadContato(idcontato: number): Observable<ContatoBean>;
  // #endregion

  // #region #crud#removecontato.
  // CODGEN {{R}}
  abstract RemoveContato(idcontato: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
