// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoFinanceiroEnum {
  DESPESA = 'DESPESA',
  RECEITA = 'RECEITA',
  TRANSFERENCIA = 'TRANSFERENCIA',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoFinanceiroEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'DESPESA', name: 'DESPESA' },
      { id: 'RECEITA', name: 'RECEITA' },
      { id: 'TRANSFERENCIA', name: 'TRANSFERENCIA' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
