import { Type } from '@angular/core';
import { AdDadosElement } from './ad-dados_element';
import { AdComponent } from './ad.component';

export class AdItem {
  constructor(
    public component: Type<AdComponent>,
    public data: AdDadosElement,
  ) {}
}
