<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>LancamentoRepeticaoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-reptiporepeticao/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['reptiporepeticao']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.reptiporepeticao</label
      >
      <ng-select
        [id]="fieldIdTable['reptiporepeticao']"
        #reptiporepeticao
        formControlName="reptiporepeticao"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of reptiporepeticaoList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoRepeticaoEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-reptiporepeticao -->
    <!-- #I-#field-reptipoperiodicidade/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['reptipoperiodicidade']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.reptipoperiodicidade</label
      >
      <ng-select
        [id]="fieldIdTable['reptipoperiodicidade']"
        #reptipoperiodicidade
        formControlName="reptipoperiodicidade"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of reptipoperiodicidadeList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoPeriodicidadeEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-reptipoperiodicidade -->
    <!-- #I-#field-repnumfrequencia/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['repnumfrequencia']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.repnumfrequencia</label
      >
      <input
        type="text"
        [id]="fieldIdTable['repnumfrequencia']"
        formControlName="repnumfrequencia"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.repnumfrequencia.invalid && (f.repnumfrequencia.dirty || f.repnumfrequencia.touched),
        }"
        numberOnly
        maxlength="10"
      />
    </div>
    <!-- #E-#field-repnumfrequencia -->
    <!-- #I-#field-repquantidade/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['repquantidade']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.repquantidade</label
      >
      <input
        type="text"
        [id]="fieldIdTable['repquantidade']"
        formControlName="repquantidade"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.repquantidade.invalid && (f.repquantidade.dirty || f.repquantidade.touched) }"
        numberOnly
        maxlength="10"
      />
    </div>
    <!-- #E-#field-repquantidade -->
    <!-- #I-#field-repparcelainicial/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['repparcelainicial']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.repparcelainicial</label
      >
      <input
        type="text"
        [id]="fieldIdTable['repparcelainicial']"
        formControlName="repparcelainicial"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.repparcelainicial.invalid && (f.repparcelainicial.dirty || f.repparcelainicial.touched),
        }"
        numberOnly
        maxlength="10"
      />
    </div>
    <!-- #E-#field-repparcelainicial -->
    <!-- #I-#field-repvalorprevisto/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['repvalorprevisto']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.repvalorprevisto</label
      >
      <input
        type="text"
        [id]="fieldIdTable['repvalorprevisto']"
        formControlName="repvalorprevisto"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.repvalorprevisto.invalid && (f.repvalorprevisto.dirty || f.repvalorprevisto.touched),
        }"
        kzMaskCurrency
        maxlength="22"
      />
    </div>
    <!-- #E-#field-repvalorprevisto -->
    <!-- #I-#field-numdocumento/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['numdocumento']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.numdocumento</label
      >
      <input
        type="text"
        [id]="fieldIdTable['numdocumento']"
        formControlName="numdocumento"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.numdocumento.invalid && (f.numdocumento.dirty || f.numdocumento.touched) }"
        maxlength="40"
      />
    </div>
    <!-- #E-#field-numdocumento -->
    <!-- #I-#field-vencfatura/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['vencfatura']" class="form-label" l10nTranslate
        >LancamentoRepeticaoBeanFragment.vencfatura</label
      >
      <input
        type="text"
        [id]="fieldIdTable['vencfatura']"
        formControlName="vencfatura"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.vencfatura.invalid && (f.vencfatura.dirty || f.vencfatura.touched) }"
        maxlength="40"
      />
    </div>
    <!-- #E-#field-vencfatura -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
<!--
                    -->
