// adjust-z-index.directive.ts
import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appAdjustZIndex]',
  standalone: true,
})
export class AdjustZIndexDirective implements AfterViewInit, OnDestroy {
  private observer: MutationObserver;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.initMutationObserver();
  }

  ngOnDestroy(): void {
    this.disconnectMutationObserver();
  }

  private initMutationObserver(): void {
    const modalElement = this.el.nativeElement.closest('.modal');
    if (!modalElement) {
      return;
    }

    const modalZIndex = window.getComputedStyle(modalElement).zIndex;
    const newZIndex = +modalZIndex + 10;

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          mutation.addedNodes.forEach((node) => {
            if ((node as HTMLElement).classList?.contains('ng-dropdown-panel')) {
              this.renderer.setStyle(node, 'z-index', newZIndex.toString());
            }
          });
        }
      });
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  private disconnectMutationObserver(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
