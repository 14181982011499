import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { UploadFileResponse } from '../models/UploadFileResponse';

@Injectable()
export class UploadFileService {
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}

  pushFileToStorage(bean: any, file: File): Observable<HttpEvent<UploadFileResponse>> {
    const url = this.helper.ToURL('/uploadFile');
    const formdata: FormData = new FormData();
    formdata.append('bean', JSON.stringify(bean));
    formdata.append('file', file);
    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.helper.GetToken(),
      }),
    });
    return this.http.request(req);
  }

  pushFilesToStorage(bean: any, files: FileList): Observable<HttpEvent<UploadFileResponse>> {
    const url = this.helper.ToURL('/uploadMultipleFiles');
    const formdata: FormData = new FormData();
    formdata.append('bean', JSON.stringify(bean));
    for (let nFileId = 0; nFileId < files.length; nFileId++) {
      formdata.append('file', files.item(nFileId));
    }
    const req = new HttpRequest('POST', url, formdata, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.helper.GetToken(),
      }),
    });
    return this.http.request(req);
  }
}
