// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { Utilities } from 'src/app/core/services/utilities';
import { TipoPeriodicidadeEnumRepo } from '../../../models/TipoPeriodicidadeEnum';
import { TipoRepeticaoEnumRepo } from '../../../models/TipoRepeticaoEnum';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-lancamento-repeticao-bean-fragment',
  templateUrl: './lancamento-repeticao-bean-fragment.component.html',
  imports: [CommonModule, ReactiveFormsModule, L10nTranslateDirective, L10nTranslatePipe, NgbModule, NgSelectModule],
  providers: [],
})
export class LancamentoRepeticaoBeanFragmentComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  reptiporepeticaoList: SelectOptionItem[] = TipoRepeticaoEnumRepo.optionList();
  reptipoperiodicidadeList: SelectOptionItem[] = TipoPeriodicidadeEnumRepo.optionList();

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      reptiporepeticao: [null],
      reptipoperiodicidade: [null],
      repnumfrequencia: [null, Validators.maxLength(10)],
      repquantidade: [null, Validators.maxLength(10)],
      repparcelainicial: [null, Validators.maxLength(10)],
      repvalorprevisto: [null, Validators.maxLength(22)],
      numdocumento: [null, Validators.maxLength(40)],
      vencfatura: [null, Validators.maxLength(40)],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      reptiporepeticao: this.CrudForm.get('reptiporepeticao').value,
      reptipoperiodicidade: this.CrudForm.get('reptipoperiodicidade').value,
      repnumfrequencia: Utilities.unmask(this.CrudForm.get('repnumfrequencia').value),
      repquantidade: Utilities.unmask(this.CrudForm.get('repquantidade').value),
      repparcelainicial: Utilities.unmask(this.CrudForm.get('repparcelainicial').value),
      repvalorprevisto: this.CrudForm.get('repvalorprevisto').value,
      numdocumento: this.CrudForm.get('numdocumento').value,
      vencfatura: this.CrudForm.get('vencfatura').value,
    };
    return reg;
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion

  // #region #declarations#.
  // #endregion
}
