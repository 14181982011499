import { Directive, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const elm = this.el.nativeElement;
    elm.focus();
    const el = elm.querySelector('input');
    if (el) {
      el.focus();
      el.select();
    }
  }
}
