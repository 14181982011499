import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { HomeComponent } from './core/components/home/home.component';
import { HomeLayoutComponent } from './core/components/layouts/home.layout.component';
import { InfoTextLayoutComponent } from './core/components/layouts/info-text.layout.component';
import { LoginLayoutComponent } from './core/components/layouts/login.layout.component';
import { EsqueceuSenhaComponent } from './core/components/login/esqueceu-senha.component';
import { LoginComponent } from './core/components/login/login.component';
import { PoliticaPrivacidadeComponent } from './core/components/politica-privacidade/politica-privacidade.component';
import { TermoDeUsoComponent } from './core/components/termo-de-uso/termo-de-uso.component';
import { AuthGuard } from './core/helpers/auth.guard';
import { Jwt401Interceptor } from './core/helpers/jwt-401.interceptor';
import { ConfirmarEmailComponent } from './modules/admin/components/criar-conta-usuario/confirmar-email/confirmar-email.component';
import { CriarContaUsuarioComponent } from './modules/admin/components/criar-conta-usuario/criar-conta-usuario/criar-conta-usuario.component';
import { FeedbackFinalSucessoComponent } from './modules/admin/components/criar-conta-usuario/feedback-final-sucesso/feedback-final-sucesso.component';
import { TerminarCadastroComponent } from './modules/admin/components/criar-conta-usuario/terminar-cadastro/terminar-cadastro.component';
import { ChangePasswordFormComponent } from './modules/admin/components/user/change-password-form/change-password-form.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          breadcrumb: 'Home',
          root: true,
        },
      },
      {
        path: 'user/chpwd',
        component: ChangePasswordFormComponent,
        data: {
          breadcrumb: 'Trocar-senha',
          root: true,
        },
      },
      {
        path: 'tb',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'doc',
        loadChildren: () => import('./modules/documento/documento.module').then((m) => m.DocumentoModule),
      },
      {
        path: 'fin',
        loadChildren: () => import('./modules/financeiro/financeiro.module').then((m) => m.FinanceiroModule),
      },
      {
        path: 'agro',
        loadChildren: () => import('./modules/agro/agro.module').then((m) => m.AgroModule),
      },
      {
        path: 'estoque',
        loadChildren: () => import('./modules/estoque/estoque.module').then((m) => m.EstoqueModule),
      },
      {
        path: 'manejoanimal',
        loadChildren: () => import('./modules/manejoanimal/manejoanimal.module').then((m) => m.ManejoanimalModule),
      },
      {
        path: 'pessoas',
        loadChildren: () => import('./modules/pessoas/pessoas.module').then((m) => m.PessoasModule),
      },
      {
        path: 'reprodutivo',
        loadChildren: () => import('./modules/reprodutivo/reprodutivo.module').then((m) => m.ReprodutivoModule),
      },
      /*     {
        path: 'batch',
        loadChildren: () => import('./modules/batch/batch.module').then((m) => m.BatchModule),
      }, */
      // <<incluir aqui>>
    ],
  },
  {
    path: '',
    component: InfoTextLayoutComponent,
    children: [
      { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
      { path: 'termo-de-uso', component: TermoDeUsoComponent },
    ],
  },
  {
    path: '',
    component: LoginLayoutComponent, // somente login
    children: [
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'esqueceu-senha', component: EsqueceuSenhaComponent },
      { path: 'criar-conta', component: CriarContaUsuarioComponent },
      { path: 'confirmar-email', component: ConfirmarEmailComponent },
      { path: 'terminar-cadastro', component: TerminarCadastroComponent },
      { path: 'feedback-final-sucesso', component: FeedbackFinalSucessoComponent },
    ],
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    CommonModule,
    ImageCropperComponent,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      //   preloadingStrategy: PreloadAllModules,
      //   enableTracing: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 95],
    }),
  ],
  exports: [RouterModule],
  providers: [
    provideHttpClient(withInterceptorsFromDi()), // HttpClient com interceptores
    { provide: HTTP_INTERCEPTORS, useClass: Jwt401Interceptor, multi: true }, // Adiciona o interceptor ao DI
  ],
})
export class AppRoutingModule {}
