// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum UnidadeFederacaoEnum {
  AC = 'AC',
  AL = 'AL',
  AP = 'AP',
  AM = 'AM',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MT = 'MT',
  MS = 'MS',
  MG = 'MG',
  PA = 'PA',
  PB = 'PB',
  PR = 'PR',
  PE = 'PE',
  PI = 'PI',
  RJ = 'RJ',
  RN = 'RN',
  RS = 'RS',
  RO = 'RO',
  RR = 'RR',
  SC = 'SC',
  SP = 'SP',
  SE = 'SE',
  TO = 'TO',
}

// #region optionlist#method.
// CODGEN {{R}}
export class UnidadeFederacaoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'AC', name: 'AC' },
      { id: 'AL', name: 'AL' },
      { id: 'AP', name: 'AP' },
      { id: 'AM', name: 'AM' },
      { id: 'BA', name: 'BA' },
      { id: 'CE', name: 'CE' },
      { id: 'DF', name: 'DF' },
      { id: 'ES', name: 'ES' },
      { id: 'GO', name: 'GO' },
      { id: 'MA', name: 'MA' },
      { id: 'MT', name: 'MT' },
      { id: 'MS', name: 'MS' },
      { id: 'MG', name: 'MG' },
      { id: 'PA', name: 'PA' },
      { id: 'PB', name: 'PB' },
      { id: 'PR', name: 'PR' },
      { id: 'PE', name: 'PE' },
      { id: 'PI', name: 'PI' },
      { id: 'RJ', name: 'RJ' },
      { id: 'RN', name: 'RN' },
      { id: 'RS', name: 'RS' },
      { id: 'RO', name: 'RO' },
      { id: 'RR', name: 'RR' },
      { id: 'SC', name: 'SC' },
      { id: 'SP', name: 'SP' },
      { id: 'SE', name: 'SE' },
      { id: 'TO', name: 'TO' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
