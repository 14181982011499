<!-- {{NOTGENERATE}} -->
<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title t-header-title">
      {{ getTitulo() }}
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="OnCloseModal(false)"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="CrudForm" (ngSubmit)="onSubmit()">
      <div class="w-100">
        <app-lancamento-bean-fragment
          #vlancamento
          [EditMode]="IsEditMode()"
          [CrudForm]="CrudForm"
          [tipoLancamento]="tipolancamento"
          [idconta]="idconta"
        ></app-lancamento-bean-fragment>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row flex-fill">
      <!-- PAINEL DE ICONES -->
      <!-- CONFIRMADO -->
      <button
        role="button"
        class="btn btn-sm btn-status"
        triggers="hover"
        [class.natv]="!filterToogle.confirmado"
        (click)="onFilter('confirmado')"
        ngbTooltip="Marcar como confirmado"
      >
        <div class="icon-circle m-cor-conciliado m-f-size" [class.natv]="!filterToogle.confirmado">
          <i class="bi bi-check-lg"></i>
        </div>
      </button>
      <!-- CONCILIADO -->
      <button
        role="button"
        class="btn btn-sm btn-status"
        triggers="hover"
        (click)="onFilter('conciliado')"
        ngbTooltip="Marcar como conciliado"
      >
        <div class="icon-circle m-cor-conciliado m-f-size" [class.natv]="!filterToogle.conciliado">
          <i class="bi bi-check-all"></i>
        </div>
      </button>
      <!-- ANEXAR ARQUIVOS -->
      <div ngbDropdown container="body" placement="bottom-end">
        <button
          role="button"
          class="btn btn-sm btn-status position-relative"
          triggers="hover"
          ngbTooltip="Anexar documentos"
          ngbDropdownToggle
        >
          <div class="icon-circle m-cor-alarm fs-6" [class.natv]="!filterToogle.anexos">
            <i class="bi bi-paperclip"></i>
          </div>
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="onDocumentoAnexo($event, 'novo')">Novo documento</button>
          <button ngbDropdownItem (click)="onDocumentoAnexo($event, 'download')">Baixar documento</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="onDocumentoAnexo($event, 'irpara')">Ir para página Documentos</button>
        </div>
      </div>
      <!-- ALARME DE AVISO -->
      <div ngbDropdown container="body" placement="bottom-end">
        <button
          role="button"
          class="btn btn-sm btn-status position-relative"
          triggers="hover"
          ngbTooltip="Avisos serão enviados"
          ngbDropdownToggle
        >
          <div class="icon-circle m-cor-alarm fs-6" [class.natv]="!filterToogle.alarm">
            <i class="bi bi-bell"></i>
          </div>
          @if (filterToogle.alarm) {
            <span class="m-cor-danger border border-light m-badge-circle">
              <span>{{ filterToogle.alarmval }}</span>
            </span>
          }
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="onFilterAlarm($event, 1)">Avisa 1 dia antes</button>
          <button ngbDropdownItem (click)="onFilterAlarm($event, 2)">Avisa 2 dias antes</button>
          <button ngbDropdownItem (click)="onFilterAlarm($event, 5)">Avisa 5 dias antes</button>
          <div class="dropdown-divider"></div>
          <button ngbDropdownItem (click)="onFilterAlarm($event, 0)">Sem aviso</button>
        </div>
      </div>
      <!-- EXIBIR EM CONTAS A PAGAR -->
      <button
        role="button"
        class="btn btn-sm btn-status"
        triggers="hover"
        (click)="onFilter('cpagar')"
        ngbTooltip="Exibir no contas a pagar"
      >
        <div class="icon-circle m-cor-cpagar fs-6" [class.natv]="!filterToogle.cpagar">
          <i class="bi bi-card-checklist"></i>
        </div>
      </button>
      <!-- FIM - PAINEL DE ICONES -->
    </div>
    <button type="button" class="btn btn-secondary" (click)="OnCloseModal(false)">
      <i class="fa fa-times" aria-hidden="true"></i> {{ 'LancamentoActionAdd.BTN-BACK' | translate: locale.language }}
    </button>
    @if (IsEditMode()) {
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="!CrudForm.valid">
        <i class="fa fa-save" aria-hidden="true"></i> {{ 'LancamentoActionAdd.BTN-ENTER' | translate: locale.language }}
      </button>
    }
    @if (!IsEditMode()) {
      <div class="btn-group" role="group" aria-label="...">
        <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="!CrudForm.valid">
          <i class="fa fa-save" aria-hidden="true"></i>
          {{ 'AdminUserActionAdd.BTN-ENTER' | translate: locale.language }}
        </button>
        <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Salvar e continuar">
          <button
            type="button"
            class="btn btn-primary border-start tpx-border-botao"
            (click)="onSubmit('+')"
            [disabled]="!CrudForm.valid"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </span>
      </div>
    }
  </div>
</div>
