// {{GENERATE}}
// @fragment
// @Wrapper:TbLancamento
// URL:/financeiro/lancamento
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal, NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { Observable, Subscription, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs';
import { MonthYearPickerComponent } from 'src/app/core/accessors/month-year-picker.component';
import { SelectOptionChangeEvent, SelectValueComponent } from 'src/app/core/accessors/select-value.component';
import { AutofocusDirective } from 'src/app/core/directives/autofocus.directive';
import { KzMaskCurrencyDirective } from 'src/app/core/directives/kz-mask-currency.directive';
import { Constants } from 'src/app/core/helpers/constants';
import { DateValidator } from 'src/app/core/helpers/DateValidator';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { CloseModalResult } from 'src/app/core/models/CloseModalResult';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AlertService } from 'src/app/core/services/alert.service';
import { SelectOptionEvent, SelectOptionService } from 'src/app/core/services/select-option.service';
import { Utilities } from 'src/app/core/services/utilities';
import { AdminContatoActionAddComponent } from 'src/app/modules/admin/components/contato/admin-contato-action/admin-contato-action-add.component';
import { ConfUtilizacaoConfiguration } from 'src/app/modules/admin/models/ConfUtilizacaoEnum';
import { ContatoBean } from 'src/app/modules/admin/models/ContatoBean';
import { DocumentoActionAddComponent } from 'src/app/modules/documento/components/docs/documento-action/documento-action-add.component';
import { TbDocumentBean } from 'src/app/modules/documento/models/TbDocumentBean';
import { LancamentoDetalheBeanListFragmentComponent } from 'src/app/modules/financeiro/components/lancamentos/lancamento-detalhe-bean/lancamento-detalhe-bean-list-fragment.component';
import { CategoriaBean } from '../../../models/CategoriaBean';
import { ContaBean } from '../../../models/ContaBean';
import { ContaMoedaBean } from '../../../models/ContaMoedaBean';
import { FormaPagamentoBean } from '../../../models/FormaPagamentoBean';
import { RegraPreenchimentoBean } from '../../../models/RegraPreenchimentoBean';
import { StatusLancamentoEnumRepo } from '../../../models/StatusLancamentoEnum';
import { TagBean } from '../../../models/TagBean';
import { TipoFinanceiroEnumRepo } from '../../../models/TipoFinanceiroEnum';
import { TipoLancamentoEnum, TipoLancamentoEnumRepo } from '../../../models/TipoLancamentoEnum';
import { ConfUtilizacaoBrokerService } from '../../../services/conf-utilizacao-broker.service';
import { MoedaRepositorioService } from '../../../services/moeda-repositorio.service';
import { TypeaheadRegraPreenchimentoService } from '../../../services/typeahead-regra-preenchimento.service';
import { CategoriaActionAddComponent } from '../../categorias/categoria-action/categoria-action-add.component';
import { ContasActionAddComponent } from '../../contas/contas-action/contas-action-add.component';
import { FormaPagamentoActionAddComponent } from '../../formas-pagamento/forma-pagamento-action/forma-pagamento-action-add.component';
import { TagActionAddComponent } from '../../tags/tag-action/tag-action-add.component';
import { LancamentoRepeticaoBeanFragmentComponent } from '../lancamento-repeticao-bean/lancamento-repeticao-bean-fragment.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-lancamento-bean-fragment',
  templateUrl: './lancamento-bean-fragment.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgbModule,
    NgSelectModule,
    SelectValueComponent,
    LancamentoDetalheBeanListFragmentComponent,
    NgbTypeaheadModule,
    KzMaskCurrencyDirective,
    MonthYearPickerComponent,
    LancamentoRepeticaoBeanFragmentComponent,
    AutofocusDirective,
  ],
  providers: [],
  host: {
    '[attr.id]': 'instanceId',
  },
})
export class LancamentoBeanFragmentComponent implements OnInit, OnDestroy, AfterViewInit {
  // #region #properties#.
  model: any;
  regraPreenchimento: RegraPreenchimentoBean = null;
  contaMoeda: ContaMoedaBean = null;
  nomeMoeda: string = 'BRL';
  mostraField = {};
  fieldState = {
    label: {
      valor: 'Valor',
      data: 'Data',
    },
    detalhado: {
      fields: [
        'tipolancamento',
        //'tipofinanceiro',
        'valor',
        'data',
        //'datacompetencia',
        'descricao',
        'idconta',
        //'idcontadestino',
        'idformapagamento',
        //'idcategoria',
        'listadetalhes',
        //'idcontato',
        'observacoes',
        //'idcentrocusto',
        //'idprojeto',
        //'listatags',
        'listaarquivos',
        'criarnovaregra',
        //'statuslancamento',
        //'repeticao',
      ],
    },
    simples: {
      fields: [
        'tipolancamento',
        //'tipofinanceiro',
        'valor',
        'data',
        'datacompetencia',
        'descricao',
        'idconta',
        //'idcontadestino',
        'idformapagamento',
        'idcategoria',
        //'listadetalhes',
        'idcontato',
        'observacoes',
        'idcentrocusto',
        'idprojeto',
        'listatags',
        'listaarquivos',
        'criarnovaregra',
        //'statuslancamento',
        //'repeticao',
      ],
    },
  };
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  @Input() tipoLancamento: TipoLancamentoEnum;
  @Input() idconta: number;
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  tipolancamentoList: SelectOptionItem[] = TipoLancamentoEnumRepo.optionList();
  tipofinanceiroList: SelectOptionItem[] = TipoFinanceiroEnumRepo.optionList();
  statuslancamentoList: SelectOptionItem[] = StatusLancamentoEnumRepo.optionList();
  @ViewChild('vlistadetalhes', { static: true }) childListadetalhes: LancamentoDetalheBeanListFragmentComponent;
  @ViewChild('vrepeticao', { static: true }) childRepeticao: LancamentoRepeticaoBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private optionService: SelectOptionService,
    private typeaheadService: TypeaheadRegraPreenchimentoService,
    private utilizacaoService: ConfUtilizacaoBrokerService,
    private moedaService: MoedaRepositorioService,
    private alertService: AlertService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
    this.addSubscription(
      this.CrudForm.get('tipolancamento').valueChanges.subscribe((change) => {
        console.log('TIPO LANÇAMENTO MUDOU:', change);
        this.FiltraFieldsByTipolancamento(change);
      }),
    );
    this.addSubscription(
      this.CrudForm.get('statuslancamento').valueChanges.subscribe((change) => {
        //console.log('STATUS LANÇAMENTO MUDOU:', change);
        this.ajustaFieldStateLabel();
      }),
    );
    this.addSubscription(
      this.CrudForm.get('idconta').valueChanges.subscribe((change) => {
        if (change) {
          //console.log('CONTA MUDOU:', change);
          this.idconta = Number(change);
          this.ajustaDadosConta(this.idconta);
        }
      }),
    );
    this.addSubscription(
      this.CrudForm.get('idcategoria').valueChanges.subscribe((change) => {
        if (change) {
          console.log('CATEGORIA MUDOU:', change);
        }
      }),
    );
    if (this.idconta) {
      this.ajustaDadosConta(this.idconta);
    }
    this.FiltraFieldsByTipolancamento(this.f.tipolancamento.value);
    this.ajustaFieldStateLabel();
  }

  private addSubscription(sub: Subscription): void {
    this.subscriptions.push(sub);
  }

  private ajustaFieldStateLabel(): void {
    const simbolo = this.contaMoeda ? this.contaMoeda.moeda.simbolo : 'R$';
    if (!this.IsEditMode) {
      this.fieldState.label.valor = `Valor (${simbolo})`;
      this.fieldState.label.data = 'Data';
    } else {
      switch (this.CrudForm.get('statuslancamento').value) {
        case 'PENDENTE':
        case 'AGENDADO':
          this.fieldState.label.valor = `Valor previsto (${simbolo})`;
          this.fieldState.label.data = 'Data prevista';
          break;
        case 'CONFIRMADO':
        case 'CONCILIADO':
          this.fieldState.label.valor = `Valor efetivo (${simbolo})`;
          this.fieldState.label.data = 'Data efetiva';
          break;
        default:
          break;
      }
    }
  }

  private ajustaDadosConta(idconta: number): void {
    this.moedaService.getContaMoeda(idconta).subscribe({
      next: (data) => {
        if (data && data.moeda) {
          //console.log('CONTA MOEDA MUDOU:', data);
          this.contaMoeda = data;
          this.nomeMoeda = this.contaMoeda.moeda.nome;
          this.ajustaFieldStateLabel();
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  ngOnDestroy() {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private habilitaCtrl(name: string): void {
    //const isdebug = 'datacompetencia' === name;
    if (ConfUtilizacaoConfiguration.configuracoes[name]) {
      /** verifica a configuração */
      if (this.utilizacaoService.isHabilitado(ConfUtilizacaoConfiguration.configuracoes[name].e)) {
        this.CrudForm.get(name).enable({ onlySelf: true, emitEvent: false });
        //if (isdebug) console.log('HABILITADO:', name);
      } else {
        //if (isdebug) console.log('NÃO CONFIGURADO:', name);
      }
    } else {
      this.CrudForm.get(name).enable({ onlySelf: true, emitEvent: false });
      //if (isdebug) console.log('HABILITADO:', name);
    }
  }

  private FiltraFieldsByTipolancamento(
    tipolancamento: 'DESPESA' | 'RECEITA' | 'TRANSFERENCIA' | 'LAN_DETALHADO',
  ): void {
    //console.log('FiltraFields', tipolancamento);
    this.CrudForm.disable({ onlySelf: false, emitEvent: false });
    if (!this.EditMode) {
      this.CrudForm.get('tipolancamento').enable({ onlySelf: true, emitEvent: false });
    }
    if ('LAN_DETALHADO' === tipolancamento) {
      this.fieldState.detalhado.fields.forEach((name) => {
        this.habilitaCtrl(name);
      });
    } else {
      /**
       * Receita, Despesa e Transferência
       */
      this.fieldState.simples.fields.forEach((name) => {
        if ((this.EditMode && name !== 'tipolancamento') || !this.EditMode) {
          this.habilitaCtrl(name);
        }
      });
      if (!this.EditMode) {
        this.habilitaCtrl('datacompetencia');
      }
      this.CrudForm.get('tipofinanceiro').patchValue(
        TipoLancamentoEnumRepo.toTipoFinanceiroEnum(TipoLancamentoEnumRepo.stringToEnum(tipolancamento)),
      );
      if ('TRANSFERENCIA' === tipolancamento) {
        this.habilitaCtrl('idcontadestino');
      }
    }
    this.mostraField = this.VisibylityFormFields();
  }

  private VisibylityFormFields() {
    const vis = {};
    Object.keys(this.CrudForm.controls).forEach((name) => {
      const control = this.CrudForm.get(name);
      vis[name] = control.enabled;
    });
    return vis;
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformvalidators.
  // CODGEN
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      tipolancamento: [null, Validators.required],
      tipofinanceiro: [null, Validators.required],
      valor: [null, [Validators.required, Validators.maxLength(22), Validators.min(0.01)]],
      valorprevisto: [null, [Validators.required, Validators.maxLength(22)]],
      valorefetivo: [null, [Validators.required, Validators.maxLength(22)]],
      data: [null, [Validators.required, DateValidator.validateOptionalDate]],
      dataprevista: [null, [Validators.required, DateValidator.validateOptionalDate]],
      dataefetiva: [null, [Validators.required, DateValidator.validateOptionalDate]],
      datacompetencia: [null, [Validators.required, DateValidator.validateOptionalDate]],
      descricao: [null, Validators.maxLength(200)],
      idconta: [null, Validators.required],
      idcontadestino: [null],
      idformapagamento: [null],
      idcategoria: [null, Validators.required],
      listadetalhes: [null],
      idcontato: [null],
      observacoes: [null, Validators.maxLength(400)],
      idcentrocusto: [null],
      idprojeto: [null],
      listatags: [null],
      listaarquivos: [null],
      criarnovaregra: [null],
      statuslancamento: [null],
      repeticao: this.formBuilder.group(this.childRepeticao.CrudFormValidators(this.rootformid, 'repeticao')),
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childRepeticao.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN
  CrudFormToRegistro() {
    const item: SelectOptionItem = this.CrudForm.get('descricao').value;
    if (item && item.name) {
      this.CrudForm.get('descricao').patchValue(item.name);
      //console.log('ACERTA DESCRICAO');
    }
    const reg = {
      tipolancamento: this.CrudForm.get('tipolancamento').value,
      tipofinanceiro: this.CrudForm.get('tipofinanceiro').value,
      valor: this.CrudForm.get('valor').value,
      valorprevisto: this.CrudForm.get('valorprevisto').value,
      valorefetivo: this.CrudForm.get('valorefetivo').value,
      data: this.CrudForm.get('data').value,
      dataprevista: this.CrudForm.get('dataprevista').value,
      dataefetiva: this.CrudForm.get('dataefetiva').value,
      datacompetencia: this.CrudForm.get('datacompetencia').value,
      descricao: this.CrudForm.get('descricao').value,
      idconta: this.CrudForm.get('idconta').value,
      idcontadestino: this.CrudForm.get('idcontadestino').value,
      idformapagamento: this.CrudForm.get('idformapagamento').value,
      idcategoria: this.CrudForm.get('idcategoria').value,
      listadetalhes: this.CrudForm.get('listadetalhes').value,
      idcontato: this.CrudForm.get('idcontato').value,
      observacoes: this.CrudForm.get('observacoes').value,
      idcentrocusto: this.CrudForm.get('idcentrocusto').value,
      idprojeto: this.CrudForm.get('idprojeto').value,
      listatags: this.CrudForm.get('listatags').value,
      listaarquivos: this.CrudForm.get('listaarquivos').value,
      criarnovaregra: this.CrudForm.get('criarnovaregra').value,
      statuslancamento: this.CrudForm.get('statuslancamento').value,
      repeticao: this.childRepeticao.CrudFormToRegistro(),
    };
    return reg;
  }
  // #endregion

  // #region #oncriarnovaregrachange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnCriarnovaregraChange(_evt) {}
  // #endregion

  // #region #onaddnewitem.
  // CODGEN {{R}}
  OnAddNewItem(event: SelectOptionEvent, name: string): void {
    //console.log('Add -->', name, JSON.stringify(event));
    switch (name) {
      case 'idconta':
        Utilities.OpenNewModalWindow(this.modalService, ContasActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.idconta.patchValue((message.registro as ContaBean).idconta);
        });
        break;
      case 'idcontadestino':
        Utilities.OpenNewModalWindow(this.modalService, ContasActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.idcontadestino.patchValue((message.registro as ContaBean).idconta);
        });
        break;
      case 'idformapagamento':
        Utilities.OpenNewModalWindow(
          this.modalService,
          FormaPagamentoActionAddComponent,
          (message: CloseModalResult) => {
            this.optionService.publish(event);
            this.f.idformapagamento.patchValue((message.registro as FormaPagamentoBean).idformapagamento);
          },
        );
        break;
      case 'idcategoria':
        Utilities.OpenNewModalWindow(this.modalService, CategoriaActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.idcategoria.patchValue((message.registro as CategoriaBean).id);
        });
        break;
      case 'idcontato':
        Utilities.OpenNewModalWindow(this.modalService, AdminContatoActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.idcontato.patchValue((message.registro as ContatoBean).idcontato);
        });
        break;
      case 'idcentrocusto':
        break;
      case 'idprojeto':
        break;
      case 'listatags':
        Utilities.OpenNewModalWindow(this.modalService, TagActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.listatags.patchValue([(message.registro as TagBean).idtag]);
        });
        break;
      case 'listaarquivos':
        Utilities.OpenNewModalWindow(this.modalService, DocumentoActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.listaarquivos.patchValue((message.registro as TbDocumentBean).id);
        });
        break;
    }
  }
  // #endregion

  // #region #routine-definitions#.

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length > 2),
      switchMap((term) => this.typeaheadService.search(term)),
    );

  formatter = (result: any) => (result.name ? result.name : result);

  inputFormatter = (item: any) => (item.name ? item.name : item);

  onItemDescricaoSelect(event: any): void {
    const item = event.item as SelectOptionItem;
    //console.log('ID Selecionado:', JSON.stringify(item));
    this.CrudForm.get('descricao')?.patchValue(item.name);
    this.regraPreenchimento = JSON.parse(item.tag);
    this.patchRegraValue(this.regraPreenchimento);
  }

  patchRegraValue(regra: RegraPreenchimentoBean): void {
    if (regra) {
      this.CrudForm.get('tipolancamento').patchValue(regra.tipolancamento);
      this.CrudForm.get('idconta').patchValue(regra.idconta);
      this.CrudForm.get('idcontadestino').patchValue(regra.idcontadestino);
      this.CrudForm.get('idformapagamento').patchValue(regra.idformapagamento);
    }
  }

  OnSelectChange(event: SelectOptionChangeEvent): void {}
  // #endregion

  // #region #declarations#.
  onLoadRows(event: number, opcao: 'idcategoria', componente: SelectValueComponent): void {
    const key = componente.getKey();
    const id = this.CrudForm.get(opcao).value;
    console.log('ID ATUAL', id, key);
    if (id) {
      const item = componente.getRowById(id);
      if (!item) {
        // id não é opção.
        const first = componente.getFirstRow();
        if (first) {
          console.log('FIRST ID ROW', first.id, key);
          this.CrudForm.get(opcao).patchValue(Number(first.id));
        }
      } else {
        console.log('OK ID ROW', id, key);
      }
    } else {
      const first = componente.getFirstRow();
      if (first) {
        console.log('FIRST ID ROW', first.id, key);
        this.CrudForm.get(opcao).patchValue(Number(first.id));
      }
    }
  }

  // #endregion
}
