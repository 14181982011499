import { CommonModule } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { HttpHelper } from '../../helpers/http-helper';
import { UploadFileResponse } from '../../models/UploadFileResponse';
import { AlertService } from '../../services/alert.service';
import { UploadFileService } from '../../services/upload-file.service';

/**
 * https://stackblitz.com/edit/angular-rksspi?file=src%2Fapp%2Fapp.component.ts
 * https://www.bezkoder.com/angular-14-spring-boot-file-upload/
 */

export interface Upload {
  content: unknown | null;
  names?: string[];
  progress: number;
  state: 'PENDENTE' | 'ENVIANDO' | 'OK' | 'ERRO';
}

@Component({
  selector: 'app-file-upload-style',
  templateUrl: './file-upload-style.component.html',
  styleUrls: ['./file-upload-style.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class FileUploadStyleComponent implements OnInit {
  error: string;
  dragAreaClass: string;
  currentFileList?: FileList;
  fileInfos: UploadFileResponse[] = [];
  upload: Upload;
  @Input() dados: unknown;
  @Input() multiple: boolean = false;
  @Input() accept: string = 'image/*';
  @Input() autosend: boolean = false;
  @Input() title: string = 'ou solte o arquivos aqui.';
  @Input() titleButton: string = 'Selecione o arquivo';

  @Output() onOk = new EventEmitter<void>();
  @Output() onSelectFileList = new EventEmitter<FileList>();

  constructor(
    private uploadFileService: UploadFileService,
    private alertService: AlertService,
    private helper: HttpHelper,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFileChange(event: any) {
    this.saveFileList(event.target.files);
  }

  ngOnInit() {
    this.dragAreaClass = 'dragarea';
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.saveFileList(event.dataTransfer.files);
    }
  }

  saveFileList(files?: FileList) {
    this.currentFileList = files;
    this.onSelectFileList.emit(files);
    const names: string[] = [];
    for (let i = 0; i < this.currentFileList.length; i++) {
      names.push(this.currentFileList[i].name);
    }
    this.upload = { content: null, names: names, progress: 0, state: 'PENDENTE' };
    if (this.autosend) {
      this.executaUpload((message: any) => {});
    }
  }

  public executaUpload(callback: (input: any) => void): void {
    this.error = '';
    if (!this.currentFileList) {
      return;
    }
    const names: string[] = [];
    for (let i = 0; i < this.currentFileList.length; i++) {
      names.push(this.currentFileList[i].name);
    }
    this.uploadFileService.pushFilesToStorage(this.dados, this.currentFileList).subscribe({
      next: (event: HttpEvent<UploadFileResponse>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.upload = { content: null, names: names, progress: 0, state: 'PENDENTE' };
            break;
          case HttpEventType.UploadProgress:
            this.upload = {
              content: null,
              names: names,
              progress: Math.round((event.loaded / event.total) * 100),
              state: 'ENVIANDO',
            };
            break;
          case HttpEventType.Response:
            //console.log('HTTPEVENT->', JSON.stringify(event.body));
            this.upload = {
              content: event.body,
              names: names,
              progress: 100,
              state: 'OK',
            };
            break;
          default:
            this.upload = {
              content: null,
              names: names,
              progress: 0,
              state: 'ENVIANDO',
            };
        }
        if (this.upload.state === 'OK') {
          this.fileInfos.push(this.upload.content);
          this.helper.refreshUser();
          this.onOk.emit();
          callback(this.upload.content);
        }
      },
      error: (error) => {
        this.alertService.error(error);
        this.upload = {
          content: error,
          names: names,
          progress: 0,
          state: 'ERRO',
        };
      },
    });
  }

  limpaLista(): void {
    this.upload = null;
    this.currentFileList = null;
  }

  public isvalid(): boolean {
    if (!this.currentFileList) {
      //console.log('IS-VALID', false);
      return false;
    }
    //console.log('IS-VALID', true);
    return true;
  }
}
