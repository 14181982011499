<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form -->
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-nome -->
    <div class="col-sm-12 mb-3">
      <div class="input-group position-relative">
        <input
          type="text"
          [id]="fieldIdTable['nome']"
          formControlName="nome"
          placeholder="Nome"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
          maxlength="150"
          appCharCount="150"
        />
        <div class="input-group-text">
          <input type="color" formControlName="labelcor" />
        </div>
      </div>
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.maxlength) {
            <div l10nTranslate>TagBeanFragment.nome-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nome -->
    <!-- #I-#field-labelcor -->
    <!-- #E-#field-labelcor -->
    <!-- #I-#end-row-one -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
