import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  private colors: string[] = [
    '#25CCF7',
    '#FD7272',
    '#54a0ff',
    '#00d2d3',
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#ecf0f1',
    '#95a5a6',
    '#f39c12',
    '#d35400',
    '#c0392b',
    '#bdc3c7',
    '#7f8c8d',
    '#55efc4',
    '#81ecec',
    '#74b9ff',
    '#a29bfe',
    '#dfe6e9',
    '#00b894',
    '#00cec9',
    '#0984e3',
    '#6c5ce7',
    '#ffeaa7',
    '#fab1a0',
    '#ff7675',
    '#fd79a8',
    '#fdcb6e',
    '#e17055',
    '#d63031',
    '#feca57',
    '#5f27cd',
    '#54a0ff',
    '#01a3a4',
    '#2644ff',
    '#3056ff',
    '#3b68ff',
    '#467aff',
    '#518cff',
    '#5c9eff',
    '#67b0ff',
    '#72c2ff',
    '#7dd4ff',
    '#88e6ff',
    '#93f8ff',
    '#9ffff6',
    '#a7ffed',
    '#afffe4',
    '#b7ffd9',
    '#bfffff',
    '#c2e3ff',
    '#c5c7ff',
    '#c8abff',
    '#cb8fff',
    '#ce73ff',
    '#d156ff',
    '#d43aff',
    '#d71eff',
    '#da02ff',
    '#f000ff',
    '#f81eff',
    '#ff3c3c',
    '#ff5a5a',
    '#ff7878',
    '#ff9696',
    '#ffb4b4',
    '#ffd2d2',
    '#fff0f0',
    '#fff6e9',
    '#fffdc3',
    '#fff59f',
    '#ffed7b',
    '#ffe557',
    '#ffdd33',
    '#ffd50f',
    '#f3cd00',
    '#e7c500',
    '#dbc600',
    '#cfc700',
    '#c3c800',
    '#b7c900',
    '#abc900',
    '#9fc900',
    '#93c900',
    '#87c900',
    '#7bc900',
    '#6fc900',
    '#63c900',
    '#57c900',
    '#4bc900',
    '#3fc900',
    '#33c900',
    '#27c900',
    '#1bc900',
    '#0fc900',
    '#00c90d',
    '#00c929',
    '#00c935',
    '#00c941',
    '#00c94d',
    '#00c959',
    '#00c965',
    '#00c971',
    '#00c97d',
    '#00c989',
    '#00c995',
    '#00c9a1',
    '#00c9ad',
    '#00c9b9',
    '#00c9c5',
    '#00c9d1',
    '#00c9dd',
    '#00c9e9',
    '#00c9f5',
    '#00c9ff',
    '#00b5ff',
    '#00a1ff',
    '#008dff',
    '#0079ff',
    '#0065ff',
    '#0051ff',
    '#003dff',
    '#0029ff',
    '#0015ff',
    '#0001ff',
    '#0d00ff',
    '#2100ff',
    '#3500ff',
    '#4900ff',
    '#5d00ff',
    '#7100ff',
    '#8500ff',
    '#9900ff',
    '#ad00ff',
  ];

  constructor() {}

  getColors(): string[] {
    return this.colors;
  }

  getRandomColor(): string {
    const randomIndex = Math.floor(Math.random() * this.colors.length);
    return this.colors[randomIndex];
  }

  ajustarLuminosidade(cor: string, percentagem: number): string {
    let r = parseInt(cor.slice(1, 3), 16);
    let g = parseInt(cor.slice(3, 5), 16);
    let b = parseInt(cor.slice(5, 7), 16);
    r = Math.min(255, Math.max(0, r + r * percentagem));
    g = Math.min(255, Math.max(0, g + g * percentagem));
    b = Math.min(255, Math.max(0, b + b * percentagem));
    return `#${(r | (1 << 8)).toString(16).slice(1)}${(g | (1 << 8)).toString(16).slice(1)}${(b | (1 << 8)).toString(16).slice(1)}`;
  }
}
