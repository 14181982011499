<!-- {{NOTGENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div [class.modal-header]="isModal">
    <div class="t-header-title" l10nTranslate>MeusDadosForm.HEADERTITLE</div>
    @if (isModal) {
      <button type="button" class="btn-close" (click)="OnCloseModal(false)" aria-label="Close"></button>
    }
  </div>
  <!-- class.modal-header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body/R -->
  <div [class.modal-body]="isModal">
    <!-- #E-#start-body -->
    <!-- #I-#field-md/R -->
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-xl-4 p-1">
          <div class="card h-100">
            <div class="d-flex flex-column justify-content-center p-3">
              <div class="d-flex justify-content-center mt-4">
                <app-avatar [avatar]="avatar"></app-avatar>
              </div>
              <div class="d-flex justify-content-center">
                <button type="button" (click)="toggleFoto()" class="btn btn-link">Editar</button>
              </div>
              <div class="d-flex justify-content-center mt-4">
                <div class="list-group">
                  <a
                    href="#"
                    class="list-group-item list-group-item-action"
                    [class.active]="controle_lista?.geral"
                    aria-current="b_opcao_geral"
                    (click)="OnMenuClick('geral')"
                    l10nTranslate
                    ><i class="fa fa-fw fa-user me-2" aria-hidden="true"></i>MeusDadosForm.M-GERAL</a
                  >
                  <a
                    href="#"
                    class="list-group-item list-group-item-action"
                    [class.active]="controle_lista?.usuarios"
                    aria-current="b_opcao_usuarios"
                    (click)="OnMenuClick('usuarios')"
                    l10nTranslate
                    ><i class="fa fa-fw fa-users me-2" aria-hidden="true"></i>MeusDadosForm.M-USUARIOS</a
                  >
                  <a
                    href="#"
                    class="list-group-item list-group-item-action"
                    [class.active]="controle_lista?.auditoria"
                    aria-current="b_opcao_auditoria"
                    (click)="OnMenuClick('auditoria')"
                    l10nTranslate
                    ><i class="fa fa-fw fa-search me-2" aria-hidden="true"></i>MeusDadosForm.M-AUDITORIA</a
                  >
                  <a
                    href="#"
                    class="list-group-item list-group-item-action"
                    [class.active]="controle_lista?.acesso"
                    aria-current="b_opcao_acesso"
                    (click)="OnMenuClick('acesso')"
                    l10nTranslate
                    ><i class="fa fa-fw fa-lock me-2" aria-hidden="true"></i>MeusDadosForm.M-ACESSO</a
                  >
                  <a
                    href="#"
                    class="list-group-item list-group-item-action"
                    [class.active]="controle_lista?.moeda"
                    aria-current="b_opcao_moeda"
                    (click)="OnMenuClick('moeda')"
                    l10nTranslate
                    ><i class="fa fa-fw fa-money me-2" aria-hidden="true"></i>MeusDadosForm.M-MOEDA</a
                  >
                  <a
                    href="#"
                    class="list-group-item list-group-item-action"
                    [class.active]="controle_lista?.restricoes"
                    aria-current="b_opcao_restricoes"
                    (click)="OnMenuClick('restricoes')"
                    l10nTranslate
                    ><i class="fa fa-fw fa-user-secret me-2" aria-hidden="true"></i>MeusDadosForm.M-RESTRICOES</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-xl-8 p-1">
          <div class="card h-100">
            @if (controle_lista?.geral) {
              <div class="p-1 pt-3 w-100">
                <app-user-profile-form (onOk)="onOK()"></app-user-profile-form>
              </div>
            }
            <!-- #E-#field-md -->
            <!-- #I-#field-cp/R -->
            @if (controle_lista?.acesso) {
              <div class="p-1 pt-3 w-100">
                <app-change-password-form (onOk)="onOK()"></app-change-password-form>
              </div>
            }
            @if (controle_lista?.moeda) {
              <div class="p-1 pt-3 w-100">
                <app-moeda-padrao-selecao-fragment (onOk)="onOK()"></app-moeda-padrao-selecao-fragment>
              </div>
            }
          </div>
        </div>

        <!-- #E-#field-cp -->
        <!-- #I-#end-body/R -->
      </div>
    </div>
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form -->
  <div [class.modal-footer]="isModal"></div>
  <!-- class.modal-footer -->
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
<!-- #I-#On-Menu-Menuprofile/R -->
<!-- #E-#On-Menu-Menuprofile -->
