// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum AuthorityEnum {
  USER = 'USER',
  GESTOR = 'GESTOR',
  OPERADOR = 'OPERADOR',
  ADMIN = 'ADMIN',
  HOLDER = 'HOLDER',
}

// #region optionlist#method.
// CODGEN
export class AuthorityEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'USER', name: 'USER' },
      { id: 'GESTOR', name: 'GESTOR' },
      { id: 'OPERADOR', name: 'OPERADOR' },
      { id: 'ADMIN', name: 'ADMIN' },
      { id: 'HOLDER', name: 'HOLDER' },
    ];
  }
  static authorityList(roles: AuthorityEnum[]): string {
    let v = '';
    let vg = '';
    for (const item of roles) {
      v += vg + item;
      vg = ', ';
    }
    return v;
  }
}
// #endregion

// #region #declarations#.
// #endregion
