// {{GENERATE}}
// @CRUD
// @GRID
// @HOST
// URL:/financeiro/categoria
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { BagParameter } from 'src/app/core/models/BagParameter';
import { ResponseDataBean } from 'src/app/core/models/ResponseDataBean';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { UserPainelConfigBean } from 'src/app/modules/admin/models/UserPainelConfigBean';
import { ArvoreCategoria } from '../../models/ArvoreCategoria';
import { CategoriaBean } from '../../models/CategoriaBean';
import { ContaMoedaBean } from '../../models/ContaMoedaBean';
import { EntityDescriptionBean } from '../../models/EntityDescriptionBean';
import { EntityDescriptionRequest } from '../../models/EntityDescriptionRequest';
import { CategoriaActionService } from '../categoria-action.service';

@Injectable()
export class CategoriaActionImplService implements CategoriaActionService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #getallcategoria.
  // CODGEN {{R}}
  GetAllCategoria(par: string): Observable<CategoriaBean[]> {
    const url = this.helper.ToURL('/financeiro/categoria/getAllCategoria');
    const params = new HttpParams().append('par', par);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<CategoriaBean[]>(url, headers);
  }
  // #endregion

  // #region #crud#createcategoria.
  // CODGEN {{R}}
  CreateCategoria(bean: CategoriaBean): Observable<CategoriaBean> {
    const url = this.helper.ToURL('/financeiro/categoria/createCategoria');
    const headers = this.helper.GetHeaders();
    return this.http.post<CategoriaBean>(url, bean, headers);
  }
  // #endregion

  // #region #crud#updatecategoria.
  // CODGEN {{R}}
  UpdateCategoria(bean: CategoriaBean): Observable<void> {
    const url = this.helper.ToURL('/financeiro/categoria/updateCategoria');
    const headers = this.helper.GetHeaders();
    return this.http.put<void>(url, bean, headers);
  }
  // #endregion

  // #region #crud#getreadcategoria.
  // CODGEN {{R}}
  GetReadCategoria(id: number): Observable<CategoriaBean> {
    const url = this.helper.ToURL('/financeiro/categoria/readCategoria');
    const params = new HttpParams().append('id', id.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.get<CategoriaBean>(url, headers);
  }
  // #endregion

  // #region #crud#removecategoria.
  // CODGEN {{R}}
  RemoveCategoria(id: number): Observable<void> {
    const url = this.helper.ToURL('/financeiro/categoria/removeCategoria');
    const params = new HttpParams().append('id', id.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, headers);
  }
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/financeiro/categoria/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/financeiro/categoria/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region metodo#get-arvore-categoria.
  // CODGEN {{R}}
  GetArvoreCategoria(opcoes: BagParameter): Observable<ArvoreCategoria> {
    const url = this.helper.ToURL('/financeiro/categoria/getarvorecategoria');
    const headers = this.helper.GetHeaders();
    return this.http.post<ArvoreCategoria>(url, opcoes, headers);
  }
  // #endregion

  // #region metodo#get-entity-description.
  // CODGEN {{R}}
  GetEntityDescription(items: EntityDescriptionRequest): Observable<EntityDescriptionBean> {
    const url = this.helper.ToURL('/financeiro/categoria/getentitydescription');
    const headers = this.helper.GetHeaders();
    return this.http.post<EntityDescriptionBean>(url, items, headers);
  }
  // #endregion

  // #region metodo#get-conta-moeda-list.
  // CODGEN {{R}}
  GetContaMoedaList(): Observable<ContaMoedaBean[]> {
    const url = this.helper.ToURL('/financeiro/categoria/getcontamoedalist');
    const params = new HttpParams();
    const headers = this.helper.GetHeaders(params);
    return this.http.get<ContaMoedaBean[]>(url, headers);
  }
  // #endregion

  // #region metodo#get-painel-config.
  // CODGEN {{R}}
  GetPainelConfig(nome: string): Observable<UserPainelConfigBean> {
    const url = this.helper.ToURL('/financeiro/categoria/getpainelconfig');
    const params = new HttpParams().append('nome', nome);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<UserPainelConfigBean>(url, headers);
  }
  // #endregion

  // #region metodo#save-painel-config.
  // CODGEN {{R}}
  SavePainelConfig(painel: UserPainelConfigBean): Observable<ResponseDataBean> {
    const url = this.helper.ToURL('/financeiro/categoria/savepainelconfig');
    const headers = this.helper.GetHeaders();
    return this.http.post<ResponseDataBean>(url, painel, headers);
  }
  // #endregion

  // #region #declarations#.
  InativarCategoria(id: number): Observable<void> {
    const url = this.helper.ToURL('/financeiro/categoria/inativarCategoria');
    let params = new HttpParams();
    params = params.append('id', id.toString());
    const header = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, header);
  }

  AtivarCategoria(id: number): Observable<void> {
    const url = this.helper.ToURL('/financeiro/categoria/ativarCategoria');
    let params = new HttpParams();
    params = params.append('id', id.toString());
    const header = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, header);
  }

  // #endregion
}
