<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>DadosFiscaisBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-cpfcnpj/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['cpfcnpj']" class="form-label" l10nTranslate>DadosFiscaisBeanFragment.cpfcnpj</label>
      <input
        type="text"
        [id]="fieldIdTable['cpfcnpj']"
        formControlName="cpfcnpj"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.cpfcnpj.invalid && (f.cpfcnpj.dirty || f.cpfcnpj.touched) }"
        appCpfCnpjMask
      />
      @if (f.cpfcnpj.errors) {
        <div class="invalid-feedback">
          @if (f.cpfcnpj.errors.required) {
            <div l10nTranslate>DadosFiscaisBeanFragment.cpfcnpj-REQUIRED</div>
          }
          @if (f.cpfcnpj.errors.cpfcnpj) {
            <div l10nTranslate>DadosFiscaisBeanFragment.cpfcnpj-CPFCNPJ</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-cpfcnpj -->
    <!-- #I-#field-razaosocial/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['razaosocial']" class="form-label" l10nTranslate
        >DadosFiscaisBeanFragment.razaosocial</label
      >
      <input
        type="text"
        [id]="fieldIdTable['razaosocial']"
        formControlName="razaosocial"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.razaosocial.invalid && (f.razaosocial.dirty || f.razaosocial.touched) }"
        maxlength="200"
      />
      @if (f.razaosocial.errors) {
        <div class="invalid-feedback">
          @if (f.razaosocial.errors.required) {
            <div l10nTranslate>DadosFiscaisBeanFragment.razaosocial-REQUIRED</div>
          }
          @if (f.razaosocial.errors.maxlength) {
            <div l10nTranslate>DadosFiscaisBeanFragment.razaosocial-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-razaosocial -->
    <!-- #I-#field-inscricaomunicipal/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['inscricaomunicipal']" class="form-label" l10nTranslate
        >DadosFiscaisBeanFragment.inscricaomunicipal</label
      >
      <input
        type="text"
        [id]="fieldIdTable['inscricaomunicipal']"
        formControlName="inscricaomunicipal"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.inscricaomunicipal.invalid && (f.inscricaomunicipal.dirty || f.inscricaomunicipal.touched),
        }"
        maxlength="40"
      />
      @if (f.inscricaomunicipal.errors) {
        <div class="invalid-feedback">
          @if (f.inscricaomunicipal.errors.maxlength) {
            <div l10nTranslate>DadosFiscaisBeanFragment.inscricaomunicipal-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-inscricaomunicipal -->
    <!-- #I-#field-inscricaoestadual/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['inscricaoestadual']" class="form-label" l10nTranslate
        >DadosFiscaisBeanFragment.inscricaoestadual</label
      >
      <input
        type="text"
        [id]="fieldIdTable['inscricaoestadual']"
        formControlName="inscricaoestadual"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.inscricaoestadual.invalid && (f.inscricaoestadual.dirty || f.inscricaoestadual.touched),
        }"
        maxlength="40"
      />
      @if (f.inscricaoestadual.errors) {
        <div class="invalid-feedback">
          @if (f.inscricaoestadual.errors.maxlength) {
            <div l10nTranslate>DadosFiscaisBeanFragment.inscricaoestadual-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-inscricaoestadual -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
