// {{GENERATE}}
// @fragment
// @ListFragment
// @Wrapper:TbContaTransacao
// URL:/financeiro/lancamento
import { Injectable } from '@angular/core';
import { TipoFinanceiroEnum } from 'src/app/modules/financeiro/models/TipoFinanceiroEnum';
import { EntityDescriptionItem } from './EntityDescriptionItem';
import { EntityKey } from './EntityKey';
import { TipoEntityEnum } from './TipoEntityEnum';

export interface LancamentoDetalheBean {
  idlancamentodetalhe?: number;
  tipofinanceiro?: TipoFinanceiroEnum;
  valor?: number;
  valorprevisto?: number;
  valorefetivo?: number;
  descricao?: string;
  idcategoria?: number;
  idcontato?: number;
  idcentrocusto?: number;
  idprojeto?: number;
  listatags?: number[];
  relatedentities?: EntityDescriptionItem[];
}

// #region #declarations#.

@Injectable()
export class LancamentoDetalhe {
  public static extractEntityKeyList(item: LancamentoDetalheBean): EntityKey[] {
    const keys: EntityKey[] = [];
    LancamentoDetalhe.addIgnoreNull(keys, { tipo: TipoEntityEnum.CAT, id: item.idcategoria });
    LancamentoDetalhe.addIgnoreNull(keys, { tipo: TipoEntityEnum.CST, id: item.idcentrocusto });
    LancamentoDetalhe.addIgnoreNull(keys, { tipo: TipoEntityEnum.CON, id: item.idcontato });
    LancamentoDetalhe.addIgnoreNull(keys, { tipo: TipoEntityEnum.PRJ, id: item.idprojeto });
    if (item.listatags && item.listatags.length > 0) {
      for (let i = 0; i < item.listatags.length; i++) {
        LancamentoDetalhe.addIgnoreNull(keys, { tipo: TipoEntityEnum.TAG, id: item.listatags[i] });
      }
    }
    return keys;
  }

  public static addIgnoreNull(keys: EntityKey[], node: EntityKey): void {
    if (node && node.id && node.id > 0) {
      keys.push(node);
    }
  }
}

// #endregion
