export class TablePainelContext {
  private table = new Map<string, any>();

  constructor() {}

  save(key: string, value: any) {
    this.table.set(key, value);
    //console.log('SAVE-->' + key + '=' + value);
  }

  read(key: string): any {
    let val = this.table.get(key);
    //console.log('READ-->' + key + '=' + val);
    if (!val) {
      const nkey = key.replace(/^.*\./, '');
      val = this.table.get(nkey);
      //console.log('READ-->' + nkey + '=' + val);
    }
    return val;
  }

  remove(key: string) {
    this.table.delete(key);
  }

  clear() {
    this.table.clear();
  }
}
