import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdComponent } from './ad.component';
import { AdDadosElement } from './ad-dados_element';
import { JsonPipe } from '@angular/common';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [FormsModule, JsonPipe],
  template: `
    <div class="job-ad">
      <div>{{ data.id }}</div>
      {{ data.card | json }}
      {{ periodo }}
    </div>
  `,
})
export class HeroJobAdComponent implements AdComponent, OnInit, OnDestroy {
  private readonly PERIODO_KEY = 'home.event.periodo';
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  protected periodo: NgbDateStruct;
  @Input() data: AdDadosElement;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataService.getSubject().subscribe({
        next: (evt) => {
          if (evt) {
            if (evt.cmd === 'CLEAR' || evt.key === this.PERIODO_KEY) {
              this.atualizaDados();
            }
          }
        },
      }),
    );
    this.atualizaDados();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private atualizaDados() {
    this.periodo = this.dataService.read(this.PERIODO_KEY);
  }
}
