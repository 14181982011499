// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { TipoFinanceiroEnum } from './TipoFinanceiroEnum';

export const enum TipoLancamentoEnum {
  DESPESA = 'DESPESA',
  RECEITA = 'RECEITA',
  TRANSFERENCIA = 'TRANSFERENCIA',
  LAN_DETALHADO = 'LAN_DETALHADO',
}

// #region optionlist#method.
// CODGEN
export class TipoLancamentoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'DESPESA', name: 'DESPESA' },
      { id: 'RECEITA', name: 'RECEITA' },
      { id: 'TRANSFERENCIA', name: 'TRANSFERENCIA' },
      { id: 'LAN_DETALHADO', name: 'LAN_DETALHADO' },
    ];
  }

  static toTipoFinanceiroEnum(e: TipoLancamentoEnum | null): TipoFinanceiroEnum | null {
    if (!e) {
      return null;
    }
    switch (e) {
      case TipoLancamentoEnum.DESPESA:
        return TipoFinanceiroEnum.DESPESA;
      case TipoLancamentoEnum.TRANSFERENCIA:
        return TipoFinanceiroEnum.TRANSFERENCIA;
      case TipoLancamentoEnum.RECEITA:
        return TipoFinanceiroEnum.RECEITA;
      case TipoLancamentoEnum.LAN_DETALHADO:
      default:
        return null;
    }
  }

  static stringToEnum(tipo: 'DESPESA' | 'RECEITA' | 'TRANSFERENCIA' | 'LAN_DETALHADO'): TipoLancamentoEnum {
    switch (tipo) {
      case 'DESPESA':
        return TipoLancamentoEnum.DESPESA;
      case 'RECEITA':
        return TipoLancamentoEnum.RECEITA;
      case 'TRANSFERENCIA':
        return TipoLancamentoEnum.TRANSFERENCIA;
      case 'LAN_DETALHADO':
      default:
        return TipoLancamentoEnum.LAN_DETALHADO;
    }
  }
}
// #endregion

// #region #declarations#.

// #endregion
