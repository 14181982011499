import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangepasswordBean } from 'src/app/core/models/ChangepasswordBean';
import { LoginRequest } from 'src/app/core/models/LoginRequest';
import { MenuTree } from 'src/app/core/models/MenuTree';
import { SecurityToken } from 'src/app/core/models/SecurityToken';
import { UserBean } from 'src/app/core/models/UserBean';
import { BrokerProfileBean } from '../models/BrokerProfileBean';
import { UserFavoritoBean } from '../models/UserFavoritoBean';

@Injectable()
export abstract class AuthenticationService {
  // v1/aaa/sss
  abstract PostLogin(parametro: LoginRequest): Observable<SecurityToken>;

  abstract GetLogout(): Observable<string>;

  abstract GetFindMenuTree(): Observable<MenuTree>;

  abstract PostChangepassword(par: ChangepasswordBean): Observable<any>;

  abstract PostUpdateChangepassword(bean: UserBean): Observable<any>;

  abstract PostUpdateUserFavoritos(bean: UserFavoritoBean): Observable<any>;

  abstract GetBrokerProfileBean(): Observable<BrokerProfileBean>;
}
