// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum StatusLancamentoEnum {
  PENDENTE = 'PENDENTE',
  AGENDADO = 'AGENDADO',
  CONFIRMADO = 'CONFIRMADO',
  CONCILIADO = 'CONCILIADO',
  NAO_CONCILIADO = 'NAO_CONCILIADO',
}

// #region optionlist#method.
// CODGEN {{R}}
export class StatusLancamentoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'PENDENTE', name: 'PENDENTE' },
      { id: 'AGENDADO', name: 'AGENDADO' },
      { id: 'CONFIRMADO', name: 'CONFIRMADO' },
      { id: 'CONCILIADO', name: 'CONCILIADO' },
      { id: 'NAO_CONCILIADO', name: 'NAO_CONCILIADO' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
