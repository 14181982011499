import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppVersion } from '../../services/AppVersion';
import { AlertComponent } from '../header-principal/alert.component';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login.layout.component.html',
  styleUrls: ['./login.layout.component.css'],
  standalone: true,
  imports: [RouterOutlet, AlertComponent],
})
export class LoginLayoutComponent {
  version = AppVersion.Version;
}
