// {{NOTGENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { StatusEnumRepo } from 'src/app/core/models/StatusEnum';
import { AlertService } from 'src/app/core/services/alert.service';
import { SelectOptionService } from 'src/app/core/services/select-option.service';
import { TipoFinanceiroEnumRepo } from '../../../models/TipoFinanceiroEnum';
import { Subscription } from 'rxjs';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-categoria-bean-fragment',
  templateUrl: './categoria-bean-fragment.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgxMaskDirective,
  ],
})
export class CategoriaBeanFragmentComponent implements OnInit, OnDestroy, AfterViewInit {
  // #region #properties#.
  protected fieldIdTable = {};
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  // #endregion

  private instanceId = Constants.GetId();
  private rootformid: string;
  private myname: string;
  public contexto = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  private idsubcategoriadeList: SelectOptionItem[] = [];
  listCategoriaPaiFiltrada: SelectOptionItem[] = [];
  tipoList = TipoFinanceiroEnumRepo.optionList();
  statusList = StatusEnumRepo.optionList();

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private optionService: SelectOptionService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.OnInitInvokeService();
  }

  ngOnDestroy() {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN
  /*
   * Quando troca o Tipo financeiro,
   * refaz a lista de pais em função do novo tipo.
   */
  TrataValueChanges(): void {
    const valueChangeSub = this.f.tipo.valueChanges.subscribe((change) => {
      console.log('TIPO MUDOU:', change);
      this.FiltraListaCategoriasPai(change);
      this.ConsisteCategoriaPaiAndReset();
    });
    this.subscriptions.push(valueChangeSub);
  }

  FiltraListaCategoriasPai(tipo: string): void {
    //console.log('FiltraListaCategoriasPai Tipo:', tipo);
    if (tipo && (tipo === 'DESPESA' || tipo === 'RECEITA')) {
      const lista: SelectOptionItem[] = [];
      if (!this.IsEditMode()) {
        lista.push({ id: '0', name: '---', tag: '' });
      }
      for (const item of this.idsubcategoriadeList) {
        if (item.tag === tipo) {
          lista.push(item);
        }
      }
      this.listCategoriaPaiFiltrada = [...lista];
    } else {
      this.listCategoriaPaiFiltrada = [...this.idsubcategoriadeList];
    }
  }

  ConsisteCategoriaPaiAndReset(): void {
    if (this.f.tipo?.enabled && this.f.idsubcategoriade?.value && this.f.tipo?.value) {
      const idsubcategoriade = this.f.idsubcategoriade.value;
      for (const item of this.listCategoriaPaiFiltrada) {
        if (item.id === idsubcategoriade) {
          /* OK */
          return;
        }
      }
      console.log('Reset Categoria Pai:', this.f.tipo.value, idsubcategoriade);
      this.f.idsubcategoriade.reset();
    }
  }
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN
  SetValoresDefault(): void {
    console.log(
      '-->SetValoresDefault MODO:',
      this.IsEditMode() ? 'EDIT' : 'ADD',
      'VALORES:',
      this.CrudFormToRegistro(),
    );

    if (this.IsEditMode()) {
      this.CrudForm.get('tipo')?.disable();
      const idsubcategoriade = this.f.idsubcategoriade.value;
      if (!idsubcategoriade) {
        this.CrudForm.get('idsubcategoriade')?.disable();
      }
    } else {
      const tipo = this.f.tipo.value;
      if (tipo) {
        this.CrudForm.get('tipo')?.disable();
      }
      const idsubcategoriade = this.f.idsubcategoriade.value;
      if (idsubcategoriade) {
        this.CrudForm.get('idsubcategoriade')?.disable();
      }
    }
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN
  OnInitInvokeService() {
    this.OnLoadSelectedRows();
  }
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onloadselectedrows.
  // CODGEN
  OnLoadSelectedRows() {
    console.log('OnLoadSelectedRows');
    this.optionService.GetSelectOptions('/financeiro/categoria', 'CATEGORIABEAN', 'ONLY-SUPER').subscribe({
      next: (data) => {
        this.idsubcategoriadeList = data.items;
        this.FiltraListaCategoriasPai(this.f.tipo.value);
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      tipo: [null, Validators.required],
      nome: [null, Validators.required],
      idsubcategoriade: [null],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(_validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return {
      tipo: this.CrudForm.get('tipo')?.value,
      nome: this.CrudForm.get('nome')?.value,
      idsubcategoriade: this.CrudForm.get('idsubcategoriade')?.value,
    };
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
  // #region #declarations#.
  // #endregion
}
