<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div [class.modal-header]="isModal">
    <div class="t-header-title" l10nTranslate>ReportPanel.HEADERTITLE</div>
    @if (isModal) {
      <button type="button" class="btn-close" (click)="OnCloseModal(false)" aria-label="Close"></button>
    }
  </div>
  <!-- class.modal-header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body/R -->
  <div [class.modal-body]="isModal">
    <!-- #E-#start-body -->
    <!-- #I-#end-body/R -->
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form/R -->
  <div [class.modal-footer]="isModal">
    <div class="d-flex align-content-start flex-wrap"></div>
  </div>
  <!-- class.modal-footer -->
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
