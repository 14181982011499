// {{GENERATE}}
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { CrudModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { TagBean } from '../../../models/TagBean';
import { TagActionService } from '../../../services/tag-action.service';
import { TagBeanFragmentComponent } from '../tag-bean/tag-bean-fragment.component';
import { ColorService } from 'src/app/core/services/color.service';
import { Utilities } from 'src/app/core/services/utilities';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-tag-action-add',
  templateUrl: './tag-action-add.component.html',
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    TagBeanFragmentComponent,
  ],
  providers: [],
})
export class TagActionAddComponent implements CrudModelComponent, OnInit, AfterViewInit {
  // #region #properties#.
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  registro: TagBean;
  masks = Constants;
  @ViewChild('vtag', { static: true }) childTag: TagBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: TagActionService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public translation: L10nTranslationService,
    public colorService: ColorService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onsubmit.
  // CODGEN {{R}}
  onSubmit(opcao = '') {
    // stop here if form is invalid
    if (this.CrudForm.invalid) {
      return;
    }

    this.registro = Object.assign({}, this.registro, this.CrudFormToRegistro());
    if (this.viewType === ViewTypeEnum.ADD) {
      this.crudService.CreateTag(this.registro).subscribe({
        next: (data) => {
          this.registro = data;
          this.OnCloseModal(true, opcao === '+');
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    } else {
      this.crudService.UpdateTag(this.registro).subscribe({
        next: () => {
          this.OnCloseModal(true);
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN
  OnInitInvokeService() {
    if (this.viewType === ViewTypeEnum.READ) {
      this.crudService.GetReadTag(this.registro.idtag).subscribe({
        next: (data) => {
          try {
            this.registro = data;
            this.CrudForm.patchValue(this.registro);
          } catch (error) {
            console.log('erro:', error);
            this.alertService.error('Erro no carregamento dos dados.');
          }
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    } else {
      this.f.labelcor.patchValue(this.colorService.getRandomColor());
    }
  }
  // #endregion

  IsEditMode(): boolean {
    return this.viewType === ViewTypeEnum.READ;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      registro: this.registro,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return this.childTag.CrudFormValidators(this.rootformid);
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childTag.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return this.childTag.CrudFormToRegistro();
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
