import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpHeaders } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, Injectable, ModuleWithProviders, Optional } from '@angular/core';
import { L10nConfig, L10nLoader, L10nProvider, L10nTranslationLoader } from 'angular-l10n';
import { Observable } from 'rxjs';
import { AppExceptionHandler } from './core/helpers/app-exception-handler';
import { Jwt401Interceptor } from './core/helpers/jwt-401.interceptor';

// locale
export const l10nConfig: L10nConfig = {
  format: 'language-region',
  defaultLocale: { language: 'pt-BR', currency: 'BRL', timeZone: 'America/Sao_Paulo' },
  cache: true,
  keySeparator: '.',
  providers: [
    { name: 'locale', asset: '/assets/locale/locale', options: { version: '1.0.0' } },
    { name: 'admin-locale', asset: '/assets/locale/admin-locale', options: { version: '1.0.0' } },
    { name: 'documento-locale', asset: '/assets/locale/documento-locale', options: { version: '1.0.0' } },
    { name: 'financeiro-locale', asset: '/assets/locale/financeiro-locale', options: { version: '1.0.0' } },
    { name: 'agro-locale', asset: '/assets/locale/agro-locale', options: { version: '1.0.0' } },
    { name: 'estoque-locale', asset: '/assets/locale/estoque-locale', options: { version: '1.0.0' } },
    { name: 'manejoanimal-locale', asset: '/assets/locale/manejoanimal-locale', options: { version: '1.0.0' } },
    { name: 'pessoas-locale', asset: '/assets/locale/pessoas-locale', options: { version: '1.0.0' } },
    { name: 'reprodutivo-locale', asset: '/assets/locale/reprodutivo-locale', options: { version: '1.0.0' } },
  ],
  schema: [
    { locale: { language: 'en-US', currency: 'USD' }, dir: 'ltr', text: 'United States' },
    { locale: { language: 'pt-BR', currency: 'BRL', timeZone: 'America/Sao_Paulo' }, dir: 'ltr', text: 'Brasil' },
  ],
};

registerLocaleData(localePt);

// Advanced initialization.
export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init();
}

@Injectable()
export class HttpTranslationLoader implements L10nTranslationLoader {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(@Optional() private remoteService: HttpClient) {}
  public get(language: string, provider: L10nProvider): Observable<{ [key: string]: any }> {
    const url = `${provider.asset}-${language}.json`;
    const options = {
      headers: this.headers,
    };
    return this.remoteService.get(url, options);
  }
}

export class ErrorHandlingModule {
  static forRoot(): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule: ErrorHandlingModule,
      providers: [
        { provide: ErrorHandler, useClass: AppExceptionHandler },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Jwt401Interceptor,
          multi: true,
        },
      ],
    };
  }
}
// APP_INITIALIZER will execute the function when the app is initialized and delay what it provides.
