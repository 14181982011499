<!-- {{NOTGENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container>
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div [class.modal-header]="isModal">
    <div class="t-header-title" l10nTranslate>EditarFotoPerfilForm.HEADERTITLE</div>
    @if (isModal) {
      <button type="button" class="btn-close" (click)="OnCloseModal(false)" aria-label="Close"></button>
    }
  </div>
  <!-- class.modal-header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body/R -->
  <div [class.modal-body]="isModal">
    <!-- #E-#start-body -->
    <!-- #I-#field-imagem -->
    <div class="row">
      <div class="col text-center">
        <p>Uma foto ajuda as pessoas a reconhecerem você e permite que você saiba quando a conta está conectada.</p>
      </div>
    </div>
    <div class="row">
      <div class="col text-center mb-3">
        <div>
          <a href="javascript:void(0)" (click)="file.click()">Click aqui para fazer upload do computador...</a>
          <input
            type="file"
            #file
            accept="image/*"
            (change)="fileChangeEvent($event)"
            onclick="this.value = null"
            style="display: none"
          />
        </div>
      </div>
    </div>
    <div class="row" [ngClass]="{ 'd-none': imageChangedEvent === '' }">
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="form-group">
              <button type="button" class="btn" (click)="rotateRight()">
                <i class="fa fa-fw fa-rotate-right"></i>Girar
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-center">
            <image-cropper
              class="mb-2"
              output="base64"
              [ngStyle]="{ width: '300px' }"
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded($event)"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()"
              [roundCropper]="true"
              [canvasRotation]="canvasRotation"
              [transform]="transform"
            ></image-cropper>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mb-3 d-flex justify-content-center">
        <img [src]="croppedImage" [ngStyle]="{ width: '100px', height: '100px', borderRadius: '50%' }" />
        <!-- #I-#end-body/R -->
      </div>
    </div>
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form/R -->
  <div [class.modal-footer]="isModal">
    <div class="d-flex align-content-start flex-wrap">
      @if (isModal) {
        <button type="button" class="btn btn-secondary me-2" (click)="OnCloseModal(false)" l10nTranslate>
          <i class="fa fa-times"></i> EditarFotoPerfilForm.BTN-BACK
        </button>
      }
      <button
        type="button"
        class="btn btn-primary"
        (click)="OnProcessar()"
        [disabled]="imageChangedEvent === ''"
        l10nTranslate
      >
        <i class="fa fa-save"></i> EditarFotoPerfilForm.BTN-ENTER
      </button>
    </div>
  </div>
  <!-- class.modal-footer -->
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
