<!-- {{NOTGENERATE}} -->
<ng-container>
  <div class="row">
    <div class="col">
      <table class="table table-sm w-100">
        <tbody>
          @for (item of rowList; track item.name; let i = $index) {
            <tr [class.table-secondary]="item.marca">
              <th>
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  aria-label="..."
                  (click)="onClick(item)"
                  [checked]="item.status"
                />
              </th>
              <th>
                <div l10nTranslate>PaineisFinanceirosEnum.{{ item.name }}</div>
              </th>
              <th>
                <button type="button" class="btn btn-light btn-sm me-1" (click)="onClickCmd('UP', i)">
                  <i class="bi bi-arrow-up"></i>
                </button>
                <button type="button" class="btn btn-light btn-sm" (click)="onClickCmd('DOWN', i)">
                  <i class="bi bi-arrow-down"></i>
                </button>
              </th>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
<!--
-->
