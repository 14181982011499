// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Observable, Subscription, combineLatest, tap } from 'rxjs';
import { TxDatePickerFragmentComponent } from 'src/app/core/components/ngb-ctrls/tx-date-picker/tx-date-picker-fragment.component';
import { NumberOnlyDirective } from 'src/app/core/directives/number-only.directive';
import { IconesHelper } from 'src/app/core/helpers/IconesHelper';
import { SelectOptionUtils } from 'src/app/core/helpers/SelectOptionUtils';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { BagParameterObject } from 'src/app/core/models/BagParameter';
import { BrokerProfileHelper } from 'src/app/core/models/BrokerProfileBean';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { StatusEnumRepo } from 'src/app/core/models/StatusEnum';
import { CrudModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { BrokerModuleProfileService } from 'src/app/core/services/broker-module-profile.service';
import { SelectOptionService } from 'src/app/core/services/select-option.service';
import { SystemModuleEnum } from 'src/app/modules/admin/models/SystemModuleEnum';
import { DisponibilidadeSaldoEnum, DisponibilidadeSaldoEnumRepo } from '../../../models/DisponibilidadeSaldoEnum';
import { MoedaHelper } from '../../../models/MoedaBean';
import { TipoContaEnum, TipoContaEnumRepo } from '../../../models/TipoContaEnum';
import { CartaoCreditoBeanFragmentComponent } from '../cartao-credito-bean/cartao-credito-bean-fragment.component';
import { ContaBancoBeanFragmentComponent } from '../conta-banco-bean/conta-banco-bean-fragment.component';
import { SaldoFinanceiroBeanFragmentComponent } from '../saldo-financeiro-bean/saldo-financeiro-bean-fragment.component';
import { SelectIconModalComponent } from '../select-icon-modal/select-icon-modal.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-conta-bean-fragment',
  templateUrl: './conta-bean-fragment.component.html',
  styleUrls: ['./conta-bean-fragment.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NumberOnlyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    SaldoFinanceiroBeanFragmentComponent,
    TxDatePickerFragmentComponent,
    ContaBancoBeanFragmentComponent,
    CartaoCreditoBeanFragmentComponent,
  ],
  providers: [provideNgxMask()],
})
export class ContaBeanFragmentComponent implements OnInit, OnDestroy, AfterViewInit {
  // #region #properties#.
  public parentCrud: CrudModelComponent;
  private timestamp_instance = Math.trunc(new Date().getTime() / 10000);
  public toogle_conta_banco = false;
  private lastProfileFinanceiro: BagParameterObject = null;
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  moedaList: SelectOptionItem[] = [];
  tipocontaList: SelectOptionItem[] = TipoContaEnumRepo.optionList();
  tipodisponibilidadesaldoList: SelectOptionItem[] = DisponibilidadeSaldoEnumRepo.optionList();
  @ViewChild('vdatasaldoinicial', { static: true }) childDatasaldoinicial: TxDatePickerFragmentComponent;
  @ViewChild('vsaldoinicial', { static: true }) childSaldoinicial: SaldoFinanceiroBeanFragmentComponent;
  @ViewChild('vcontabanco', { static: true }) childContabanco: ContaBancoBeanFragmentComponent;
  @ViewChild('vcartaocredito', { static: true }) childCartaocredito: CartaoCreditoBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private httpHelper: HttpHelper,
    private optionService: SelectOptionService,
    private profileService: BrokerModuleProfileService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.fieldsDdadosIniciais();
    this.OnInitInvokeService();
    this.OnInvokeSelectOptionsMoeda();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  fieldsDdadosIniciais(): void {
    this.lastProfileFinanceiro = BrokerProfileHelper.FindProfile(
      this.profileService.getProfile(),
      SystemModuleEnum.FINANCEIRO,
    );
    const moeda = MoedaHelper.GetMoeda(this.lastProfileFinanceiro);
    this.f.contabanco.disable();
    this.f.cartaocredito.disable();
    this.f.moeda.patchValue(parseInt(`${moeda.idmoeda}`, 10));
    this.f.tipoconta.patchValue(TipoContaEnum.DINHEIRO);
    this.f.datasaldoinicial.patchValue({ srcdate: Constants.ToNgbDateNow() });
    this.f.saldoinicial.patchValue({ valorsaldo: '0,00', indicadorsaldo: 'CREDOR' });
    this.f.tipodisponibilidadesaldo.patchValue(DisponibilidadeSaldoEnum.IMEDIATO);
    this.f.idiconeconta.patchValue('moeda-01.png');
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN
  TrataValueChanges(): void {
    const valueChangeSub = this.f.tipoconta.valueChanges.subscribe((change) => {
      console.log('TIPO CONTA MUDOU:', change);
      this.f.contabanco.disable();
      this.f.cartaocredito.disable();
      switch (change) {
        case TipoContaEnum.CONTA_CORRENTE:
          this.f.contabanco.enable();
          break;
        case TipoContaEnum.CARTAO_CREDITO:
          this.f.cartaocredito.enable();
          break;
        default:
          break;
      }
    });
    this.subscriptions.push(valueChangeSub);
  }
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    this.OnInvokeSelectCombineLatest();
  }
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onloadselectedrows.
  // CODGEN {{R}}
  OnLoadSelectedRows() {}
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      tipoconta: [null, Validators.required],
      moeda: [null, Validators.required],
      nome: [null, [Validators.required, Validators.maxLength(80)]],
      idiconeconta: [null, Validators.maxLength(80)],
      datasaldoinicial: this.formBuilder.group(
        this.childDatasaldoinicial.CrudFormValidators(this.rootformid, 'datasaldoinicial'),
      ),
      saldoinicial: this.formBuilder.group(this.childSaldoinicial.CrudFormValidators(this.rootformid, 'saldoinicial')),
      tipodisponibilidadesaldo: [null, Validators.required],
      participabalancopatrimonial: [null],
      contabanco: this.formBuilder.group(this.childContabanco.CrudFormValidators(this.rootformid, 'contabanco')),
      cartaocredito: this.formBuilder.group(
        this.childCartaocredito.CrudFormValidators(this.rootformid, 'cartaocredito'),
      ),
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childDatasaldoinicial.CrudFormGroupValidators(validators);
    this.childSaldoinicial.CrudFormGroupValidators(validators);
    this.childContabanco.CrudFormGroupValidators(validators);
    this.childCartaocredito.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      tipoconta: this.CrudForm.get('tipoconta').value,
      moeda: this.CrudForm.get('moeda').value,
      nome: this.CrudForm.get('nome').value,
      idiconeconta: this.CrudForm.get('idiconeconta').value,
      datasaldoinicial: this.childDatasaldoinicial.CrudFormToRegistro(),
      saldoinicial: this.childSaldoinicial.CrudFormToRegistro(),
      tipodisponibilidadesaldo: this.CrudForm.get('tipodisponibilidadesaldo').value,
      participabalancopatrimonial: this.CrudForm.get('participabalancopatrimonial').value,
      contabanco: this.childContabanco.CrudFormToRegistro(),
      cartaocredito: this.childCartaocredito.CrudFormToRegistro(),
    };
    return reg;
  }
  // #endregion

  // #region #onparticipabalancopatrimonialchange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnParticipabalancopatrimonialChange(_evt) {}
  // #endregion

  // #region #onsearch.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnSearch(item): void {}
  // #endregion

  // #region #customsearchfn.
  // CODGEN {{R}}
  CustomSearchFn(term: string, item: SelectOptionItem): boolean | null {
    return SelectOptionUtils.SelectOptionItemSearchFn(term, item);
  }
  // #endregion

  // #region #oninvokeselectcombinelatest.
  // CODGEN
  OnInvokeSelectCombineLatest(): void {
    const op: Observable<SelectOptionList>[] = [];
    op.push(this.OnInvokeSelectOptionsMoeda());
    combineLatest(op).subscribe({
      next: () => {
        this.parentCrud.OnInitInvokeService();
        this.OnLoadSelectedRows();
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #oninvokeselectoptionsmoeda.
  // CODGEN {{R}}
  OnInvokeSelectOptionsMoeda(): Observable<SelectOptionList> {
    const host = '/financeiro/categoria';
    return this.optionService.GetSelectOptions(host, 'MOEDABEAN', '').pipe(
      tap((data) => {
        this.moedaList = [...SelectOptionUtils.TransformToNumericId(data.items)];
      }),
    );
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion

  // #region #declarations#.
  TemContaCorrente(): boolean {
    if (this.f.tipoconta && TipoContaEnum.CONTA_CORRENTE === this.f.tipoconta?.value) {
      return true;
    }
    return false;
  }
  TemCartaoDeCredito(): boolean {
    if (this.f.tipoconta && TipoContaEnum.CARTAO_CREDITO === this.f.tipoconta?.value) {
      return true;
    }
    return false;
  }
  SelecionaIcone(): void {
    SelectIconModalComponent.OpenNewModal(this.modalService).result.then((result) => {
      if (result?.ok && result?.selecao) {
        this.f.idiconeconta.patchValue(result.selecao);
      }
    });
  }

  ContaIconeUrl(): string | null {
    return IconesHelper.ContaIconeUrl(this.httpHelper, this.f.idiconeconta?.value, this.timestamp_instance);
  }

  MoedaIconeUrl(): string | null {
    return IconesHelper.MoedaIconeUrl(this.httpHelper, this.f.moeda?.value, this.timestamp_instance);
  }
  // #endregion
}
