<div class="w-100 d-flex flex-row">
  <!-- Sidebar -->
  <div id="sidebar-container" #eMainMenu class="sidebar-expanded d-md-block border-right" [ngClass]="SidebarClass()">
    <div class="w-100 p-2">
      <span class="small-icon float-right cursor-pointer" (click)="OnSmallToggle()"
        ><i id="sidebar-icon" [ngClass]="SmallIcon()"></i
      ></span>
    </div>
    <!-- Bootstrap List Group -->
    <ul class="w-100 list-group sticky-top">
      <ng-template #recursiveList let-list>
        @for (item of list; track item.id) {
          @switch (item.type) {
            @case ('SUBMENU') {
              <a
                href="#S{{ item.id }}"
                data-bs-toggle="collapse"
                aria-expanded="false"
                placement="right"
                role="button"
                class="item-color list-group-item list-group-item-action flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-start align-items-center">
                  <span class="{{ item.icon }} me-3"></span>
                  <span class="menu-collapsed fw-bold">{{ item.title }}</span>
                  <span class="submenu-icon ms-auto"></span>
                </div>
              </a>
              <!-- Submenu content -->
              @if (item.children.length > 0) {
                <div id="S{{ item.id }}" class="collapse sidebar-submenu">
                  <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: item.children }"></ng-container>
                </div>
              }
            }
            @case ('ACTION') {
              <button
                routerLinkActive="active"
                type="button"
                class="btn btn-link"
                (click)="OnGoMenu(item)"
                placement="right"
                class="item-color list-group-item list-group-item-action"
              >
                <div class="d-flex w-100 justify-content-start align-items-center">
                  <span class="{{ item.icon }} me-2"></span>
                  <span class="menu-collapsed">{{ item.title }}</span>
                  @if (item.pref && !item.userpref) {
                    <span class="fa fa-star-o fa-fw heartIcon" (click)="OnTogglePref($event, item)"></span>
                  }
                  @if (item.pref && item.userpref) {
                    <span class="fa fa-star fa-fw heartIcon" (click)="OnTogglePref($event, item)"></span>
                  }
                </div>
              </button>
            }
            @case ('SEPARATOR') {
              <li class="list-group-item sidebar-separator-title d-flex align-items-center menu-collapsed">
                <small>{{ item.title }}</small>
              </li>
            }
            @case ('SEARCH') {
              <li class="list-group-item sidebar-separator-title d-flex align-items-center menu-collapsed">
                <div class="w-100">
                  <ng-select placeholder="Pesquisar" (change)="OnPesquisarChange($event)">
                    @for (item of searchOptionList; track item.id) {
                      <ng-option [value]="item.id">{{ item.name }}</ng-option>
                    }
                  </ng-select>
                </div>
              </li>
            }
          }
        }
      </ng-template>
      <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: menuTree }"></ng-container>
      <a
        [routerLink]="['/login']"
        class="item-color list-group-item list-group-item-action d-none"
        placement="right"
        ngbTooltip="Sair"
        tooltipClass="tpx-tooltip"
        (click)="OnSidebarCollapse()"
      >
        <div class="d-flex w-100 justify-content-start align-items-center">
          <span class="fa fa-sign-out fa-lg me-2"></span>
          <span class="menu-collapsed">Sair</span>
        </div>
      </a>
    </ul>
    <!-- List Group END-->
  </div>
</div>
