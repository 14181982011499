// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ContaBean } from '../models/ContaBean';
import { MoedaPadraoSelecao } from '../models/MoedaPadraoSelecao';

@Injectable()
export abstract class ContasActionService {
  // #region #getallconta.
  // CODGEN {{R}}
  abstract GetAllConta(par: string): Observable<ContaBean[]>;
  // #endregion

  // #region #crud#createconta.
  // CODGEN {{R}}
  abstract CreateConta(bean: ContaBean): Observable<ContaBean>;
  // #endregion

  // #region #crud#updateconta.
  // CODGEN {{R}}
  abstract UpdateConta(bean: ContaBean): Observable<void>;
  // #endregion

  // #region #crud#getreadconta.
  // CODGEN {{R}}
  abstract GetReadConta(idconta: number): Observable<ContaBean>;
  // #endregion

  // #region #crud#removeconta.
  // CODGEN {{R}}
  abstract RemoveConta(idconta: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region metodo#-put-altera-moeda-padrao.
  // CODGEN {{R}}
  abstract PutAlteraMoedaPadrao(moeda: MoedaPadraoSelecao): Observable<void>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
