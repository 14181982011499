import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[kzMaskCurrency]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: KzMaskCurrencyDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class KzMaskCurrencyDirective implements ControlValueAccessor, OnInit {
  onTouched;
  onChange;

  separadorDecimal: string;
  separadorMilhar: string;
  prefixo: string;

  @Input('kzMaskCurrency') kzMask;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.separadorDecimal = this.kzMask.decimal || ',';
    this.separadorMilhar = this.kzMask.milhar || '.';
    this.prefixo = this.kzMask.prefixo || '';
  }

  writeValue(value): void {
    if (value) {
      if (!isNaN(value)) {
        value = parseFloat(value).toFixed(2);
      }
      this.el.nativeElement.value = this.aplicarMascara(String(value));
    }
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  @HostListener('keyup', ['$event'])
  onKeyup($event) {
    const valor: string = this.aplicarMascara($event.target.value);

    if (valor === '') {
      this.onChange('');
      $event.target.value = '';
      return;
    }

    if (this.separadorDecimal === ',') {
      this.onChange(valor.replace(/\./g, '').replace(',', '.'));
    } else {
      this.onChange(valor.replace(/,/g, ''));
    }

    $event.target.value = valor;
  }

  @HostListener('blur', ['$event'])
  onBlur($event) {
    const pattern = '0' + this.separadorDecimal + '00';
    if ($event.target.value.indexOf(pattern) === -1) {
      return;
    }
    // this.onChange('');
    // $event.target.value = '';
  }

  /**
   * Aplica a máscara a determinado valor.
   *
   * @param string valorConverter
   * @return string
   */
  aplicarMascara(valorConverter: string): string {
    // const valorNum = parseInt(valorConverter.replace(/\D/g, ''), 10);
    // const valorNum = valorConverter.replace(/\D/g, '').replace(/^[0]+/g, '');
    const valorNum = valorConverter.replace(/\D/g, '');
    if (isNaN(parseInt(valorNum, 10))) {
      return '';
    }
    let valorMask = '';
    const valor = valorNum.length > 3 ? valorNum.toString().replace(/^0+/g, '') : valorNum.toString();
    switch (valor.length) {
      case 1:
        valorMask = '0' + this.separadorDecimal + '0' + valor;
        break;
      case 2:
        valorMask = '0' + this.separadorDecimal + valor;
        break;
      case 3:
        valorMask = valor.substring(0, 1) + this.separadorDecimal + valor.substring(1, 3);
        break;
      default:
        break;
    }
    if (valorMask === '') {
      let sepMilhar = 0;
      for (let i = valor.length - 3; i >= 0; i--) {
        if (sepMilhar === 3) {
          valorMask = this.separadorMilhar + valorMask;
          sepMilhar = 0;
        }
        valorMask = valor.charAt(i) + valorMask;
        sepMilhar++;
      }
      valorMask = valorMask + this.separadorDecimal + valor.substring(valor.length - 2, valor.length);
    }
    if (this.prefixo !== '') {
      valorMask = this.prefixo + ' ' + valorMask;
    }
    return valorMask;
  }
}
