<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>ContaBancoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-banconome/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['banconome']" class="form-label" l10nTranslate>ContaBancoBeanFragment.banconome</label>
      <input
        type="text"
        [id]="fieldIdTable['banconome']"
        formControlName="banconome"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.banconome.invalid && (f.banconome.dirty || f.banconome.touched) }"
        maxlength="150"
      />
      @if (f.banconome.errors) {
        <div class="invalid-feedback">
          @if (f.banconome.errors.maxlength) {
            <div l10nTranslate>ContaBancoBeanFragment.banconome-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-banconome -->
    <!-- #I-#field-agencianumero/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['agencianumero']" class="form-label" l10nTranslate
        >ContaBancoBeanFragment.agencianumero</label
      >
      <input
        type="text"
        [id]="fieldIdTable['agencianumero']"
        formControlName="agencianumero"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.agencianumero.invalid && (f.agencianumero.dirty || f.agencianumero.touched) }"
        maskAgenciaBancaria
      />
    </div>
    <!-- #E-#field-agencianumero -->
    <!-- #I-#field-numeroconta/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['numeroconta']" class="form-label" l10nTranslate
        >ContaBancoBeanFragment.numeroconta</label
      >
      <input
        type="text"
        [id]="fieldIdTable['numeroconta']"
        formControlName="numeroconta"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.numeroconta.invalid && (f.numeroconta.dirty || f.numeroconta.touched) }"
        maskContaBancaria
      />
    </div>
    <!-- #E-#field-numeroconta -->
    <!-- #I-#field-valorlimite/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['valorlimite']" class="form-label" l10nTranslate
        >ContaBancoBeanFragment.valorlimite</label
      >
      <input
        type="text"
        [id]="fieldIdTable['valorlimite']"
        formControlName="valorlimite"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valorlimite.invalid && (f.valorlimite.dirty || f.valorlimite.touched) }"
        kzMaskCurrency
        maxlength="22"
      />
      @if (f.valorlimite.errors) {
        <div class="invalid-feedback">
          @if (f.valorlimite.errors.maxlength) {
            <div l10nTranslate>ContaBancoBeanFragment.valorlimite-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valorlimite -->
    <!-- #I-#field-agenciacontato/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['agenciacontato']" class="form-label" l10nTranslate
        >ContaBancoBeanFragment.agenciacontato</label
      >
      <input
        type="text"
        [id]="fieldIdTable['agenciacontato']"
        formControlName="agenciacontato"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.agenciacontato.invalid && (f.agenciacontato.dirty || f.agenciacontato.touched) }"
        maxlength="100"
      />
      @if (f.agenciacontato.errors) {
        <div class="invalid-feedback">
          @if (f.agenciacontato.errors.maxlength) {
            <div l10nTranslate>ContaBancoBeanFragment.agenciacontato-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-agenciacontato -->
    <!-- #I-#field-agenciatelefone/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['agenciatelefone']" class="form-label" l10nTranslate
        >ContaBancoBeanFragment.agenciatelefone</label
      >
      <input
        type="text"
        [id]="fieldIdTable['agenciatelefone']"
        formControlName="agenciatelefone"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.agenciatelefone.invalid && (f.agenciatelefone.dirty || f.agenciatelefone.touched),
        }"
        mask="{{ masks.ngx.cellphoneMask }}"
      />
    </div>
    <!-- #E-#field-agenciatelefone -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
