import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nIntlService, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Constants } from '../../helpers/constants';
import { MenuTree } from '../../models/MenuTree';
import { MenuTypeEnum } from '../../models/MenuTypeEnum';
import { FavoritosService } from '../../services/favoritos.service';

declare function SidebarCollapse(): void;
declare function ResetWidthOfGrids(): void;
//declare function ToggleMasterMenu(): void;

export interface MenuConfig {
  isSmall: boolean;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, RouterLinkActive, NgSelectModule, NgbTooltip, RouterLink],
})
export class MenuComponent implements OnInit, AfterViewChecked {
  /** Controle de tamanho para refresh de grids */
  @ViewChild('eMainMenu', { static: true }) eMainMenu: ElementRef;
  private currentComponentWidth;

  menuTree: MenuTree[];
  isLoggedIn$: Observable<boolean>;
  config: MenuConfig;
  searchOptionList = [];

  constructor(
    private helper: HttpHelper,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private favoritosService: FavoritosService,
    public translation: L10nTranslationService,
    public router: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public intl: L10nIntlService,
  ) {}

  ngOnInit() {
    // console.log('MenuComponent.ngOnInit');
    this.isLoggedIn$ = this.helper.isLoggedIn;
    this.LoadMenu();
    this.authenticationService.GetFindMenuTree().subscribe({
      next: (data) => {
        if (data && data.children) {
          this.menuTree = this.TranslateMenuTree(data.children);
          this.ExtractSearchOptions(this.menuTree);
          this.favoritosService.RefreshMenuTree(this.menuTree);
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  ngAfterViewChecked() {
    if (this.eMainMenu.nativeElement.clientWidth !== this.currentComponentWidth) {
      this.currentComponentWidth = this.eMainMenu.nativeElement.clientWidth;
      setTimeout(() => {
        ResetWidthOfGrids();
      }, 10);
    }
  }

  TranslateMenuTree(tree: MenuTree[]): MenuTree[] {
    //console.log('LANGUAGE:' + this.locale.language);
    if (tree) {
      tree.forEach((o) => {
        if (o.title) {
          o.title = this.translation.translate('Menu.' + o.title, { language: this.locale.language });
        }
        if (o.description) {
          o.description = this.translation.translate('MenuDescription.' + o.description);
        }
        if (o.children) {
          o.children = this.TranslateMenuTree(o.children);
        }
      });
    }
    return tree;
  }

  OnSidebarCollapse() {
    SidebarCollapse();
  }

  OnSmallToggle() {
    this.config.isSmall = !this.config.isSmall;
    this.SaveMenu();
  }

  SidebarClass(): string {
    return 'sidebar-expanded-not-small';
  }

  SmallIcon(): string {
    return this.config.isSmall ? 'fa fa-thumb-tack fa-rotate-90 text-black-50' : 'fa fa-thumb-tack';
  }

  private LoadMenu() {
    try {
      this.config = JSON.parse(localStorage.getItem(Constants.MNU01));
      if (!this.config) {
        this.config = {
          isSmall: false,
        };
      }
    } catch (e) {
      //console.log(e);
      this.config = {
        isSmall: false,
      };
    }
  }

  private SaveMenu() {
    try {
      localStorage.setItem(Constants.MNU01, JSON.stringify(this.config));
    } catch (e) {
      //console.log(e);
    }
  }

  private ExtractSearchOptions(tree: MenuTree[]) {
    this.searchOptionList = [];
    this.ExtractSearchOptionsElement(tree);
  }

  private ExtractSearchOptionsElement(tree: MenuTree[]) {
    if (tree) {
      tree.forEach((o) => {
        if (o.type === MenuTypeEnum.ACTION) {
          this.searchOptionList.push({ id: o.param, name: o.title });
        }
        if (o.children) {
          this.ExtractSearchOptionsElement(o.children);
        }
      });
    }
  }

  OnPesquisarChange(evt) {
    //console.log('Evt:', evt);
    //this.router.navigate(['/batch/', evt]);
  }

  OnTogglePref(event, tree: MenuTree) {
    event.stopPropagation();
    //console.log('OnTogglePref: ' + tree.title + ' (' + tree.param + ')');
    if (tree && tree.param) {
      this.favoritosService.OnToggle(tree);
    }
  }

  OnGoMenu(tree: MenuTree): void {
    console.log('MenuTree: ' + tree.title + ' (' + tree.href + ')');
    SidebarCollapse();
    this.router.navigate([tree.href]);
  }
}
