// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { PasswordStrongValidator } from 'src/app/core/helpers/password-strong.validator';
import { PasswordValidation } from 'src/app/core/helpers/password-validation';
import { ChangepasswordBean } from 'src/app/core/models/ChangepasswordBean';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { Utilities } from 'src/app/core/services/utilities';
import { PasswordStrengthComponent } from '../../../../../core/components/password-strength/password-strength.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgxMaskDirective,
    PasswordStrengthComponent,
  ],
})
export class ChangePasswordFormComponent implements OnInit {
  // #region #properties#.
  @Output() onOk = new EventEmitter<void>();
  showPassword2 = false;
  showPassword3 = false;
  protected fieldIdTable = {};
  // #endregion

  showPassword = false;
  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private crudService: AuthenticationService,
    private helper: HttpHelper,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({
      senhaatual: [null, Validators.required],
      senha: [null, [Validators.required, PasswordStrongValidator.Strong]],
      resenha: [null, [Validators.required, PasswordValidation.MustMatch('senha')]],
    });
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN {{R}}
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
    const bean = this.CrudFormToRegistro() as ChangepasswordBean;
    bean.username = this.helper.getUser().username;
    this.crudService.PostChangepassword(bean).subscribe({
      next: () => {
        this.CrudForm.reset();
        this.alertService.success('Senha trocada com sucesso.');
        this.onOk.emit();
      },
      error: (error) => {
        this.CrudForm.reset();
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      senhaatual: this.CrudForm.get('senhaatual').value,
      senha: this.CrudForm.get('senha').value,
      resenha: this.CrudForm.get('resenha').value,
    };
    return reg;
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
