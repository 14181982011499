// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { FormaPagamentoBean } from '../models/FormaPagamentoBean';

@Injectable()
export abstract class FormaPagamentoActionService {
  // #region #getallformpag.
  // CODGEN {{R}}
  abstract GetAllFormpag(par: string): Observable<FormaPagamentoBean[]>;
  // #endregion

  // #region #crud#createformpag.
  // CODGEN {{R}}
  abstract CreateFormpag(bean: FormaPagamentoBean): Observable<FormaPagamentoBean>;
  // #endregion

  // #region #crud#updateformpag.
  // CODGEN {{R}}
  abstract UpdateFormpag(bean: FormaPagamentoBean): Observable<void>;
  // #endregion

  // #region #crud#getreadformpag.
  // CODGEN {{R}}
  abstract GetReadFormpag(idformapagamento: number): Observable<FormaPagamentoBean>;
  // #endregion

  // #region #crud#removeformpag.
  // CODGEN {{R}}
  abstract RemoveFormpag(idformapagamento: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
