// {{GENERATE}}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { SmtpConfigFormBean } from '../../models/SmtpConfigFormBean';
import { ConfigOpcoesActionService } from '../config-opcoes-action.service';

@Injectable()
export class ConfigOpcoesActionImplService implements ConfigOpcoesActionService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #crud#createsmtp.
  // CODGEN {{R}}
  CreateSmtp(bean: SmtpConfigFormBean): Observable<SmtpConfigFormBean> {
    const url = this.helper.ToURL('/admin/smtp/createSmtp');
    const headers = this.helper.GetHeaders();
    return this.http.post<SmtpConfigFormBean>(url, bean, headers);
  }
  // #endregion

  // #region #crud#updatesmtp.
  // CODGEN {{R}}
  UpdateSmtp(bean: SmtpConfigFormBean): Observable<void> {
    const url = this.helper.ToURL('/admin/smtp/updateSmtp');
    const headers = this.helper.GetHeaders();
    return this.http.put<void>(url, bean, headers);
  }
  // #endregion

  // #region #crud#getreadsmtp.
  // CODGEN {{R}}
  GetReadSmtp(idsmtp: number): Observable<SmtpConfigFormBean> {
    const url = this.helper.ToURL('/admin/smtp/readSmtp');
    const params = new HttpParams().append('idsmtp', idsmtp.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SmtpConfigFormBean>(url, headers);
  }
  // #endregion

  // #region #crud#removesmtp.
  // CODGEN {{R}}
  RemoveSmtp(idsmtp: number): Observable<void> {
    const url = this.helper.ToURL('/admin/smtp/removeSmtp');
    const params = new HttpParams().append('idsmtp', idsmtp.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, headers);
  }
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/smtp/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/smtp/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region #declarations#.
  GetEnviaEmailTesteSmtp(texto: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/smtp/enviaemailteste');
    let params = new HttpParams();
    params = params.append('texto', texto);
    const header = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, header);
  }
  // #endregion
}
