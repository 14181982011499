// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoContaEnum {
  CONTA_CORRENTE = 'CONTA_CORRENTE',
  CARTAO_CREDITO = 'CARTAO_CREDITO',
  DINHEIRO = 'DINHEIRO',
  OUTROS = 'OUTROS',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoContaEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'CONTA_CORRENTE', name: 'CONTA_CORRENTE' },
      { id: 'CARTAO_CREDITO', name: 'CARTAO_CREDITO' },
      { id: 'DINHEIRO', name: 'DINHEIRO' },
      { id: 'OUTROS', name: 'OUTROS' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
