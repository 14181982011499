import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangepasswordBean } from 'src/app/core/models/ChangepasswordBean';
import { LoginRequest } from 'src/app/core/models/LoginRequest';
import { MenuTree } from 'src/app/core/models/MenuTree';
import { SecurityToken } from 'src/app/core/models/SecurityToken';
import { UserBean } from 'src/app/core/models/UserBean';
import { HttpHelper } from '../../helpers/http-helper';
import { BrokerProfileBean } from '../../models/BrokerProfileBean';
import { UserFavoritoBean } from '../../models/UserFavoritoBean';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class AuthenticationImplService implements AuthenticationService {
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}

  PostLogin(parametro: LoginRequest): Observable<SecurityToken> {
    this.helper.clearCredentials();
    const url = this.helper.ToURL('/v1/authentication/login');
    const header = this.helper.GetHeaders();
    return this.http.post<SecurityToken>(url, parametro, header);
  }

  GetLogout(): Observable<string> {
    this.helper.clearCredentials();
    const url = this.helper.ToURL('/v1/authentication/logout');
    const header = this.helper.GetHeaders();
    return this.http.get<string>(url, header);
  }

  GetFindMenuTree(): Observable<MenuTree> {
    const url = this.helper.ToURL('/v1/authentication/findMenuTree');
    const header = this.helper.GetHeaders();
    return this.http.get<MenuTree>(url, header);
  }

  PostChangepassword(parametro: ChangepasswordBean): Observable<any> {
    const url = this.helper.ToURL('/v1/authentication/changepassword');
    const header = this.helper.GetHeaders();
    return this.http.post<ChangepasswordBean>(url, parametro, header);
  }

  PostUpdateChangepassword(bean: UserBean): Observable<any> {
    const url = this.helper.ToURL('/v1/authentication/updatechangepassword');
    const header = this.helper.GetHeaders();
    return this.http.post<UserBean>(url, bean, header);
  }

  PostUpdateUserFavoritos(bean: UserFavoritoBean): Observable<any> {
    const url = this.helper.ToURL('/v1/authentication/updateuserfavoritos');
    const header = this.helper.GetHeaders();
    return this.http.post<UserFavoritoBean>(url, bean, header);
  }

  GetBrokerProfileBean(): Observable<BrokerProfileBean> {
    const url = this.helper.ToURL('/v1/authentication/getBrokerProfileBean');
    const header = this.helper.GetHeaders();
    return this.http.get<BrokerProfileBean>(url, header);
  }
}
