<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>DadosContatoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-pessoacontato/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['pessoacontato']" class="form-label" l10nTranslate
        >DadosContatoBeanFragment.pessoacontato</label
      >
      <input
        type="text"
        [id]="fieldIdTable['pessoacontato']"
        formControlName="pessoacontato"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.pessoacontato.invalid && (f.pessoacontato.dirty || f.pessoacontato.touched) }"
        maxlength="150"
      />
    </div>
    <!-- #E-#field-pessoacontato -->
    <!-- #I-#field-datanascimento/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['datanascimento']" class="form-label" l10nTranslate
        >DadosContatoBeanFragment.datanascimento</label
      >
      <div class="input-group">
        <input
          type="text"
          [id]="fieldIdTable['datanascimento']"
          formControlName="datanascimento"
          ngbDatepicker
          #ddatanascimento="ngbDatepicker"
          class="form-control"
          placeholder="dd/mm/aaaa"
          appMaskDate
          [ngClass]="{ 'is-invalid': f.datanascimento.invalid && (f.datanascimento.dirty || f.datanascimento.touched) }"
        />
        <button class="btn btn-outline-secondary" (click)="ddatanascimento.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
      @if (f.datanascimento.errors) {
        <div class="invalid-feedback">
          @if (f.datanascimento.errors.validateoptionaldate) {
            <div l10nTranslate>DadosContatoBeanFragment.datanascimento-VALIDATEOPTIONALDATE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-datanascimento -->
    <!-- #I-#field-email/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['email']" class="form-label" l10nTranslate>DadosContatoBeanFragment.email</label>
      <input
        type="text"
        [id]="fieldIdTable['email']"
        formControlName="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched) }"
        maxlength="256"
      />
      @if (f.email.errors) {
        <div class="invalid-feedback">
          @if (f.email.errors.email) {
            <div l10nTranslate>DadosContatoBeanFragment.email-EMAIL</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-email -->
    <!-- #I-#field-fone1/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['fone1']" class="form-label" l10nTranslate>DadosContatoBeanFragment.fone1</label>
      <input
        type="text"
        [id]="fieldIdTable['fone1']"
        formControlName="fone1"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.fone1.invalid && (f.fone1.dirty || f.fone1.touched) }"
        mask="{{ masks.ngx.cellphoneMask }}"
      />
      @if (f.fone1.errors) {
        <div class="invalid-feedback">
          @if (f.fone1.errors.fone) {
            <div l10nTranslate>DadosContatoBeanFragment.fone1-FONE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-fone1 -->
    <!-- #I-#field-fone2/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['fone2']" class="form-label" l10nTranslate>DadosContatoBeanFragment.fone2</label>
      <input
        type="text"
        [id]="fieldIdTable['fone2']"
        formControlName="fone2"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.fone2.invalid && (f.fone2.dirty || f.fone2.touched) }"
        mask="{{ masks.ngx.cellphoneMask }}"
      />
      @if (f.fone2.errors) {
        <div class="invalid-feedback">
          @if (f.fone2.errors.fone) {
            <div l10nTranslate>DadosContatoBeanFragment.fone2-FONE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-fone2 -->
    <!-- #I-#field-redessociais/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['redessociais']" class="form-label" l10nTranslate
        >DadosContatoBeanFragment.redessociais</label
      >
      <input
        type="text"
        [id]="fieldIdTable['redessociais']"
        formControlName="redessociais"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.redessociais.invalid && (f.redessociais.dirty || f.redessociais.touched) }"
        maxlength="1024"
      />
    </div>
    <!-- #E-#field-redessociais -->
    <!-- #I-#field-horariocontato/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['horariocontato']" class="form-label" l10nTranslate
        >DadosContatoBeanFragment.horariocontato</label
      >
      <input
        type="text"
        [id]="fieldIdTable['horariocontato']"
        formControlName="horariocontato"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.horariocontato.invalid && (f.horariocontato.dirty || f.horariocontato.touched) }"
        maxlength="256"
      />
    </div>
    <!-- #E-#field-horariocontato -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
