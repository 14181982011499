import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { AlertService } from 'src/app/core/services/alert.service';
import { ConfUtilizacaoEnum } from '../../admin/models/ConfUtilizacaoEnum';
import { ConfUtilizacaoBroker } from '../models/ConfUtilizacaoBroker';
import { ConfUtilizacaoItem } from '../models/ConfUtilizacaoItem';
import { ConfUtilizacaoActionService } from './conf-utilizacao-action.service';

@Injectable({
  providedIn: 'root',
})
export class ConfUtilizacaoBrokerService implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private subject = new Subject<ConfUtilizacaoBroker>();
  private lastConfiguration: ConfUtilizacaoBroker = null;
  private isload: boolean = false;

  constructor(
    private confService: ConfUtilizacaoActionService,
    private alertService: AlertService,
    private helper: HttpHelper,
  ) {
    const subscription = this.helper.getObservableUser().subscribe({
      next: (user) => {
        if (!user) {
          this.lastConfiguration = null;
        } else {
          console.group('USUARIO MUDOU');
          this.loadConfiguracoes();
          console.groupEnd();
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public getSubject(): Observable<ConfUtilizacaoBroker> {
    return this.subject.asObservable();
  }

  public isPresent(): boolean {
    if (
      this.lastConfiguration &&
      this.helper.IsActiveUser() &&
      this.helper.GetBroker().idBroker === this.lastConfiguration.idbroker
    ) {
      return true;
    }
    return false;
  }

  public load(callback: (input: any) => void): void {
    if (!this.isload) {
      //console.log('*** BUSCA CONFIGURAÇÕES DO USUARIO');
      this.isload = true;
      this.confService.GetConfUtilizacaoBroker().subscribe({
        next: (data) => {
          //console.log('LOAD CONFIGURAÇÕES');
          this.lastConfiguration = data;
          this.subject.next(this.lastConfiguration);
          callback(data);
        },
        error: (error) => {
          this.alertService.error(error);
          callback(error);
        },
        complete: () => {
          this.isload = false;
        },
      });
    } else {
      //console.log('*** BUSCA CONFIGURAÇÕES EM ANDAMENTO');
    }
  }

  public loadConfiguracoes(): void {
    if (this.helper.IsActiveUser()) {
      this.load((message: any) => {});
    }
  }

  public find(name: ConfUtilizacaoEnum): ConfUtilizacaoItem | null {
    if (
      name != null &&
      this.lastConfiguration &&
      this.lastConfiguration.itens &&
      this.lastConfiguration.itens.length > 0
    ) {
      for (const item of this.lastConfiguration.itens) {
        if (item.name === name) {
          return item;
        }
      }
    }
    return null;
  }

  public isHabilitado(name: ConfUtilizacaoEnum): boolean {
    const val = this.find(name);
    if (val != null) {
      return true;
    }
    return false;
  }

  public isObrigatorio(name: ConfUtilizacaoEnum): boolean {
    const val = this.find(name);
    if (val != null && val.obrigatorio) {
      return true;
    }
    return false;
  }
}
