// {{GENERATE}}
// @CRUD
// @GRID
// @HOST
// @Route:/admin/user/action/grid
// URL:/admin/user
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { RecuperacaoSenhaBean } from 'src/app/core/models/RecuperacaoSenhaBean';
import { RequestDataBean } from 'src/app/core/models/RequestDataBean';
import { ResponseDataBean } from 'src/app/core/models/ResponseDataBean';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { HistoricoBean } from '../../models/HistoricoBean';
import { StoreUserAvatarRequest } from '../../models/StoreUserAvatarRequest';
import { TbUserBean } from '../../models/TbUserBean';
import { UserRegistration } from '../../models/UserRegistration';
import { AdminUserActionService } from '../admin-user-action.service';

@Injectable()
export class AdminUserActionImplService implements AdminUserActionService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #getalluser.
  // CODGEN {{R}}
  GetAllUser(par: string): Observable<TbUserBean[]> {
    const url = this.helper.ToURL('/admin/user/getAllUser');
    const params = new HttpParams().append('par', par);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<TbUserBean[]>(url, headers);
  }
  // #endregion

  // #region #crud#createuser.
  // CODGEN {{R}}
  CreateUser(bean: TbUserBean): Observable<TbUserBean> {
    const url = this.helper.ToURL('/admin/user/createUser');
    const headers = this.helper.GetHeaders();
    return this.http.post<TbUserBean>(url, bean, headers);
  }
  // #endregion

  // #region #crud#updateuser.
  // CODGEN {{R}}
  UpdateUser(bean: TbUserBean): Observable<void> {
    const url = this.helper.ToURL('/admin/user/updateUser');
    const headers = this.helper.GetHeaders();
    return this.http.put<void>(url, bean, headers);
  }
  // #endregion

  // #region #crud#getreaduser.
  // CODGEN {{R}}
  GetReadUser(iduser: number): Observable<TbUserBean> {
    const url = this.helper.ToURL('/admin/user/readUser');
    const params = new HttpParams().append('iduser', iduser.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.get<TbUserBean>(url, headers);
  }
  // #endregion

  // #region #crud#removeuser.
  // CODGEN {{R}}
  RemoveUser(iduser: number): Observable<void> {
    const url = this.helper.ToURL('/admin/user/removeUser');
    const params = new HttpParams().append('iduser', iduser.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, headers);
  }
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/user/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/admin/user/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region metodo#find-all-history.
  // CODGEN {{R}}
  FindAllHistory(username: string, tipo: string): Observable<HistoricoBean[]> {
    const url = this.helper.ToURL('/admin/user/findallhistory');
    const params = new HttpParams().append('username', username).append('tipo', tipo);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<HistoricoBean[]>(url, headers);
  }
  // #endregion

  // #region metodo#start-registration.
  // CODGEN {{R}}
  StartRegistration(bean: RequestDataBean): Observable<ResponseDataBean> {
    const url = this.helper.ToURL('/admin/user/startregistration');
    const headers = this.helper.GetHeaders();
    return this.http.post<ResponseDataBean>(url, bean, headers);
  }
  // #endregion

  // #region metodo#confirm-registration.
  // CODGEN {{R}}
  ConfirmRegistration(bean: RequestDataBean): Observable<UserRegistration> {
    const url = this.helper.ToURL('/admin/user/confirmregistration');
    const headers = this.helper.GetHeaders();
    return this.http.post<UserRegistration>(url, bean, headers);
  }
  // #endregion

  // #region metodo#finalize-registration.
  // CODGEN {{R}}
  FinalizeRegistration(bean: UserRegistration): Observable<ResponseDataBean> {
    const url = this.helper.ToURL('/admin/user/finalizeregistration');
    const headers = this.helper.GetHeaders();
    return this.http.post<ResponseDataBean>(url, bean, headers);
  }
  // #endregion

  // #region metodo#update-user-profie.
  // CODGEN {{R}}
  UpdateUserProfie(user: TbUserBean): Observable<ResponseDataBean> {
    const url = this.helper.ToURL('/admin/user/updateuserprofie');
    const headers = this.helper.GetHeaders();
    return this.http.post<ResponseDataBean>(url, user, headers);
  }
  // #endregion

  // #region metodo#store-user-avatar.
  // CODGEN {{R}}
  StoreUserAvatar(par: StoreUserAvatarRequest): Observable<ResponseDataBean> {
    const url = this.helper.ToURL('/admin/user/storeuseravatar');
    const headers = this.helper.GetHeaders();
    return this.http.post<ResponseDataBean>(url, par, headers);
  }
  // #endregion

  // #region metodo#recuperacao-senha.
  // CODGEN {{R}}
  RecuperacaoSenha(par: RecuperacaoSenhaBean): Observable<RecuperacaoSenhaBean> {
    const url = this.helper.ToURL('/admin/user/recuperacaosenha');
    const headers = this.helper.GetHeaders();
    return this.http.post<RecuperacaoSenhaBean>(url, par, headers);
  }
  // #endregion

  // #region #declarations#.
  // #endregion
}
