import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminContatoActionAddComponent } from './components/contato/admin-contato-action/admin-contato-action-add.component';
import { AdminContatoActionGridComponent } from './components/contato/admin-contato-action/admin-contato-action-grid.component';
import { ConfirmarEmailComponent } from './components/criar-conta-usuario/confirmar-email/confirmar-email.component';
import { CriarContaUsuarioComponent } from './components/criar-conta-usuario/criar-conta-usuario/criar-conta-usuario.component';
import { TerminarCadastroComponent } from './components/criar-conta-usuario/terminar-cadastro/terminar-cadastro.component';
import { ReportPanelComponent } from './components/report/report-panel/report-panel.component';
import { AdminUserActionAddComponent } from './components/user/admin-user-action/admin-user-action-add.component';
import { AdminUserActionGridComponent } from './components/user/admin-user-action/admin-user-action-grid.component';
import { ChangePasswordFormComponent } from './components/user/change-password-form/change-password-form.component';
import { ConfigOpcoesActionPaineltabComponent } from './components/user/config-opcoes-action/config-opcoes-action-paineltab.component';
import { ConfigOpcoesActionTabComponent } from './components/user/config-opcoes-action/config-opcoes-action-tab.component';
import { EditarFotoPerfilFormComponent } from './components/user/editar-foto-perfil-form/editar-foto-perfil-form.component';
import { HistoricoActionGridComponent } from './components/user/historico-action/historico-action-grid.component';
import { MeusDadosFormComponent } from './components/user/meus-dados-form/meus-dados-form.component';
import { PersonalizarHomeComponent } from './components/user/personalizar-home/personalizar-home.component';
import { UserProfileFormComponent } from './components/user/user-profile-form/user-profile-form.component';

const routes: Routes = [
  {
    path: 'admin/contato/action/add',
    component: AdminContatoActionAddComponent,
    data: { breadcrumb: 'admin-contato-action-add', root: true },
  },
  {
    path: 'admin/contato/action',
    component: AdminContatoActionGridComponent,
    data: { breadcrumb: 'admin-contato-action', root: true },
  },
  {
    path: 'admin/user/action/grid/add',
    component: AdminUserActionAddComponent,
    data: { breadcrumb: 'admin-user-action-add', root: true },
  },
  {
    path: 'admin/user/action/grid',
    component: AdminUserActionGridComponent,
    data: { breadcrumb: 'admin-user-action', root: true },
  },
  {
    path: 'change/password/form',
    component: ChangePasswordFormComponent,
    data: { breadcrumb: 'change-password-form', root: true },
  },
  {
    path: 'config/opcoes/action/paineltab',
    component: ConfigOpcoesActionPaineltabComponent,
    data: { breadcrumb: 'config-opcoes-action', root: true },
  },
  {
    path: 'config/opcoes/action/tab',
    component: ConfigOpcoesActionTabComponent,
    data: { breadcrumb: 'config-opcoes-action-tab', root: true },
  },
  {
    path: 'confirmar/email',
    component: ConfirmarEmailComponent,
    data: { breadcrumb: 'confirmar-email', root: true },
  },
  {
    path: 'criar/conta/usuario',
    component: CriarContaUsuarioComponent,
    data: { breadcrumb: 'criar-conta-usuario', root: true },
  },
  {
    path: 'editar/foto/perfil/form',
    component: EditarFotoPerfilFormComponent,
    data: { breadcrumb: 'editar-foto-perfil-form', root: true },
  },
  {
    path: 'historico/action',
    component: HistoricoActionGridComponent,
    data: { breadcrumb: 'historico-action', root: true },
  },
  {
    path: 'meus/dados/form',
    component: MeusDadosFormComponent,
    data: { breadcrumb: 'meus-dados-form', root: true },
  },
  {
    path: 'admin/user/person',
    component: PersonalizarHomeComponent,
    data: { breadcrumb: 'personalizar-home', root: true },
  },
  {
    path: 'report/panel',
    component: ReportPanelComponent,
    data: { breadcrumb: 'report-panel', root: true },
  },
  {
    path: 'terminar/cadastro',
    component: TerminarCadastroComponent,
    data: { breadcrumb: 'terminar-cadastro', root: true },
  },
  {
    path: 'user/profile/form',
    component: UserProfileFormComponent,
    data: { breadcrumb: 'user-profile-form', root: true },
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class AdminRoutingModule {}
