// {{NOTGENERATE}}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { Subscription } from 'rxjs';
import { AvatarComponent } from 'src/app/core/components/avatar/avatar.component';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { Avatar, AvatarDados } from 'src/app/core/models/AvatarDados';
import { Utilities } from 'src/app/core/services/utilities';
import { ChangePasswordFormComponent } from '../change-password-form/change-password-form.component';
import { EditarFotoPerfilFormComponent } from '../editar-foto-perfil-form/editar-foto-perfil-form.component';
import { UserProfileFormComponent } from '../user-profile-form/user-profile-form.component';
import { MoedaPadraoSelecaoFragmentComponent } from 'src/app/modules/financeiro/components/moeda/moeda-padrao-selecao/moeda-padrao-selecao-fragment.component';

// #region #pre-definition#.
interface VariaveisControle {
  change_password?: boolean;
  user_profile?: boolean;
  geral?: boolean;
  usuarios?: boolean;
  auditoria?: boolean;
  acesso?: boolean;
  moeda?: boolean;
  restricoes?: boolean;
}
// #endregion

@Component({
  standalone: true,
  selector: 'app-meus-dados-form',
  templateUrl: './meus-dados-form.component.html',
  styleUrls: ['./meus-dados-form.component.css'],
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgxMaskDirective,
    ChangePasswordFormComponent,
    UserProfileFormComponent,
    AvatarComponent,
    MoedaPadraoSelecaoFragmentComponent,
  ],
})
export class MeusDadosFormComponent implements OnInit, OnDestroy {
  // #region #properties#.
  avatar: Avatar;
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private avatarSz = 180;
  controle_lista: VariaveisControle = {};

  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();
  isModal: boolean = false;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private helper: HttpHelper,
    private formBuilder: FormBuilder,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({});
    const subscription = this.helper.getObservableUser().subscribe({
      next: () => {
        this.AtualizaAvatar();
      },
    });
    this.subscriptions.push(subscription);
    this.AtualizaAvatar();
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN {{R}}
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return {};
  }
  // #endregion

  // #region #opennewmodal.
  // CODGEN {{R}}
  static OpenNewModal(modalService: NgbModal): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, MeusDadosFormComponent);
    const view: MeusDadosFormComponent = modalRef.componentInstance;
    view.isModal = true;
    return modalRef;
  }
  // #endregion

  // #region #routine-definitions#.
  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  toggleFoto(): void {
    EditarFotoPerfilFormComponent.OpenNewModal(this.modalService);
  }

  private AtualizaAvatar(): void {
    if (this.helper.credentials && this.helper.credentials.user) {
      this.avatar = AvatarDados.fromUser(this.helper.credentials.user, this.avatarSz);
    } else {
      this.avatar = AvatarDados.fromUser(null, this.avatarSz);
    }
  }

  OnMenuClick(opcao: string): void {
    this.controle_lista = {};
    switch (opcao) {
      case 'geral':
      case 'usuarios':
      case 'auditoria':
      case 'acesso':
      case 'moeda':
      case 'restricoes':
        this.controle_lista[opcao] = true;
        break;
    }
  }

  onOK(): void {
    this.controle_lista = {};
    this.OnCloseModal(true);
  }
  // #endregion
}
