<div
  class="avatar"
  [style.width.px]="avatar.size"
  [style.height.px]="avatar.size"
  [ngStyle]="{ 'background-color': circleColor }"
>
  @if (!showInitials) {
    <img src="{{ srcimage }}" [style.width.px]="avatar.size - 2" [style.height.px]="avatar.size - 2" />
  }
  @if (showInitials) {
    <div class="avatar__letter">
      {{ initials }}
    </div>
  }
</div>
