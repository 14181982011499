// {{GENERATE}}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { Constants } from 'src/app/core/helpers/constants';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-report-panel',
  templateUrl: './report-panel.component.html',
  styleUrls: ['./report-panel.component.css'],
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgxMaskDirective,
  ],
})
export class ReportPanelComponent implements OnInit {
  // #region #properties#.
  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();
  isModal: boolean = false;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({});
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN {{R}}
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {};
    return reg;
  }
  // #endregion

  // #region #opennewmodal.
  // CODGEN {{R}}
  static OpenNewModal(modalService: NgbModal): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, ReportPanelComponent);
    const view: ReportPanelComponent = modalRef.componentInstance;
    view.isModal = true;
    return modalRef;
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
