<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div class="d-flex align-items-center">
    <div class="t-header-title" l10nTranslate>ChangePasswordForm.HEADERTITLE</div>
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body -->
  <div>
    <div class="row mx-4 mt-2">
      <!-- #E-#start-body -->
      <!-- #I-#field-senhaatual -->
      <div class="col-sm-12 col-md-6 mb-3">
        <label [for]="fieldIdTable['senhaatual']" class="form-label" l10nTranslate>ChangePasswordForm.senhaatual</label>
        <input
          type="{{ showPassword ? 'text' : 'password' }}"
          [id]="fieldIdTable['senhaatual']"
          formControlName="senhaatual"
          class="form-control"
          autocomplete="current-password"
          maxlength="50"
          [ngClass]="{ 'is-invalid': f.senhaatual.invalid && (f.senhaatual.dirty || f.senhaatual.touched) }"
        />
        <span class="p-viewer">
          <i
            (click)="showPassword = !showPassword"
            [class]="showPassword ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
          ></i>
        </span>
        @if (f.senhaatual.errors) {
          <div class="invalid-feedback">
            @if (f.senhaatual.errors.required) {
              <div l10nTranslate>ChangePasswordForm.senhaatual-REQUIRED</div>
            }
          </div>
        }
      </div>
      <!-- #E-#field-senhaatual -->
      <!-- #I-#field-senha -->
      <div class="col-sm-12 col-md-6 mb-3">
        <label [for]="fieldIdTable['senha']" class="form-label" l10nTranslate>ChangePasswordForm.senha</label>
        <input
          type="{{ showPassword2 ? 'text' : 'password' }}"
          [id]="fieldIdTable['senha']"
          formControlName="senha"
          class="form-control"
          autocomplete="new-password"
          maxlength="50"
          [ngClass]="{ 'is-invalid': f.senha.invalid && (f.senha.dirty || f.senha.touched) }"
        />
        <span class="p-viewer">
          <i
            (click)="showPassword2 = !showPassword2"
            [class]="showPassword2 ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
          ></i>
        </span>
        @if (f.senha.errors) {
          <div class="invalid-feedback">
            @if (f.senha.errors.required) {
              <div l10nTranslate>ChangePasswordForm.senha-REQUIRED</div>
            }
          </div>
        }
        <app-password-strength [passwordToCheck]="CrudForm.value.senha"></app-password-strength>
      </div>
      <!-- #E-#field-senha -->
      <!-- #I-#field-resenha -->
      <div class="col-sm-12 col-md-6 mb-3">
        <label [for]="fieldIdTable['resenha']" class="form-label" l10nTranslate>ChangePasswordForm.resenha</label>
        <input
          type="{{ showPassword3 ? 'text' : 'password' }}"
          [id]="fieldIdTable['resenha']"
          formControlName="resenha"
          class="form-control"
          autocomplete="new-password"
          maxlength="50"
          [ngClass]="{ 'is-invalid': f.resenha.invalid && (f.resenha.dirty || f.resenha.touched) }"
        />
        <span class="p-viewer">
          <i
            (click)="showPassword3 = !showPassword3"
            [class]="showPassword3 ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
          ></i>
        </span>
        @if (f.resenha.errors) {
          <div class="invalid-feedback">
            @if (f.resenha.errors.required) {
              <div l10nTranslate>ChangePasswordForm.resenha-REQUIRED</div>
            }
          </div>
        }
      </div>
      <!-- #E-#field-resenha -->
      <!-- #I-#end-body/R -->
    </div>
    <!-- class.modal-body -->
    <!-- #E-#end-body -->
    <!-- #I-#footer-form/R -->
    <div class="row mx-4">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary"
          (click)="OnProcessar()"
          [disabled]="!CrudForm.valid"
          l10nTranslate
        >
          <i class="fa fa-save"></i> ChangePasswordForm.BTN-ENTER
        </button>
      </div>
    </div>
    <!-- class.modal-footer -->
    <!-- #E-#footer-form -->
    <!-- #I-#end-container -->
  </div>
</ng-container>
<!-- #E-#end-container -->
