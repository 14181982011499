import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { HttpHelper } from '../helpers/http-helper';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}

  getJwtToken(): string {
    return this.helper.GetToken(); // Obter token JWT do armazenamento local
  }

  refreshToken(): Observable<string> {
    return this.http.post<{ token: string }>('/api/auth/refresh-token', {}).pipe(
      tap((response) => {
        localStorage.setItem('jwtToken', response.token); // Armazenar novo token
      }),
      map((response) => response.token),
    );
  }

  logout(): void {
    this.helper.clearCredentials();
    // Outras lógicas de logout, como redirecionar para página de login
  }
}
