// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { SmtpConfigFormBean } from '../models/SmtpConfigFormBean';

@Injectable()
export abstract class ConfigOpcoesActionService {
  // #region #crud#createsmtp.
  // CODGEN {{R}}
  abstract CreateSmtp(bean: SmtpConfigFormBean): Observable<SmtpConfigFormBean>;
  // #endregion

  // #region #crud#updatesmtp.
  // CODGEN {{R}}
  abstract UpdateSmtp(bean: SmtpConfigFormBean): Observable<void>;
  // #endregion

  // #region #crud#getreadsmtp.
  // CODGEN {{R}}
  abstract GetReadSmtp(idsmtp: number): Observable<SmtpConfigFormBean>;
  // #endregion

  // #region #crud#removesmtp.
  // CODGEN {{R}}
  abstract RemoveSmtp(idsmtp: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region #declarations#.
  abstract GetEnviaEmailTesteSmtp(texto: string): Observable<SelectOptionList>;

  // #endregion
}
