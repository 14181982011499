import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm',
  templateUrl: 'confirm.component.html',
  styleUrls: ['confirm.component.css'],
  standalone: true,
  imports: [],
})
export class ConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() isImage: boolean;

  imagePath: SafeResourceUrl;

  constructor(
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    if (this.isImage) {
      this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.message);
    }
  }

  IsTemConfirm(): boolean {
    return this.btnOkText && this.btnOkText.length > 0;
  }

  IsTemCancel(): boolean {
    return this.btnCancelText && this.btnCancelText.length > 0;
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  OnCloseModal() {
    this.activeModal.close('Close click');
  }
}
