// {{GENERATE}}
// @Component
// @Modal
// @Route:/extrato/painel
// URL:/financeiro/extrato
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageResponse } from 'src/app/core/models/PageResponse';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ChartDataLabel } from '../models/ChartDataLabel';
import { ExtratoLancamentoBean } from '../models/ExtratoLancamentoBean';
import { ExtratoRequest } from '../models/ExtratoRequest';
import { PeriodoExtrato } from '../models/PeriodoExtrato';
import { SaldosResponse } from '../models/SaldosResponse';

@Injectable()
export abstract class ExtratoContasPainelService {
  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region metodo#get-extrato-conta.
  // CODGEN {{R}}
  abstract GetExtratoConta(conta: ExtratoRequest): Observable<PageResponse<ExtratoLancamentoBean>>;
  // #endregion

  // #region metodo#get-extrato-saldos.
  // CODGEN {{R}}
  abstract GetExtratoSaldos(opcao: ExtratoRequest): Observable<SaldosResponse>;
  // #endregion

  // #region metodo#get-movimento-full.
  // CODGEN {{R}}
  abstract GetMovimentoFull(periodo: PeriodoExtrato): Observable<ExtratoLancamentoBean[]>;
  // #endregion

  // #region metodo#get-movimento-chart.
  // CODGEN {{R}}
  abstract GetMovimentoChart(opcao: ExtratoRequest): Observable<ChartDataLabel[]>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
