<nav class="navbar fixed-top navbar-light bg-light border-bottom">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <div class="d-flex justify-content-center align-items-center">
        <img class="img-responsive logo-image" src="/assets/images/rural-1.svg" alt="7Bov" />
        <div class="text-center ps-1 logo-fonte">7Bov</div>
      </div>
    </a>
  </div>
</nav>
<app-alert></app-alert>
<div class="container position-relative" style="top: 8rem">
  <div class="row" style="padding-bottom: 6rem">
    <router-outlet></router-outlet>
  </div>
</div>
<footer>
  <nav class="navbar fixed-bottom navbar-light bg-light border-top">
    <div class="container-fluid">
      <div class="d-flex justify-content-around align-items-center mb-3 w-100">
        <div class="p-2 text-center">
          &copy; {{ today | date: 'yyyy' }}
          <a target="_blank" rel="TechPlugX Solutions" href="https://www.techplugx.com.br">TechPlugX Solutions Ltda</a>.
        </div>
        <div class="p-2 text-center">
          <a href="/#/termo-de-uso" target="_blank">Termos de Uso</a> e
          <a href="/#/politica-privacidade" target="_blank">Políticas de Privacidade</a>.
        </div>
      </div>
    </div>
  </nav>
</footer>
