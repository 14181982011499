import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SecurityToken } from 'src/app/core/models/SecurityToken';
import { User } from 'src/app/core/models/User';
import { environment } from 'src/environments/environment';
import { BrokerDetails } from '../models/BrokerDetails';
import { BrokerTypeEnum } from '../models/BrokerTypeEnum';
import { RoleEnum } from '../models/RoleEnum';

@Injectable({
  providedIn: 'root',
})
export class HttpHelper {
  public credentials: SecurityToken;
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  private userSubject = new Subject<User>();

  constructor() {
    this.credentials = null;
  }

  public getObservableUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public refreshUser(): void {
    this.userSubject.next(this.credentials ? this.credentials.user : null);
  }

  public getUser(): User {
    return this.credentials ? this.credentials.user : null;
  }

  public ToURL(url: string): string {
    if (url && url.startsWith('/')) {
      return environment.apiUrlServer + url;
    }
    return environment.apiUrlServer + '/' + url;
  }

  public IsActiveUser(): boolean {
    return this.credentials && this.credentials != null && this.credentials.token != null;
  }

  public IsCustomer() {
    return this.IsActiveUser() && BrokerTypeEnum.CUSTOMER === this.GetBroker().tipo;
  }

  public IsAdmin(): boolean {
    return this.getUser().roles.some((e) => e === RoleEnum.ADMIN || e === RoleEnum.GESTOR);
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }

  public GetBroker(): BrokerDetails {
    return this.credentials ? this.credentials.broker : null;
  }

  public GetHeaders(params?: HttpParams) {
    return this.GetHeadersContentType('application/json', params);
  }

  public GetToken(): string {
    return this.credentials ? this.credentials.token : null;
  }

  public GetHeadersContentType(contentType: string, params?: HttpParams) {
    const token = this.credentials ? this.credentials.token : null;
    if (token) {
      return {
        params: params,
        reportProgress: false,
        headers: new HttpHeaders({
          'Content-Type': contentType,
          //Authorization: 'Bearer ' + token,
          'Transport-Security': 'max-age=31536000;includeSubDomains',
        }),
      };
    } else {
      return {
        params: params,
        reportProgress: false,
        headers: new HttpHeaders({
          'Content-Type': contentType,
          'Transport-Security': 'max-age=31536000;includeSubDomains',
        }),
      };
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleError(error: HttpErrorResponse) {
    //console.log('Service ####### STATUS:' + JSON.stringify(error));
  }

  public saveCredentials(data: SecurityToken) {
    this.credentials = data;
    this.userSubject.next(this.credentials ? this.credentials.user : null);
    this.loggedIn.next(true);
  }

  public clearCredentials() {
    this.credentials = null;
    this.userSubject.next(null);
  }

  logout() {
    this.loggedIn.next(false);
  }
}
