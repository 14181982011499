import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AlertComponent } from '../header-principal/alert.component';

@Component({
  selector: 'app-info-text.layout',
  templateUrl: './info-text.layout.component.html',
  styleUrls: ['./info-text.layout.component.css'],
  standalone: true,
  imports: [RouterOutlet, AlertComponent, DatePipe],
})
export class InfoTextLayoutComponent {
  today: number = Date.now();
}
