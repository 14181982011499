<!-- {{GENERATE}} -->
<div class="tpx-crud-card">
  <div class="tpx-crud-header">
    <div class="tpx-crud-title">
      <div class="t-header-title" l10nTranslate>ConfigOpcoesActionPaineltab.HEADERTITLE</div>
    </div>
  </div>
  <div class="tpx-crud-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink l10nTranslate>ConfigOpcoesActionPaineltab.smtp</a>
        <ng-template ngbNavContent>
          <app-config-opcoes-action-tab #vsmtp [ContextData]="ContextData"></app-config-opcoes-action-tab>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tpx-nav-item"></div>
  </div>
  <div class="tpx-crud-footer"></div>
</div>
