import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PtBrMatPaginatorIntl } from 'src/app/core/helpers/pt-br-mat-paginator-intl';
import { AdminRoutingModule } from './admin.routing';
import { FeedbackFinalSucessoComponent } from './components/criar-conta-usuario/feedback-final-sucesso/feedback-final-sucesso.component';
import { AdminUserActionService } from './services/admin-user-action.service';
import { ConfigOpcoesActionService } from './services/config-opcoes-action.service';
import { AdminUserActionImplService } from './services/impl/admin-user-action-impl.service';
import { ConfigOpcoesActionImplService } from './services/impl/config-opcoes-action-impl.service';
import { AdminContatoActionService } from './services/admin-contato-action.service';
import { AdminContatoActionImplService } from './services/impl/admin-contato-action-impl.service';

@NgModule({
  imports: [CommonModule, AdminRoutingModule, ReactiveFormsModule, FeedbackFinalSucessoComponent],
  exports: [],
  providers: [
    { provide: MatPaginatorIntl, useClass: PtBrMatPaginatorIntl },
    { provide: ConfigOpcoesActionService, useClass: ConfigOpcoesActionImplService },
    { provide: AdminUserActionService, useClass: AdminUserActionImplService },
    { provide: AdminContatoActionService, useClass: AdminContatoActionImplService },
  ],
  bootstrap: [],
})
export class AdminModule {
  static forRoot(): ModuleWithProviders<AdminModule> {
    return {
      ngModule: AdminModule,
    };
  }
}
