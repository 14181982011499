// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum StatusEnum {
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  BLOCKED = 'BLOCKED',
}

// #region optionlist#method.
// CODGEN {{R}}
export class StatusEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'DISABLE', name: 'DISABLE' },
      { id: 'ENABLE', name: 'ENABLE' },
      { id: 'BLOCKED', name: 'BLOCKED' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
