// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TxDatePickerFragmentComponent } from 'src/app/core/components/ngb-ctrls/tx-date-picker/tx-date-picker-fragment.component';
import { DateMaskDirective } from 'src/app/core/directives/date-mask.directive';
import { KzMaskCurrencyDirective } from 'src/app/core/directives/kz-mask-currency.directive';
import { NumberOnlyDirective } from 'src/app/core/directives/number-only.directive';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { Utilities } from 'src/app/core/services/utilities';
import { TipoBandeiraCartaoEnumRepo } from '../../../models/TipoBandeiraCartaoEnum';
import { TipoLimiteEnumRepo } from '../../../models/TipoLimiteEnum';
import { ContaBancoBeanFragmentComponent } from '../conta-banco-bean/conta-banco-bean-fragment.component';
import { SaldoFinanceiroBeanFragmentComponent } from '../saldo-financeiro-bean/saldo-financeiro-bean-fragment.component';
import { SelectOptionUtils } from 'src/app/core/helpers/SelectOptionUtils';
import { AlertService } from 'src/app/core/services/alert.service';
import { SelectOptionService } from 'src/app/core/services/select-option.service';
import { Observable, combineLatest, tap } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-cartao-credito-bean-fragment',
  templateUrl: './cartao-credito-bean-fragment.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    KzMaskCurrencyDirective,
    NumberOnlyDirective,
    DateMaskDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    SaldoFinanceiroBeanFragmentComponent,
    ContaBancoBeanFragmentComponent,
    TxDatePickerFragmentComponent,
  ],
  providers: [provideNgxMask()],
})
export class CartaoCreditoBeanFragmentComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  debitonacontaList: SelectOptionItem[] = [];
  tipolimiteList: SelectOptionItem[] = TipoLimiteEnumRepo.optionList();
  @ViewChild('vdataprimeirafatura', { static: true }) childDataprimeirafatura: TxDatePickerFragmentComponent;
  @ViewChild('vsaldofatura', { static: true }) childSaldofatura: SaldoFinanceiroBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private optionService: SelectOptionService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    this.OnInvokeSelectCombineLatest();
  }
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onloadselectedrows.
  // CODGEN {{R}}
  OnLoadSelectedRows() {}
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      valorlimite: [null, [Validators.required, Validators.maxLength(22)]],
      tipolimite: [null, Validators.required],
      diavencfatura: [null, [Validators.required, Validators.maxLength(2)]],
      dataprimeirafatura: this.formBuilder.group(
        this.childDataprimeirafatura.CrudFormValidators(this.rootformid, 'dataprimeirafatura'),
      ),
      diafechfatura: [null, [Validators.required, Validators.maxLength(2)]],
      saldofatura: this.formBuilder.group(this.childSaldofatura.CrudFormValidators(this.rootformid, 'saldofatura')),
      debitonaconta: [null],
      nomecartaoprincipal: [null, [Validators.required, Validators.maxLength(80)]],
      nomescartoesadicionais: [null], // List<String>:JSGRID
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childDataprimeirafatura.CrudFormGroupValidators(validators);
    this.childSaldofatura.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      valorlimite: this.CrudForm.get('valorlimite').value,
      tipolimite: this.CrudForm.get('tipolimite').value,
      diavencfatura: Utilities.unmask(this.CrudForm.get('diavencfatura').value),
      dataprimeirafatura: this.childDataprimeirafatura.CrudFormToRegistro(),
      diafechfatura: Utilities.unmask(this.CrudForm.get('diafechfatura').value),
      saldofatura: this.childSaldofatura.CrudFormToRegistro(),
      debitonaconta: this.CrudForm.get('debitonaconta').value,
      nomecartaoprincipal: this.CrudForm.get('nomecartaoprincipal').value,
    };
    return reg;
  }
  // #endregion

  // #region #onsearch.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnSearch(item): void {}
  // #endregion

  // #region #customsearchfn.
  // CODGEN {{R}}
  CustomSearchFn(term: string, item: SelectOptionItem): boolean | null {
    return SelectOptionUtils.SelectOptionItemSearchFn(term, item);
  }
  // #endregion

  // #region #oninvokeselectcombinelatest.
  // CODGEN {{R}}
  OnInvokeSelectCombineLatest(): void {
    const op: Observable<SelectOptionList>[] = [];
    op.push(this.OnInvokeSelectOptionsDebitonaconta());
    combineLatest(op).subscribe({
      next: () => {
        this.OnLoadSelectedRows();
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #oninvokeselectoptionsdebitonaconta.
  // CODGEN {{R}}
  OnInvokeSelectOptionsDebitonaconta(): Observable<SelectOptionList> {
    const host = '/financeiro/categoria';
    return this.optionService.GetSelectOptions(host, 'CONTABANCOBEAN', '').pipe(
      tap((data) => {
        this.debitonacontaList = [...SelectOptionUtils.TransformToNumericId(data.items)];
      }),
    );
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion

  // #region #declarations#.
  // #endregion
}
