// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { TbDocumentBean } from '../models/TbDocumentBean';

@Injectable()
export abstract class DocumentoActionService {
  // #region #getalldoc.
  // CODGEN {{R}}
  abstract GetAllDoc(par: string): Observable<TbDocumentBean[]>;
  // #endregion

  // #region #crud#createdoc.
  // CODGEN {{R}}
  abstract CreateDoc(bean: TbDocumentBean): Observable<TbDocumentBean>;
  // #endregion

  // #region #crud#updatedoc.
  // CODGEN {{R}}
  abstract UpdateDoc(bean: TbDocumentBean): Observable<void>;
  // #endregion

  // #region #crud#getreaddoc.
  // CODGEN {{R}}
  abstract GetReadDoc(id: number): Observable<TbDocumentBean>;
  // #endregion

  // #region #crud#removedoc.
  // CODGEN {{R}}
  abstract RemoveDoc(id: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
