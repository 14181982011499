import { Injectable } from '@angular/core';
import { catchError, debounceTime, finalize, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { LocalDateType } from 'src/app/core/models/local-date-type';
import { AlertService } from 'src/app/core/services/alert.service';
import { ExtratoLancamentoBean, ExtratoLancamentoBeanExt } from '../models/ExtratoLancamentoBean';
import { ExtratoPeriodoEnum } from '../models/ExtratoPeriodoEnum';
import { PaineisFinanceirosEnum } from '../models/PaineisFinanceirosEnum';
import { PeriodoExtrato } from '../models/PeriodoExtrato';
import { TipoContaTransacaoEnum } from '../models/TipoContaTransacaoEnum';
import { ExtratoContasPainelService } from './extrato-contas-painel.service';

@Injectable({
  providedIn: 'root',
})
export class PainelVisaoGeralService {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private current_version: number = 1;
  private modify_version: number = -1;
  private isLoading = false; // Variável de controle para evitar duplicação

  private periodo: LocalDateType;
  private items: ExtratoLancamentoBean[] = [];
  private subject = new Subject<PainelCmdInterface>();

  constructor(
    private alertService: AlertService,
    private extratoService: ExtratoContasPainelService,
  ) {}

  public getSubject(): Observable<PainelCmdInterface> {
    return this.subject.asObservable();
  }
  /**
   * Marca o cache como invalido.
   */
  private setModify(): void {
    this.modify_version++;
    this.subject.next({ cmd: 'MODIFY' });
    console.log('MODIFY', this.periodo, this.modify_version);
  }

  public setPeriodo(periodo: LocalDateType): void {
    this.periodo = periodo;
    this.setModify();
  }

  public refreshConfig(): void {
    this.modify_version++;
    this.subject.next({ cmd: 'CONFIG' });
  }

  public getPeriodo(): LocalDateType {
    return this.periodo;
  }

  public search(tipo: PaineisFinanceirosEnum): Observable<ExtratoLancamentoBean[]> {
    // Se a versão não foi modificada ou se já estamos carregando os dados
    if (this.modify_version === this.current_version || this.isLoading) {
      // Retorna os dados filtrados localmente com debounce
      return of(this.filterItems(tipo)).pipe(debounceTime(300));
    }
    const periodo: PeriodoExtrato = {
      tipo: ExtratoPeriodoEnum.MENSAL,
      datainicial: this.periodo,
      datafinal: this.periodo,
    };
    console.log('LOAD', this.periodo, this.modify_version);
    return this.extratoService.GetMovimentoFull(periodo).pipe(
      debounceTime(300), // Adiciona debounce para limitar a frequência da chamada
      switchMap((data) => {
        this.current_version++;
        this.modify_version = this.current_version;
        this.items = [...data];
        return of(this.filterItems(tipo));
      }),
      catchError((error) => {
        this.alertService.error(error);
        return of([]);
      }),
      finalize(() => {
        // Libera o lock quando a requisição termina (sucesso ou erro)
        this.isLoading = false;
      }),
    );
  }

  private filterItems(tipo: PaineisFinanceirosEnum): ExtratoLancamentoBeanExt[] {
    switch (tipo) {
      case PaineisFinanceirosEnum.DC:
        return this.items.filter(
          (item) => item.tipo === TipoContaTransacaoEnum.TRN && item.despesa && item.despesa > 0,
        );
      case PaineisFinanceirosEnum.RC:
        return this.items.filter(
          (item) => item.tipo === TipoContaTransacaoEnum.TRN && item.receita && item.receita > 0,
        );
      default:
        return this.items;
    }
  }
}

export interface PainelCmdInterface {
  cmd: 'MODIFY' | 'CONFIG';
}
