<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>LancamentoDetalheBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-tipofinanceiro/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['tipofinanceiro']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.tipofinanceiro</label
      >
      <ng-select
        [id]="fieldIdTable['tipofinanceiro']"
        #tipofinanceiro
        formControlName="tipofinanceiro"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipofinanceiroList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoFinanceiroEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipofinanceiro -->
    <!-- #I-#field-valor/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['valor']" class="form-label" l10nTranslate>LancamentoDetalheBeanFragment.valor</label>
      <input
        type="text"
        [id]="fieldIdTable['valor']"
        formControlName="valor"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valor.invalid && (f.valor.dirty || f.valor.touched) }"
        kzMaskCurrency
        maxlength="22"
      />
      @if (f.valor.errors) {
        <div class="invalid-feedback">
          @if (f.valor.errors.required) {
            <div l10nTranslate>LancamentoDetalheBeanFragment.valor-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valor -->
    <!-- #I-#field-descricao -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['descricao']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.descricao</label
      >
      <input
        type="text"
        [id]="fieldIdTable['descricao']"
        formControlName="descricao"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.descricao.invalid && (f.descricao.dirty || f.descricao.touched) }"
        maxlength="200"
      />
      @if (f.descricao.errors) {
        <div class="invalid-feedback">
          @if (f.descricao.errors.required) {
            <div l10nTranslate>LancamentoDetalheBeanFragment.descricao-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-descricao -->
    <!-- #I-#field-idcategoria -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['idcategoria']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.idcategoria</label
      >
      <app-select-value
        [id]="fieldIdTable['idcategoria']"
        formControlName="idcategoria"
        host="/financeiro/categoria"
        par="CATEGORIABEAN"
        [opcao]="f.tipofinanceiro.value"
        table="CAT"
        [isRequired]="true"
        (onAddNewItem)="OnAddNewItem($event, 'idcategoria')"
        (onSelectChange)="OnSelectChange($event)"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcategoria -->
    <!-- #I-#field-idcontato/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['idcontato']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.idcontato</label
      >
      <app-select-value
        [id]="fieldIdTable['idcontato']"
        formControlName="idcontato"
        host="/admin/user"
        par="CONTATOBEAN"
        opcao=""
        table="CON"
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'idcontato')"
        (onSelectChange)="OnSelectChange($event)"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcontato -->
    <!-- #I-#field-idcentrocusto/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['idcentrocusto']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.idcentrocusto</label
      >
      <app-select-value
        [id]="fieldIdTable['idcentrocusto']"
        formControlName="idcentrocusto"
        host="/financeiro/categoria"
        par="CENTROCUSTOBEAN"
        opcao="CST"
        [isRequired]="false"
        (onSelectChange)="OnSelectChange($event)"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcentrocusto -->
    <!-- #I-#field-idprojeto/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      <label [for]="fieldIdTable['idprojeto']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.idprojeto</label
      >
      <app-select-value
        [id]="fieldIdTable['idprojeto']"
        formControlName="idprojeto"
        host="/financeiro/categoria"
        par="PROJETOBEAN"
        opcao=""
        table="PRJ"
        [isRequired]="false"
        (onSelectChange)="OnSelectChange($event)"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idprojeto -->
    <!-- #I-#field-listatags/R -->
    <div class="col-sm-12 mb-3">
      <label [for]="fieldIdTable['listatags']" class="form-label" l10nTranslate
        >LancamentoDetalheBeanFragment.listatags</label
      >
      <app-select-value
        [id]="fieldIdTable['listatags']"
        formControlName="listatags"
        host="/financeiro/categoria"
        par="TAGBEAN"
        opcao=""
        table="TAG"
        [multiple]="true"
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'listatags')"
        (onSelectChange)="OnSelectChange($event)"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-listatags -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
