// {{NOTGENERATE}}
// @form
// @fragment
// @Wrapper:TbUserPainelConfig
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgbModule, NgbScrollSpyModule } from '@ng-bootstrap/ng-bootstrap';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { RegVisaoGeral, UserHrefBeanUtilities } from 'src/app/core/models/UserHrefBean';
import { UserPainelConfigBean } from '../../../models/UserPainelConfigBean';
import { ResourcesFinanceirosEnum } from 'src/app/modules/financeiro/models/ResourcesFinanceirosEnum';

// #region #pre-definition#.

// #endregion

@Component({
  standalone: true,
  selector: 'app-personalizar-home-bean-fragment',
  templateUrl: './personalizar-home-bean-fragment.component.html',
  imports: [CommonModule, L10nTranslateDirective, L10nTranslatePipe, NgbModule, NgbScrollSpyModule],
  providers: [],
})
export class PersonalizarHomeBeanFragmentComponent implements OnInit {
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  protected rowList: RegVisaoGeral[] = [];

  masks = Constants;

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  ngOnInit(): void {}

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  onReadRegistro(registro: UserPainelConfigBean): void {
    if (registro && registro.nome === ResourcesFinanceirosEnum.FINANCEIRO_PAINEL) {
      this.rowList = [
        ...UserHrefBeanUtilities.toRegVisaoGeralList(registro.parametros, UserHrefBeanUtilities.VISAO_GERAL),
      ];
    } else {
      this.rowList = [];
    }
  }

  CrudFormToRegistro() {
    const reg = UserHrefBeanUtilities.toUserHrefBeanList(this.rowList);
    reg.unshift({
      tipo: 'VERSION',
      href: '1.0',
      nodes: [],
    });
    return reg;
  }

  onClick(item: RegVisaoGeral): void {
    item.status = !item.status;
    this.clearMarca();
  }

  onClickCmd(cmd: 'UP' | 'DOWN', o_index: number): void {
    const lista: RegVisaoGeral[] = this.rowList.map((x) => {
      x.marca = false;
      return x;
    });
    const n_index = o_index + Number(cmd === 'UP' ? -1 : 1);
    if (o_index >= 0 && n_index >= 0 && n_index <= lista.length) {
      // Remove o elemento do array
      const [element] = lista.splice(o_index, 1);
      // Insere o elemento na nova posição
      element.marca = true;
      lista.splice(n_index, 0, element);
      this.rowList = [...lista];
    } else {
      console.error('Elemento não encontrado ou índice inválido.');
    }
  }

  clearMarca(): void {
    this.rowList.forEach((elm) => {
      elm.marca = false;
    });
  }
}
