// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SelectValueComponent } from 'src/app/core/accessors/select-value.component';
import { NumberOnlyDirective } from 'src/app/core/directives/number-only.directive';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UnidadeFederacaoEnumRepo } from '../../../models/UnidadeFederacaoEnum';
import { WEndereco } from 'src/app/core/models/WEndereco';
import { SelectOptionUtils } from 'src/app/core/helpers/SelectOptionUtils';
import { Subscription } from 'rxjs';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-endereco-bean-fragment',
  templateUrl: './endereco-bean-fragment.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NumberOnlyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    SelectValueComponent,
  ],
  providers: [provideNgxMask()],
})
export class EnderecoBeanFragmentComponent implements OnInit, OnDestroy, AfterViewInit {
  // #region #properties#.
  currentEndereco: WEndereco = {};
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  ufList: SelectOptionItem[] = UnidadeFederacaoEnumRepo.optionList();

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private utilsService: UtilsService,
    private alertService: AlertService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }

  ngOnDestroy() {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN
  TrataValueChanges(): void {
    const valueChangeSub = this.f.cep.valueChanges.subscribe(() => {
      if (this.f.cep.value) {
        const cep: string = Utilities.unmask(this.f.cep.value).toString();
        if (cep.length > 7) {
          if (
            !this.currentEndereco ||
            this.currentEndereco.cep !== cep ||
            this.currentEndereco.logradouro !== this.f.logradouro?.value
          ) {
            this.utilsService.GetCep(cep).subscribe({
              next: (data) => {
                this.currentEndereco = data;
                this.f.logradouro.patchValue(data.logradouro);
                this.f.bairro.patchValue(data.bairro);
                this.f.municipio.patchValue(data.localidade);
                this.f.uf.patchValue(data.uf);
              },
              error: (error) => {
                this.alertService.error(error);
              },
            });
          }
        }
      }
    });
    this.subscriptions.push(valueChangeSub);
  }
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    this.OnLoadSelectedRows();
  }
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onloadselectedrows.
  // CODGEN {{R}}
  OnLoadSelectedRows() {}
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      cep: [null, Validators.required],
      logradouro: [null, [Validators.required, Validators.maxLength(150)]],
      numero: [null, [Validators.required, Validators.maxLength(80)]],
      complemento: [null, Validators.maxLength(80)],
      bairro: [null, Validators.maxLength(80)],
      municipio: [null, [Validators.required, Validators.maxLength(256)]],
      uf: [null, Validators.required],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      cep: Utilities.unmask(this.CrudForm.get('cep').value),
      logradouro: this.CrudForm.get('logradouro').value,
      numero: this.CrudForm.get('numero').value,
      complemento: this.CrudForm.get('complemento').value,
      bairro: this.CrudForm.get('bairro').value,
      municipio: this.CrudForm.get('municipio').value,
      uf: this.CrudForm.get('uf').value,
    };
    return reg;
  }
  // #endregion

  // #region #onsearch.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnSearch(item): void {}
  // #endregion

  // #region #customsearchfn.
  // CODGEN {{R}}
  CustomSearchFn(term: string, item: SelectOptionItem): boolean | null {
    return SelectOptionUtils.SelectOptionItemSearchFn(term, item);
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion

  // #region #declarations#.
  // #endregion
}
