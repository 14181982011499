<!-- {{NOTGENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>TbDocumentBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-nome/R -->
    <div class="col-sm-12 mb-3 required">
      <label [for]="fieldIdTable['nome']" class="form-label" l10nTranslate>TbDocumentBeanFragment.nome</label>
      <input
        type="text"
        [id]="fieldIdTable['nome']"
        formControlName="nome"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
        maxlength="80"
      />
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.required) {
            <div l10nTranslate>TbDocumentBeanFragment.nome-REQUIRED</div>
          }
          @if (f.nome.errors.maxlength) {
            <div l10nTranslate>TbDocumentBeanFragment.nome-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nome -->
    <!-- #I-#field-descricao/R -->
    <div class="col-sm-12 mb-3">
      <label [for]="fieldIdTable['descricao']" class="form-label" l10nTranslate>TbDocumentBeanFragment.descricao</label>
      <textarea
        [id]="fieldIdTable['descricao']"
        formControlName="descricao"
        class="form-control"
        rows="3"
        [ngClass]="{ 'is-invalid': f.descricao.invalid && (f.descricao.dirty || f.descricao.touched) }"
      >
      </textarea>
      @if (f.descricao.errors) {
        <div class="invalid-feedback">
          @if (f.descricao.errors.maxlength) {
            <div l10nTranslate>TbDocumentBeanFragment.descricao-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-descricao -->
    <!-- #I-#field-idimagem/R -->
    <div class="col-sm-12 mb-3 d-none">
      <label [for]="fieldIdTable['idimagem']" class="form-label" l10nTranslate>TbDocumentBeanFragment.idimagem</label>
      <input
        type="text"
        [id]="fieldIdTable['idimagem']"
        formControlName="idimagem"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.idimagem.invalid && (f.idimagem.dirty || f.idimagem.touched) }"
        maxlength="80"
      />
      @if (f.idimagem.errors) {
        <div class="invalid-feedback">
          @if (f.idimagem.errors.maxlength) {
            <div l10nTranslate>TbDocumentBeanFragment.idimagem-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-idimagem -->
    <!-- #I-#end-row-one -->
    <div class="col-sm-12" [class.d-none]="IsEditMode()">
      <div>
        <app-file-upload-style
          #fileupload
          [dados]="rquestArqData()"
          [multiple]="false"
          [accept]="'*/*'"
          [autosend]="false"
          (onSelectFileList)="onSelectFileList($event)"
        ></app-file-upload-style>
      </div>
    </div>
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
