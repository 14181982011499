// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum DisponibilidadeSaldoEnum {
  IMEDIATO = 'IMEDIATO',
  FUTURO = 'FUTURO',
  IMOBILIZADO = 'IMOBILIZADO',
}

// #region optionlist#method.
// CODGEN {{R}}
export class DisponibilidadeSaldoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'IMEDIATO', name: 'IMEDIATO' },
      { id: 'FUTURO', name: 'FUTURO' },
      { id: 'IMOBILIZADO', name: 'IMOBILIZADO' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
