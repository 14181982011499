<!-- {{GENERATE}} -->
<!-- #I-#tpx-card-body/R -->
<div class="card card-equal-height w-card">
  <div class="card-body">
    <!-- #E-#tpx-card-body -->
    <!-- Row NOME-->
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex align-items-center">
          <!-- #I-#tpx-field-nome/R -->
          <div class="text-content card-title">
            {{ DisplayField('nome', registro.nome, registro) }}
          </div>
          <!-- #E-#tpx-field-nome -->
          <div class="ms-auto">
            <!-- #I-#On-Menu-Clickcontato/R -->
            <div class="ellipse-dropdown">
              <div ngbDropdown class="d-inline-block dropdown">
                <button
                  type="button"
                  class="btn btn-ellipse"
                  id="dropdownBasic1"
                  ngbDropdownToggle
                  (click)="preventClose($event)"
                >
                  <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="OnMenuClickcontato($event, 'editar')" l10nTranslate>
                    <i class="fa fa-fw fa-pencil-square-o me-2" aria-hidden="true"></i>AdminContatoActionCard.M-EDITAR
                  </button>
                  @if (registro.status === 'ENABLE') {
                    <button ngbDropdownItem (click)="OnMenuClickcontato($event, 'inativar')" l10nTranslate>
                      <i class="fa fa-fw fa-ban me-2" aria-hidden="true"></i>AdminContatoActionCard.M-INATIVAR
                    </button>
                  }
                  @if (registro.status === 'DISABLE') {
                    <button ngbDropdownItem (click)="OnMenuClickcontato($event, 'ativar')" l10nTranslate>
                      <i class="fa fa-fw fa-check-square-o me-2" aria-hidden="true"></i>AdminContatoActionCard.M-ATIVAR
                    </button>
                  }
                  <button ngbDropdownItem (click)="OnMenuClickcontato($event, 'excluir')" l10nTranslate>
                    <i class="fa fa-fw fa-trash-o me-2" aria-hidden="true"></i>AdminContatoActionCard.M-EXCLUIR
                  </button>
                </div>
              </div>
            </div>
            <!-- #E-#On-Menu-Clickcontato -->
          </div>
        </div>
      </div>
    </div>
    <!-- Row OBSERVACOES-->
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex align-items-center">
          <!-- #I-#tpx-field-observacoes -->
          <div class="text-content">
            {{ DisplayField('observacoes', registro.observacoes, registro) }}
          </div>
          <!-- #E-#tpx-field-observacoes -->
        </div>
      </div>
    </div>
    <!-- #I-#tpx-card-footer-inativo/R -->
    <div class="d-flex justify-content-end">
      @if (registro.status === 'DISABLE') {
        <div class="position-absolute fst-italic text-secondary">(Inativo)</div>
      }
    </div>
    <!-- #E-#tpx-card-footer-inativo -->
    <!-- #I-#tpx-card-footer/R -->
  </div>
</div>
<!-- #E-#tpx-card-footer -->
