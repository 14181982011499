// {{GENERATE}}

import { ValueName } from './ValueName';

export interface BagParameter {
  nodes?: ValueName[];
}

// #region #declarations#.

export class BagParameterObject implements BagParameter {
  nodes?: ValueName[];
  constructor(bag: BagParameter | ValueName[] | null = null) {
    if (bag === null) {
      this.nodes = [];
    } else if (Array.isArray(bag)) {
      this.nodes = bag;
    } else {
      this.nodes = bag.nodes;
    }
  }

  public clear(): void {
    this.nodes = [];
  }

  public findNodeById(id: string): ValueName | null {
    if (id != null && this.nodes && this.nodes.length > 0) {
      for (const node of this.nodes) {
        if (node.id === id) {
          return node;
        }
      }
    }
    return null;
  }

  public getTextById(id: string, defvalue: string | null = ''): string | null {
    const vn = this.findNodeById(id);
    if (vn != null) {
      return vn.text;
    }
    return defvalue;
  }

  public jsonParseById(id: string): any {
    const r = this.getTextById(id);
    if (r) {
      return JSON.parse(r);
    }
    return null;
  }

  public addUniqId(node: ValueName): BagParameterObject {
    const vn = this.findNodeById(node.id);
    if (vn != null) {
      vn.text = node.text;
      vn.tag = node.tag;
    } else {
      if (!this.nodes) {
        this.nodes = [];
      }
      this.nodes.push({ id: node.id, text: node.text, tag: node.tag });
    }
    return this;
  }

  public setTextById(id: string, text: string): BagParameterObject {
    return this.addUniqId({ id: id, text: text });
  }
}
// #endregion
