<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div class="row mb-3">
    <div class="t-header-title" l10nTranslate>TerminarCadastro.HEADERTITLE</div>
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body/R -->
  <!-- #E-#start-body -->
  <!-- #I-#field-email -->
  <div class="row mb-3">
    <div class="col-sm-12">
      <label [for]="fieldIdTable['email']" class="form-label" l10nTranslate>TerminarCadastro.email</label>
      <input
        type="text"
        [id]="fieldIdTable['email']"
        formControlName="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched) }"
        readonly
      />
      @if (f.email.errors) {
        <div class="invalid-feedback">
          @if (f.email.errors.email) {
            <div l10nTranslate>TerminarCadastro.email-EMAIL</div>
          }
          @if (f.email.errors.required) {
            <div l10nTranslate>TerminarCadastro.email-REQUIRED</div>
          }
        </div>
      }
    </div>
  </div>
  <!-- #E-#field-email -->
  <!-- #I-#field-nome -->
  <div class="row mb-3">
    <div class="col-sm-12">
      <label [for]="fieldIdTable['nome']" class="form-label" l10nTranslate>TerminarCadastro.nome</label>
      <input
        type="text"
        [id]="fieldIdTable['nome']"
        formControlName="nome"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
      />
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.required) {
            <div l10nTranslate>TerminarCadastro.nome-REQUIRED</div>
          }
        </div>
      }
    </div>
  </div>
  <!-- #E-#field-nome -->
  <!-- #I-#field-senha -->
  <div class="row mb-3">
    <div class="col-sm-12">
      <label [for]="fieldIdTable['senha']" class="form-label" l10nTranslate>TerminarCadastro.senha</label>
      <input
        type="password"
        [id]="fieldIdTable['senha']"
        formControlName="senha"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.senha.invalid && (f.senha.dirty || f.senha.touched) }"
        autocomplete="current-password"
        maxlength="50"
      />
      @if (f.senha.errors) {
        <div class="invalid-feedback">
          @if (f.senha.errors.required) {
            <div l10nTranslate>TerminarCadastro.senha-REQUIRED</div>
          }
          @if (f.senha.errors.maxLength) {
            <div l10nTranslate>TerminarCadastro.senha-MAX</div>
          }
        </div>
      }
      <app-password-strength [passwordToCheck]="CrudForm.value.senha"></app-password-strength>
    </div>
  </div>
  <!-- #E-#field-senha -->
  <!-- #I-#field-confirmesenha -->
  <div class="row mb-3">
    <div class="col-sm-12">
      <label [for]="fieldIdTable['confirmesenha']" class="form-label" l10nTranslate
        >TerminarCadastro.confirmesenha</label
      >
      <input
        type="password"
        [id]="fieldIdTable['confirmesenha']"
        formControlName="confirmesenha"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.confirmesenha.invalid && (f.confirmesenha.dirty || f.confirmesenha.touched) }"
      />
      @if (f.confirmesenha.errors) {
        <div class="invalid-feedback">
          @if (f.confirmesenha.errors.required) {
            <div l10nTranslate>TerminarCadastro.confirmesenha-REQUIRED</div>
          }
        </div>
      }
    </div>
  </div>
  <!-- #E-#field-confirmesenha -->
  <!-- #I-#end-body/R -->
  <div class="row mb-3">
    <div class="col">
      <button
        type="button"
        class="btn btn-primary w-100"
        (click)="OnProcessar()"
        [disabled]="!CrudForm.valid"
        l10nTranslate
      >
        TerminarCadastro.BTN-ENTER
      </button>
    </div>
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form/R -->
  <div class="row mb-3">
    <div class="col d-flex justify-content-center">
      <button type="button" class="btn btn-link" (click)="OnCancelar()" l10nTranslate>
        CriarContaUsuario.BTN-BACK
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div class="d-flex flex-column justify-content-center">
        <div class="text-center">Ao se cadastrar voc&ecirc; declara concordar com nossos</div>
        <div class="text-center">
          <a href="/#/termo-de-uso" target="_blank">Termos de Uso</a> e
          <a href="/#/politica-privacidade" target="_blank">Pol&iacute;ticas de Privacidade</a>.
        </div>
      </div>
    </div>
  </div>
  <!-- class.modal-footer -->
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
