import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

/**
 * https://svgfind.com/svg/8498975/agriculture
 */
@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.css'],
  standalone: true,
  imports: [FormsModule],
})
export class PoliticaPrivacidadeComponent {}
