/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Utilities } from './utilities';

export enum DialogType {
  confirm = 'confirm',
  success = 'success',
  error = 'error',
  warning = 'warning',
  message = 'message',
  clear = 'clear',
}

export interface AlertDialogInterface {
  title: string;
  message: string;
  type: DialogType;
  btnOkText?: string;
  btnCancelText?: string;
  timeOut?: number;
  isImage?: boolean;
  okCallback?: (val?: any) => any;
  cancelCallback?: () => any;
}

export class AlertDialog implements AlertDialogInterface {
  constructor(
    public title: string,
    public message: string,
    public type: DialogType,
    public btnOkText?: string,
    public btnCancelText?: string,
    public timeOut?: number,
    public isImage?: boolean,
    public okCallback?: (val?: any) => any,
    public cancelCallback?: () => any,
  ) {}
}

@Injectable()
export class AlertService implements OnDestroy {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private subject = new Subject<any>();
  private dialogs = new Subject<AlertDialogInterface>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router) {
    const subscription = router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          if (this.keepAfterNavigationChange) {
            this.keepAfterNavigationChange = false;
          }
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  warning(message: string, timeOut = 2500, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: DialogType.warning, message: message, timeOut: timeOut });
  }

  success(message: string, timeOut = 2500, keepAfterNavigationChange = false): void {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: DialogType.success, message: message, timeOut: timeOut });
  }

  error(message: string, keepAfterNavigationChange = false): void {
    if (Utilities.TestIsString(message) && message.startsWith('Atenção:')) {
      message = message.substring(8).trim();
    }
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: DialogType.error, message: message });
  }

  clear(): void {
    this.subject.next({ type: DialogType.clear, message: 'clear' });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  getDialogEvent(): Observable<AlertDialogInterface> {
    return this.dialogs.asObservable();
  }

  showDialog(
    title: string,
    message: string,
    type: DialogType,
    okCallback: (val?: any) => any,
    cancelCallback: () => any,
    btnOkText = 'Confirmar',
    btnCancelText = 'Cancelar',
    isImage = false,
  ) {
    this.dialogs.next({
      title: title,
      message: message,
      type: type,
      okCallback: okCallback,
      cancelCallback: cancelCallback,
      btnOkText: btnOkText,
      btnCancelText: btnCancelText,
      isImage: isImage,
    });
  }
}
