<div class="month-year-picker flex-column px-3">
  <button type="button" (click)="onNextMonth()">
    <i class="fa fa-fw fa-chevron-up" aria-hidden="true"></i>
  </button>
  <div class="text-center wtext">
    <strong>{{ month }}</strong>
  </div>
  <div class="text-center wtext">{{ year }}</div>
  <button type="button" (click)="onPrevMonth()">
    <i class="fa fa-fw fa-chevron-down" aria-hidden="true"></i>
  </button>
  <button type="button" class="btn btn-sm" (click)="doClickDataAtual()" ngbTooltip="usar data atual" placement="bottom">
    <i class="fa fa-calendar-check-o fa-2x" aria-hidden="true"></i>
  </button>
</div>
