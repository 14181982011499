import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { HttpHelper } from '../helpers/http-helper';
import { BrokerProfileBean } from '../models/BrokerProfileBean';
import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class BrokerModuleProfileService implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private subject = new Subject<BrokerProfileBean>();
  private lastProfile: BrokerProfileBean = null;

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private helper: HttpHelper,
  ) {
    const subscription = this.helper.getObservableUser().subscribe({
      next: () => {
        this.loadFromServer();
      },
    });
    this.subscriptions.push(subscription);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public getSubject(): Observable<BrokerProfileBean> {
    return this.subject.asObservable();
  }

  public getProfile(): BrokerProfileBean {
    return this.lastProfile;
  }

  public loadFromServer() {
    if (this.helper.IsActiveUser()) {
      this.authenticationService.GetBrokerProfileBean().subscribe({
        next: (data) => {
          this.lastProfile = data;
          this.subject.next(this.lastProfile);
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
}
