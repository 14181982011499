// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoContatoEnum {
  CLIENTE = 'CLIENTE',
  FORNECEDOR = 'FORNECEDOR',
  CLIENTE_FORNECEDOR = 'CLIENTE_FORNECEDOR',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoContatoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'CLIENTE', name: 'CLIENTE' },
      { id: 'FORNECEDOR', name: 'FORNECEDOR' },
      { id: 'CLIENTE_FORNECEDOR', name: 'CLIENTE_FORNECEDOR' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
