import { cepPattern, cnpjPattern, cpfPattern, telefoneCelularPattern, telefoneFixoPattern } from './pipe-constants';

declare let VMasker: any;
const CPF_LENGTH = 11;

export class Formatter {
  public static removeNonDigitValues(value: string): string {
    return value ? value.replace(/[^\d]/g, '').trim() : value;
  }

  /**
   * formato '99:99:99'
   * @param value
   * @returns
   */
  public static maskTime(value: string): string {
    if (!value) {
      return '';
    }
    return VMasker.toPattern(this.removeNonDigitValues(value), '99:99:99');
  }

  /**
   * formato 'dd/mm/aaaa'
   * @param value
   * @returns
   */
  public static maskDate(value: string): string {
    if (!value) {
      return '';
    }
    return VMasker.toPattern(this.removeNonDigitValues(value), '99/99/9999');
  }

  /**
   * formato 'dd/mm/aaaa hh:mm:ss'
   * @param value
   * @returns
   */
  public static maskDateTime(value: string): string {
    if (!value) {
      return '';
    }
    return VMasker.toPattern(this.removeNonDigitValues(value), '99/99/9999 99:99:99');
  }

  /**
   * formato:'99999-999'
   * @param value
   * @returns
   */
  public static maskCep(value: string): string {
    if (!value) {
      return '';
    }
    return VMasker.toPattern(this.removeNonDigitValues(value), cepPattern);
  }

  public static maskCnpj(value: string): string {
    if (!value) {
      return '';
    }
    return VMasker.toPattern(this.removeNonDigitValues(value), cnpjPattern);
  }

  public static maskCpf(value: string): string {
    if (!value) {
      return '';
    }
    return VMasker.toPattern(this.removeNonDigitValues(value), cpfPattern);
  }

  public static maskCpfOuCnpj(value: string): string {
    if (!value) {
      return '';
    }
    const nvalue = this.removeNonDigitValues(value);
    if (nvalue.toString().length > CPF_LENGTH) {
      return VMasker.toPattern(nvalue, cnpjPattern);
    }
    return VMasker.toPattern(nvalue, cpfPattern);
  }

  public static maskAgenciaBancaria(value: string): string {
    if (!value) {
      return '';
    }
    const pattern = '99999-A';
    const nvalue = this.removeNonDigitValues(value);
    return VMasker.toPattern(nvalue, pattern);
  }

  public static maskContaBancaria(value: string): string {
    if (!value) {
      return '';
    }
    const nvalue = this.removeNonDigitValues(value);
    if (nvalue.toString().length > CPF_LENGTH) {
      return VMasker.toPattern(nvalue, cnpjPattern);
    }
    return VMasker.toPattern(nvalue, cpfPattern);
  }

  public static maskTelefone(value: string): string {
    if (!value) {
      return '';
    }
    const nvalue = this.removeNonDigitValues(value);
    const telefonePattern: string = nvalue.toString().length === 11 ? telefoneCelularPattern : telefoneFixoPattern;
    return VMasker.toPattern(nvalue, telefonePattern);
  }

  public static truncate(str: string, sz: number): string {
    if (str && str.length > sz) {
      return str.slice(0, sz) + '...';
    }
    return str;
  }

  public static separatedByCommas(roles: string[]): string {
    let v = '';
    let vg = '';
    if (roles) {
      for (const item of roles) {
        v += vg + item;
        vg = ', ';
      }
    }
    return v;
  }
}
