import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IBreadcrumb } from '../models/IBreadcrumb';

export enum TitlePanelType {
  header_title_2 = 'header_title_2',
  breadcrumbs = 'breadcrumbs',
}

export interface TitlePanelEvent {
  type: TitlePanelType;
  title?: string;
  breadcrumbs?: string[];
}

@Injectable()
export class TitlePanelService {
  private subject = new Subject<TitlePanelEvent>();

  constructor() {}

  publish(type: TitlePanelType, title: string): void {
    this.subject.next({ type: type, title: title });
  }

  publishBreadcrumb(breadcrumbs: IBreadcrumb[]): void {
    const p = [];
    for (const item of breadcrumbs) {
      if (item.url) {
        p.push(item.url);
      }
    }
    this.subject.next({ type: TitlePanelType.breadcrumbs, breadcrumbs: p });
  }

  getEvents(): Observable<TitlePanelEvent> {
    return this.subject.asObservable();
  }
}
