<!-- {{GENERATE}} -->
<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title t-header-title" l10nTranslate>TagActionAdd.HEADERTITLE{{ IsEditMode() ? '-E' : '-A' }}</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="OnCloseModal(false)"></button>
  </div>
  <div class="modal-body">
    <form [id]="fieldIdTable['form']" [formGroup]="CrudForm" (ngSubmit)="onSubmit()">
      <div class="w-100">
        <app-tag-bean-fragment #vtag [EditMode]="IsEditMode()" [CrudForm]="CrudForm"></app-tag-bean-fragment>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="OnCloseModal(false)">
      <i class="fa fa-times" aria-hidden="true"></i> {{ 'TagActionAdd.BTN-BACK' | translate: locale.language }}
    </button>
    @if (IsEditMode()) {
      <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="!CrudForm.valid">
        <i class="fa fa-save" aria-hidden="true"></i> {{ 'TagActionAdd.BTN-ENTER' | translate: locale.language }}
      </button>
    }
    @if (!IsEditMode()) {
      <div class="btn-group" role="group" aria-label="...">
        <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="!CrudForm.valid">
          <i class="fa fa-save" aria-hidden="true"></i>
          {{ 'AdminUserActionAdd.BTN-ENTER' | translate: locale.language }}
        </button>
        <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Salvar e continuar">
          <button
            type="button"
            class="btn btn-primary border-start tpx-border-botao"
            (click)="onSubmit('+')"
            [disabled]="!CrudForm.valid"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </span>
      </div>
    }
  </div>
</div>
