// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoPessoaEnum {
  PF = 'PF',
  PJ = 'PJ',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoPessoaEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'PF', name: 'PF' },
      { id: 'PJ', name: 'PJ' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
