import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LRUCache } from 'lru-cache';
import { asyncScheduler, catchError, map, Observable, scheduled, Subscription } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { AlertService } from 'src/app/core/services/alert.service';
import { ContaMoedaBean } from '../models/ContaMoedaBean';
import { CategoriaActionService } from './categoria-action.service';

@Injectable({
  providedIn: 'root',
})
export class MoedaRepositorioService implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private cache: LRUCache<number, ContaMoedaBean>;

  constructor(
    private actionService: CategoriaActionService,
    private alertService: AlertService,
    private helper: HttpHelper,
  ) {
    this.cache = new LRUCache<number, ContaMoedaBean>({
      max: 50,
      ttl: 1000 * 60 * 60, // Time-to-live for cache entries (1 hour)
    });
    const subscription = this.helper.getObservableUser().subscribe({
      next: () => {
        this.loadDados();
      },
    });
    this.subscriptions.push(subscription);
    this.loadDados();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.cache.clear();
  }

  private loadDados(): void {
    if (!this.helper.IsActiveUser()) {
      return;
    }
    this.actionService.GetContaMoedaList().subscribe({
      next: (data) => {
        this.cache.clear();
        this.setItemList(data);
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  // Adicionar um item ao cache
  public setItem(item: ContaMoedaBean): void {
    if (item && item.idconta) {
      this.cache.set(item.idconta, item);
    }
  }

  private setItemList(items: ContaMoedaBean[]): void {
    if (items && items.length > 0) {
      items.forEach((item) => {
        this.setItem(item);
      });
    }
  }
  /**
   * Busca no cache/servidor a moeda.
   * @param idConta - id da conta
   * @returns
   */
  public getContaMoeda(idConta: number): Observable<ContaMoedaBean | undefined> {
    const cachedItem = this.cache.get(idConta);
    if (cachedItem) {
      // Usando 'scheduled' em vez de 'of' para agendar a emissão de 'cachedItem'
      return scheduled([cachedItem], asyncScheduler);
    } else {
      return this.actionService.GetContaMoedaList().pipe(
        map((response) => {
          if (response && response.length > 0) {
            let element: ContaMoedaBean = undefined;
            response.forEach((item) => {
              this.setItem(item);
              if (item.idconta === idConta) {
                element = item;
              }
            });
            return element;
          }
          return undefined;
        }),
        catchError((error) => {
          this.alertService.error(error);
          // Usando 'scheduled' para retornar 'undefined' em caso de erro
          return scheduled([undefined], asyncScheduler);
        }),
      );
    }
  }
}
