// {{GENERATE}}
// @Component
// @Modal
// @Route:/admin/user/person
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { CategoriaActionService } from 'src/app/modules/financeiro/services/categoria-action.service';
import { UserPainelConfigBean } from '../../../models/UserPainelConfigBean';
import { PersonalizarHomeBeanFragmentComponent } from '../personalizar-home-bean/personalizar-home-bean-fragment.component';
import { ResourcesFinanceirosEnum } from 'src/app/modules/financeiro/models/ResourcesFinanceirosEnum';
import { PainelVisaoGeralService } from 'src/app/modules/financeiro/services/painel-visao-geral.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-personalizar-home',
  templateUrl: './personalizar-home.component.html',
  styleUrls: ['./personalizar-home.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgbModule,
    PersonalizarHomeBeanFragmentComponent,
  ],
  providers: [],
})
export class PersonalizarHomeComponent implements OnInit {
  // #region #properties#.
  registro: UserPainelConfigBean;
  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  isModal: boolean = false;
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @ViewChild('vbean', { static: true }) childBean: PersonalizarHomeBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private crudService: CategoriaActionService,
    private painelService: PainelVisaoGeralService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({});
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN
  OnProcessar(): void {
    if (this.registro) {
      this.registro.parametros = this.CrudFormToRegistro().bean;
      this.crudService.SavePainelConfig(this.registro).subscribe({
        next: (data) => {
          try {
            this.painelService.refreshConfig();
            this.OnCloseModal(true);
          } catch (error) {
            this.alertService.error('Erro no carregamento dos dados.');
          }
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      bean: this.childBean.CrudFormToRegistro(),
    };
    return reg;
  }
  // #endregion

  // #region #opennewmodal.
  // CODGEN {{R}}
  static OpenNewModal(modalService: NgbModal): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, PersonalizarHomeComponent);
    const view: PersonalizarHomeComponent = modalRef.componentInstance;
    view.isModal = true;
    return modalRef;
  }
  // #endregion

  // #region #routine-definitions#.
  IsEditMode(): boolean {
    return false;
  }

  OnInitInvokeService() {
    this.crudService.GetPainelConfig(ResourcesFinanceirosEnum.FINANCEIRO_PAINEL).subscribe({
      next: (data) => {
        try {
          this.registro = data;
          this.childBean.onReadRegistro(data);
        } catch (error) {
          //console.log('erro:', error);
          this.alertService.error('Erro no carregamento dos dados.');
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion
}
