<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>EnderecoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-cep/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['cep']" class="form-label" l10nTranslate>EnderecoBeanFragment.cep</label>
      <input
        type="text"
        [id]="fieldIdTable['cep']"
        formControlName="cep"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.cep.invalid && (f.cep.dirty || f.cep.touched) }"
        mask="{{ masks.ngx.cepMask }}"
      />
      @if (f.cep.errors) {
        <div class="invalid-feedback">
          @if (f.cep.errors.required) {
            <div l10nTranslate>EnderecoBeanFragment.cep-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-cep -->
    <!-- #I-#field-logradouro/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['logradouro']" class="form-label" l10nTranslate>EnderecoBeanFragment.logradouro</label>
      <input
        type="text"
        [id]="fieldIdTable['logradouro']"
        formControlName="logradouro"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.logradouro.invalid && (f.logradouro.dirty || f.logradouro.touched) }"
        maxlength="150"
      />
      @if (f.logradouro.errors) {
        <div class="invalid-feedback">
          @if (f.logradouro.errors.required) {
            <div l10nTranslate>EnderecoBeanFragment.logradouro-REQUIRED</div>
          }
          @if (f.logradouro.errors.maxlength) {
            <div l10nTranslate>EnderecoBeanFragment.logradouro-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-logradouro -->
    <!-- #I-#field-numero/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['numero']" class="form-label" l10nTranslate>EnderecoBeanFragment.numero</label>
      <input
        type="text"
        [id]="fieldIdTable['numero']"
        formControlName="numero"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.numero.invalid && (f.numero.dirty || f.numero.touched) }"
        maxlength="80"
      />
      @if (f.numero.errors) {
        <div class="invalid-feedback">
          @if (f.numero.errors.required) {
            <div l10nTranslate>EnderecoBeanFragment.numero-REQUIRED</div>
          }
          @if (f.numero.errors.maxlength) {
            <div l10nTranslate>EnderecoBeanFragment.numero-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-numero -->
    <!-- #I-#field-complemento/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['complemento']" class="form-label" l10nTranslate
        >EnderecoBeanFragment.complemento</label
      >
      <input
        type="text"
        [id]="fieldIdTable['complemento']"
        formControlName="complemento"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.complemento.invalid && (f.complemento.dirty || f.complemento.touched) }"
        maxlength="80"
      />
      @if (f.complemento.errors) {
        <div class="invalid-feedback">
          @if (f.complemento.errors.maxlength) {
            <div l10nTranslate>EnderecoBeanFragment.complemento-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-complemento -->
    <!-- #I-#field-bairro/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['bairro']" class="form-label" l10nTranslate>EnderecoBeanFragment.bairro</label>
      <input
        type="text"
        [id]="fieldIdTable['bairro']"
        formControlName="bairro"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.bairro.invalid && (f.bairro.dirty || f.bairro.touched) }"
        maxlength="80"
      />
      @if (f.bairro.errors) {
        <div class="invalid-feedback">
          @if (f.bairro.errors.maxlength) {
            <div l10nTranslate>EnderecoBeanFragment.bairro-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-bairro -->
    <!-- #I-#field-municipio/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['municipio']" class="form-label" l10nTranslate>EnderecoBeanFragment.municipio</label>
      <input
        type="text"
        [id]="fieldIdTable['municipio']"
        formControlName="municipio"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.municipio.invalid && (f.municipio.dirty || f.municipio.touched) }"
        maxlength="256"
      />
      @if (f.municipio.errors) {
        <div class="invalid-feedback">
          @if (f.municipio.errors.required) {
            <div l10nTranslate>EnderecoBeanFragment.municipio-REQUIRED</div>
          }
          @if (f.municipio.errors.maxlength) {
            <div l10nTranslate>EnderecoBeanFragment.municipio-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-municipio -->
    <!-- #I-#field-uf/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['uf']" class="form-label" l10nTranslate>EnderecoBeanFragment.uf</label>
      <ng-select
        [id]="fieldIdTable['uf']"
        #uf
        formControlName="uf"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of ufList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>UnidadeFederacaoEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-uf -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
