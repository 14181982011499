import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { AlertPanel } from '../models/AlertPanel';
import { WEndereco } from '../models/WEndereco';

@Injectable()
export abstract class UtilsService {
  abstract GetCep(code: string): Observable<WEndereco>;

  abstract GetAlertPanel(): Observable<AlertPanel>;

  abstract GetAlertPanelMessages(): Observable<AlertPanel>;

  abstract Putreadmessage(messageid: number): Observable<any>;

  abstract PostCmdSelectList(bean: SelectOptionList): Observable<any>;
}
