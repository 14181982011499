<!-- {{GENERATE}} -->
<div class="tpx-crud-card">
  <!-- #I-#tpx-crud-header/R -->
  <div class="tpx-crud-header">
    <div class="row d-flex justify-content-between align-items-center">
      <div class="col-sm-12 col-md-8 mb-3 tpx-crud-title">
        <div class="t-header-title" l10nTranslate>AdminUserActionGrid.HEADERTITLE</div>
      </div>
      <div class="col-sm-12 col-md-4">
        <app-search-box
          (searchChange)="OnSearchChanged($event)"
          placeholder="{{ 'AdminUserActionGrid.SEARCH' | translate: locale.language }}"
        ></app-search-box>
      </div>
    </div>
  </div>
  <!-- #E-#tpx-crud-header -->
  <div class="tpx-crud-body">
    <!-- #I-#row-app-search-box/R -->
    <div class="row control-box d-flex align-items-center">
      <div class="col-sm-14">
        <div class="navbar action-box p-0">
          <ul class="nav navbar-nav navbar-right p-0"></ul>
        </div>
      </div>
    </div>
    <!-- #E-#row-app-search-box -->
    <!-- #I-#row-on-mostrar-ativas/R -->
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-between align-items-center">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            (change)="OnMostrarAtivas($event)"
            [checked]="checkedTodas"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">Mostrar apenas ativas.</label>
        </div>
      </div>
    </div>
    <!-- #E-#row-on-mostrar-ativas -->
    <div class="table-wrapper">
      <!-- #I-#datatable-grid-start/R -->
      <mat-table [dataSource]="rows" class="mt-3" matSort>
        <!-- #E-#datatable-grid-start -->
        <!-- #I-#field-column-username/R -->
        <!-- username Column -->
        <ng-container matColumnDef="username">
          <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate
            >AdminUserActionGrid.username</mat-header-cell
          >
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">{{ 'AdminUserActionGrid.username' | translate: locale.language }} :</span>
            <div class="tpx-cel-grid">{{ DisplayField('username', row.username, row) }}</div>
          </mat-cell>
        </ng-container>
        <!-- #E-#field-column-username -->
        <!-- #I-#field-column-name/R -->
        <!-- name Column -->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate>AdminUserActionGrid.name</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">{{ 'AdminUserActionGrid.name' | translate: locale.language }} :</span>
            <div class="tpx-cel-grid">{{ DisplayField('name', row.name, row) }}</div>
          </mat-cell>
        </ng-container>
        <!-- #E-#field-column-name -->
        <!-- #I-#field-column-status/R -->
        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate>AdminUserActionGrid.status</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">{{ 'AdminUserActionGrid.status' | translate: locale.language }} :</span>
            <div class="tpx-cel-grid">{{ DisplayField('status', row.status, row) }}</div>
          </mat-cell>
        </ng-container>
        <!-- #E-#field-column-status -->
        <!-- #I-#field-column-email/R -->
        <!-- email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate>AdminUserActionGrid.email</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">{{ 'AdminUserActionGrid.email' | translate: locale.language }} :</span>
            <div class="tpx-cel-grid">{{ DisplayField('email', row.email, row) }}</div>
          </mat-cell>
        </ng-container>
        <!-- #E-#field-column-email -->
        <!-- #I-#field-column-roles/R -->
        <!-- roles Column -->
        <ng-container matColumnDef="roles">
          <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate>AdminUserActionGrid.roles</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="mobile-label">{{ 'AdminUserActionGrid.roles' | translate: locale.language }} :</span>
            <div class="tpx-cel-grid">{{ DisplayField('roles', row.roles, row) }}</div>
          </mat-cell>
        </ng-container>
        <!-- #E-#field-column-roles -->
        <!-- #I-#field-column-action/R -->
        <!-- action Column -->
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef aria-label="row actions" l10nTranslate
            >AdminUserActionGrid.action</mat-header-cell
          >
          <mat-cell *matCellDef="let row">
            <div class="d-flex flex-wrap tpx-action-column">
              <div class="tpx-btn-cell">
                <button
                  type="button"
                  (click)="OnEditRow(row)"
                  class="btn tpx-btn-action btn-primary"
                  ngbTooltip="{{ 'AdminUserActionGrid.ACT-EDIT' | translate: locale.language }}"
                  container="body"
                >
                  <i class="fa fa-edit fa-fw" aria-hidden="true"></i
                  >{{ 'AdminUserActionGrid.ACT-EDIT' | translate: locale.language }}
                </button>
              </div>
              <div class="tpx-btn-cell">
                <button
                  type="button"
                  (click)="OnDeleteRow(row)"
                  class="btn tpx-btn-action btn-danger"
                  ngbTooltip="{{ 'AdminUserActionGrid.ACT-DELETE' | translate: locale.language }}"
                  container="body"
                >
                  <i class="fa fa-trash-o fa-fw" aria-hidden="true"></i
                  >{{ 'AdminUserActionGrid.ACT-DELETE' | translate: locale.language }}
                </button>
              </div>
            </div>
          </mat-cell>
        </ng-container>
        <!-- #E-#field-column-action -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 50]"
        showFirstLastButtons
        aria-label="Select page of users"
      ></mat-paginator>
    </div>
  </div>
  <!-- #I-#tpx-crud-footer/R -->
  <div class="tpx-crud-footer">
    <div class="row">
      <div class="col footer-button-height">
        <button
          type="button"
          class="btn btn-primary btn-circle btn-xl footer-button"
          (click)="OnAddElement()"
          ngbTooltip="{{ 'AdminUserActionGrid.BTN-NEW' | translate: locale.language }}"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
  <!-- #E-#tpx-crud-footer -->
</div>
