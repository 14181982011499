// {{NOTGENERATE}}
// @Component
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionDirective, NgbAccordionModule, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { AdDadosElement } from 'src/app/core/components/painel-ad/ad-dados_element';
import { AdComponent } from 'src/app/core/components/painel-ad/ad.component';
import { Constants } from 'src/app/core/helpers/constants';
import { BagParameterObject } from 'src/app/core/models/BagParameter';
import { LocalDateType } from 'src/app/core/models/local-date-type';
import { RegVisaoGeral, UserHrefBeanUtilities } from 'src/app/core/models/UserHrefBean';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { UserPainelConfigBean } from 'src/app/modules/admin/models/UserPainelConfigBean';
import { ChartDataLabel } from '../../../models/ChartDataLabel';
import { ExtratoPeriodoEnum } from '../../../models/ExtratoPeriodoEnum';
import { ExtratoRequest } from '../../../models/ExtratoRequest';
import { LancamentoBean } from '../../../models/LancamentoBean';
import { PaineisFinanceirosEnum, PaineisFinanceirosEnumRepo } from '../../../models/PaineisFinanceirosEnum';
import { ResourcesFinanceirosEnum } from '../../../models/ResourcesFinanceirosEnum';
import { CategoriaActionService } from '../../../services/categoria-action.service';
import { ExtratoContasPainelService } from '../../../services/extrato-contas-painel.service';
import { LancamentoUtilitiesService } from '../../../services/lancamento-utilities.service';
import { PainelVisaoGeralService } from '../../../services/painel-visao-geral.service';
import { EditableCellComponent } from 'src/app/core/accessors/editable-cell.component';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { StatusEnumRepo } from 'src/app/core/models/StatusEnum';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-visao-geral-card',
  templateUrl: './visao-geral-card.component.html',
  styleUrls: ['./visao-geral-card.component.css'],
  host: { class: 'w-100' },
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgbModule,
    BaseChartDirective,
    NgbAccordionModule,
  ],
  providers: [],
})
export class VisaoGeralCardComponent implements AdComponent, OnInit, OnDestroy, AfterViewInit {
  // #region #properties#.
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  protected periodo: LocalDateType;
  protected tipo: PaineisFinanceirosEnum = null;
  protected dadosGrafico: ChartDataLabel[] = [];
  protected NomeMoeda: string = 'BRL';
  protected valorTotal: number = 0;
  protected definicoes: RegVisaoGeral;
  protected mensagemVazio: string = 'Sem dados no mês selecionado.';
  protected mensagemVazioBtn = 'Adicionar...';

  @Input() data: AdDadosElement;
  @Output() onAdChange = new EventEmitter<AdDadosElement>();

  @ViewChild('itemContainer', { static: false }) itemContainer!: ElementRef;
  @ViewChild('accordion') accordionComponent: NgbAccordionDirective;

  // Pie
  public pieChartOptions: ChartOptions<'doughnut'> = {
    responsive: false,
  };
  public pieChartLabels = [];
  public pieChartDatasets = [{ data: [], backgroundColor: [], tooltip: {}, fdata: [] }];
  public pieChartLegend = false;
  public pieChartPlugins = [];

  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private painelService: PainelVisaoGeralService,
    private crudService: CategoriaActionService,
    private extratoContasPainelService: ExtratoContasPainelService,
    private modalService: NgbModal,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.renderer.listen(this.itemContainer.nativeElement, 'resize', () => {
      this.updateItemHeight();
    });
    // Calcular a altura inicial
    this.updateItemHeight();
  }

  private updateItemHeight(): void {
    this.data.itemHeight = this.itemContainer.nativeElement.offsetHeight;
    if (this.onAdChange) {
      this.onAdChange.emit(this.data);
    }
  }

  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({});
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.subscriptions.push(
      this.painelService.getSubject().subscribe({
        next: (evt) => {
          if (evt) {
            if (evt.cmd === 'MODIFY') {
              this.atualizaDados();
            }
          }
        },
      }),
    );
    this.atualizaDados();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private atualizaDados() {
    this.definicoes = this.data.painel ? JSON.parse(this.data.card.painel) : {};
    // console.log('definicoes', JSON.stringify(this.definicoes));
    this.tipo = PaineisFinanceirosEnumRepo.toEnum(this.data.card.name);
    if (this.tipo) {
      this.periodo = this.painelService.getPeriodo();
      const request: ExtratoRequest = {
        tipo: this.data.card.name,
        periodo: {
          tipo: ExtratoPeriodoEnum.MENSAL,
          datainicial: this.periodo,
          datafinal: this.periodo,
        },
        parameters: new BagParameterObject(),
      };
      this.extratoContasPainelService.GetMovimentoChart(request).subscribe({
        next: (data) => {
          try {
            this.dadosGrafico = [...this.geraGrafico(data)];
          } catch (error) {
            this.dadosGrafico = [];
          }
          setTimeout(() => {
            this.updateItemHeight();
          });
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }

  private geraGrafico(dados: ChartDataLabel[]) {
    let valorTotal: number = 0;
    this.pieChartDatasets = [{ data: [], backgroundColor: [], tooltip: {}, fdata: [] }];
    this.pieChartLabels = [];
    dados.forEach((elm) => {
      valorTotal = Number(valorTotal) + Number(elm.valor);
      this.pieChartDatasets[0].data.push(elm.valor);
      this.pieChartDatasets[0].backgroundColor.push(elm.cor);
      this.pieChartDatasets[0].fdata.push(this.formatTooltipValor(elm));
      this.pieChartDatasets[0].tooltip = this.tooltipConfiguration();
      this.pieChartLabels.push(elm.label);
    });
    this.valorTotal = valorTotal;
    return dados;
  }

  formatTooltipValor(row: ChartDataLabel): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valor);
  }

  tooltipConfiguration() {
    return {
      callbacks: {
        label: function (context) {
          const index = context.dataIndex;
          let label = context.label;
          if (!label) label = 'Unknown';
          let dataArr = context.chart.data.datasets[0].fdata;
          return ' ' + label + ': ' + dataArr[index];
        },
      },
    };
  }

  onClickLabel(row: ChartDataLabel): void {
    console.log('CLICK:', row);
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformtoregistro.
  // CODGEN
  CrudFormToRegistro() {
    const reg = {};
    return reg;
  }
  // #endregion

  // #region #routine-definitions#.
  onItemShow(op: boolean): void {
    console.log('onItemShow', op);
    this.updateItemHeight();
    this.definicoes.open = op;
    this.OnSalvar(this.definicoes);
  }

  OnSalvar(definicoes: RegVisaoGeral): void {
    if (definicoes) {
      const painel: UserPainelConfigBean = {
        nome: ResourcesFinanceirosEnum.FINANCEIRO_PAINEL,
        parametros: [],
      };
      painel.parametros.push(UserHrefBeanUtilities.toUserHrefBean(definicoes));
      this.crudService.SavePainelConfig(painel).subscribe({
        next: (data) => {
          console.log('salvo', JSON.stringify(data));
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }

  protected onActionButton(cmd: 'DESPESA' | 'RECEITA' | 'TRANSFERENCIA' | 'LAN_DETALHADO'): void {
    const registro: LancamentoBean = LancamentoUtilitiesService.buildLancamentoBean({
      idconta: null,
      cmd: cmd,
    });
    this.onLancamento(registro);
  }

  private onLancamento(registro: LancamentoBean): void {
    LancamentoUtilitiesService.doOpenModalLancamento(this.modalService, registro, (result) => {
      if (result?.ok) {
        if (result?.repeat) {
          this.onLancamento(registro);
        }
      }
    });
  }

  onSave(newValue: string | number, row: ChartDataLabel, name: string) {
    console.log('Valor salvo no componente pai:', newValue);
    row[name] = Number(Utilities.unmaskDecimal(newValue));
  }

  onCancel() {
    console.log('Edição cancelada!');
  }
  // #endregion
}
