import { Injectable } from '@angular/core';
import { HttpHelper } from './http-helper';

@Injectable()
export class IconesHelper {
  /**
   *
   * @returns Um novo timestamp para evitar problemas de cache de imagens no browser.
   */
  public static BuildTimestamp(): number {
    const timestamp_instance = Math.trunc(new Date().getTime() / 10000);
    return timestamp_instance;
  }
  /**
   *
   * @param httpHelper - Retorna a URL para recuperar uma imagem no servidor
   * @param iconeConta - nome do icone da conta.
   * @param timestamp -
   * @returns
   */
  public static ContaIconeUrl(httpHelper: HttpHelper, iconeConta: string | null, timestamp: number): string | null {
    if (!iconeConta) {
      return null;
    }
    let endereco_url = '/';
    if ('conta_default' === iconeConta) {
      endereco_url = 'moeda-01.png';
    } else {
      endereco_url = iconeConta;
    }
    const url = httpHelper.ToURL('/front/image/icones/') + endereco_url + '/' + timestamp;
    //console.log('URL:', url);
    return url;
  }

  public static MoedaIconeUrl(httpHelper: HttpHelper, moeda: number | string | null, timestamp: number): string | null {
    if (!moeda) {
      return null;
    }
    const url = httpHelper.ToURL('/image/ICONE_MOEDA/') + moeda + '/' + timestamp;
    //console.log('URL:', url);
    return url;
  }
}
