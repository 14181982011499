<div class="search-form d-flex">
  <div class="search-form-icon"><i class="fa fa-search left-icon search-icon"></i></div>
  <input
    id="searchInput"
    #searchInput
    type="search"
    ngModel=""
    (ngModelChange)="onValueChange($event)"
    [attr.placeholder]="placeholder + ' ...'"
    class="form-control left-icon right-icon"
  />
</div>
