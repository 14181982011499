<div class="row" style="min-height: 80px">
  <div class="col-2"><i [ngClass]="icone_tipo" aria-hidden="true"></i></div>
  <div class="col-8">
    @if (title) {
      <div [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
        {{ title }}
      </div>
    }
    @if (message && options.enableHtml) {
      <div
        role="alert"
        aria-live="polite"
        class="custom-message"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div>
    }
    @if (message && !options.enableHtml) {
      <div
        role="alert"
        aria-live="polite"
        class="custom-message text-wrap"
        [class]="options.messageClass"
        [attr.aria-label]="message"
      >
        <div>{{ message }}</div>
      </div>
    }
  </div>
  <div class="col-2 text-right">
    @if (options.closeButton) {
      <a (click)="remove()" class="btn btn-sm toast-close-button"> &times; </a>
    }
  </div>
</div>
@if (options.progressBar) {
  <div>
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
}
