// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { RequestDataBean } from 'src/app/core/models/RequestDataBean';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { AdminUserActionService } from '../../../services/admin-user-action.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-criar-conta-usuario',
  templateUrl: './criar-conta-usuario.component.html',
  styleUrls: ['./criar-conta-usuario.component.css'],
  imports: [CommonModule, ReactiveFormsModule, L10nTranslateDirective, L10nTranslatePipe, NgSelectModule, NgbModule],
})
export class CriarContaUsuarioComponent implements OnInit {
  // #region #properties#.
  protected fieldIdTable = {};
  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private crudService: AdminUserActionService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({
      email: [null, [Validators.email, Validators.required]],
    });
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
    const bean: RequestDataBean = { name: 'start', parameters: [] };
    bean.parameters.push({ id: 'email', text: this.CrudFormToRegistro().email });
    this.crudService.StartRegistration(bean).subscribe({
      next: (data) => {
        if (data && data.message === 'OK') {
          Utilities.GoToRotaWithParameters(this.router, '/confirmar-email', data.parameters);
        } else {
          this.OnCancelar();
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  OnCancelar(): void {
    this.router.navigate(['/login']);
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      email: this.CrudForm.get('email').value,
    };
    return reg;
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
