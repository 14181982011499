// {{GENERATE}}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ContaBean } from '../../models/ContaBean';
import { ContasActionService } from '../contas-action.service';
import { MoedaPadraoSelecao } from '../../models/MoedaPadraoSelecao';

@Injectable()
export class ContasActionImplService implements ContasActionService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #getallconta.
  // CODGEN {{R}}
  GetAllConta(par: string): Observable<ContaBean[]> {
    const url = this.helper.ToURL('/financeiro/contas/getAllConta');
    const params = new HttpParams().append('par', par);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<ContaBean[]>(url, headers);
  }
  // #endregion

  // #region #crud#createconta.
  // CODGEN {{R}}
  CreateConta(bean: ContaBean): Observable<ContaBean> {
    const url = this.helper.ToURL('/financeiro/contas/createConta');
    const headers = this.helper.GetHeaders();
    return this.http.post<ContaBean>(url, bean, headers);
  }
  // #endregion

  // #region #crud#updateconta.
  // CODGEN {{R}}
  UpdateConta(bean: ContaBean): Observable<void> {
    const url = this.helper.ToURL('/financeiro/contas/updateConta');
    const headers = this.helper.GetHeaders();
    return this.http.put<void>(url, bean, headers);
  }
  // #endregion

  // #region #crud#getreadconta.
  // CODGEN {{R}}
  GetReadConta(idconta: number): Observable<ContaBean> {
    const url = this.helper.ToURL('/financeiro/contas/readConta');
    const params = new HttpParams().append('idconta', idconta.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.get<ContaBean>(url, headers);
  }
  // #endregion

  // #region #crud#removeconta.
  // CODGEN {{R}}
  RemoveConta(idconta: number): Observable<void> {
    const url = this.helper.ToURL('/financeiro/contas/removeConta');
    const params = new HttpParams().append('idconta', idconta.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, headers);
  }
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/financeiro/contas/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/financeiro/contas/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region metodo#-put-altera-moeda-padrao.
  // CODGEN {{R}}
  PutAlteraMoedaPadrao(moeda: MoedaPadraoSelecao): Observable<void> {
    const url = this.helper.ToURL('/financeiro/contas/putalteramoedapadrao');
    const headers = this.helper.GetHeaders();
    return this.http.post<void>(url, moeda, headers);
  }
  // #endregion

  // #region #declarations#.
  // #endregion
}
