<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form -->
  <div [class.modal-header]="isModal">
    <div class="t-header-title" l10nTranslate>UserProfileForm.HEADERTITLE</div>
  </div>
  <!-- class.modal-header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body -->
  <div [class.modal-body]="isModal">
    <div class="row m-0 p-0">
      <div class="col">
        <div class="row mx-2 pt-4">
          <!-- #E-#start-body -->
          <!-- #I-#field-email -->
          <div class="col-sm-12 col-md-6 mb-3">
            <label [for]="fieldIdTable['email']" class="form-label" l10nTranslate>UserProfileForm.email</label>
            <input
              type="text"
              [id]="fieldIdTable['email']"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched) }"
              maxlength="256"
              readonly
            />
            @if (f.email.errors) {
              <div class="invalid-feedback">
                @if (f.email.errors.email) {
                  <div l10nTranslate>UserProfileForm.email-EMAIL</div>
                }
                @if (f.email.errors.required) {
                  <div l10nTranslate>UserProfileForm.email-REQUIRED</div>
                }
                @if (f.email.errors.maxlength) {
                  <div l10nTranslate>UserProfileForm.email-MAXLENGTH</div>
                }
              </div>
            }
          </div>
          <!-- #E-#field-email -->
          <!-- #I-#field-name -->
          <div class="col-sm-12 col-md-6 mb-3">
            <label [for]="fieldIdTable['name']" class="form-label" l10nTranslate>UserProfileForm.name</label>
            <input
              type="text"
              [id]="fieldIdTable['name']"
              formControlName="name"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.name.invalid && (f.name.dirty || f.name.touched) }"
              maxlength="256"
            />
            @if (f.name.errors) {
              <div class="invalid-feedback">
                @if (f.name.errors.required) {
                  <div l10nTranslate>UserProfileForm.name-REQUIRED</div>
                }
                @if (f.name.errors.maxlength) {
                  <div l10nTranslate>UserProfileForm.name-MAXLENGTH</div>
                }
              </div>
            }
          </div>
          <!-- #E-#field-name -->
          <!-- #I-#field-phone -->
          <div class="col-sm-12 col-md-6 mb-3">
            <label [for]="fieldIdTable['phone']" class="form-label" l10nTranslate>UserProfileForm.phone</label>
            <input
              type="text"
              [id]="fieldIdTable['phone']"
              formControlName="phone"
              class="form-control"
              [ngClass]="{ 'is-invalid': f.phone.invalid && (f.phone.dirty || f.phone.touched) }"
              mask="{{ masks.ngx.cellphoneMask }}"
            />
          </div>
          <!-- #E-#field-phone -->
          <!-- #I-#field-emailnotices -->
          <div class="col-sm-12 col-md-6 mb-3">
            <div class="form-check">
              <input
                type="checkbox"
                formControlName="emailnotices"
                class="form-check-input"
                [id]="fieldIdTable['ck-emailnotices']"
                (change)="OnEmailnoticesChange($event)"
              />
              <label class="form-check-label ps-2" [for]="fieldIdTable['ck-emailnotices']" l10nTranslate
                >UserProfileForm.emailnotices</label
              >
            </div>

            <!-- #E-#field-emailnotices -->
            <!-- #I-#field-whatsappnotice -->

            <div class="form-check">
              <input
                type="checkbox"
                formControlName="whatsappnotice"
                class="form-check-input"
                [id]="fieldIdTable['ck-whatsappnotice']"
                (change)="OnWhatsappnoticeChange($event)"
              />
              <label class="form-check-label ps-2" [for]="fieldIdTable['ck-whatsappnotice']" l10nTranslate
                >UserProfileForm.whatsappnotice</label
              >
            </div>

            <!-- #E-#field-whatsappnotice -->
            <!-- #I-#field-telegrannotice -->

            <div class="form-check">
              <input
                type="checkbox"
                formControlName="telegrannotice"
                class="form-check-input"
                [id]="fieldIdTable['ck-telegrannotice']"
                (change)="OnTelegrannoticeChange($event)"
              />
              <label class="form-check-label ps-2" [for]="fieldIdTable['ck-telegrannotice']" l10nTranslate
                >UserProfileForm.telegrannotice</label
              >
            </div>
          </div>
          <!-- #E-#field-telegrannotice -->
          <!-- #I-#end-body -->
          <div class="col-sm-12 mb-3">
            <button
              type="button"
              class="btn btn-primary"
              (click)="OnProcessar()"
              [disabled]="!CrudForm.valid"
              l10nTranslate
            >
              <i class="fa fa-save"></i> UserProfileForm.BTN-ENTER
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form -->
  <!-- class.modal-footer -->
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
