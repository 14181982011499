<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>FormaPagamentoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-nome/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['nome']" class="form-label" l10nTranslate>FormaPagamentoBeanFragment.nome</label>
      <input
        type="text"
        [id]="fieldIdTable['nome']"
        formControlName="nome"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
        maxlength="80"
      />
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.maxlength) {
            <div l10nTranslate>FormaPagamentoBeanFragment.nome-MAXLENGTH</div>
          }
          @if (f.nome.errors.required) {
            <div l10nTranslate>FormaPagamentoBeanFragment.nome-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nome -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
