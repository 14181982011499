<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>CartaoCreditoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-valorlimite/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['valorlimite']" class="form-label" l10nTranslate
        >CartaoCreditoBeanFragment.valorlimite</label
      >
      <input
        type="text"
        [id]="fieldIdTable['valorlimite']"
        formControlName="valorlimite"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valorlimite.invalid && (f.valorlimite.dirty || f.valorlimite.touched) }"
        kzMaskCurrency
        maxlength="22"
      />
      @if (f.valorlimite.errors) {
        <div class="invalid-feedback">
          @if (f.valorlimite.errors.required) {
            <div l10nTranslate>CartaoCreditoBeanFragment.valorlimite-REQUIRED</div>
          }
          @if (f.valorlimite.errors.maxlength) {
            <div l10nTranslate>CartaoCreditoBeanFragment.valorlimite-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valorlimite -->
    <!-- #I-#field-tipolimite/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['tipolimite']" class="form-label" l10nTranslate
        >CartaoCreditoBeanFragment.tipolimite</label
      >
      <ng-select
        [id]="fieldIdTable['tipolimite']"
        #tipolimite
        formControlName="tipolimite"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipolimiteList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoLimiteEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipolimite -->
    <!-- #I-#field-diavencfatura/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['diavencfatura']" class="form-label" l10nTranslate
        >CartaoCreditoBeanFragment.diavencfatura</label
      >
      <input
        type="text"
        [id]="fieldIdTable['diavencfatura']"
        formControlName="diavencfatura"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.diavencfatura.invalid && (f.diavencfatura.dirty || f.diavencfatura.touched) }"
        numberOnly
        maxlength="2"
      />
      @if (f.diavencfatura.errors) {
        <div class="invalid-feedback">
          @if (f.diavencfatura.errors.required) {
            <div l10nTranslate>CartaoCreditoBeanFragment.diavencfatura-REQUIRED</div>
          }
          @if (f.diavencfatura.errors.maxlength) {
            <div l10nTranslate>CartaoCreditoBeanFragment.diavencfatura-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-diavencfatura -->
    <!-- #I-#field-dataprimeirafatura/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <app-tx-date-picker-fragment
        #vdataprimeirafatura
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('dataprimeirafatura')"
        [Title]="'CartaoCreditoBeanFragment.dataprimeirafatura'"
      ></app-tx-date-picker-fragment>
    </div>
    <!-- #E-#field-dataprimeirafatura -->
    <!-- #I-#field-diafechfatura/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['diafechfatura']" class="form-label" l10nTranslate
        >CartaoCreditoBeanFragment.diafechfatura</label
      >
      <input
        type="text"
        [id]="fieldIdTable['diafechfatura']"
        formControlName="diafechfatura"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.diafechfatura.invalid && (f.diafechfatura.dirty || f.diafechfatura.touched) }"
        numberOnly
        maxlength="2"
      />
      @if (f.diafechfatura.errors) {
        <div class="invalid-feedback">
          @if (f.diafechfatura.errors.required) {
            <div l10nTranslate>CartaoCreditoBeanFragment.diafechfatura-REQUIRED</div>
          }
          @if (f.diafechfatura.errors.maxlength) {
            <div l10nTranslate>CartaoCreditoBeanFragment.diafechfatura-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-diafechfatura -->
    <!-- #I-#field-saldofatura -->
    <div class="col-sm-12 col-md-6 mb-3">
      <app-saldo-financeiro-bean-fragment
        #vsaldofatura
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('saldofatura')"
        [Title]="'CartaoCreditoBeanFragment.saldofatura'"
      ></app-saldo-financeiro-bean-fragment>
    </div>
    <!-- #E-#field-saldofatura -->
    <!-- #I-#field-debitonaconta/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['debitonaconta']" class="form-label" l10nTranslate
        >CartaoCreditoBeanFragment.debitonaconta</label
      >
      <ng-select
        [id]="fieldIdTable['debitonaconta']"
        #debitonaconta
        formControlName="debitonaconta"
        class="w-ctrl"
        [items]="debitonacontaList"
        bindValue="id"
        bindLabel="name"
        [searchable]="true"
        [searchFn]="CustomSearchFn"
        (search)="OnSearch($event)"
        [hideSelected]="false"
      >
        <ng-template ng-label-tmp let-item="item"
          ><span>{{ item.name }}</span></ng-template
        >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index"
          ><span>{{ item.name }}</span></ng-template
        >
      </ng-select>
    </div>
    <!-- #E-#field-debitonaconta -->
    <!-- #I-#field-nomecartaoprincipal/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['nomecartaoprincipal']" class="form-label" l10nTranslate
        >CartaoCreditoBeanFragment.nomecartaoprincipal</label
      >
      <input
        type="text"
        [id]="fieldIdTable['nomecartaoprincipal']"
        formControlName="nomecartaoprincipal"
        class="form-control"
        [ngClass]="{
          'is-invalid': f.nomecartaoprincipal.invalid && (f.nomecartaoprincipal.dirty || f.nomecartaoprincipal.touched),
        }"
        maxlength="80"
      />
      @if (f.nomecartaoprincipal.errors) {
        <div class="invalid-feedback">
          @if (f.nomecartaoprincipal.errors.required) {
            <div l10nTranslate>CartaoCreditoBeanFragment.nomecartaoprincipal-REQUIRED</div>
          }
          @if (f.nomecartaoprincipal.errors.maxlength) {
            <div l10nTranslate>CartaoCreditoBeanFragment.nomecartaoprincipal-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nomecartaoprincipal -->
    <!-- #I-#field-nomescartoesadicionais/R -->
    <div class="col-sm-12 col-md-6 mb-3"></div>
    <!-- #E-#field-nomescartoesadicionais -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
