<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div>
    <div class="t-header-title" l10nTranslate>ConfirmarEmail.HEADERTITLE</div>
  </div>
  <div class="mt-3 text-center">
    <p>
      Enviamos o c&oacute;digo de ativa&ccedil;&atilde;o de sua conta para:<br />
      <strong>{{ maskemail }}</strong
      ><br />
      Caso n&atilde;o tenha recebido <a href="javascript:;" (click)="OnReenvio()">clique aqui para reenvio.</a>
    </p>
  </div>
  <!-- #E-#header-form -->
  <!-- #I-#start-body/R -->
  <div>
    <!-- #E-#start-body -->
    <!-- #I-#field-codigo/R -->
    <div class="row mb-3">
      <div class="col-sm-12">
        <label [for]="fieldIdTable['codigo']" class="form-label" l10nTranslate>ConfirmarEmail.codigo</label>
        <input
          type="text"
          [id]="fieldIdTable['codigo']"
          formControlName="codigo"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.codigo.invalid && (f.codigo.dirty || f.codigo.touched) }"
        />
        @if (f.codigo.errors) {
          <div class="invalid-feedback">
            @if (f.codigo.errors.required) {
              <div l10nTranslate>ConfirmarEmail.codigo-REQUIRED</div>
            }
          </div>
        }
      </div>
    </div>
    <!-- #E-#field-codigo -->
    <!-- #I-#end-body/R -->
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="OnProcessar()"
            [disabled]="!CrudForm.valid"
            l10nTranslate
          >
            ConfirmarEmail.BTN-ENTER
          </button>
        </div>
      </div>
    </div>
    <!-- class.modal-body -->
    <!-- #E-#end-body -->
    <!-- #I-#footer-form/R -->
    <div class="row mb-3">
      <div class="col d-flex justify-content-center">
        <button type="button" class="btn btn-link" (click)="OnCancelar()" l10nTranslate>
          CriarContaUsuario.BTN-BACK
        </button>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col d-flex justify-content-center">
        <div class="text-center">Ao se cadastrar voc&ecirc; declara concordar com nossos</div>
        <div class="text-center">
          <a href="/#/termo-de-uso" target="_blank">Termos de Uso</a> e
          <a href="/#/politica-privacidade" target="_blank">Pol&iacute;ticas de Privacidade</a>.
        </div>
      </div>
    </div>
    <!-- class.modal-footer -->
    <!-- #E-#footer-form -->
    <!-- #I-#end-container/R -->
  </div>
</ng-container>
<!-- #E-#end-container -->
