<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>ContatoBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-tipopessoa/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['tipopessoa']" class="form-label" l10nTranslate>ContatoBeanFragment.tipopessoa</label>
      <ng-select
        [attr.id]="fieldIdTable['tipopessoa']"
        #tipopessoa
        formControlName="tipopessoa"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipopessoaList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoPessoaEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipopessoa -->
    <!-- #I-#field-tipocontato/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required">
      <label [for]="fieldIdTable['tipocontato']" class="form-label" l10nTranslate
        >ContatoBeanFragment.tipocontato</label
      >
      <ng-select
        [attr.id]="fieldIdTable['tipocontato']"
        #tipocontato
        formControlName="tipocontato"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipocontatoList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoContatoEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipocontato -->
    <!-- #I-#field-nome/R -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['nome']" class="form-label" l10nTranslate>ContatoBeanFragment.nome</label>
      <input
        type="text"
        [attr.id]="fieldIdTable['nome']"
        formControlName="nome"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
        maxlength="200"
      />
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.required) {
            <div l10nTranslate>ContatoBeanFragment.nome-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nome -->
    <!-- #I-#field-observacoes -->
    <div class="col-sm-12 mb-3">
      <label [for]="fieldIdTable['observacoes']" class="form-label" l10nTranslate
        >ContatoBeanFragment.observacoes</label
      >
      <textarea
        [id]="fieldIdTable['observacoes']"
        formControlName="observacoes"
        class="form-control"
        rows="3"
        [ngClass]="{ 'is-invalid': f.observacoes.invalid && (f.observacoes.dirty || f.observacoes.touched) }"
        maxlength="500"
        [appCharCount]="500"
      >
      </textarea>
    </div>
    <!-- #E-#field-observacoes -->
    <!-- #I-#field-incluiopcionalendereco -->
    <div class="col-sm-12 mb-3">
      <button type="button" class="btn btn-link px-0" (click)="toggleSection('A')" l10nTranslate>
        <i class="fa pe-1" aria-hidden="true" [class]="toogle_endereco ? 'fa-minus' : 'fa-plus'"></i
        >ContatoBeanFragment.incluiopcionalendereco
      </button>
    </div>
    <div [class.d-none]="!this.toogle_endereco">
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="form-check">
          <input
            type="checkbox"
            formControlName="incluiopcionalendereco"
            class="form-check-input"
            [id]="fieldIdTable['ck-incluiopcionalendereco']"
            (change)="OnIncluiopcionalenderecoChange($event)"
          />
          <label class="form-check-label ps-2" [for]="fieldIdTable['ck-incluiopcionalendereco']" l10nTranslate
            >ContatoBeanFragment.HABILITADO</label
          >
        </div>
      </div>
      <!-- #E-#field-incluiopcionalendereco -->
      <!-- #I-#field-endereco -->
      <div class="col-sm-12 mb-3">
        <app-endereco-bean-fragment
          #vendereco
          [EditMode]="IsEditMode()"
          [CrudForm]="$any(CrudForm.get('endereco'))"
        ></app-endereco-bean-fragment>
      </div>
    </div>
    <!-- #E-#field-endereco -->
    <!-- #I-#field-incluiopcionaldadoscontato -->
    <div class="col-sm-12 mb-3">
      <button type="button" class="btn btn-link px-0" (click)="toggleSection('B')" l10nTranslate>
        <i class="fa pe-1" aria-hidden="true" [class]="toogle_dados_contato ? 'fa-minus' : 'fa-plus'"></i
        >ContatoBeanFragment.incluiopcionaldadoscontato
      </button>
    </div>
    <div [class.d-none]="!this.toogle_dados_contato">
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="form-check">
          <input
            type="checkbox"
            formControlName="incluiopcionaldadoscontato"
            class="form-check-input"
            [id]="fieldIdTable['ck-incluiopcionaldadoscontato']"
            (change)="OnIncluiopcionaldadoscontatoChange($event)"
          />
          <label class="form-check-label ps-2" [for]="fieldIdTable['ck-incluiopcionaldadoscontato']" l10nTranslate
            >ContatoBeanFragment.HABILITADO</label
          >
        </div>
      </div>
      <!-- #E-#field-incluiopcionaldadoscontato -->
      <!-- #I-#field-dadocontato -->
      <div class="col-sm-12 mb-3">
        <app-dados-contato-bean-fragment
          #vdadocontato
          [EditMode]="IsEditMode()"
          [CrudForm]="CrudForm.get('dadocontato')"
        ></app-dados-contato-bean-fragment>
      </div>
    </div>
    <!-- #E-#field-dadocontato -->
    <!-- #I-#field-incluiopcionaldadosfiscais -->
    <div class="col-sm-12 mb-3">
      <button type="button" class="btn btn-link px-0" (click)="toggleSection('C')" l10nTranslate>
        <i class="fa pe-1" aria-hidden="true" [class]="toogle_dados_fiscais ? 'fa-minus' : 'fa-plus'"></i
        >ContatoBeanFragment.incluiopcionaldadosfiscais
      </button>
    </div>
    <div [class.d-none]="!this.toogle_dados_fiscais">
      <div class="col-sm-12 col-md-6 mb-3">
        <div class="form-check">
          <input
            type="checkbox"
            formControlName="incluiopcionaldadosfiscais"
            class="form-check-input"
            [id]="fieldIdTable['ck-incluiopcionaldadosfiscais']"
            (change)="OnIncluiopcionaldadosfiscaisChange($event)"
          />
          <label class="form-check-label ps-2" [for]="fieldIdTable['ck-incluiopcionaldadosfiscais']" l10nTranslate
            >ContatoBeanFragment.HABILITADO</label
          >
        </div>
      </div>
      <!-- #E-#field-incluiopcionaldadosfiscais -->
      <!-- #I-#field-dadofiscal -->
      <div class="col-sm-12 mb-3">
        <app-dados-fiscais-bean-fragment
          #vdadofiscal
          [EditMode]="IsEditMode()"
          [CrudForm]="CrudForm.get('dadofiscal')"
          [tipopessoa]="f.tipopessoa.value"
        ></app-dados-fiscais-bean-fragment>
      </div>
    </div>
    <!-- #E-#field-dadofiscal -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
<!--
-->
