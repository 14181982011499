/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface DataCmdInterface {
  cmd: string;
  key?: string;
}

@Injectable()
export class DataService {
  private subject = new Subject<DataCmdInterface>();
  private table = new Map<string, any>();

  constructor() {}

  getSubject(): Observable<DataCmdInterface> {
    return this.subject.asObservable();
  }

  save(key: string, value: any): void {
    this.table.set(key, value);
    this.subject.next({ cmd: 'SAVE', key: key });
  }

  read(key: string): any {
    return this.table.get(key);
  }

  remove(key: string): void {
    this.table.delete(key);
    this.subject.next({ cmd: 'REMOVE', key: key });
  }

  clear(): void {
    this.table.clear();
    this.subject.next({ cmd: 'CLEAR' });
  }
}
