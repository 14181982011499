// {{GENERATE}}
// @CRUD
// @GRID
// @HOST
// URL:/financeiro/categoria
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BagParameter } from 'src/app/core/models/BagParameter';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ArvoreCategoria } from '../models/ArvoreCategoria';
import { CategoriaBean } from '../models/CategoriaBean';
import { EntityDescriptionBean } from '../models/EntityDescriptionBean';
import { EntityDescriptionRequest } from '../models/EntityDescriptionRequest';
import { ContaMoedaBean } from '../models/ContaMoedaBean';
import { ResponseDataBean } from 'src/app/core/models/ResponseDataBean';
import { UserPainelConfigBean } from '../../admin/models/UserPainelConfigBean';

@Injectable()
export abstract class CategoriaActionService {
  // #region #getallcategoria.
  // CODGEN {{R}}
  abstract GetAllCategoria(par: string): Observable<CategoriaBean[]>;
  // #endregion

  // #region #crud#createcategoria.
  // CODGEN {{R}}
  abstract CreateCategoria(bean: CategoriaBean): Observable<CategoriaBean>;
  // #endregion

  // #region #crud#updatecategoria.
  // CODGEN {{R}}
  abstract UpdateCategoria(bean: CategoriaBean): Observable<void>;
  // #endregion

  // #region #crud#getreadcategoria.
  // CODGEN {{R}}
  abstract GetReadCategoria(id: number): Observable<CategoriaBean>;
  // #endregion

  // #region #crud#removecategoria.
  // CODGEN {{R}}
  abstract RemoveCategoria(id: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region metodo#get-arvore-categoria.
  // CODGEN {{R}}
  abstract GetArvoreCategoria(opcoes: BagParameter): Observable<ArvoreCategoria>;
  // #endregion

  // #region metodo#get-entity-description.
  // CODGEN {{R}}
  abstract GetEntityDescription(items: EntityDescriptionRequest): Observable<EntityDescriptionBean>;
  // #endregion

  // #region metodo#get-conta-moeda-list.
  // CODGEN {{R}}
  abstract GetContaMoedaList(): Observable<ContaMoedaBean[]>;
  // #endregion

  // #region metodo#get-painel-config.
  // CODGEN {{R}}
  abstract GetPainelConfig(nome: string): Observable<UserPainelConfigBean>;
  // #endregion

  // #region metodo#save-painel-config.
  // CODGEN {{R}}
  abstract SavePainelConfig(painel: UserPainelConfigBean): Observable<ResponseDataBean>;
  // #endregion

  // #region #declarations#.
  abstract InativarCategoria(id: number): Observable<void>;

  abstract AtivarCategoria(id: number): Observable<void>;
  // #endregion
}
