import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Utilities } from '../services/utilities';

export class ConstantsAngular2TextMask {}
// https://www.npmjs.com/package/ngx-mask
// NGX MASK is the best directive to solve masking input with needed pattern
export class ConstantsNgxMasks {
  public static cellphoneMask = '(00) 0000-0000||(00) 0 0000-0000';
  public static cepMask = '00000-000';
  public static cnpjMask = '00.000.000/0000-00';
  public static cpfMask = '000.000.000-00';
  public static cpfcnpjMask = '00.000.000/0000-00';
  public static decimalMask = '0*,0*';
  public static moneyMask = '0*,00';
  public static numberMask = '0*';
  public static rgMask = '0*';
  public static dateMask = '00/00/0000';
  public static dateTimeMask = '00/00/0000 00:00:00';
}

/*
<input type="text" [mask]="value.length > 11 ? '00.000.000/0000-00' : '000.000.000-00'"
Double:
<input type="text" mask="separator.2" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="true" placeholder="Valor com 2 casas decimais" />
<input type="text" mask="separator" thousandSeparator="." decimalMarker="," [allowNegativeNumbers]="true" placeholder="Valor com precisão variável" />

CPF
<input type="text" mask="000.000.000-00" placeholder="CPF"/>
<input type="text" mask="00.000.000/0000-00" placeholder="CNPJ"/>
<input type="text" [mask]="value.length > 11 ? '00.000.000/0000-00' : '000.000.000-00'" placeholder="CPF/CNPJ"/>


<input type="text" mask="000.000.000-00" placeholder="CPF" />
<input type="text" mask="00.000.000/0000-00" placeholder="CNPJ" />
<input type="text" mask="00000-000" placeholder="CEP" />
<input type="text" mask="separator.2" placeholder="Double" />
<input type="text" mask="00/00/0000" placeholder="Data" />
<input type="text" mask="00/00/0000 00:00" placeholder="Data e Hora" />
*/

export class Constants {
  private static st_id = 0;

  public static REGEX_NUMERO = '^\\d+$';
  public static REGEX_REAL = '^\\d+?(.|,\\d+)$';

  public static MNU01 = 'mnu01';
  public static FILTERS01 = 'flt1';
  public static minDate = { year: new Date().getFullYear() - 10, month: 1, day: 1 };
  public static maxDate = Constants.ToNgbDateNow();
  public static ngx = ConstantsNgxMasks;

  static messages = {
    emptyMessage: `
          <div>
            <p>Nenhum registro encontrado.</p>
          </div>
        `,
    totalMessage: 'registros',
  };

  static GetId(): string {
    return '' + Constants.st_id++;
  }

  static CompareFn(a: any, b: any): boolean {
    return a && b ? a.id === b.id : a === b;
  }

  public static pt_BR = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
  };

  public static pt_BR_FULL = {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'Igual',
    notEquals: 'Diferente',
    noFilter: 'Sem filtro',
    filter: 'Filtro',
    lt: 'Menor que',
    lte: 'Menor que ou igual a',
    gt: 'Maior que',
    gte: 'Maior que ou igual a',
    dateIs: 'Data é',
    dateIsNot: 'Data não é',
    dateBefore: 'Date é anterior',
    dateAfter: 'Data é posterior',
    custom: 'Customizado',
    clear: 'Limpar',
    close: 'Fechar',
    apply: 'Aplicar',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Adicionar Regra',
    removeRule: 'Remover Regra',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolha',
    upload: 'Upload',
    cancel: 'Cancelar',
    completed: 'Concluído',
    pending: 'Pendente',
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    chooseYear: 'Escolha Ano',
    chooseMonth: 'Escolha Mês',
    chooseDate: 'Escolha Data',
    prevDecade: 'Década Anterior',
    nextDecade: 'Década Seguinte',
    prevYear: 'Ano Anterior',
    nextYear: 'Ano Seguinte',
    prevMonth: 'Mês Anterior',
    nextMonth: 'Mês Seguinte',
    prevHour: 'Hora Anterior',
    nextHour: 'Hora Seguinte',
    prevMinute: 'Minuto Anterior',
    nextMinute: 'Minuto Seguinte',
    prevSecond: 'Segundo Anterior',
    nextSecond: 'Segundo Seguinte',
    am: 'am',
    pm: 'pm',
    today: 'Hoje',
    weekHeader: 'Sem',
    firstDayOfWeek: 0,
    dateFormat: 'dd/mm/yy',
    weak: 'Fraco',
    medium: 'Médio',
    strong: 'Forte',
    passwordPrompt: 'Digite uma senha',
    emptyFilterMessage: 'Nenhum resultado encontrado',
    searchMessage: '{0} resultados disponíveis',
    selectionMessage: '{0} itens selecionados',
    emptySelectionMessage: 'Nenhum item selecionado',
    emptySearchMessage: 'Nenhum resultado encontrado',
    emptyMessage: 'Nenhuma opção disponível',
    aria: {
      trueLabel: 'Verdadeiro',
      falseLabel: 'Falso',
      nullLabel: 'Não selecionado',
      star: '1 estrela',
      stars: '{star} estrelas',
      selectAll: 'Todos itens selecionados',
      unselectAll: 'Nenhum item selecionado',
      close: 'Fechar',
      previous: 'Anterior',
      next: 'Seguinte',
      navigation: 'Navegação',
      scrollTop: 'Rolar para Topo',
      moveTop: 'Mover para Topo',
      moveUp: 'Mover para Cima',
      moveDown: 'Mover para Baixo',
      moveBottom: 'Mover para Final',
      moveToTarget: 'Mover para Alvo',
      moveToSource: 'Mover para Fonte',
      moveAllToTarget: 'Mover Todos para Alvo',
      moveAllToSource: 'Mover Todos para Fonte',
      pageLabel: '{page}',
      firstPageLabel: 'Primeira Página',
      lastPageLabel: 'Última Página',
      nextPageLabel: 'Página Seguinte',
      previousPageLabel: 'Página Anterior',
      rowsPerPageLabel: 'Linhas por página',
      jumpToPageDropdownLabel: 'Pular para Menu da Página',
      jumpToPageInputLabel: 'Pular para Campo da Página',
      selectRow: 'Linha Selecionada',
      unselectRow: 'Linha Não Selecionada',
      expandRow: 'Linha Expandida',
      collapseRow: 'Linha Recolhida',
      showFilterMenu: 'Mostrar Menu de Filtro',
      hideFilterMenu: 'Esconder Menu de Filtro',
      filterOperator: 'Operador de Filtro',
      filterConstraint: 'Restrição de Filtro',
      editRow: 'Editar Linha',
      saveEdit: 'Salvar Editar',
      cancelEdit: 'Cancelar Editar',
      listView: 'Exibição em Lista',
      gridView: 'Exibição em Grade',
      slide: 'Deslizar',
      slideNumber: '{slideNumber}',
      zoomImage: 'Ampliar Imagem',
      zoomIn: 'Mais Zoom',
      zoomOut: 'Menos Zoom',
      rotateRight: 'Girar à Direita',
      rotateLeft: 'Girar à Esquerda',
    },
  };
  // https://github.com/text-mask/text-mask/tree/master/angular2/#readme

  public static ToNgbDate(dt: Date): NgbDateStruct {
    return { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() };
  }

  public static ToNgbDateNow(): NgbDateStruct {
    return Constants.ToNgbDate(new Date());
  }

  public static ParseKeyNumber(val: string | null): number | string | null {
    if (val && Utilities.TestIsString(val)) {
      return parseInt(val, 10);
    }
    return val;
  }
}
