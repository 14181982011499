// {{GENERATE}}
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { BagParameter, BagParameterObject } from 'src/app/core/models/BagParameter';
import { CrudModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { TbDocumentBean } from '../../../models/TbDocumentBean';
import { DocumentoActionService } from '../../../services/documento-action.service';
import { DocumentoActionImplService } from '../../../services/impl/documento-action-impl.service';
import { TbDocumentBeanFragmentComponent } from '../tb-document-bean/tb-document-bean-fragment.component';
import { UploadFileResult, UploadFileNode } from 'src/app/core/models/CloseModalResult';
import { SelectOptionEvent, SelectOptionService } from 'src/app/core/services/select-option.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-documento-action-add',
  templateUrl: './documento-action-add.component.html',
  providers: [{ provide: DocumentoActionService, useClass: DocumentoActionImplService }],
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    TbDocumentBeanFragmentComponent,
  ],
})
export class DocumentoActionAddComponent implements CrudModelComponent, OnInit, AfterViewInit {
  // #region #properties#.
  bagParameter: BagParameterObject;
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  registro: TbDocumentBean;
  masks = Constants;
  @ViewChild('vdoc', { static: true }) childDoc: TbDocumentBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: DocumentoActionService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public translation: L10nTranslationService,
    private optionService: SelectOptionService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onsubmit.
  // CODGEN
  onSubmit(opcao = '') {
    // stop here if form is invalid
    if (!this.isvalid()) {
      return;
    }

    this.registro = Object.assign({}, this.registro, this.CrudFormToRegistro());
    if (this.viewType === ViewTypeEnum.ADD) {
      this.childDoc.executaUpload((message: any) => {
        console.log('EXECUTA UPLOAD:', message);
        const registro: UploadFileResult = message;
        if (registro.files && registro.files.length > 0) {
          const filepack: UploadFileNode = registro.files[0];
          const arquivo = JSON.parse(filepack.fileName);
          const nid = arquivo['IdArquivo'];
          if (nid) {
            this.registro.id = nid;
            this.registro.idimagem = arquivo['IdImagem'];
            const evento: SelectOptionEvent = { host: '/documentos/action', par: 'TBDOCUMENTBEAN', opcao: '' };
            this.optionService.publish(evento);
            this.optionService.GetSelectOptions(evento.host, evento.par, evento.opcao).subscribe({
              next: (data) => {
                console.log('RECEBEU MENSAGEM', data);
                this.OnCloseModal(true, opcao === '+');
              },
              error: (error) => {
                this.alertService.error(error);
                this.OnCloseModal(false, opcao === '+');
              },
            });
          }
        }
      });
      /*
      this.crudService.CreateDoc(this.registro).subscribe({
        next: (data) => {
          this.registro = data;
          this.OnCloseModal(true, opcao === '+');
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
      */
    } else {
      this.crudService.UpdateDoc(this.registro).subscribe({
        next: () => {
          this.OnCloseModal(true);
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    if (this.viewType === ViewTypeEnum.READ) {
      this.crudService.GetReadDoc(this.registro.id).subscribe({
        next: (data) => {
          try {
            this.registro = data;
            this.CrudForm.patchValue(this.registro);
          } catch (error) {
            //console.log('erro:', error);
            this.alertService.error('Erro no carregamento dos dados.');
          }
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
  // #endregion

  IsEditMode(): boolean {
    return this.viewType === ViewTypeEnum.READ;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      registro: this.registro,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return this.childDoc.CrudFormValidators(this.rootformid);
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childDoc.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return this.childDoc.CrudFormToRegistro();
  }
  // #endregion

  // #region #routine-definitions#.

  public isvalid(): boolean {
    if (this.IsEditMode()) {
      return this.CrudForm.valid;
    } else {
      return this.CrudForm.valid && this.childDoc.isvalid();
    }
  }

  setBagParameter(bag: BagParameter): void {
    this.bagParameter = new BagParameterObject(bag);
  }
  // #endregion
}
