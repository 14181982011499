<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-6">
    <div class="t-header-title mb-3">POLÍTICA DE PRIVACIDADE</div>
    <p class="pt-15 text-break tbx-justfy-text">
      Nossa Política de Privacidade explica quais dados pessoais coletamos, como usamos os dados pessoais, como os dados
      pessoais são compartilhados e os direitos de privacidade.
    </p>
    <p class="text-break tbx-justfy-text">
      Ao fornecer seus dados em nosso site, o 7CONTA se compromete na não divulgação de qualquer informação à terceiros.
      Com isso, evita-se o recebimento de e-mails indesejados, ou mesmo que suas informações cadastrais sejam utilizadas
      para qualquer fim. Os dados fornecidos por nossos usuários são armazenados em nosso banco de dados. Tais dados,
      serão utilizados no envio de malas diretas, divulgando notícias, ou qualquer informação relacionada ao nosso site.
      Caso o internauta não desejar receber tais e-mails, favor entrar em contato conosco pelo link fale conosco para o
      administrador do site excluir seu cadastro de nosso banco de dados.
    </p>
  </div>
</div>
