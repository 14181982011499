import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { HttpHelper } from '../../helpers/http-helper';
import { AlertPanel } from '../../models/AlertPanel';
import { WEndereco } from '../../models/WEndereco';
import { UtilsService } from '../utils.service';

@Injectable()
export class UtilsImplService implements UtilsService {
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}

  Putreadmessage(messageid: number): Observable<any> {
    const url = this.helper.ToURL('/admin/alert/putreadmessage');
    let params = new HttpParams();
    params = params.append('messageid', messageid.toString());
    const header = this.helper.GetHeaders(params);
    return this.http.get<any>(url, header);
  }

  GetCep(code: string): Observable<WEndereco> {
    const url = this.helper.ToURL('/utils/getCEP');
    let params = new HttpParams();
    params = params.append('code', code);
    const header = this.helper.GetHeaders(params);
    return this.http.get<WEndereco>(url, header);
  }

  GetAlertPanel(): Observable<AlertPanel> {
    const url = this.helper.ToURL('/admin/alert/alertpanel');
    const params = new HttpParams();
    const header = this.helper.GetHeaders(params);
    return this.http.get<AlertPanel>(url, header);
  }

  GetAlertPanelMessages(): Observable<AlertPanel> {
    const url = this.helper.ToURL('/admin/alert/alertpanelmessages');
    const params = new HttpParams();
    const header = this.helper.GetHeaders(params);
    return this.http.get<AlertPanel>(url, header);
  }

  PostCmdSelectList(bean: SelectOptionList): Observable<any> {
    const url = this.helper.ToURL('/admin/alert/cmdselectlist');
    const header = this.helper.GetHeaders();
    return this.http.post<any>(url, bean, header);
  }
}
