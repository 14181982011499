<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form -->
  <div class="mb-3">
    <div class="t-header-title" l10nTranslate>CriarContaUsuario.HEADERTITLE</div>
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body -->
  <div class="row mb-3">
    <!-- #E-#start-body -->
    <!-- #I-#field-email -->
    <div class="col-sm-12">
      <label [for]="fieldIdTable['email']" class="form-label" l10nTranslate>CriarContaUsuario.email</label>
      <input
        type="text"
        [id]="fieldIdTable['email']"
        formControlName="email"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.email.invalid && (f.email.dirty || f.email.touched) }"
      />
      @if (f.email.errors) {
        <div class="invalid-feedback">
          @if (f.email.errors.email) {
            <div l10nTranslate>CriarContaUsuario.email-EMAIL</div>
          }
          @if (f.email.errors.required) {
            <div l10nTranslate>CriarContaUsuario.email-REQUIRED</div>
          }
        </div>
      }
    </div>
  </div>
  <!-- #E-#field-email -->
  <!-- #I-#end-body -->
  <div class="row mb-3">
    <div class="col-sm-12">
      <button
        type="button"
        class="btn btn-primary w-100"
        (click)="OnProcessar()"
        [disabled]="!CrudForm.valid"
        l10nTranslate
      >
        CriarContaUsuario.BTN-ENTER
      </button>
    </div>
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form -->
  <div class="row mb-3">
    <div class="col d-flex justify-content-center">
      <div class="mb-3">
        <button type="button" class="btn btn-link" (click)="OnCancelar()" l10nTranslate>
          CriarContaUsuario.BTN-BACK
        </button>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <div class="d-flex flex-column justify-content-center">
        <div class="text-center">Ao se cadastrar voc&ecirc; declara concordar com nossos</div>
        <div class="text-center">
          <a href="/#/termo-de-uso" target="_blank">Termos de Uso</a> e
          <a href="/#/politica-privacidade" target="_blank">Pol&iacute;ticas de Privacidade</a>.
        </div>
      </div>
    </div>
  </div>
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
