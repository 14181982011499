import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { Formatter } from '../pipes/Formatter';

@Directive({
  selector: '[appMaskHour]',
  standalone: true,
})
export class HourMaskDirective implements OnInit {
  public nativeElement: HTMLInputElement;

  constructor(private element: ElementRef) {
    this.nativeElement = this.element.nativeElement;
  }

  ngOnInit(): void {
    this.nativeElement.value = Formatter.maskTime(this.nativeElement.value);
  }

  @HostListener('input') logChange() {
    this.nativeElement.value = Formatter.maskTime(this.nativeElement.value);
  }

  @HostListener('focus') onFocus() {
    this.nativeElement.value = Formatter.maskTime(this.nativeElement.value);
  }

  @HostListener('blur') onBlur() {
    this.nativeElement.value = Formatter.maskTime(this.nativeElement.value);
  }
}
