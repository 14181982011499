<nav class="navbar fixed-top header-bg header-botton">
  <div class="container-fluid">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex align-items-center pe-2">
        <div class="row text-center">
          <div class="d-flex justify-content-center align-items-center">
            <img class="img-responsive logo-image" src="/assets/images/rural-1.svg" alt="7Bov" />
            <div class="d-flex flex-column">
              <div class="text-center ps-1 pt-3 logo-fonte">7Bov</div>
              <!-- MENU CONTROLLER-->
              <div id="navbtn_toggler" class="d-flex ms-2">
                <span
                  class="fa fa-bars navbtn-menubutton cursor-pointer"
                  style="border: 1px solid transparent; padding: 2px !important"
                ></span>
              </div>
              <!---->
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="text-center">
          <h6>{{ headerTitle2 }}</h6>
        </div>
      </div>
      <div class="d-flex flex-wrap align-items-center overflow-hidden" style="max-height: 74px">
        @for (item of favoritosList; track item.title) {
          <div class="col-sm-auto mx-2 my-1 bg-light border rounded-3">
            <div
              class="card-btn-pointer d-flex align-items-center justify-content-center"
              (click)="OnClickFavoritoPref(item)"
            >
              <div class="{{ item.icon }}"></div>
              <div class="ms-1 d-none d-lg-block" data-bs-toggle="tooltip" title="{{ item.description }}">
                <strong>{{ item.title }}</strong>
              </div>
            </div>
          </div>
        }
      </div>

      <div class="d-flex align-items-center ps-2">
        <strong class="pe-2">{{ userName }}</strong>
        <div class="text-center ml-auto" style="padding-top: 10px">
          <div ngbDropdown class="dropdown">
            <app-avatar
              [avatar]="avatar"
              ngbDropdownToggle
              (click)="OnAvatarClick()"
              id="dropdownMenuButton1"
              aria-expanded="false"
            ></app-avatar>
            <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="dropdownMenuButton1">
              <li>
                <div class="text-center">
                  <strong>{{ userName }}</strong>
                </div>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a ngbDropdownItem href="javascript:;" (click)="ClickPerfil()">
                  <span class="fa fa-fw fa-user me-2"></span>
                  <span>Meus Dados</span>
                </a>
              </li>
              <li>
                <a ngbDropdownItem [routerLink]="['/login']">
                  <span class="fa fa-fw fa-sign-out me-2"></span>
                  <span>Sair</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<app-alert></app-alert>
