<div class="upload-area">
  <div draggable="true" [ngClass]="dragAreaClass">
    <div class="row">
      <div class="col text-center">
        <a href="javascript:void(0)" (click)="file.click()">{{ titleButton }}</a>
        <div class="py-2" [innerHTML]="title"></div>
        <input
          type="file"
          #file
          [multiple]="multiple"
          [accept]="accept"
          (change)="onFileChange($event)"
          onclick="this.value = null"
          style="display: none"
        />
      </div>
    </div>
  </div>
  @if (upload) {
    <div class="mb-3 p-2">
      <div class="row mb-1">
        <div class="col">
          <div class="d-flex align-items-start">
            <table class="table table-borderless" style="margin-bottom: 0">
              @for (name of upload.names; track name; let i = $index) {
                <tr>
                  <td>{{ i + 1 }}</td>
                  <td class="d-inline-block text-truncate up-max-text">{{ name }}</td>
                  <td>{{ upload.progress }}%</td>
                  <td class="text-end">{{ upload.state }}</td>
                </tr>
              }
            </table>
            <button type="button" class="btn lixeira" (click)="limpaLista()">
              <i class="fa fa-fw fa-trash-o"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</div>
