import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { L10nTranslationService } from 'angular-l10n';
import { Constants } from '../../helpers/constants';
import { BagParameter, BagParameterObject } from '../../models/BagParameter';

@Component({
  selector: 'app-loader-modal',
  templateUrl: './loader-modal.component.html',
  standalone: true,
  imports: [ReactiveFormsModule],
})
export class LoaderModalComponent implements AfterViewInit {
  private instanceId = Constants.GetId();

  @ViewChild('dynamicContainer', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  componentToLoad: Type<any>;
  @Output() componentLoaded = new EventEmitter<any>();
  private componentRef: any;
  CrudForm: FormGroup;
  bagParameter: BagParameterObject;
  title: string;

  constructor(
    // @Inject(L10N_LOCALE) public locale: L10nLocale,
    // private alertService: AlertService,
    // private crudService: LancamentoActionService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
    public translation: L10nTranslationService,
  ) {}

  ngAfterViewInit() {
    this.loadComponent(this.componentToLoad);
    this.cdr.detectChanges();
  }

  loadComponent(component: Type<any>) {
    this.container.clear();
    this.componentRef = this.container.createComponent(component);
    this.cdr.detectChanges(); // Assegura que a detecção de mudanças seja feita
    setTimeout(() => {
      this.CrudForm = (this.componentRef?.instance as any).CrudForm;
      this.cdr.detectChanges(); // Assegura que a detecção de mudanças seja feita
      this.componentLoaded.emit(this.componentRef.instance); // Notifica que o componente foi carregado
    });
  }

  getComponentInstance(): any {
    return this.componentRef?.instance;
  }

  get f() {
    return this.CrudForm.controls;
  }

  close() {
    // Implement close logic based on your modal service
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  IsEditMode(): boolean {
    return false;
  }

  OnCloseModal(status = true, repeat = false): void {
    if (!status) {
      this.activeModal.close({
        ok: false,
        registro: null,
        repeat: false,
      });
    } else if (this.componentRef?.instance && typeof this.componentRef.instance.CrudFormToRegistro === 'function') {
      const registro = this.componentRef.instance.CrudFormToRegistro();
      this.activeModal.close({
        ok: status,
        registro: registro,
        repeat: repeat,
      });
    } else {
      console.error('CrudFormToRegistro is not a function or does not exist');
      this.activeModal.close({
        ok: false,
        registro: null,
        repeat: false,
        error: 'CrudFormToRegistro is not a function or does not exist',
      });
    }
  }

  onSubmit(opcao = ''): void {
    // stop here if form is invalid
    if (this.CrudForm?.valid) {
      this.OnCloseModal(true, opcao === '+');
    }
  }

  setBagParameter(bag: BagParameter): void {
    this.bagParameter = new BagParameterObject(bag);
    this.title = this.bagParameter.getTextById('title', null);
    if (this.title.startsWith('@')) {
      this.title = this.translation.translate('Titles.' + this.title.substring(1));
    }
  }
}
