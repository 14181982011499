<!-- {{GENERATE}} -->
<form [id]="fieldIdTable['form']" [formGroup]="CrudForm" (ngSubmit)="onSubmit()">
  <div class="tpx-crud-card">
    <div class="tpx-crud-body tpx-card">
      <div class="form-group">
        <div class="w-100">
          <app-smtp-config-form-bean-fragment
            #vsmtp
            [EditMode]="IsEditMode()"
            [CrudForm]="CrudForm"
          ></app-smtp-config-form-bean-fragment>
        </div>
      </div>
    </div>
    <div class="tpx-crud-footer">
      <div class="form-group">
        <button type="button" class="btn btn-secondary" (click)="OnBackButton()">
          <i class="fa fa-times"></i> {{ 'ConfigOpcoesActionTab.BTN-BACK' | translate: locale.language }}
        </button>
        <button type="button" class="btn btn-primary" (click)="onSubmit()" [disabled]="!CrudForm.valid">
          <i class="fa fa-save" aria-hidden="true"></i>
          {{ 'ConfigOpcoesActionTab.BTN-ENTER' | translate: locale.language }}
        </button>
      </div>
    </div>
  </div>
</form>
