// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { PasswordStrengthComponent } from 'src/app/core/components/password-strength/password-strength.component';
import { Constants } from 'src/app/core/helpers/constants';
import { PasswordStrongValidator } from 'src/app/core/helpers/password-strong.validator';
import { PasswordValidation } from 'src/app/core/helpers/password-validation';
import { ValueName } from 'src/app/core/models/ValueName';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { UserRegistration } from '../../../models/UserRegistration';
import { AdminUserActionService } from '../../../services/admin-user-action.service';
import { FeedbackFinal } from '../feedback-final-sucesso/feedback-final-sucesso.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-terminar-cadastro',
  templateUrl: './terminar-cadastro.component.html',
  styleUrls: ['./terminar-cadastro.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,

    PasswordStrengthComponent,
  ],
})
export class TerminarCadastroComponent implements OnInit {
  // #region #properties#.
  registro: UserRegistration;
  protected fieldIdTable = {};
  // #endregion

  showPassword = false;
  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private crudService: AdminUserActionService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    const parametros: ValueName[] = Utilities.extractParameters(this.route);
    this.registro = JSON.parse(Utilities.getTextParameter(parametros, 'registro'));
    this.CrudForm = this.formBuilder.group({
      email: [this.registro.email, [Validators.email, Validators.required]],
      nome: [null, Validators.required],
      senha: [null, [Validators.required, PasswordStrongValidator.Strong, Validators.maxLength(80)]],
      confirmesenha: [null, [Validators.required, PasswordValidation.MustMatch('senha')]],
    });
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
    const dados = this.CrudFormToRegistro();
    this.registro.email = dados.email;
    this.registro.senha = dados.senha;
    this.registro.confirmesenha = dados.confirmesenha;
    this.registro.nome = dados.nome;
    this.crudService.FinalizeRegistration(this.registro).subscribe({
      next: (data) => {
        if (data && 'OK' === data.message) {
          FeedbackFinal.GoTo(this.router, {
            tituloHeader: 'Cadastro realizado com sucesso',
            titulo2:
              'Bem-vindo(a) <strong>' + dados.nome + '</strong>.<br>Você já pode acessar o 7Bov a partir de agora.',
            tituloLink: 'Acessar o 7Bov',
            urlLink: '/login',
          });
        } else {
          this.OnCancelar();
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      email: this.CrudForm.get('email').value,
      nome: this.CrudForm.get('nome').value,
      senha: this.CrudForm.get('senha').value,
      confirmesenha: this.CrudForm.get('confirmesenha').value,
    };
    return reg;
  }
  // #endregion

  // #region #routine-definitions#.
  OnCancelar(): void {
    this.router.navigate(['/login']);
  }
  // #endregion
}
