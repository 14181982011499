// {{NOTGENERATE}}
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/core/services/alert.service';
import { SelectOptionEvent, SelectOptionService } from 'src/app/core/services/select-option.service';
import { Utilities } from 'src/app/core/services/utilities';
import { SelectOptionUtils } from '../helpers/SelectOptionUtils';
import { Constants } from '../helpers/constants';
import { SelectOptionItem } from '../models/SelectOptionItem';

export interface SelectOptionChangeEvent {
  table?: string;
  items?: SelectOptionItem[];
}

/**
 * https://stackoverflow.com/questions/45659742/angular4-no-value-accessor-for-form-control
 *
 * Uso:
 *     <app-select-value formControlName="conta"></app-select-value>
 */
@Component({
  standalone: true,
  selector: 'app-select-value',
  templateUrl: './select-value.component.html',
  styles: [
    `
      .create-new {
        cursor: pointer;
        padding-top: 5px;
        padding-bottom: 10px;
      }
      .ng-dropdown-footer {
        border-top: unset !important;
        padding: 0px 10px !important;
      }
      .ng-dropdown-footer:hover {
        background-color: #f5faff;
      }
    `,
  ],
  imports: [FormsModule, NgSelectModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectValueComponent),
      multi: true,
    },
  ],
})
export class SelectValueComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private instanceId = Constants.GetId();

  @Input() host: string;
  @Input() par: string;
  @Input() opcao: string;
  @Input() isRequired: boolean = false;
  @Input() multiple: boolean = false;
  @Input() table: string = null;
  @Input() placeholder: string = null;

  @Output() onAddNewItem = new EventEmitter<SelectOptionEvent>();
  @Output() onSelectChange = new EventEmitter<SelectOptionChangeEvent>();
  @Output() onLoadRows = new EventEmitter<Number>();
  protected fieldIdTable = {};

  ngControl!: NgControl;
  currentOpcao: string = null;
  selectRows: any = [];
  registro: any = [];
  rows: SelectOptionItem[] = [];

  onChange: any = () => {};
  onTouch: any = () => {};

  @ViewChild('ngSelect', { static: true }) ngSelect!: NgSelectComponent;

  constructor(
    private injector: Injector,
    private alertService: AlertService,
    private optionService: SelectOptionService,
  ) {
    this.fieldIdTable['wrapper'] = this.GetId('wrapper');
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: any) {
    this.selectRows = input || (this.multiple ? [] : null);
    this.registro = this.selectRows;
    // const key = SelectOptionService.ToKey(this.host, this.par, this.opcao);
    //console.log('writeValue:', key, this.selectRows, this.registro);
    this.onChange(input);
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.ngSelect) {
      this.ngSelect.setDisabledState(isDisabled);
    }
  }

  onInput(value: string): void {
    this.onChange(value);
  }

  setDefaultValue() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['opcao']) {
      if (this.currentOpcao != changes['opcao'].currentValue) {
        console.log('OPÇÃO MUDOU:', changes['opcao']);
        this.currentOpcao = changes['opcao'].currentValue;
        this.OnInvokeServer();
      }
    }
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, undefined);
    this.OnInvokeServer();
    const subscription = this.optionService.getEvents().subscribe({
      next: (evt) => {
        if (evt && this.host === evt.host && this.par === evt.par && this.opcao === evt.opcao) {
          /** alterou o dado no servidor, deve recarregar os itens */
          this.OnInvokeServer();
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  CustomSearchFn(term: string, item: SelectOptionItem): boolean | null {
    if (item) {
      return Utilities.searchArray(term, false, item.name);
    }
    return true;
  }

  /**
   * Busca lista de opções no cache
   */
  OnInvokeServer(): void {
    if (this.host && this.par) {
      const key = SelectOptionService.ToKey(this.host, this.par, this.opcao);
      this.optionService.GetSelectOptions(this.host, this.par, this.opcao).subscribe({
        next: (data) => {
          this.rows = [...SelectOptionUtils.TransformToNumericId(data.items)];
          if (this.registro) {
            //console.log('OnInvokeServer - REGISTRO:', key, this.selectRows, this.registro);
            this.selectRows = this.registro;
          }
          console.group('onLoadRows');
          this.onLoadRows.emit(this.rows!.length);
          console.log('onLoadRows - ROWS:', key, this.rows!.length);
          console.groupEnd();
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }

  OnAddElement() {
    //console.log('botao de adição pressionado');
    this.onAddNewItem.emit({ host: this.host, par: this.par, opcao: this.opcao });
    this.currentOpcao = null;
  }

  onChangeInterno(event): void {
    this.onTouch();
    if (event) {
      const mevent: SelectOptionChangeEvent = { table: this.table, items: this.multiple ? event : [event] };
      //console.log('EVENTO:', mevent);
      this.onSelectChange.emit(mevent);
      this.onChange(this.multiple ? event.map((item: SelectOptionItem) => item.id) : (event as SelectOptionItem).id);
    } else {
      this.onChange(event);
    }
  }

  hasListeners(): boolean {
    return this.onAddNewItem.observed;
  }

  GetFormControlName(): string | null {
    if (this.ngControl && this.ngControl.name) {
      return this.ngControl.name.toString();
    }
    return null;
  }

  getRowById(id: number): SelectOptionItem | null {
    if (this.rows && this.rows.length > 0) {
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rows[i].id == id) {
          return this.rows[i];
        }
      }
    }
    return null;
  }

  getFirstRow(): SelectOptionItem | null {
    if (this.rows && this.rows.length > 0) {
      return this.rows[0];
    }
    return null;
  }

  getKey(): string {
    return SelectOptionService.ToKey(this.host, this.par, this.opcao);
  }
}
