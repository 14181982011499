<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form -->

  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-tipo -->
    <div class="col-sm-12 mb-3">
      <label [for]="fieldIdTable['tipo']" class="form-label" l10nTranslate>CategoriaBeanFragment.tipo</label>
      <select [id]="fieldIdTable['tipo']" #tipo formControlName="tipo" class="w-ctrl form-select">
        @for (item of tipoList; track item.id) {
          <option [value]="item.id" l10nTranslate>TipoFinanceiroEnum.{{ item.name }}</option>
        }
      </select>
    </div>
    <!-- #E-#field-tipo -->
    <!-- #I-#field-nome -->
    <div class="col-sm-12 mb-3">
      <label [for]="fieldIdTable['nome']" class="form-label" l10nTranslate>CategoriaBeanFragment.nome</label>
      <input
        type="text"
        [id]="fieldIdTable['nome']"
        formControlName="nome"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
      />
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.required) {
            <div l10nTranslate>CategoriaBeanFragment.nome-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nome -->
    <!-- #I-#field-idsubcategoriade -->
    <div class="col-sm-12 mb-3">
      <label [for]="fieldIdTable['idsubcategoriade']" class="form-label" l10nTranslate
        >CategoriaBeanFragment.idsubcategoriade</label
      >
      <select
        [id]="fieldIdTable['idsubcategoriade']"
        #idsubcategoriade
        formControlName="idsubcategoriade"
        class="w-ctrl form-select"
      >
        @for (item of listCategoriaPaiFiltrada; track item.id) {
          <option [value]="item.id">{{ item.name }}</option>
        }
      </select>
    </div>
    <!-- #E-#field-idsubcategoriade -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
