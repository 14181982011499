<div class="panel-splash-fundo">
  <div class="d-flex justify-content-center mt-5 w-100">
    <div class="row-fluid">
      <div class="cardshadow">
        <div class="row pb-4">
          <div class="col">
            <div class="d-flex justify-content-center align-items-center">
              <img class="img-responsive logo-image" src="/assets/images/rural-1.svg" alt="7Bov" />
              <div class="text-center ps-1 logo-fonte">7Bov</div>
            </div>
          </div>
        </div>
        <router-outlet></router-outlet>
        <app-alert></app-alert>
      </div>
      <div class="d-flex justify-content-between">
        <div class="tech-mark">
          <span style="font-size: 9px">Version: {{ version }}</span>
        </div>
        <div class="tech-mark">
          <a target="_blank" rel="TechPlugX Solutions" href="https://www.techplugx.com.br">
            <p>By TechPlugX Solutions</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
