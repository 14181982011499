import { FormControl, ValidationErrors } from '@angular/forms';

export class PasswordValidation {
  static MustMatch(newpassword: string): ValidationErrors | null {
    return (control: FormControl) => {
      if (!control || !control.parent) {
        return null;
      }
      return control.parent.get(newpassword).value === control.value ? null : { mustmatch: true };
    };
  }
}
