// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { Subscription } from 'rxjs';
import { IconesHelper } from 'src/app/core/helpers/IconesHelper';
import { SelectOptionUtils } from 'src/app/core/helpers/SelectOptionUtils';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { BagParameterObject } from 'src/app/core/models/BagParameter';
import { BrokerProfileHelper } from 'src/app/core/models/BrokerProfileBean';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AlertService } from 'src/app/core/services/alert.service';
import { BrokerModuleProfileService } from 'src/app/core/services/broker-module-profile.service';
import { SelectOptionService } from 'src/app/core/services/select-option.service';
import { SystemModuleEnum } from 'src/app/modules/admin/models/SystemModuleEnum';
import { MoedaBean, MoedaHelper } from '../../../models/MoedaBean';
import { MoedaPadraoSelecao } from '../../../models/MoedaPadraoSelecao';
import { ContasActionService } from '../../../services/contas-action.service';
import { ContasActionImplService } from '../../../services/impl/contas-action-impl.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-moeda-padrao-selecao-fragment',
  templateUrl: './moeda-padrao-selecao-fragment.component.html',
  imports: [CommonModule, ReactiveFormsModule, L10nTranslateDirective, L10nTranslatePipe, NgSelectModule, NgbModule],
  providers: [{ provide: ContasActionService, useClass: ContasActionImplService }],
})
export class MoedaPadraoSelecaoFragmentComponent implements OnInit, OnDestroy, AfterViewInit {
  // #region #properties#.
  private timestamp_instance = Math.trunc(new Date().getTime() / 10000);
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  private lastProfileFinanceiro: BagParameterObject = new BagParameterObject();
  private currenteMoeda: MoedaBean = {};
  private selectedMoeda: MoedaBean = {};
  @Output() onOk = new EventEmitter<void>();
  // #endregion

  showPassword = false;
  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  moedaList: SelectOptionItem[] = [];
  indconversaoList: SelectOptionItem[] = [];

  // #region #radio.array-indconversao.
  // CODGEN {{R}}
  indconversaoRList = [{ value: 'ALTERAROUTRAS' }, { value: 'NAOALTERAROUTRAS' }];
  // #endregion

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private optionService: SelectOptionService,
    private httpHelper: HttpHelper,
    private profileService: BrokerModuleProfileService,
    private translation: L10nTranslationService,
    private crudService: ContasActionService,
    public activeModal: NgbActiveModal,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.showPassword = false;
    const validators = [];
    this.CrudFormGroupValidators(validators);
    this.CrudForm = this.formBuilder.group(
      this.CrudFormValidators(this.GetId('root')),
      validators.length > 0 ? { validators: validators } : {},
    );
    const profileSubject = this.profileService.getSubject().subscribe({
      next: (dados) => {
        this.lastProfileFinanceiro = BrokerProfileHelper.FindProfile(dados, SystemModuleEnum.FINANCEIRO);
        this.currenteMoeda = MoedaHelper.GetMoeda(this.lastProfileFinanceiro);
        this.f.moeda.patchValue(parseInt(`${this.currenteMoeda.idmoeda}`, 10));
        this.f.indconversao.patchValue('NAOALTERAROUTRAS');
      },
    });
    this.subscriptions.push(profileSubject);
    const valueChangeSub = this.f.moeda.valueChanges.subscribe((moeda) => {
      this.selectedMoeda = MoedaHelper.FindMoeda(this.moedaList, moeda);
    });
    this.subscriptions.push(valueChangeSub);
    this.OnInvokeSelectOptionsMoeda();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    this.OnInvokeSelectCombineLatest();
  }
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onloadselectedrows.
  // CODGEN {{R}}
  OnLoadSelectedRows() {}
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      moeda: [null, Validators.required],
      indconversao: [null],
      senha: [null, Validators.required],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      moeda: this.CrudForm.get('moeda').value,
      indconversao: this.CrudForm.get('indconversao').value,
      senha: this.CrudForm.get('senha').value,
    };
    return reg;
  }
  // #endregion

  // #region #onsearch.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnSearch(item): void {}
  // #endregion

  // #region #customsearchfn.
  // CODGEN {{R}}
  CustomSearchFn(term: string, item: SelectOptionItem): boolean | null {
    return SelectOptionUtils.SelectOptionItemSearchFn(term, item);
  }
  // #endregion

  // #region #oninvokeselectcombinelatest.
  // CODGEN
  OnInvokeSelectCombineLatest(): void {
    this.OnInvokeSelectOptionsMoeda();
  }
  // #endregion

  // #region #oninvokeselectoptionsmoeda.
  // CODGEN
  OnInvokeSelectOptionsMoeda(): void {
    const host = '/financeiro/categoria';
    this.optionService.GetSelectOptions(host, 'MOEDABEAN', '').subscribe({
      next: (data) => {
        this.moedaList = [...SelectOptionUtils.TransformToNumericId(data.items)];
        this.profileService.loadFromServer();
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #routine-definitions#.

  OnProcessar(): void {
    if (this.IsTrocouMoeda()) {
      const reg: MoedaPadraoSelecao = this.CrudFormToRegistro();
      this.crudService.PutAlteraMoedaPadrao(reg).subscribe({
        next: () => {
          console.log('OK SERVIÇO');
          /**
           * refresca a moeda padrão.
           */
          this.profileService.loadFromServer();
          this.onOk.emit();
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }

  MoedaIconeUrl(): string | null {
    return IconesHelper.MoedaIconeUrl(this.httpHelper, this.f.moeda?.value, this.timestamp_instance);
  }
  // #endregion

  // #region #declarations#.

  IsTrocouMoeda(): boolean {
    return this.currenteMoeda?.idmoeda !== this.selectedMoeda?.idmoeda;
  }

  ToTranslateMoeda(opcao: string): string {
    const txt: string = this.translation.translate(`MoedaPadraoSelecaoFragment.indconversao-${opcao}`).toString();
    if (txt && txt.indexOf('{1}')) {
      const tde = `${this.currenteMoeda?.descricao} (${this.currenteMoeda?.simbolo})`;
      const tpara = `${this.selectedMoeda?.descricao} (${this.selectedMoeda?.simbolo})`;
      return txt.replace('{1}', tde).replace('{2}', tpara);
    }
    return txt;
  }

  // #endregion
}
