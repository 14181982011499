// {{GENERATE}}
// @fragment
// @ListFragment
// @Wrapper:TbContaTransacao
// URL:/financeiro/lancamento
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { SelectOptionChangeEvent, SelectValueComponent } from 'src/app/core/accessors/select-value.component';
import { Constants } from 'src/app/core/helpers/constants';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { CloseModalResult } from 'src/app/core/models/CloseModalResult';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { SelectOptionEvent, SelectOptionService } from 'src/app/core/services/select-option.service';
import { Utilities } from 'src/app/core/services/utilities';
import { AdminContatoActionAddComponent } from 'src/app/modules/admin/components/contato/admin-contato-action/admin-contato-action-add.component';
import { ContatoBean } from 'src/app/modules/admin/models/ContatoBean';
import { CategoriaBean } from '../../../models/CategoriaBean';
import { TagBean } from '../../../models/TagBean';
import { TipoFinanceiroEnumRepo } from '../../../models/TipoFinanceiroEnum';
import { CategoriaActionAddComponent } from '../../categorias/categoria-action/categoria-action-add.component';
import { TagActionAddComponent } from '../../tags/tag-action/tag-action-add.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { KzMaskCurrencyDirective } from 'src/app/core/directives/kz-mask-currency.directive';
import { NumberOnlyDirective } from 'src/app/core/directives/number-only.directive';
import { BagParameterObject, BagParameter } from 'src/app/core/models/BagParameter';
import { ConfUtilizacaoConfiguration } from 'src/app/modules/admin/models/ConfUtilizacaoEnum';
import { LancamentoDetalheBean, LancamentoDetalhe } from '../../../models/LancamentoDetalheBean';
import { ConfUtilizacaoBrokerService } from '../../../services/conf-utilizacao-broker.service';
import { EntityDescriptionService } from '../../../services/entity-description.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-lancamento-detalhe-bean-fragment',
  templateUrl: './lancamento-detalhe-bean-fragment.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NumberOnlyDirective,
    KzMaskCurrencyDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    SelectValueComponent,
  ],
  providers: [provideNgxMask()],
})
export class LancamentoDetalheBeanFragmentComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  bagParameter: BagParameterObject;
  idlancamentodetalhe: number;
  idDetalhe: number;
  opcao: string;
  mostraField = {};
  fieldState = {
    fields: [
      'tipofinanceiro',
      'valor',
      'valorprevisto',
      'valorefetivo',
      'descricao',
      'idcategoria',
      'idcontato',
      'idcentrocusto',
      'idprojeto',
      'listatags',
    ],
  };
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  tipofinanceiroList: SelectOptionItem[] = TipoFinanceiroEnumRepo.optionList();

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private optionService: SelectOptionService,
    private entityDescriptionService: EntityDescriptionService,
    private utilizacaoService: ConfUtilizacaoBrokerService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformvalidators.
  // CODGEN
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      tipofinanceiro: [null, Validators.required],
      valor: [null, [Validators.required, Validators.maxLength(22)]],
      valorprevisto: [null, [Validators.maxLength(22)]],
      valorefetivo: [null, [Validators.maxLength(22)]],
      descricao: [null, Validators.maxLength(200)],
      idcategoria: [null, Validators.required],
      idcontato: [null],
      idcentrocusto: [null],
      idprojeto: [null],
      listatags: [null],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN
  CrudFormToRegistro() {
    const reg = {
      tipofinanceiro: this.CrudForm.get('tipofinanceiro').value,
      valor: this.CrudForm.get('valor').value,
      valorprevisto: this.CrudForm.get('valorprevisto').value,
      valorefetivo: this.CrudForm.get('valorefetivo').value,
      descricao: this.CrudForm.get('descricao').value,
      idcategoria: this.CrudForm.get('idcategoria').value,
      idcontato: this.CrudForm.get('idcontato').value,
      idcentrocusto: this.CrudForm.get('idcentrocusto').value,
      idprojeto: this.CrudForm.get('idprojeto').value,
      listatags: this.CrudForm.get('listatags').value,
    };
    (reg as LancamentoDetalheBean).relatedentities = this.entityDescriptionService.getCachedItemList(
      LancamentoDetalhe.extractEntityKeyList(reg),
    );
    return reg;
  }
  // #endregion

  // #region #onaddnewitem.
  // CODGEN {{R}}
  OnAddNewItem(event: SelectOptionEvent, name: string): void {
    //console.log('Add -->', name, JSON.stringify(event));
    switch (name) {
      case 'idcategoria':
        Utilities.OpenNewModalWindow(this.modalService, CategoriaActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.idcategoria.patchValue((message.registro as CategoriaBean).id);
        });
        break;
      case 'idcontato':
        Utilities.OpenNewModalWindow(this.modalService, AdminContatoActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.idcontato.patchValue((message.registro as ContatoBean).idcontato);
        });
        break;
      case 'idcentrocusto':
        break;
      case 'idprojeto':
        break;
      case 'listatags':
        Utilities.OpenNewModalWindow(this.modalService, TagActionAddComponent, (message: CloseModalResult) => {
          this.optionService.publish(event);
          this.f.listatags.patchValue((message.registro as TagBean).idtag);
        });
        break;
    }
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion

  // #region #declarations#.

  private habilitaCtrl(name: string): void {
    if (ConfUtilizacaoConfiguration.configuracoes[name]) {
      /** verifica a configuração */
      if (this.utilizacaoService.isHabilitado(ConfUtilizacaoConfiguration.configuracoes[name].e)) {
        this.CrudForm.get(name).enable({ onlySelf: true, emitEvent: false });
      }
    } else {
      this.CrudForm.get(name).enable({ onlySelf: true, emitEvent: false });
    }
  }

  private FiltraFieldsByTipolancamento(): void {
    this.CrudForm.disable({ onlySelf: false, emitEvent: false });
    this.fieldState.fields.forEach((name) => {
      this.habilitaCtrl(name);
    });
    this.mostraField = this.VisibylityFormFields();
  }

  private VisibylityFormFields() {
    const vis = {};
    Object.keys(this.CrudForm.controls).forEach((name) => {
      const control = this.CrudForm.get(name);
      vis[name] = control.enabled;
    });
    return vis;
  }

  setBagParameter(bag: BagParameter): void {
    this.bagParameter = new BagParameterObject(bag);
    const registro = JSON.parse(this.bagParameter.getTextById('registro', '{}')) as LancamentoDetalheBean;
    this.idlancamentodetalhe = registro.idlancamentodetalhe;
    this.CrudForm.patchValue(registro);
    this.CrudForm.markAllAsTouched();
    this.opcao = registro.tipofinanceiro;
    this.entityDescriptionService.addItemList(registro.relatedentities);
  }

  OnSelectChange(event: SelectOptionChangeEvent): void {
    this.entityDescriptionService.addItemSelect(event);
  }
  // #endregion
}
