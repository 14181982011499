<!-- {{GENERATE}} -->
<div class="tpx-crud-card">
  <!-- #I-#tpx-crud-header/R -->
  <div class="tpx-crud-header">
    <div class="row d-flex justify-content-between align-items-center">
      <div class="col-sm-12 col-md-8 mb-3 tpx-crud-title">
        <div class="t-header-title" l10nTranslate>AdminContatoActionGrid.HEADERTITLE</div>
      </div>
      <div class="col-sm-12 col-md-4">
        <app-search-box
          (searchChange)="OnSearchChanged($event)"
          placeholder="{{ 'AdminContatoActionGrid.SEARCH' | translate: locale.language }}"
        ></app-search-box>
      </div>
    </div>
  </div>
  <!-- #E-#tpx-crud-header -->
  <div class="tpx-crud-body">
    <!-- #I-#row-app-search-box/R -->
    <div class="row control-box d-flex align-items-center">
      <div class="col-sm-12">
        <div class="navbar action-box p-0">
          <ul class="nav navbar-nav navbar-right p-0"></ul>
        </div>
      </div>
    </div>
    <!-- #E-#row-app-search-box -->
    <!-- #I-#row-on-mostrar-ativas/R -->
    <div class="row">
      <div class="col-sm-12 d-flex justify-content-between align-items-center">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            (change)="OnMostrarAtivas($event.target)"
            [checked]="checkedTodas"
          />
          <label class="form-check-label" for="flexSwitchCheckDefault">Mostrar apenas ativas.</label>
        </div>
      </div>
    </div>
    <!-- #E-#row-on-mostrar-ativas -->
    <!-- #I-#row-app-card-grid/R -->
    <div class="row">
      <div class="col-sm-12">
        <div class="card-grid">
          @for (node of this.rows.data; track node.idcontato) {
            <app-admin-contato-action-card
              [registro]="node"
              (onOk)="OnCardModify($event)"
            ></app-admin-contato-action-card>
          }
        </div>
      </div>
    </div>
    <!-- #E-#row-app-card-grid -->
    <!-- #I-#tpx-crud-footer/R -->
    <div class="tpx-crud-footer">
      <div class="row">
        <div class="col footer-button-height">
          <button
            type="button"
            class="btn btn-primary btn-circle btn-xl footer-button"
            (click)="OnAddElement()"
            ngbTooltip="{{ 'AdminContatoActionGrid.BTN-NEW' | translate: locale.language }}"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- #E-#tpx-crud-footer -->
  </div>
</div>
