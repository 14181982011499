import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { L10nTranslationService } from 'angular-l10n';
import { StatusCodes } from 'http-status-codes';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AlertDialog, AlertService, DialogType } from 'src/app/core/services/alert.service';
import { ConfirmComponent } from '../../helpers/confirm.component';

@Component({
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['alert.component.css'],
  standalone: true,
})
export class AlertComponent implements OnInit, OnDestroy {
  static readonly REFRESH_PAGE_ON_TOAST_CLICK_MESSAGE: string = 'Erro de sistema não esperado';
  static readonly DEFAULT_ERROR_TITLE: string = 'Evento ocorrido';
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições

  constructor(
    private translation: L10nTranslationService,
    private router: Router,
    private alertService: AlertService,
    private toastaService: ToastrService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    const subscription = this.alertService.getMessage().subscribe({
      next: (data) => {
        //console.log('ERROR ==> ' + JSON.stringify(data));
        if (data) {
          if (data.type && data.message) {
            if (data.type === 'error' && data.message.status !== 200) {
              if (data.message.name === 'HttpErrorResponse') {
                if (data.message.error && data.message.error.title) {
                  this.showError(this.translate(data.message.error.title));
                  return;
                }
              }
            }
            if (this.isString(data.message)) {
              this.showMessage(data);
              return;
            }
          }
          if (data.type && data.message && this.isString(data.message)) {
            this.showMessage(data);
          } else {
            if (data.text && data.text instanceof HttpErrorResponse) {
              this.handleError(data.text);
            } else if (data instanceof HttpErrorResponse) {
              this.handleError(data.error);
            } else if (
              data.type === 'error' &&
              data.message &&
              data.message.name === 'HttpErrorResponse' &&
              data.message.statusText
            ) {
              if (data.message.statusText === 'Unknown Error') {
                this.showError(data.message.message);
              } else {
                /** 
                if (data.message.status && data.message.status === 401) {
                  this.showError('Sessão finalizada, favor renovar seu login.');
                  this.router.navigateByUrl('/login');
                } else 
                **/
                if (data.message.status && data.message.status === 409) {
                  if (data.message.error.stacktrace) {
                    this.showErrorEx(data.message.error.message, data.message.error.stacktrace);
                  } else {
                    this.mostraErroOuSucesso(data.message.error.message);
                  }
                } else if (data.message.error && data.message.error.message) {
                  this.showError(data.message.error.message);
                } else {
                  this.showError(data.message.statusText);
                }
              }
            } else {
              this.showError(data);
            }
          }
        }
      },
    });
    this.subscriptions.push(subscription);
    const subscription2 = this.alertService.getDialogEvent().subscribe((alert) => {
      this.showDialog(alert);
    });
    this.subscriptions.push(subscription2);
  }

  private translate(erro: string): string {
    if (erro === 'Bad Request') {
      return 'Solicitação incorreta de dados';
    }
    return erro;
  }

  private mostraErroOuSucesso(erro: string) {
    let idxi = 0;
    let idxf = 0;
    if ((idxi = erro.indexOf('[', 0)) >= 0 && (idxf = erro.indexOf(']', 0)) > 0) {
      const codigo = erro.substring(idxi + 1, idxf);
      const translate = this.translation.translate('Error.' + codigo);
      this.showToasst(DialogType.success, translate === 'No key' ? erro : translate, 'Atenção');
    } else {
      this.showError(erro);
    }
  }

  private isString(val) {
    return (
      typeof val === 'string' ||
      (!!val && typeof val === 'object' && Object.prototype.toString.call(val) === '[object String]')
    );
  }

  public handleError(error) {
    console.error(error);
    const httpErrorCode = error.status;
    switch (httpErrorCode) {
      case StatusCodes.UNAUTHORIZED:
        this.router.navigateByUrl('/login');
        break;
      case StatusCodes.FORBIDDEN:
        this.router.navigateByUrl('/unauthorized');
        break;
      case StatusCodes.BAD_REQUEST:
        this.showError(error.message);
        break;
      case StatusCodes.INTERNAL_SERVER_ERROR:
        {
          //console.log('Erro Translate:' + error.error.message);
          const txt: string = error.error.message;
          let idxi = 0;
          let idxf = 0;
          if ((idxi = txt.indexOf('[', 0)) >= 0 && (idxf = txt.indexOf(']', 0)) > 0) {
            const codigo = txt.substring(idxi + 1, idxf);
            const translate = this.translation.translate('Error.' + codigo);
            this.showError(translate === 'No key' ? txt : translate);
          } else {
            this.showError(txt);
          }
        }
        break;
      default:
        this.showError(AlertComponent.REFRESH_PAGE_ON_TOAST_CLICK_MESSAGE);
    }
  }

  private showMessage(dialog: AlertDialog) {
    switch (dialog.type) {
      case DialogType.success:
        this.showToasst(DialogType.success, dialog.message, '', dialog.timeOut);
        break;
      case DialogType.error:
      case DialogType.warning:
      case DialogType.message:
      default:
        this.showToasst(dialog.type, dialog.message, 'Atenção', dialog.timeOut);
        break;
    }
  }

  private showError(message: any) {
    if (!message) {
      return;
    }
    if (message.type && 'error' === message.type && message.message) {
      this.showToasst(DialogType.error, message.message, 'Atenção');
    } else if (message.startsWith && message.startsWith('INVALID_')) {
      this.showToasst(DialogType.error, this.translation.translate('Error.' + message), 'Atenção');
    } else if (JSON.stringify(message) === '{}') {
      this.showToasst(DialogType.error, 'Erro de sistema, tente novamente.', 'Atenção');
    } else if (message.indexOf && message.indexOf('Http failure response for http') >= 0) {
      this.showToasst(
        DialogType.error,
        'Erro na Conexão com o Serviço na nuvem, tente novamente mais tarde.',
        'Atenção',
      );
    } else {
      this.showToasst(DialogType.error, message, 'Atenção');
    }
  }

  private showToasst(tp: DialogType, message?: string, title?: string, timeOut: number = 5000): void {
    /*
    const iconClasses: Partial<ToastrIconClasses> = {
      error: 'toast-error',
      info: 'toast-info',
      success: 'toast-success',
      warning: 'toast-warning',
    };
    */
    const parametro: Partial<IndividualConfig> = {
      disableTimeOut: false,
      timeOut: timeOut,
      tapToDismiss: false,
      positionClass: 'toast-top-right',
      closeButton: true,
      progressBar: true,
      toastClass: 'border-blue',
      titleClass: 'toast-title',
      messageClass: 'toast-message',
    };
    try {
      switch (tp) {
        case DialogType.error:
          parametro.toastClass = 'border-red';
          this.toastaService.error(message, title, parametro);
          break;
        case DialogType.warning:
          parametro.toastClass = 'border-blue';
          this.toastaService.warning(message, title, parametro);
          break;
        default:
        case DialogType.success:
          parametro.toastClass = 'border-green';
          this.toastaService.success(message, title, parametro);
          break;
      }
      //console.log('showToasst: ' + tp + ' msg:' + message + ' tit:' + title + ' par:' + JSON.stringify(parametro));
    } catch (error) {
      console.error(error);
    }
  }

  private showErrorEx(message: string, stack: string) {
    if (message && message.startsWith('INVALID_')) {
      message = this.translation.translate('Error.' + message);
    }
    this.showToasst(DialogType.error, message, 'Atenção');
  }

  public showDialog(dialog: AlertDialog) {
    const modalRef = this.modalService.open(ConfirmComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    const instance: ConfirmComponent = modalRef.componentInstance;
    instance.title = dialog.title;
    instance.message = dialog.message;
    instance.btnOkText = dialog.btnOkText;
    instance.btnCancelText = dialog.btnCancelText;
    instance.isImage = dialog.isImage;
    modalRef.result.then(
      (result) => {
        if (result) {
          dialog.okCallback(result);
        } else {
          dialog.cancelCallback();
        }
      },
      () => {
        dialog.cancelCallback();
      },
    );
  }

  ngOnDestroy() {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  closeMensagem() {}
}
