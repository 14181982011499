// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum PaineisFinanceirosEnum {
  BP = 'BP',
  CC = 'CC',
  CP = 'CP',
  CR = 'CR',
  DC = 'DC',
  DCT = 'DCT',
  FC = 'FC',
  MD = 'MD',
  MDC = 'MDC',
  ME = 'ME',
  MI = 'MI',
  MR = 'MR',
  MRC = 'MRC',
  RC = 'RC',
  RCC = 'RCC',
  RM = 'RM',
  RCX = 'RCX',
  SCX = 'SCX',
}

// #region optionlist#method.
// CODGEN {{R}}
export class PaineisFinanceirosEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'BP', name: 'BP' },
      { id: 'CC', name: 'CC' },
      { id: 'CP', name: 'CP' },
      { id: 'CR', name: 'CR' },
      { id: 'DC', name: 'DC' },
      { id: 'DCT', name: 'DCT' },
      { id: 'FC', name: 'FC' },
      { id: 'MD', name: 'MD' },
      { id: 'MDC', name: 'MDC' },
      { id: 'ME', name: 'ME' },
      { id: 'MI', name: 'MI' },
      { id: 'MR', name: 'MR' },
      { id: 'MRC', name: 'MRC' },
      { id: 'RC', name: 'RC' },
      { id: 'RCC', name: 'RCC' },
      { id: 'RM', name: 'RM' },
      { id: 'RCX', name: 'RCX' },
      { id: 'SCX', name: 'SCX' },
    ];
  }

  static toEnum(val: string): PaineisFinanceirosEnum {
    switch (val.toUpperCase()) {
      case 'BP':
        return PaineisFinanceirosEnum.BP;
      case 'CC':
        return PaineisFinanceirosEnum.CC;
      case 'CP':
        return PaineisFinanceirosEnum.CP;
      case 'CR':
        return PaineisFinanceirosEnum.CR;
      case 'DC':
        return PaineisFinanceirosEnum.DC;
      case 'DCT':
        return PaineisFinanceirosEnum.DCT;
      case 'FC':
        return PaineisFinanceirosEnum.FC;
      case 'MD':
        return PaineisFinanceirosEnum.MD;
      case 'MDC':
        return PaineisFinanceirosEnum.MDC;
      case 'ME':
        return PaineisFinanceirosEnum.ME;
      case 'MI':
        return PaineisFinanceirosEnum.MI;
      case 'MR':
        return PaineisFinanceirosEnum.MR;
      case 'MRC':
        return PaineisFinanceirosEnum.MRC;
      case 'RC':
        return PaineisFinanceirosEnum.RC;
      case 'RCC':
        return PaineisFinanceirosEnum.RCC;
      case 'RM':
        return PaineisFinanceirosEnum.RM;
      case 'RCX':
        return PaineisFinanceirosEnum.RCX;
      case 'SCX':
        return PaineisFinanceirosEnum.SCX;
      default:
        return null;
    }
  }
}
// #endregion

// #region #declarations#.
// #endregion
