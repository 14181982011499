<form [id]="fieldIdTable['form']" [formGroup]="loginForm" method="post" (ngSubmit)="onSubmit()">
  <div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <input
            id="username"
            type="text"
            name="username"
            formControlName="username"
            autocomplete="username"
            class="form-control"
            placeholder="Digite seu E-Mail..."
            [ngClass]="{ 'is-invalid': f.username.invalid && (f.username.dirty || f.username.touched) }"
          />
          @if (f.username.errors) {
            <div class="invalid-feedback">
              @if (f.username.errors.required) {
                <div>Digite o seu E-Mail...</div>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <input
            type="password"
            name="password"
            formControlName="password"
            class="form-control"
            id="txtSenha"
            placeholder="Digite sua Senha..."
            [ngClass]="{ 'is-invalid': f.password.invalid && (f.password.dirty || f.password.touched) }"
            autocomplete="current-password"
            maxlength="50"
          />
          <span class="p-viewer">
            <i onclick="showEyeSlash(this, $('#txtSenha'))" class="fa fa-fw fa-eye-slash"></i>
          </span>
          @if (f.password.errors) {
            <div class="invalid-feedback">
              @if (f.password.errors.required) {
                <div>Digite a sua Senha...</div>
              }
            </div>
          }
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <button type="submit" class="btn btn-primary w-100" [disabled]="!loginForm.valid">Acessar</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div>
          <a href="javascript:;" (click)="OnCadastroGratuito()">Cadastre-se gratuitamente...</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mt-2">
          <a href="javascript:;" (click)="OnEsqueceuSenha()">Esqueceu a senha?</a>
        </div>
      </div>
    </div>
  </div>
</form>
