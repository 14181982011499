import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValueName } from 'src/app/core/models/ValueName';
import { Utilities } from 'src/app/core/services/utilities';

export interface FeedbackBean {
  tituloHeader?: string;
  titulo2?: string;
  tituloLink?: string;
  urlLink?: string;
}

@Injectable()
export class FeedbackFinal {
  public static GetModelo(route: ActivatedRoute): FeedbackBean {
    const params: ValueName[] = Utilities.extractParameters(route);
    const json = Utilities.getTextParameter(params, 'modelo');
    if (json != '') {
      return JSON.parse(json);
    } else {
      return {};
    }
  }

  public static GoTo(router: Router, modelo: FeedbackBean): void {
    const qt = { modelo: JSON.stringify(modelo) };
    router.navigate(['/feedback-final-sucesso'], { queryParams: qt, skipLocationChange: true, replaceUrl: false });
  }
}

@Component({
  selector: 'app-feedback-final-sucesso',
  templateUrl: './feedback-final-sucesso.component.html',
  styleUrls: ['./feedback-final-sucesso.component.css'],
  standalone: true,
})
export class FeedbackFinalSucessoComponent implements OnInit {
  modelo: FeedbackBean;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.modelo = FeedbackFinal.GetModelo(this.route);
    /*
      tituloHeader: 'Redefinir senha de acesso',
      titulo2: 'Sua senha de acesso foi redefinida com sucesso.',
      tituloLink: 'Acessar o 7Bov',
      urlLink: '/login',
    };*/
  }
}
