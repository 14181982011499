// {{GENERATE}}
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { SearchBoxComponent } from 'src/app/core/helpers/search-box.component';
import { LogFieldItem } from 'src/app/core/models/LogFieldItem';
import { GridModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService, DialogType } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { HistoricoBean } from '../../../models/HistoricoBean';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-historico-action-grid',
  templateUrl: './historico-action-grid.component.html',
  imports: [
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginator,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    ReactiveFormsModule,
    SearchBoxComponent,
  ],
})
export class HistoricoActionGridComponent implements OnInit, AfterViewChecked, GridModelComponent, AfterViewInit {
  // #region #properties#.
  tipo: string = '';
  titulo: string = '';
  datePipe = new DatePipe('pt-br');
  currencyPipe = new CurrencyPipe('pt-br');
  // #endregion

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lastsearchtext = '';
  public displayedColumns: string[] = ['id', 'moment', 'function', 'access', 'detail', 'action'];
  rows: MatTableDataSource<HistoricoBean>;
  rowsCache: HistoricoBean[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loadingIndicator: boolean;
  messages = Constants.messages;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public translation: L10nTranslationService,
  ) {
    //
    this.rows = new MatTableDataSource<HistoricoBean>([]);
  }
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit');
    this.rows.paginator = this.paginator;
    this.rows.sort = this.sort;
  }
  // #endregion

  // #region #ngafterviewchecked.
  // CODGEN {{R}}
  ngAfterViewChecked(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN
  OnInitInvokeService() {
    this.loadingIndicator = false;
  }
  // #endregion

  // #region #ondeleterow.
  // CODGEN {{R}}
  OnDeleteRow(row: HistoricoBean) {
    this.alertService.showDialog(
      this.translation.translate('HistoricoActionGrid.DELETE-TITLE'),
      Utilities.GetDeleteRowMessage(this, 'HistoricoActionGrid', row, this.displayedColumns),
      DialogType.confirm,
      () => this.OnDeleteRowHelper(row),
      () => {
        //console.log('Cancelado');
      },
    );
  }
  // #endregion

  // #region #ondeleterowhelper.
  // CODGEN {{R}}
  private OnDeleteRowHelper(row: HistoricoBean) {}
  // #endregion

  // #region #onsearchchanged.
  // CODGEN {{R}}
  OnSearchChanged(value: string) {
    this.lastsearchtext = value;
    this.rows.data = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        value,
        false,
        this.DisplayField('id', r.id, r),
        this.DisplayField('moment', r.moment, r),
        this.DisplayField('function', r.function, r),
        this.DisplayField('access', r.access, r),
        this.DisplayField('detail', r.detail, r),
      ),
    );
  }
  // #endregion

  // #region #displayfield.
  // CODGEN
  DisplayField(fieldname: string, value, row: HistoricoBean) {
    switch (fieldname) {
      case 'id':
        return value;
      case 'moment':
        return this.datePipe.transform(value, 'dd/MM/yyy HH:mm:ss');
      case 'function':
        return value;
      case 'access':
        return value;
      case 'detail':
        return value;
      default:
        return value;
    }
  }
  // #endregion

  // #region #onactivatedatatable.
  // CODGEN {{R}}
  OnActivateDatatable(event) {}
  // #endregion

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #routine-definitions#.

  ToListaHisto(row: HistoricoBean): LogFieldItem[] {
    if (row && row.detail) {
      const lista = row.detail;
      if (lista.fields && lista.fields.length > 0) {
        for (const obj of lista.fields) {
          obj.id = obj.id.toLowerCase();
        }
      }
      return lista.fields;
    } else {
      return [];
    }
  }

  static ativaModal(modalService: NgbModal, tipo: string, titulo: string, rows: HistoricoBean[]): NgbModalRef {
    const modalRef = modalService.open(HistoricoActionGridComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'tpx-custom-modal',
    });
    const view = modalRef.componentInstance as HistoricoActionGridComponent;
    view.rows.data = view.rowsCache = [...rows];
    view.tipo = tipo;
    view.titulo = titulo;
    return modalRef;
  }
  // #endregion
}
