// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoPeriodicidadeEnum {
  DIARIA = 'DIARIA',
  SEMANAL = 'SEMANAL',
  MENSAL = 'MENSAL',
  ANUAL = 'ANUAL',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoPeriodicidadeEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'DIARIA', name: 'DIARIA' },
      { id: 'SEMANAL', name: 'SEMANAL' },
      { id: 'MENSAL', name: 'MENSAL' },
      { id: 'ANUAL', name: 'ANUAL' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
