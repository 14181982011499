// {{GENERATE}}

import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModule, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { TablePainelContext } from 'src/app/core/helpers/TablePainelContext';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { SmtpConfigFormBean } from '../../../models/SmtpConfigFormBean';
import { ConfigOpcoesActionService } from '../../../services/config-opcoes-action.service';
import { SmtpConfigFormBeanFragmentComponent } from '../smtp-config-form-bean/smtp-config-form-bean-fragment.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-config-opcoes-action-tab',
  templateUrl: './config-opcoes-action-tab.component.html',
  imports: [
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgbModule,
    ReactiveFormsModule,
    SmtpConfigFormBeanFragmentComponent,
  ],
})
export class ConfigOpcoesActionTabComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  protected fieldIdTable = {};
  // #endregion

  returnUrl: string;
  private instanceId = Constants.GetId();
  private rootformid: string;
  private myname: string;
  public contexto = {};
  CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  registro: SmtpConfigFormBean;
  masks = Constants;
  @ViewChild('vsmtp', { static: true }) childSmtp: SmtpConfigFormBeanFragmentComponent;
  @Input() ContextData: TablePainelContext;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: ConfigOpcoesActionService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public activeModal: NgbActiveModal,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const validators = [];
    this.CrudFormGroupValidators(validators);
    this.CrudForm = this.formBuilder.group(
      this.CrudFormValidators(this.GetId('root')),
      validators.length > 0 ? { validators: validators } : {},
    );
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onsubmit.
  // CODGEN {{R}}
  onSubmit() {
    // stop here if form is invalid
    if (this.CrudForm.invalid) {
      return;
    }

    this.registro = Object.assign({}, this.registro, this.CrudFormToRegistro());
    this.crudService.UpdateSmtp(this.registro).subscribe({
      next: () => {
        this.OnBackButton();
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN
  OnInitInvokeService() {
    let vkey = this.ContextData.read('smtp.idsmtp');
    if (!vkey) {
      vkey = 0;
    }
    this.crudService.GetReadSmtp(vkey).subscribe({
      next: (data) => {
        try {
          this.registro = data;
          this.CrudForm.patchValue(this.registro);
        } catch (error) {
          console.error('erro:', error);
          this.alertService.error('Erro no carregamento dos dados.');
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  IsEditMode(): boolean {
    return this.viewType === ViewTypeEnum.READ;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onbackbutton.
  // CODGEN {{R}}
  OnBackButton() {
    this.router.navigate([this.returnUrl]);
  }
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return this.childSmtp.CrudFormValidators(this.rootformid);
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childSmtp.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return this.childSmtp.CrudFormToRegistro();
  }
  // #endregion

  // #region #ontabbeforechange.
  // CODGEN {{R}}
  OnTabBeforeChange($event: NgbNavChangeEvent): void {
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
