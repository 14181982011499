// {{GENERATE}}
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbDropdownModule, NgbModal, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { format } from 'date-fns';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { BagParameterObject } from 'src/app/core/models/BagParameter';
import { CloseModalResult } from 'src/app/core/models/CloseModalResult';
import { CrudModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { DocumentoActionAddComponent } from 'src/app/modules/documento/components/docs/documento-action/documento-action-add.component';
import { TbDocumentBean } from 'src/app/modules/documento/models/TbDocumentBean';
import { LancamentoBean } from '../../../models/LancamentoBean';
import { TipoLancamentoEnum } from '../../../models/TipoLancamentoEnum';
import { TipoRepeticaoEnum } from '../../../models/TipoRepeticaoEnum';
import { ConfUtilizacaoBrokerService } from '../../../services/conf-utilizacao-broker.service';
import { LancamentoActionService } from '../../../services/lancamento-action.service';
import { LancamentoBeanFragmentComponent } from '../lancamento-bean/lancamento-bean-fragment.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-lancamento-action-add',
  templateUrl: './lancamento-action-add.component.html',
  styleUrls: ['./lancamento-action-add.component.css'],
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgbTooltipModule,
    NgbDropdownModule,
    LancamentoBeanFragmentComponent,
  ],
  providers: [],
})
export class LancamentoActionAddComponent implements CrudModelComponent, OnInit, AfterViewInit {
  // #region #properties#.
  tipolancamento: TipoLancamentoEnum;
  idconta: number;
  parameters: BagParameterObject = null;
  protected filterToogle = {
    confirmado: false,
    conciliado: false,
    alarm: false,
    cpagar: false,
    anexos: false,
    alarmval: 0,
  };
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  registro: LancamentoBean;
  masks = Constants;
  @ViewChild('vlancamento', { static: true }) childLancamento: LancamentoBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: LancamentoActionService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public translation: L10nTranslationService,
    private router: Router,
    private modalService: NgbModal,
    private utilizacaoService: ConfUtilizacaoBrokerService,
  ) {}
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN
  TrataValueChanges(): void {}

  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onsubmit.
  // CODGEN {{R}}
  onSubmit(opcao = '') {
    // stop here if form is invalid
    if (this.CrudForm.invalid) {
      return;
    }
    this.registro = Object.assign({}, this.registro, this.CrudFormToRegistro());
    if (this.viewType === ViewTypeEnum.ADD) {
      this.crudService.CreateLancamento(this.registro).subscribe({
        next: (data) => {
          this.registro = data;
          this.OnCloseModal(true, opcao === '+');
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    } else {
      this.crudService.UpdateLancamento(this.registro).subscribe({
        next: () => {
          this.OnCloseModal(true);
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN
  OnInitInvokeService() {
    if (!this.utilizacaoService.isPresent()) {
      // console.group('UTILIZAÇÃO');
      this.utilizacaoService.load((message: any) => {
        if (message.error) {
          /* força um login */
          this.OnCloseModal(false);
          this.router.navigate(['/login']);
          return;
        }
        // console.log('message--->', message);
        this.OnInitInvokeService();
      });
      // console.groupEnd();
      return;
    }
    //   console.log('PRESENTE***');
    if (this.viewType === ViewTypeEnum.READ) {
      this.crudService.GetReadLancamento(this.registro.idlancamento).subscribe({
        next: (data) => {
          try {
            this.registro = data;
            this.tipolancamento = this.registro.tipolancamento;
            this.CrudForm.patchValue(this.registro);
            this.refreshFilterToogler();
            this.CrudForm.markAllAsTouched();
          } catch (error) {
            //console.log('erro:', error);
            this.alertService.error('Erro no carregamento dos dados.');
          }
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    } else {
      /** Valores default */
      this.registro = {};
      this.registro.datacompetencia = format(new Date(), 'yyyy-MM-dd');
      this.registro.data = format(new Date(), 'yyyy-MM-dd');
      this.registro.valor = 0.0;
      this.registro.tipolancamento = this.tipolancamento;
      this.registro.idconta = this.idconta;
      this.registro.repeticao = { reptiporepeticao: TipoRepeticaoEnum.UNICA };
      if (this.parameters) {
        const r = this.parameters.jsonParseById('registro-inicial');
        if (r) {
          this.registro = r;
        }
      }
      this.tipolancamento = this.registro.tipolancamento;
      this.CrudForm.patchValue(this.registro);
      console.group('LANÇAMENTO');
      console.log('TIPO:', 'TL', this.registro.tipolancamento, 'CAT', this.registro.idcategoria);
      this.refreshFilterToogler();
      this.CrudForm.markAllAsTouched();
      console.groupEnd();
    }
  }
  // #endregion

  IsEditMode(): boolean {
    return this.viewType === ViewTypeEnum.READ;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      registro: this.registro,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return this.childLancamento.CrudFormValidators(this.rootformid);
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childLancamento.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return this.childLancamento.CrudFormToRegistro();
  }
  // #endregion

  // #region #routine-definitions#.
  getTitulo(): string {
    if (this.viewType !== ViewTypeEnum.ADD) {
      if (this.registro.descricao) {
        return `Editar ${this.registro.descricao}`;
      } else {
        return 'Editar lançamento';
      }
    }
    const tipo = this.f.tipolancamento.value;
    if (tipo) {
      switch (tipo) {
        case 'DESPESA':
          return 'Nova Despesa';
        case 'RECEITA':
          return 'Nova Receita';
        case 'TRANSFERENCIA':
          return 'Nova Transferência';
        case 'LAN_DETALHADO':
          return 'Novo Lançamento detalhado';
      }
    }
    return 'Novo Lançamento';
  }

  refreshFilterToogler() {
    const st = this.CrudForm.get('statuslancamento').value;
    switch (st) {
      case 'AGENDADO':
      case 'PENDENTE':
        this.filterToogle['confirmado'] = false;
        this.filterToogle['conciliado'] = false;
        break;
      case 'CONFIRMADO':
        this.filterToogle['confirmado'] = true;
        this.filterToogle['conciliado'] = false;
        break;
      case 'CONCILIADO':
        this.filterToogle['confirmado'] = true;
        this.filterToogle['conciliado'] = true;
        break;
      default:
        break;
    }
  }

  onFilter(opcao: 'confirmado' | 'conciliado' | 'cpagar'): void {
    this.filterToogle[opcao] = !this.filterToogle[opcao];
    switch (opcao) {
      case 'confirmado':
        if (this.filterToogle[opcao]) {
          this.CrudForm.get('statuslancamento').patchValue('CONFIRMADO');
          this.filterToogle['conciliado'] = false;
        } else {
          this.CrudForm.get('statuslancamento').patchValue('PENDENTE');
          this.filterToogle['conciliado'] = false;
        }
        break;
      case 'conciliado':
        if (this.filterToogle[opcao]) {
          this.filterToogle['confirmado'] = true;
          this.CrudForm.get('statuslancamento').patchValue('CONCILIADO');
        } else {
          this.CrudForm.get('statuslancamento').patchValue('CONFIRMADO');
          this.filterToogle['confirmado'] = true;
        }
        break;
    }
  }

  onFilterAlarm($event: MouseEvent, value: number): void {
    $event.stopImmediatePropagation();
    if (!value || value === 0) {
      this.filterToogle['alarm'] = false;
      this.filterToogle['alarmval'] = 0;
    } else {
      this.filterToogle['alarm'] = true;
      this.filterToogle['alarmval'] = value;
    }
  }

  onDocumentoAnexo($event: MouseEvent, opcao: 'irpara' | 'novo' | 'download'): void {
    $event.stopImmediatePropagation();
    switch (opcao) {
      case 'irpara':
        this.router.navigate(['/doc/documento/action/grid']);
        this.OnCloseModal(false);
        break;
      case 'novo':
        this.criaNovoDocumento();
        break;
      case 'download':
        break;
    }
  }

  criaNovoDocumento(): void {
    const modalRef = Utilities.OpenNewModalWindow(
      this.modalService,
      DocumentoActionAddComponent,
      (message: CloseModalResult) => {
        //console.log('NOVO DOCUMENTO REGISTRO:', message);
        if (message.ok && message.registro) {
          const arquivo: TbDocumentBean = message.registro;
          if (arquivo.id) {
            const listaarquivos: number[] = this.CrudForm.get('listaarquivos').value
              ? this.CrudForm.get('listaarquivos').value
              : [];
            listaarquivos.push(arquivo.id);
            this.CrudForm.get('listaarquivos').patchValue(listaarquivos);
          }
          const reg = this.CrudFormToRegistro();
          //console.log('NOVO DOCUMENTO REGISTRO:', message, reg);
        }
      },
    );
    const view = modalRef.componentInstance;
    if (view.parameters) {
      view.parameters = new BagParameterObject().setTextById('title', 'teste');
    }
  }

  // #endregion
}
