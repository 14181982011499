// {{GENERATE}}
import { Injectable } from '@angular/core';
import { BrokerModuleProfileBean } from './BrokerModuleProfileBean';
import { SystemModuleEnum } from 'src/app/modules/admin/models/SystemModuleEnum';
import { BagParameterObject } from './BagParameter';

export interface BrokerProfileBean {
  iduser?: number;
  items?: BrokerModuleProfileBean[];
}

// #region #declarations#.
@Injectable()
export class BrokerProfileHelper {
  public static FindProfile(dados: BrokerProfileBean, module: SystemModuleEnum): BagParameterObject {
    for (const bean of dados.items) {
      if (bean) {
        if (bean.module == module) {
          return new BagParameterObject(bean.parameters);
        }
      }
    }
    return new BagParameterObject();
  }
}
// #endregion
