<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form -->

  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-tipoconta -->
    <div class="col-sm-6 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['tipoconta']" class="form-label" l10nTranslate>ContaBeanFragment.tipoconta</label>
      <ng-select #tipoconta formControlName="tipoconta" class="w-ctrl" [searchable]="false" [hideSelected]="true">
        @for (item of tipocontaList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoContaEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipoconta -->
    <!-- #I-#field-moeda -->
    <div class="col-sm-6 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['moeda']" class="form-label" l10nTranslate>ContaBeanFragment.moeda</label>
      <div class="d-flex">
        <div class="w-100 ng-select-group">
          <ng-select
            #moeda
            formControlName="moeda"
            class="w-ctrl"
            [items]="moedaList"
            bindValue="id"
            bindLabel="name"
            [searchable]="true"
            [searchFn]="CustomSearchFn"
            (search)="OnSearch($event)"
            [hideSelected]="false"
          >
            <ng-template ng-label-tmp let-item="item"
              ><span>{{ item.name }}</span></ng-template
            >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index"
              ><span>{{ item.name }}</span></ng-template
            >
          </ng-select>
        </div>
        <div class="ms-auto ng-select-append xcontainer">
          <img class="xcontainer-img" [src]="MoedaIconeUrl()" alt="" title="" />
        </div>
      </div>
    </div>
    <!-- #E-#field-moeda -->
    <!-- #I-#field-nome -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['nome']" class="form-label" l10nTranslate>ContaBeanFragment.nome</label>
      <div class="input-group">
        <input
          type="text"
          [id]="fieldIdTable['nome']"
          formControlName="nome"
          autocomplete="on"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.nome.invalid && (f.nome.dirty || f.nome.touched) }"
          maxlength="80"
        />
        <span class="input-group-text xcontainer" role="button" (click)="SelecionaIcone()">
          <img class="xcontainer-img" [src]="ContaIconeUrl()" alt="" title="" />
        </span>
      </div>
      @if (f.nome.errors) {
        <div class="invalid-feedback">
          @if (f.nome.errors.required) {
            <div l10nTranslate>ContaBeanFragment.nome-REQUIRED</div>
          }
          @if (f.nome.errors.maxlength) {
            <div l10nTranslate>ContaBeanFragment.nome-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-nome -->
    <!-- #I-#field-idiconeconta -->
    <div class="col-sm-12 col-md-6 mb-3 d-none">
      <label [for]="fieldIdTable['idiconeconta']" class="form-label" l10nTranslate
        >ContaBeanFragment.idiconeconta</label
      >
      <input
        type="text"
        [id]="fieldIdTable['idiconeconta']"
        formControlName="idiconeconta"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.idiconeconta.invalid && (f.idiconeconta.dirty || f.idiconeconta.touched) }"
      />
    </div>
    <!-- #E-#field-idiconeconta -->
    <!-- #I-#field-datasaldoinicial -->
    <div class="col-sm-6 col-md-6 mb-3 required">
      <app-tx-date-picker-fragment
        #vdatasaldoinicial
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('datasaldoinicial')"
        [Title]="'ContaBeanFragment.datasaldoinicial'"
      ></app-tx-date-picker-fragment>
    </div>
    <!-- #E-#field-datasaldoinicial -->
    <!-- #I-#field-saldoinicial/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <app-saldo-financeiro-bean-fragment
        #vsaldoinicial
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('saldoinicial')"
        [Title]="'ContaBeanFragment.saldoinicial'"
      ></app-saldo-financeiro-bean-fragment>
    </div>
    <!-- #E-#field-saldoinicial -->
    <!-- #I-#field-tipodisponibilidadesaldo -->
    <div class="col-sm-6 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['tipodisponibilidadesaldo']" class="form-label" l10nTranslate
        >ContaBeanFragment.tipodisponibilidadesaldo</label
      >
      <ng-select
        #tipodisponibilidadesaldo
        formControlName="tipodisponibilidadesaldo"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipodisponibilidadesaldoList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>DisponibilidadeSaldoEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipodisponibilidadesaldo -->
    <!-- #I-#field-participabalancopatrimonial -->
    <div class="col-sm-6 col-md-6 mb-3">
      <div class="form-check form-switch">
        <input
          type="checkbox"
          formControlName="participabalancopatrimonial"
          class="form-check-input"
          [id]="fieldIdTable['ck-participabalancopatrimonial']"
          (change)="OnParticipabalancopatrimonialChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-participabalancopatrimonial']" l10nTranslate
          >ContaBeanFragment.participabalancopatrimonial</label
        >
      </div>
    </div>
    <!-- #E-#field-participabalancopatrimonial -->
    <!-- #I-#field-contabanco -->

    <div class="w-100" [ngClass]="{ 'd-none': !TemContaCorrente() }">
      <div class="d-flex">
        <a
          class="ms-auto btn btn-link"
          data-bs-toggle="collapse"
          href="#collapse-banco"
          role="button"
          aria-expanded="false"
          aria-controls="collapse-banco"
          (click)="toogle_conta_banco = !toogle_conta_banco"
        >
          {{ toogle_conta_banco ? '-' : '+' }} {{ 'ContaBeanFragment.dadosbancarios' | translate: locale.language }}
        </a>
      </div>
      <div class="collapse" id="collapse-banco">
        <app-conta-banco-bean-fragment
          #vcontabanco
          [EditMode]="IsEditMode()"
          [CrudForm]="CrudForm.get('contabanco')"
        ></app-conta-banco-bean-fragment>
      </div>
    </div>

    <!-- #E-#field-contabanco -->
    <!-- #I-#field-cartaocredito -->
    <div class="w-100" [ngClass]="{ 'd-none': !TemCartaoDeCredito() }">
      <app-cartao-credito-bean-fragment
        #vcartaocredito
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('cartaocredito')"
      ></app-cartao-credito-bean-fragment>
    </div>
    <!-- #E-#field-cartaocredito -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
<!--
-->
