// {{GENERATE}}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { TbDocumentBean } from '../../models/TbDocumentBean';
import { DocumentoActionService } from '../documento-action.service';

@Injectable()
export class DocumentoActionImplService implements DocumentoActionService {
  // #region #constructor.
  // CODGEN {{R}}
  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
  ) {}
  // #endregion

  // #region #getalldoc.
  // CODGEN {{R}}
  GetAllDoc(par: string): Observable<TbDocumentBean[]> {
    const url = this.helper.ToURL('/documentos/action/getAllDoc');
    const params = new HttpParams().append('par', par);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<TbDocumentBean[]>(url, headers);
  }
  // #endregion

  // #region #crud#createdoc.
  // CODGEN {{R}}
  CreateDoc(bean: TbDocumentBean): Observable<TbDocumentBean> {
    const url = this.helper.ToURL('/documentos/action/createDoc');
    const headers = this.helper.GetHeaders();
    return this.http.post<TbDocumentBean>(url, bean, headers);
  }
  // #endregion

  // #region #crud#updatedoc.
  // CODGEN {{R}}
  UpdateDoc(bean: TbDocumentBean): Observable<void> {
    const url = this.helper.ToURL('/documentos/action/updateDoc');
    const headers = this.helper.GetHeaders();
    return this.http.put<void>(url, bean, headers);
  }
  // #endregion

  // #region #crud#getreaddoc.
  // CODGEN {{R}}
  GetReadDoc(id: number): Observable<TbDocumentBean> {
    const url = this.helper.ToURL('/documentos/action/readDoc');
    const params = new HttpParams().append('id', id.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.get<TbDocumentBean>(url, headers);
  }
  // #endregion

  // #region #crud#removedoc.
  // CODGEN {{R}}
  RemoveDoc(id: number): Observable<void> {
    const url = this.helper.ToURL('/documentos/action/removeDoc');
    const params = new HttpParams().append('id', id.toString());
    const headers = this.helper.GetHeaders(params);
    return this.http.delete<void>(url, headers);
  }
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/documentos/action/selectoptions');
    const params = new HttpParams().append('par', par).append('opcao', opcao);
    const headers = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, headers);
  }
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList> {
    const url = this.helper.ToURL('/documentos/action/cmdselectlist');
    const headers = this.helper.GetHeaders();
    return this.http.post<SelectOptionList>(url, bean, headers);
  }
  // #endregion

  // #region #declarations#.
  // #endregion
}
