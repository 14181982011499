import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { CpfCnpjValidator } from './cpf-cnpj.validator';

@Directive({
  selector: '[appCpfCnpjValidate][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CpfCnpjValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class CpfCnpjValidatorDirective extends CpfCnpjValidator implements Validator {}
