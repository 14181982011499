import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BagParameter } from '../models/BagParameter';

export interface MessageEvent {
  name?: string;
  parameter?: BagParameter;
}

@Injectable()
export class MessageService {
  private subject = new Subject<MessageEvent>();

  constructor() {}

  getSubject(): Observable<MessageEvent> {
    return this.subject.asObservable();
  }

  refresh(event: MessageEvent) {
    this.subject.next(event);
  }

  public static CATEGORIA_SERVICE = 'Categoria.Arvore';
}
