// {{GENERATE}}
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { BagParameter, BagParameterObject } from 'src/app/core/models/BagParameter';
import { CrudModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { CategoriaBean } from '../../../models/CategoriaBean';
import { CategoriaActionService } from '../../../services/categoria-action.service';
import { CategoriaBeanFragmentComponent } from '../categoria-bean/categoria-bean-fragment.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-categoria-action-add',
  templateUrl: './categoria-action-add.component.html',
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    CategoriaBeanFragmentComponent,
  ],
})
export class CategoriaActionAddComponent implements CrudModelComponent, OnInit, AfterViewInit {
  // #region #properties#.
  private parameters: BagParameter;
  // #endregion

  private rootformid: string;
  private myname: string;
  public contexto = {};
  private instanceId = Constants.GetId();
  protected fieldIdTable = {};
  CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  registro: CategoriaBean;
  masks = Constants;
  @ViewChild('vcategoria', { static: true }) childCategoria: CategoriaBeanFragmentComponent;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: CategoriaActionService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public translation: L10nTranslationService,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onsubmit.
  // CODGEN
  onSubmit(opcao = '') {
    // stop here if form is invalid
    if (this.CrudForm.invalid) {
      return;
    }
    this.registro = Object.assign({}, this.registro, this.CrudFormToRegistro());
    if (this.viewType === ViewTypeEnum.ADD) {
      this.crudService.CreateCategoria(this.registro).subscribe({
        next: () => {
          this.OnCloseModal(true, opcao === '+');
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    } else {
      this.crudService.UpdateCategoria(this.registro).subscribe({
        next: () => {
          this.OnCloseModal(true);
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    }
  }
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN
  OnInitInvokeService() {
    if (this.viewType === ViewTypeEnum.READ) {
      this.crudService.GetReadCategoria(this.registro.id).subscribe({
        next: (data) => {
          try {
            this.registro = data;
            this.CrudForm.patchValue(this.registro);
            this.childCategoria.SetValoresDefault();
          } catch (error) {
            console.log('erro:', error);
            this.alertService.error('Erro no carregamento dos dados.' + error);
          }
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
    } else {
      try {
        const par = new BagParameterObject(this.parameters);
        const vreg = {
          tipo: par.getTextById('tipo.financeiro', null),
          nome: '',
          idsubcategoriade: par.getTextById('id_pai.financeiro', null),
        };
        setTimeout(() => {
          this.CrudForm.patchValue(vreg);
          this.childCategoria.SetValoresDefault();
        }, 50);
      } catch (error) {
        console.log('erro:', error);
        this.alertService.error('Erro no carregamento dos dados.');
      }
    }
  }
  // #endregion

  IsEditMode(): boolean {
    return this.viewType === ViewTypeEnum.READ;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      registro: this.registro,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return this.childCategoria.CrudFormValidators(this.rootformid);
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {
    this.childCategoria.CrudFormGroupValidators(validators);
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    return this.childCategoria.CrudFormToRegistro();
  }
  // #endregion

  // #region #routine-definitions#.
  OnAddModalParameters(parameters: BagParameter): void {
    this.parameters = parameters;
  }
  // #endregion
}
