// {{GENERATE}}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { ContasActionService } from '../../../services/contas-action.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-select-icon-modal',
  templateUrl: './select-icon-modal.component.html',
  styleUrls: ['./select-icon-modal.component.css'],
  imports: [ReactiveFormsModule, L10nTranslateDirective, L10nTranslatePipe, NgSelectModule, NgbModule],
  providers: [],
})
export class SelectIconModalComponent implements OnInit {
  // #region #properties#.
  listaImagens: SelectOptionItem[] = [];
  ultimaSelecao: string = '';
  // #endregion

  CrudForm: FormGroup;
  masks = Constants;
  private instanceId = Constants.GetId();
  isModal: boolean = false;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private crudService: ContasActionService,
    private httpHelper: HttpHelper,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN
  ngOnInit(): void {
    this.CrudForm = this.formBuilder.group({});
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #onprocessar#method.
  // CODGEN {{R}}
  OnProcessar(): void {
    if (this.CrudForm.invalid) {
      return;
    }
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      repeat: repeat,
      selecao: this.ultimaSelecao,
    });
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {};
    return reg;
  }
  // #endregion

  // #region #opennewmodal.
  // CODGEN {{R}}
  static OpenNewModal(modalService: NgbModal): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, SelectIconModalComponent);
    const view: SelectIconModalComponent = modalRef.componentInstance;
    view.isModal = true;
    return modalRef;
  }
  // #endregion

  // #region #routine-definitions#.

  OnInitInvokeService() {
    /**
     * Carrega a Lista de icones e chama o OnInitInvokeService() do pai.
     *
     */
    this.crudService.GetSelectOptions('LISTA-ICONES', '').subscribe({
      next: (data) => {
        try {
          this.listaImagens = [...data.items];
          if (this.listaImagens) {
            const url = this.httpHelper.ToURL(data.name);
            for (let i = 0; i < this.listaImagens.length; i++) {
              this.listaImagens[i].tag = url + '/' + this.listaImagens[i].name;
            }
          } else {
            this.listaImagens = [];
          }
        } catch (error) {
          console.log('erro:', error);
          this.alertService.error('Erro no carregamento dos dados.');
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  SelecionaIcone(nome: string): void {
    console.log('SELECIONADO:', nome);
    this.ultimaSelecao = nome;
    this.OnCloseModal();
  }
  // #endregion
}
