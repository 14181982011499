function SidebarCollapse() {
  //console.log('SidebarCollapse');
  if (isMasterMenuLocked()) {
    showMasterMenu();
  } else {
    hideMasterMenu();
  }
}

function StartMenu() {
  // Hide submenus
  $('#body-row .collapse').collapse('hide');
  $('#navbtn_toggler')
    .off('click', '**')
    .on('click', function (event) {
      event.preventDefault();
      if (isMasterMenuLocked()) {
        showMasterMenu();
      } else {
        ToggleMasterMenu();
      }
    });
  $('.action-router-menu-item')
    .off('click', '**')
    .on('click', function () {
      SidebarCollapse();
    });
  SidebarCollapse();
  //console.log('StartMenu - foi ativado', 'Locked:', isMasterMenuLocked());
}

/**
 * Movendo o modal Bootstrap na página
 */
function StartModalFlutuante() {
  //console.log('StartModalFlutuante - foi ativado');
  $('.modal').modal({ keyboard: false, show: true });
  // Jquery draggable
  $('.modal-dialog').draggable({
    handle: '.modal-header',
    cursor: 'move',
    stack: '.modal-dialog',
    grid: [10, 10],
  });
}

function isMasterMenuLocked() {
  // fa fa-thumb-tack fa-rotate-90
  return !$('#sidebar-icon').hasClass('fa-rotate-90');
}

function showMasterMenu() {
  $('#aside-container').removeClass('d-none');
  $('#id-home-content').addClass('home-content-margin');
  //console.log('ShowMasterMenu - visible');
}

function hideMasterMenu() {
  $('#aside-container').addClass('d-none');
  $('#id-home-content').removeClass('home-content-margin');
  //console.log('HideMasterMenu - invisible');
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ToggleMasterMenu() {
  if ($('#aside-container').hasClass('d-none')) {
    showMasterMenu();
  } else {
    hideMasterMenu();
  }
}

function ResetWidthOfGrids() {
  const a2 = $('#principal-container').find('.datatable-body');
  if (a2) {
    a2.scrollLeft(1);
  }
}

function ResetInputFile() {
  $('input[type=file]').val('');
}

function AutoCompleteInputOff() {
  // console.log('autocmplete off');
  $('input').attr('autocomplete', 'new-password');
  $('form').attr('autocomplete', 'new-password');
}

function RearrageDivsByOrder(divname, order) {
  // 	<div id="container">
  //     <div id="div1">1</div>
  //     <div id="div2">2</div>
  //     <div id="div3">3</div>
  //     <div id="div4">4</div>
  // </div>

  var container = document.getElementById(divname);
  //var divs = container.getElementsByTagName('div');
  var tmpdiv = document.createElement('div');
  //order = [2, 1, 3, 4];

  for (var i = 0; i < order.length; i++) {
    tmpdiv.appendChild(document.getElementById(order[i]));
  }

  container.parentNode.replaceChild(tmpdiv, container);
}

function showEyeSlash(fcn, x) {
  const icon = $(fcn);
  if (x.attr('type') == 'password') {
    icon.removeClass('fa-eye-slash').addClass('fa-eye');
    x.attr('type', 'text');
  } else {
    icon.removeClass('fa-eye').addClass('fa-eye-slash');
    x.attr('type', 'password');
  }
}
