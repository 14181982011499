import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MenuTree } from '../models/MenuTree';
import { MenuTypeEnum } from '../models/MenuTypeEnum';
import { UserFavoritoBean } from '../models/UserFavoritoBean';
import { AlertService } from './alert.service';
import { AuthenticationService } from './authentication.service';
import { Utilities } from './utilities';

export enum FavoritoType {
  MENU_TREE = 'menu-tree',
}

export interface FavoritosEvent {
  cmd: FavoritoType;
  lista?: MenuTree[];
}

@Injectable()
export class FavoritosService {
  public static MENU_TREE = 'menu-tree';
  public menuTree: MenuTree[] = [];
  private favoritos = new Subject<FavoritosEvent>();

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) {}

  public getFavoritosEvent(): Observable<FavoritosEvent> {
    return this.favoritos.asObservable();
  }

  /**
   * é atualizado no login
   * @param tree Publica um novo MenuTree
   */
  public RefreshMenuTree(tree: MenuTree[]) {
    this.menuTree = tree;
    this.favoritos.next({
      cmd: FavoritoType.MENU_TREE,
      lista: this.GetPreferencias(),
    });
  }

  public OnToggle(tree: MenuTree): void {
    if (tree.param) {
      this.updateFavorito(tree.param);
    }
  }
  /**
   * Atualiza o Servidor com o item selecionado de preferência
   * @param param
   */
  private updateFavorito(param: string): void {
    const params: string[] = [];
    this.TogglePreference(param, this.menuTree, params);
    const request: UserFavoritoBean = {
      hrefs: [],
    };
    params.forEach((p) => {
      request.hrefs.push({ tipo: 'M', href: p });
    });
    this.authenticationService.PostUpdateUserFavoritos(request).subscribe({
      next: () => {
        this.favoritos.next({
          cmd: FavoritoType.MENU_TREE,
          lista: this.GetPreferencias(),
        });
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  private TogglePreference(param: string, tree: MenuTree[], params: string[]): void {
    if (tree) {
      tree.forEach((o) => {
        if (o.type === MenuTypeEnum.ACTION) {
          if (o.pref && o.param === param) {
            o.userpref = !o.userpref;
          }
          if (o.pref && o.userpref) {
            params.push(o.param);
          }
        }
        if (o.children) {
          this.TogglePreference(param, o.children, params);
        }
      });
    }
  }

  public GetPreferencias(): MenuTree[] {
    if (!this.menuTree || this.menuTree.length === 0) {
      return [];
    }
    const favoritos: MenuTree[] = this.ToPlainList(this.menuTree).filter(
      (o) => o.type === MenuTypeEnum.ACTION && o.pref && o.userpref,
    );
    const sortedlist = this.SortItens(favoritos);
    // Adiciona a página '/home' ao início da lista, se não estiver presente
    const home = sortedlist.find((x) => x.href === '/home')
      ? null
      : this.ToPlainList(this.menuTree).find((x) => x.href === '/home');
    return home ? [home, ...sortedlist] : sortedlist;
  }

  private ToPlainList(tree: MenuTree[]): MenuTree[] {
    let result: MenuTree[] = [];
    if (tree) {
      tree.forEach((o) => {
        result.push(o);
        if (o.children) {
          result = result.concat(this.ToPlainList(o.children));
        }
      });
    }
    return result;
  }

  private SortItens(favoritos: MenuTree[]): MenuTree[] {
    if (!favoritos) {
      return [];
    }
    return favoritos.sort((t1, t2) => {
      const name1 = Utilities.accentedStrip(t1.title?.toLowerCase() || '');
      const name2 = Utilities.accentedStrip(t2.title?.toLowerCase() || '');
      return name1.localeCompare(name2);
    });
  }
}
