// {{GENERATE}}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { TablePainelContext } from 'src/app/core/helpers/TablePainelContext';
import { Constants } from 'src/app/core/helpers/constants';
import { ConfigOpcoesActionTabComponent } from './config-opcoes-action-tab.component';
import { SelectOptionUtils } from 'src/app/core/helpers/SelectOptionUtils';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AlertService } from 'src/app/core/services/alert.service';
import { SelectOptionService } from 'src/app/core/services/select-option.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-config-opcoes-action-paineltab',
  templateUrl: './config-opcoes-action-paineltab.component.html',
  imports: [L10nTranslateDirective, L10nTranslatePipe, NgbModule, ReactiveFormsModule, ConfigOpcoesActionTabComponent],
})
export class ConfigOpcoesActionPaineltabComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  // #endregion

  private instanceId = Constants.GetId();
  private rootformid: string;
  private myname: string;
  public contexto = {};
  public active = 1;
  ContextData: TablePainelContext = new TablePainelContext();
  @ViewChild('vsmtp', { static: true }) childSmtp: ConfigOpcoesActionTabComponent;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private optionService: SelectOptionService,
  ) {
    //
  }
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    console.log('ngOnInit');
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {
    if (this.childSmtp) {
      this.childSmtp.OnInitInvokeService();
    }
  }
  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #ontabbeforechange.
  // CODGEN {{R}}
  OnTabBeforeChange($event: NgbNavChangeEvent): void {
    setTimeout(() => {
      this.OnTabBeforeChangeTimed($event);
    });
  }
  // #endregion

  // #region #ontabbeforechangetimed.
  // CODGEN {{R}}
  OnTabBeforeChangeTimed($event: NgbNavChangeEvent): void {
    if ($event.nextId === this.GetId('tab-smtp')) {
      this.childSmtp.OnTabBeforeChange($event);
    }
  }
  // #endregion

  // #region #onsearch.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnSearch(item): void {}
  // #endregion

  // #region #customsearchfn.
  // CODGEN {{R}}
  CustomSearchFn(term: string, item: SelectOptionItem): boolean | null {
    return SelectOptionUtils.SelectOptionItemSearchFn(term, item);
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
