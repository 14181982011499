// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { ConfUtilizacaoBean } from '../models/ConfUtilizacaoBean';
import { ConfUtilizacaoBroker } from '../models/ConfUtilizacaoBroker';

@Injectable()
export abstract class ConfUtilizacaoActionService {
  // #region #getallconfiguracao.
  // CODGEN {{R}}
  abstract GetAllConfiguracao(par: string): Observable<ConfUtilizacaoBean[]>;
  // #endregion

  // #region #crud#createconfiguracao.
  // CODGEN {{R}}
  abstract CreateConfiguracao(bean: ConfUtilizacaoBean): Observable<ConfUtilizacaoBean>;
  // #endregion

  // #region #crud#updateconfiguracao.
  // CODGEN {{R}}
  abstract UpdateConfiguracao(bean: ConfUtilizacaoBean): Observable<void>;
  // #endregion

  // #region #crud#getreadconfiguracao.
  // CODGEN {{R}}
  abstract GetReadConfiguracao(idutilizacao: number): Observable<ConfUtilizacaoBean>;
  // #endregion

  // #region #crud#removeconfiguracao.
  // CODGEN {{R}}
  abstract RemoveConfiguracao(idutilizacao: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region metodo#get-conf-utilizacao-broker.
  // CODGEN {{R}}
  abstract GetConfUtilizacaoBroker(): Observable<ConfUtilizacaoBroker>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
