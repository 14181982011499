// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { TagBean } from '../models/TagBean';

@Injectable()
export abstract class TagActionService {
  // #region #getalltag.
  // CODGEN {{R}}
  abstract GetAllTag(par: string): Observable<TagBean[]>;
  // #endregion

  // #region #crud#createtag.
  // CODGEN {{R}}
  abstract CreateTag(bean: TagBean): Observable<TagBean>;
  // #endregion

  // #region #crud#updatetag.
  // CODGEN {{R}}
  abstract UpdateTag(bean: TagBean): Observable<void>;
  // #endregion

  // #region #crud#getreadtag.
  // CODGEN {{R}}
  abstract GetReadTag(idtag: number): Observable<TagBean>;
  // #endregion

  // #region #crud#removetag.
  // CODGEN {{R}}
  abstract RemoveTag(idtag: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
