// {{GENERATE}}
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';

export const enum TipoRepeticaoEnum {
  UNICA = 'UNICA',
  PARCELADA = 'PARCELADA',
  FIXA = 'FIXA',
}

// #region optionlist#method.
// CODGEN {{R}}
export class TipoRepeticaoEnumRepo {
  static optionList(): SelectOptionItem[] {
    return [
      { id: 'UNICA', name: 'UNICA' },
      { id: 'PARCELADA', name: 'PARCELADA' },
      { id: 'FIXA', name: 'FIXA' },
    ];
  }
}
// #endregion

// #region #declarations#.
// #endregion
