import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalDateType } from '../models/local-date-type';

@Component({
  selector: 'app-month-year-picker',
  standalone: true,
  imports: [NgbTooltipModule, NgbModule],
  templateUrl: './month-year-picker.component.html',
  styleUrls: ['./month-year-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthYearPickerComponent),
      multi: true,
    },
  ],
})
export class MonthYearPickerComponent implements ControlValueAccessor, OnInit {
  @Output() onEventChange = new EventEmitter<LocalDateType>();
  date: NgbDateStruct;
  value: LocalDateType;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor() {}

  ngOnInit(): void {
    this.setTodayValues();
  }

  writeValue(value: string): void {
    if (value) {
      const [month, year] = value.split('/').map(Number);
      this.date = { year, month, day: 1 };
      this.value = value;
    } else {
      this.setTodayValues();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Add code to handle the disabled state if necessary
  }

  setTodayValues(): void {
    const today = new Date();
    this.date = { year: today.getFullYear(), month: today.getMonth() + 1, day: 1 };
    this.updateValue();
  }

  onPrevMonth() {
    if (this.date.month === 1) {
      this.date.month = 12;
      this.date.year -= 1;
    } else {
      this.date.month -= 1;
    }
    this.updateValue();
  }

  onNextMonth() {
    if (this.date.month === 12) {
      this.date.month = 1;
      this.date.year += 1;
    } else {
      this.date.month += 1;
    }
    this.updateValue();
  }

  private updateValue() {
    this.value = this.formatToLocalDateType(this.date);
    if (this.onChange) {
      this.onChange(this.value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
    if (this.onEventChange) {
      this.onEventChange.emit(this.value);
    }
  }

  private formatToLocalDateType(date: NgbDateStruct | string | null): LocalDateType | string {
    if (!date) return '';
    if (this.isNgbDateStruct(date)) {
      const day = date.day.toString().padStart(2, '0');
      const month = date.month.toString().padStart(2, '0');
      const year = date.year.toString();
      return `${year}-${month}-${day}`;
    }
    return date;
  }

  private isNgbDateStruct(obj: any): obj is NgbDateStruct {
    return obj && typeof obj.year === 'number' && typeof obj.month === 'number' && typeof obj.day === 'number';
  }

  get monthYear(): string {
    return `${this.month} ${this.year}`;
  }

  get month(): string {
    const months = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    return `${months[this.date.month - 1]}`;
  }

  get year(): string {
    return `${this.date.year}`;
  }

  doClickDataAtual(): void {
    this.setTodayValues();
  }
}
