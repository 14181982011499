import { ErrorHandler, Injectable } from '@angular/core';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AppExceptionHandler implements ErrorHandler {
  handleError(error): void {
    if (error instanceof HttpErrorResponse) {
      console.error('Backend returned status code: ', error.status);
      console.error('Response body:', error.message);
    } else {
      console.error('An error occurred:', error.stack);
    }
  }
}

/*
@Injectable({ providedIn: 'root' })
export class Jwt401Interceptor implements HttpInterceptor {
  constructor(private alertService: AlertService,private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((e) => {
        this.alertService.error('QQQQQQQQQQQQQQQQQQQQQQ', e);

        return throwError(() => e);
      }),
    );
  }
}
*/
