<!-- {{NOTGENERATE}} -->
<div>
  <div class="row mb-3">
    <div class="col-sm-12">
      <button type="button" class="btn btn-light" (click)="addDetalhe()" [disabled]="isDisabled">
        <i class="fa fa-plus" aria-hidden="true"></i> Adicionar
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      @for (detalhe of detalhes; track detalhe; let i = $index) {
        <div>
          <div class="d-flex align-items-center mb-1">
            <div class="pe-1">{{ displayField(detalhe, 'descricao') }}</div>
            <div class="flex-fill d-flex flex-wrap align-items-center pe-1">
              @for (node of detalhe.relatedentities; track node.key) {
                <button
                  type="button"
                  class="btn pill-button pill-ellipsis"
                  (click)="openEntity(node)"
                  [style]="getButtonStyle(node)"
                  [disabled]="isDisabled"
                >
                  {{ node.descricao }}
                </button>
              }
            </div>
            <div class="pe-1">{{ displayField(detalhe, 'valor') }}</div>
            <div style="min-width: 7rem">
              <button type="button" class="btn btn-light ms-1" (click)="editDetalhe(i)" [disabled]="isDisabled">
                <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-light ms-1" (click)="removeDetalhe(i)" [disabled]="isDisabled">
                <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12"><hr /></div>
    <div class="col-sm-12">
      <div class="d-flex align-items-center mb-1">
        <div class="flex-fill text-end fw-bold pe-2">Valor total: {{ lastValorTotal | currency: NomeMoeda }}</div>
        @if (lastDiferenca !== 0) {
          <div class="fw-bold pe-2" [class.invalid-color]="isDiferencaValor()">
            diferença a detalhar: {{ lastDiferenca | currency: NomeMoeda }}
          </div>
        }
        <div style="min-width: 7rem"></div>
      </div>
    </div>
  </div>
</div>
