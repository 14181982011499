// {{GENERATE}}
import { Injectable } from '@angular/core';
import { BagParameterObject } from 'src/app/core/models/BagParameter';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { ProfileFinanceiroKeys } from './ProfileFinanceiroKeys';
import { StatusEnum } from 'src/app/core/models/StatusEnum';

export interface MoedaBean {
  idmoeda?: number;
  status?: StatusEnum;
  nome?: string;
  simbolo?: string;
  idiconemoeda?: string;
  descricao?: string;
}

// #region #declarations#.
@Injectable()
export class MoedaHelper {
  public static FindMoeda(moedaList: SelectOptionItem[], moeda): MoedaBean {
    if (moedaList && moedaList.length > 0) {
      for (const item of moedaList) {
        if (item.id == moeda) {
          return JSON.parse(item.tag);
        }
      }
    }
    return {};
  }

  public static GetMoeda(bag: BagParameterObject): MoedaBean {
    const json = bag.getTextById(ProfileFinanceiroKeys.MOEDA_PADRAO);
    if (!json) {
      return {};
    }
    return JSON.parse(json);
  }
}
// #endregion
