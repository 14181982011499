import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { L10nTranslateDirective } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { MeusDadosFormComponent } from 'src/app/modules/admin/components/user/meus-dados-form/meus-dados-form.component';
import { Avatar, AvatarDados } from '../../models/AvatarDados';
import { MenuTree } from '../../models/MenuTree';
import { MenuTypeEnum } from '../../models/MenuTypeEnum';
import { FavoritoType, FavoritosService } from '../../services/favoritos.service';
import { SelectOptionService } from '../../services/select-option.service';
import { TitlePanelService, TitlePanelType } from '../../services/title-panel.service';
import { AvatarComponent } from '../avatar/avatar.component';
import { AlertComponent } from './alert.component';

declare function StartMenu();

@Component({
  selector: 'app-header-principal',
  templateUrl: './header-principal.component.html',
  styleUrls: ['./header-principal.component.css'],
  standalone: true,
  imports: [
    NgbDropdown,
    AvatarComponent,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    RouterLink,
    L10nTranslateDirective,
    AlertComponent,
  ],
})
export class HeaderPrincipalComponent implements OnInit, OnDestroy, AfterViewInit {
  userName: string;
  userImage: string;
  avatar: Avatar;
  headerTitlsPrefixo = '';
  headerTitle2 = `${this.headerTitlsPrefixo}`;
  favoritosList: MenuTree[] = [];
  searchOptionList = [];
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições

  constructor(
    private helper: HttpHelper,
    private router: Router,
    private titleService: TitlePanelService,
    private modalService: NgbModal,
    private favoritosService: FavoritosService,
    private optionsService: SelectOptionService,
  ) {
    this.avatar = { name: '', image: '', size: 40, timestamp: 0 };
  }

  ngOnInit() {
    const subscription = this.helper.getObservableUser().subscribe({
      next: () => {
        this.AtualizaDadosDoUsuario();
      },
    });
    this.subscriptions.push(subscription);
    this.AtualizaDadosDoUsuario();
    //
    const subscription2 = this.titleService.getEvents().subscribe({
      next: (evt) => {
        this.optionsService.Clear();
        switch (evt.type) {
          case TitlePanelType.header_title_2:
            this.headerTitle2 = `${this.headerTitlsPrefixo} - ${evt.title}`;
            break;
          case TitlePanelType.breadcrumbs:
            if (evt.breadcrumbs) {
              this.TrataBreadcrumbs(evt.breadcrumbs);
            }
            break;
        }
      },
    });
    this.subscriptions.push(subscription2);
    this.favoritosList = this.favoritosService.GetPreferencias();
    const subscription3 = this.favoritosService.getFavoritosEvent().subscribe((dados) => {
      if (dados.cmd === FavoritoType.MENU_TREE) {
        this.favoritosList = dados.lista;
        this.atualiza();
      }
    });
    this.subscriptions.push(subscription3);
    this.atualiza();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    StartMenu();
  }

  OnAvatarClick() {
    //console.log('AVATAR CLICK');
  }

  TrataBreadcrumbs(bc: string[]): void {
    //console.log('----> BREAD: ', bc);
  }

  ClickPerfil() {
    MeusDadosFormComponent.OpenNewModal(this.modalService);
  }

  private AtualizaDadosDoUsuario(): void {
    /**
     * AVATAR
     */
    if (this.helper.IsActiveUser()) {
      this.avatar = AvatarDados.fromUser(this.helper.getUser());
    } else {
      this.avatar = AvatarDados.fromUser(null);
    }
    this.userName = this.avatar.name;
    this.userImage = this.avatar.image;
  }

  private atualiza(): void {
    const lista = [];
    this.favoritosList.forEach((o) => {
      if (o.type === MenuTypeEnum.ACTION) {
        lista.push({ id: o.param, name: o.title, icon: o.icon });
      }
    });
    this.searchOptionList = [...lista];
  }

  OnClickFavoritoClose(tree: MenuTree): void {
    this.favoritosService.OnToggle(tree);
  }

  OnClickFavoritoPref(tree: MenuTree): void {
    // console.log('MenuTree:', tree.title);
    if (tree.param && tree.param !== '') {
      this.router.navigate([tree.href]);
    } else {
      this.router.navigate([tree.href]);
    }
  }
}
