import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class Jwt401Interceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clonar a requisição e adicionar o token JWT atual (se disponível)
    const token = this.authService.getJwtToken();
    let authReq = req;

    if (token) {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // Passar a requisição adiante
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // Verificar se o erro é 401
        if (error.status === 401) {
          // Tentar obter um novo token e reexecutar a requisição original
          return this.handle401Error(req, next);
        }
        // Se não for 401, propagar o erro
        return throwError(() => error);
      }),
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Tentar renovar o token
    return this.authService.refreshToken().pipe(
      switchMap((newToken: string) => {
        // Se o token for renovado, clonar a requisição original com o novo token
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${newToken}`,
          },
        });
        // Reenviar a requisição com o novo token
        return next.handle(authReq);
      }),
      catchError((err) => {
        // Se houver falha na renovação do token, redirecionar para login
        this.authService.logout();
        //this.alertService.error('Sessão finalizada, favor renovar seu login.');
        this.router.navigateByUrl('/login');
        return throwError(() => 'Sessão finalizada, favor renovar seu login.');
      }),
    );
  }
}
