import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCpfCnpjMask]',
  standalone: true,
})
export class CpfCnpjMaskDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length <= 11) {
      value = value.substring(0, 11);
      input.value = this.formatCpf(value);
    } else {
      value = value.substring(0, 14);
      input.value = this.formatCnpj(value);
    }
  }

  private formatCpf(value: string): string {
    if (value.length <= 6) {
      return value.replace(/(\d{3})(\d{1,3})/, '$1.$2');
    } else if (value.length <= 9) {
      return value.replace(/(\d{3})(\d{3})(\d{1,3})/, '$1.$2.$3');
    } else {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
    }
  }

  private formatCnpj(value: string): string {
    if (value.length <= 7) {
      return value.replace(/(\d{2})(\d{1,3})(\d{1,3})/, '$1.$2.$3');
    } else if (value.length <= 12) {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1,4})/, '$1.$2.$3/$4');
    } else {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/, '$1.$2.$3/$4-$5');
    }
  }
}
