import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AdPainelDescription } from '../../models/AdPainelDescription';

import { AdDadosElement } from './ad-dados_element';
import { AdItem } from './ad-item';
import { HeroJobAdComponent } from './hero-job-ad.component';
import { VisaoGeralCardComponent } from 'src/app/modules/financeiro/components/painel/visao-geral-card/visao-geral-card.component';

export interface AdEvent {
  cmd?: 'CLEAR' | 'ADD';
  description?: AdPainelDescription;
}

@Injectable()
export class AdService {
  private eventSubject = new Subject<AdEvent>();

  // private adsList: AdItem[] = [];

  constructor() {}

  public getObservableEvent(): Observable<AdEvent> {
    return this.eventSubject.asObservable();
  }

  public static getAdsPainel(description: AdPainelDescription): AdItem[] {
    const lista: AdItem[] = [];
    if (description.cards) {
      let id = 1;
      description.cards.forEach((element) => {
        const data: AdDadosElement = { id: id, fragment: true, painel: description.href, card: element };
        switch (element.name) {
          // {{INSERIR-CODIGO-AQUI}}
          case 'hero':
            lista.push(new AdItem(HeroJobAdComponent, data));
            break;
          default:
            lista.push(new AdItem(VisaoGeralCardComponent, data));
            break;
        }
        id++;
      });
    }
    return lista;
  }

  // public clear(href: string | null = null): void {
  //this.adsList.splice(0, this.adsList.length);
  //   this.eventSubject.next({ cmd: 'CLEAR', description: { href: href } });
  //  }
  /*
  public add(description: AdPainelDescription): AdItem[] {
    const ads = AdService.getAdsPainel(description);
    if (ads) {
      ads.forEach((element) => {
        this.adsList.push(element);
      });
      this.eventSubject.next({ cmd: 'ADD', description: description });
    }
    return this.adsList;
  }
    */

  // public getAdsView(): AdItem[] {
  //   return this.adsList;
  // }
}
