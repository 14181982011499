<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form -->
  <div class="mb-3">
    <div class="t-header-title" l10nTranslate>MoedaPadraoSelecaoFragment.HEADERTITLE</div>
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-moeda -->
    <div class="col-sm-12 col-md-6 mb-3 required">
      <label [for]="fieldIdTable['moeda']" class="form-label" l10nTranslate>MoedaPadraoSelecaoFragment.moeda</label>
      <div class="d-flex">
        <div class="w-100 ng-select-group">
          <ng-select
            #moeda
            formControlName="moeda"
            class="w-ctrl"
            [items]="moedaList"
            bindValue="id"
            bindLabel="name"
            [searchable]="true"
            [searchFn]="CustomSearchFn"
            (search)="OnSearch($event)"
            [hideSelected]="false"
          >
            <ng-template ng-label-tmp let-item="item"
              ><span>{{ item.name }}</span></ng-template
            >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index"
              ><span>{{ item.name }}</span></ng-template
            >
          </ng-select>
        </div>
        <div class="ms-auto ng-select-append xcontainer">
          <img class="xcontainer-img" [src]="MoedaIconeUrl()" alt="" title="" />
        </div>
      </div>
    </div>
    <!-- #E-#field-moeda -->
    <!-- #I-#field-indconversao -->
    <div class="col-sm-12 col-md-12 mb-3" [class.d-none]="!IsTrocouMoeda()">
      @for (item of indconversaoRList; track item) {
        <div class="form-check form-check-inline">
          <input
            type="radio"
            formControlName="indconversao"
            class="form-check-input"
            [id]="fieldIdTable['ck-indconversao']"
            [value]="item.value"
          />
          <label class="form-check-label" [for]="fieldIdTable['ck-indconversao']">{{
            ToTranslateMoeda(item.value)
          }}</label>
        </div>
      }
    </div>
    <!-- #E-#field-indconversao -->
    <!-- #I-#field-senha -->
    <div class="col-sm-12 col-md-6 mb-3 required" [class.d-none]="!IsTrocouMoeda()">
      <label [for]="fieldIdTable['senha']" class="form-label" l10nTranslate>MoedaPadraoSelecaoFragment.senha</label>
      <input
        type="{{ showPassword ? 'text' : 'password' }}"
        [id]="fieldIdTable['senha']"
        formControlName="senha"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.senha.invalid && (f.senha.dirty || f.senha.touched) }"
        autocomplete="current-password"
        maxlength="50"
      />
      <span class="p-viewer">
        <i
          (click)="showPassword = !showPassword"
          [class]="showPassword ? 'fa fa-fw fa-eye' : 'fa fa-fw fa-eye-slash'"
        ></i>
      </span>
      @if (f.senha.errors) {
        <div class="invalid-feedback">
          @if (f.senha.errors.required) {
            <div l10nTranslate>MoedaPadraoSelecaoFragment.senha-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-senha -->
    <!-- #I-#end-row-one -->
    <div class="row mx-4">
      <div class="col">
        <button
          type="button"
          class="btn btn-primary"
          (click)="OnProcessar()"
          [disabled]="!(CrudForm.valid && IsTrocouMoeda())"
          l10nTranslate
        >
          <i class="fa fa-save"></i> ChangePasswordForm.BTN-ENTER
        </button>
      </div>
    </div>
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
