import { Directive, ElementRef, HostListener, Renderer2, Input, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appCharCount]',
  standalone: true,
})
export class CharCountDirective implements AfterViewInit {
  @Input('appCharCount') maxLength: number;
  private counterElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    this.createCounterElement();
    this.updateCounter();
  }

  @HostListener('input') onInput() {
    this.updateCounter();
  }

  private createCounterElement() {
    // Cria um elemento span para exibir o contador de caracteres
    this.counterElement = this.renderer.createElement('span');
    this.renderer.addClass(this.counterElement, 'char-counter');
    this.renderer.setStyle(this.counterElement, 'position', 'absolute');
    this.renderer.setStyle(this.counterElement, 'top', '10px');
    this.renderer.setStyle(this.counterElement, 'right', '15px');
    this.renderer.setStyle(this.counterElement, 'fontSize', '10px');
    this.renderer.setStyle(this.counterElement, 'color', 'gray');

    // Define o estilo de posicionamento do pai
    const parent = this.el.nativeElement.parentNode;
    this.renderer.setStyle(parent, 'position', 'relative');

    // Adiciona o contador ao elemento pai do input
    this.renderer.appendChild(parent, this.counterElement);
  }

  private updateCounter() {
    const inputLength = this.el.nativeElement.value.length;
    const remainingChars = this.maxLength - inputLength;
    this.counterElement.innerText = `${inputLength} / ${this.maxLength}`;
    this.renderer.setStyle(this.counterElement, 'color', remainingChars < 0 ? 'red' : 'gray');
  }
}
