import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AppVersion } from 'src/app/core/services/AppVersion';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../helpers/constants';
import { BrokerModuleProfileService } from '../../services/broker-module-profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
  ],
})
export class LoginComponent implements OnInit {
  private instanceId = Constants.GetId();
  submitted = false;
  loginForm: FormGroup;
  returnUrl: string;
  version = AppVersion.Version;
  customerList: SelectOptionItem[] = [];
  protected fieldIdTable = {};

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private helper: HttpHelper,
    private deviceService: DeviceDetectorService,
    private profileService: BrokerModuleProfileService,
  ) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    // reset login status
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      customer: [null],
    });
    this.authenticationService.GetLogout();

    if (
      environment.tuser &&
      environment.tpassword &&
      environment.tuser.length > 0 &&
      environment.tpassword.length > 0
    ) {
      this.sendLogin(environment.tuser, environment.tpassword);
    }
  }

  GetId(id: number | string): string {
    //private instanceId = Constants.GetId();
    return id + '-' + this.instanceId;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.sendLogin(this.loginForm.get('username').value, this.loginForm.get('password').value);
  }

  sendLogin(username: string, password: string, customer?: string) {
    this.customerList = [];
    const deviceInfo = JSON.stringify(this.deviceService.getDeviceInfo());
    this.authenticationService
      .PostLogin({
        username: username,
        customer: customer,
        password: password,
        deviceInfo: deviceInfo,
      })
      .subscribe({
        next: (data) => {
          if (data.ask && data.ask.options.name === 'customers') {
            this.customerList = data.ask.options.items;
          } else {
            // console.log('User: ', JSON.stringify(data.user));
            this.helper.saveCredentials(data);
            this.profileService.loadFromServer();
            if (data.user.changePassword) {
              this.router.navigate(['./user/chpwd']);
            } else {
              this.router.navigate([this.returnUrl]);
            }
          }
        },
        error: (error) => {
          if (error.status && error.status === 404 && error.error) {
            this.alertService.error(error.error);
          } else if (
            error.status &&
            error.status === 409 &&
            error.error &&
            error.error.message === 'INVALID_CUSTOMER'
          ) {
            this.OnSelecionaCustomer();
          } else if (error.status && error.status === 400 && error.error && error.error.error) {
            this.alertService.error(error.error.error);
          } else {
            this.alertService.error(error);
          }
        },
      });
  }

  OnLoadCustomerAssociado() {}

  OnSelecionaCustomer() {}

  OnEsqueceuSenha() {
    this.router.navigate(['/esqueceu-senha']);
  }

  OnCadastroGratuito() {
    this.router.navigate(['/criar-conta']);
  }

  OnSelConta(id: string): void {
    //console.log('Sl:' + id);
    this.sendLogin(this.loginForm.get('username').value, this.loginForm.get('password').value, id);
  }
}
