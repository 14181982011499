import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {
  fromModel(value: string | NgbDateStruct | null): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    if (this.isNgbDateStruct(value)) {
      return value;
    } else {
      const parts = value.split('-');
      return {
        year: parseInt(parts[0], 10),
        month: parseInt(parts[1], 10),
        day: parseInt(parts[2], 10),
      };
    }
  }

  private isNgbDateStruct(obj: any): obj is NgbDateStruct {
    return obj && typeof obj.year === 'number' && typeof obj.month === 'number' && typeof obj.day === 'number';
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? `${date.year}-${this.pad(date.month)}-${this.pad(date.day)}` : null;
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
