import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BreadcrumpComponent } from '../header-principal/breadcrumb.component';
import { HeaderPrincipalComponent } from '../header-principal/header-principal.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-home-layout',
  templateUrl: 'home.layout.component.html',
  styleUrls: ['home.layout.component.css'],
  standalone: true,
  imports: [HeaderPrincipalComponent, MenuComponent, BreadcrumpComponent, RouterOutlet],
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}
