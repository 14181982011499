<div style="max-width: 500px">
  <div>
    <div class="t-header-title text-center mb-3">{{ modelo.tituloHeader }}</div>
  </div>
  <div class="row">
    <div class="col">
      <div class="w-100 text-center mb-3" [innerHTML]="modelo.titulo2"></div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="w-100 text-center">
        <a href="{{ modelo.urlLink }}">{{ modelo.tituloLink }}</a>
      </div>
    </div>
  </div>
</div>
