// {{GENERATE}}

import { Injectable } from '@angular/core';
import { BagParameterObject } from './BagParameter';
import { ValueName } from './ValueName';

export interface UserHrefBean {
  tipo?: string;
  href?: string;
  nodes?: ValueName[];
}

// #region #declarations#.

export interface RegVisaoGeral {
  name: string;
  disponivel: boolean;
  status: boolean;
  label: string;
  open: boolean;
  rate: number;
  marca?: boolean;
}

@Injectable()
export class UserHrefBeanUtilities {
  public static readonly VISAO_GERAL = 'VISAO_GERAL';
  public static toRegVisaoGeral(val: UserHrefBean): RegVisaoGeral {
    const obj = new BagParameterObject(val.nodes);
    return {
      name: val.href,
      disponivel: obj.getTextById('disponivel', 'false') === 'true',
      status: obj.getTextById('status', 'false') === 'true',
      label: obj.getTextById('label', null),
      open: obj.getTextById('open', 'false') === 'true',
      rate: Number(obj.getTextById('rate', '0')),
    };
  }

  public static toRegVisaoGeralList(values: UserHrefBean[], tipo: string): RegVisaoGeral[] {
    const result: RegVisaoGeral[] = [];
    values.forEach((x) => {
      if (tipo === x.tipo) {
        result.push(UserHrefBeanUtilities.toRegVisaoGeral(x));
      }
    });
    return result;
  }

  public static toUserHrefBean(val: RegVisaoGeral): UserHrefBean {
    const res: UserHrefBean = {
      tipo: UserHrefBeanUtilities.VISAO_GERAL,
      href: val.name,
      nodes: [],
    };
    res.nodes.push({ id: 'disponivel', text: !val.disponivel ? 'false' : 'true' });
    res.nodes.push({ id: 'status', text: !val.status ? 'false' : 'true' });
    res.nodes.push({ id: 'label', text: val.name });
    res.nodes.push({ id: 'open', text: !val.open ? 'false' : 'true' });
    res.nodes.push({ id: 'rate', text: `${val.rate}` });
    return res;
  }

  public static toUserHrefBeanList(rowList: RegVisaoGeral[]): UserHrefBean[] {
    return rowList.map((elm) => UserHrefBeanUtilities.toUserHrefBean(elm));
  }
}

/** {
    "tipo" : "VISAO_GERAL",
    "href" : "BP",
    "nodes" : [ {
      "id" : "status",
      "text" : "true"
    }, {
      "id" : "label",
      "text" : "BP"
    }, {
      "id" : "open",
      "text" : "false"
    }, {
      "id" : "rate",
      "text" : "0"
    } ]
  } */
// #endregion
