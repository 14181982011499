import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Formatter } from '../pipes/Formatter';
declare let VMasker: any;
/**
 * Conforme XSDs publicados,
 * assumimos que o número da conta bancária (um numérico de até 20 dígitos)
 * seria separado de seu respectivo dígito (um caracter, letra ou dígito).
 *
 * De modo semelhante, a agência: número da agência (numérico de 5 dígitos) e dígito (um caracter, letra ou dígito).
 * Esses dígitos são usados apenas no cadastro das contas bancárias.
 * Agência:  :mask="['####', '####-#', '####-##']"
 * Conta: :mask="['#####-#', '######-#', '#######-#', '########-#', '#########-#', '###########-#']"
 */
@Directive({
  selector: '[maskAgenciaBancaria]',
  standalone: true,
})
export class MaskAgenciaBancariaDirective {
  private el: NgControl;
  private mask = ['9999', '9999-9', '9999-99'];

  constructor(private ngControl: NgControl) {
    this.el = ngControl;
  }

  // Listen for the input event to also handle copy and paste.
  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    if (!value) {
      return this.el.control.patchValue(value);
    }
    const vclr = Formatter.removeNonDigitValues(value);
    let lmask;
    let idx;
    for (idx = 0; idx < this.mask.length; idx++) {
      lmask = this.mask[idx];
      if (vclr.length < lmask.length) {
        //console.log('P->', idx, vclr, lmask);
        this.el.control.patchValue(VMasker.toPattern(vclr, lmask));
        return;
      }
    }
    //console.log('L->', idx, vclr, lmask);
    this.el.control.patchValue(VMasker.toPattern(vclr, lmask));
  }
}
