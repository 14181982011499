// {{GENERATE}}
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { StatusEnumRepo } from 'src/app/core/models/StatusEnum';
import { Utilities } from 'src/app/core/services/utilities';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-smtp-config-form-bean-fragment',
  templateUrl: './smtp-config-form-bean-fragment.component.html',
  imports: [
    CommonModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxMaskDirective,
  ],
})
export class SmtpConfigFormBeanFragmentComponent implements OnInit, AfterViewInit {
  // #region #properties#.
  protected fieldIdTable = {};
  // #endregion

  showPassword = false;
  private instanceId = Constants.GetId();
  private rootformid: string;
  private myname: string;
  public contexto = {};
  @Input() EditMode: boolean;
  @Input() CrudForm: FormGroup;
  viewType: ViewTypeEnum = ViewTypeEnum.ADD;
  masks = Constants;
  statusList: SelectOptionItem[] = StatusEnumRepo.optionList();

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
  ) {
    //
  }
  // #endregion

  // convenience getter for easy access to form fields
  get f() {
    return this.CrudForm.controls;
  }

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.showPassword = false;
    if (!this.CrudForm) {
      this.CrudForm = this.formBuilder.group(this.CrudFormValidators(this.GetId('root')));
    }
    this.fieldIdTable = Utilities.createFieldIdTable(this.CrudForm, this.instanceId);
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SetValoresDefault();
      this.TrataValueChanges();
    });
  }
  // #endregion

  // #region #tratavaluechanges.
  // CODGEN {{R}}
  TrataValueChanges(): void {}
  // #endregion

  // #region #setvaloresdefault.
  // CODGEN {{R}}
  SetValoresDefault(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {}
  // #endregion

  IsEditMode(): boolean {
    return this.EditMode;
  }

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #crudformvalidators.
  // CODGEN {{R}}
  CrudFormValidators(rootformid: string, myname?: string) {
    this.rootformid = rootformid;
    this.myname = myname;
    return {
      status: [null, Validators.required],
      host: [null, [Validators.required, Validators.maxLength(256)]],
      port: [null, [Validators.required, Validators.maxLength(10)]],
      user: [null, [Validators.required, Validators.maxLength(256)]],
      password: [null, Validators.required],
      fromname: [null, [Validators.required, Validators.maxLength(256)]],
      contextpath: [null, [Validators.required, Validators.maxLength(256)]],
      tags: [null, Validators.maxLength(256)],
      flgstarttls: [null],
      flgauth: [null],
    };
  }
  // #endregion

  // #region #crudformgroupvalidators.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CrudFormGroupValidators(validators): void {}
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {
    const reg = {
      status: this.CrudForm.get('status').value,
      host: this.CrudForm.get('host').value,
      port: Utilities.unmask(this.CrudForm.get('port').value),
      user: this.CrudForm.get('user').value,
      password: this.CrudForm.get('password').value,
      fromname: this.CrudForm.get('fromname').value,
      contextpath: this.CrudForm.get('contextpath').value,
      tags: this.CrudForm.get('tags').value,
      flgstarttls: this.CrudForm.get('flgstarttls').value,
      flgauth: this.CrudForm.get('flgauth').value,
    };
    return reg;
  }
  // #endregion

  // #region #onflgstarttlschange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnFlgstarttlsChange(_evt) {}
  // #endregion

  // #region #onflgauthchange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnFlgauthChange(_evt) {}
  // #endregion

  // #region #routine-definitions#.
  // #endregion
  // #region #declarations#.
  // #endregion
}
