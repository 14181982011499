// {{GENERATE}}
import { Injectable } from '@angular/core';
import { Utilities } from 'src/app/core/services/utilities';
import { EntityKey } from './EntityKey';
import { TipoEntityEnum } from './TipoEntityEnum';

export interface EntityDescriptionItem {
  key?: EntityKey;
  descricao?: string;
  cor?: string;
}

// #region #declarations#.

export interface PillsColor {
  backcolor?: string;
  color?: string;
}

@Injectable()
export class EntityDescription {
  public static toPillsColor(node: EntityDescriptionItem): PillsColor {
    return { backcolor: node.cor, color: Utilities.GetContrastingTextColor(node.cor) };
  }

  public static getButtonStyle(node: EntityDescriptionItem): { [key: string]: string } {
    if (node && node.cor) {
      return {
        'background-color': node.cor,
        color: Utilities.GetContrastingTextColor(node.cor),
      };
    } else {
      return null;
    }
  }

  public static getTooltip(node: EntityDescriptionItem): string {
    switch (node.key.tipo) {
      case 'CNT':
        return 'Coonta';
      case 'FPG':
        return 'Forma pagamento';
      case 'CAT':
        return 'Categoria';
      case 'CON':
        return 'Contato';
      case 'CST':
        return 'Centro de custo';
      case 'PRJ':
        return 'Projeto';
      case 'TAG':
        return 'Tag';
    }
    return null;
  }

  public static stringToEnum(value: string): TipoEntityEnum | null {
    switch (value) {
      case 'CNT':
        return TipoEntityEnum.CNT;
      case 'FPG':
        return TipoEntityEnum.FPG;
      case 'CAT':
        return TipoEntityEnum.CAT;
      case 'CON':
        return TipoEntityEnum.CON;
      case 'CST':
        return TipoEntityEnum.CST;
      case 'PRJ':
        return TipoEntityEnum.PRJ;
      case 'TAG':
        return TipoEntityEnum.TAG;
    }
    return null;
  }
}

// #endregion
