<!-- {{NOTGENERATE}} -->
<!-- #I-#start-container -->
<ng-container [formGroup]="CrudForm">
  <div class="item" #itemContainer>
    <div ngbAccordion #accordion="ngbAccordion">
      <div ngbAccordionItem [collapsed]="!definicoes.open" (shown)="onItemShow(true)" (hidden)="onItemShow(false)">
        <div ngbAccordionHeader class="h2">
          <button ngbAccordionButton l10nTranslate>PaineisFinanceirosEnum.{{ data.card.name }}</button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              @if (definicoes.disponivel && tipo && dadosGrafico && dadosGrafico.length > 0) {
                <div class="d-flex justify-content-center w-100 p-3">
                  <canvas
                    baseChart
                    [type]="'doughnut'"
                    [datasets]="pieChartDatasets"
                    [labels]="pieChartLabels"
                    [options]="pieChartOptions"
                    [plugins]="pieChartPlugins"
                    [legend]="pieChartLegend"
                    style="width: 180px; height: 180px"
                  >
                  </canvas>
                </div>
                <table class="table table-sm w-100">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (row of dadosGrafico; track row.id; let i = $index) {
                      <tr>
                        <td class="td-linha">
                          <span class="m-circle" [style]="{ 'background-color': row.cor }"></span>
                        </td>
                        <td>
                          <button type="button" class="btn btn-link" style="padding: 0 0" (click)="onClickLabel(row)">
                            {{ row.label }}
                          </button>
                        </td>
                        <td class="text-align-start td-linha">
                          <span>{{ row.percentual | percent }}</span>
                        </td>
                        <td class="text-align-direita td-linha">
                          <span [class]="row.valor < 0 ? 'negativo' : 'positivo'">{{
                            row.valor | currency: NomeMoeda
                          }}</span>
                        </td>
                      </tr>
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td class="fw-bold">Total:</td>
                      <td class="text-align-direita fw-bold">
                        <span [class]="valorTotal < 0 ? 'negativo' : 'positivo'">
                          {{ valorTotal | currency: NomeMoeda }}</span
                        >
                      </td>
                    </tr>
                  </tfoot>
                </table>
              } @else {
                @if (!definicoes.disponivel) {
                  <div class="d-flex justify-content-center w-100 p-3">
                    <button type="button" class="btn btn-primary">Adicionar este recurso...</button>
                  </div>
                } @else {
                  <div class="d-flex justify-content-center w-100 p-3">
                    {{ mensagemVazio }}
                  </div>
                  <div class="d-flex justify-content-center w-100 p-3">
                    <button type="button" class="btn btn-primary">{{ mensagemVazioBtn }}</button>
                  </div>
                }
              }
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
