// {{GENERATE}}
// @CRUD
// @GRID:check
// @Route:/lancamento/action
// URL:/financeiro/lancamento
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GraphicDataContainer } from 'src/app/core/models/GraphicDataContainer';
import { RequestChartDataBean } from 'src/app/core/models/RequestChartDataBean';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { LancamentoBean } from '../models/LancamentoBean';

@Injectable()
export abstract class LancamentoActionService {
  // #region #getalllancamento.
  // CODGEN {{R}}
  abstract GetAllLancamento(par: string): Observable<LancamentoBean[]>;
  // #endregion

  // #region #crud#createlancamento.
  // CODGEN {{R}}
  abstract CreateLancamento(bean: LancamentoBean): Observable<LancamentoBean>;
  // #endregion

  // #region #crud#updatelancamento.
  // CODGEN {{R}}
  abstract UpdateLancamento(bean: LancamentoBean): Observable<void>;
  // #endregion

  // #region #crud#getreadlancamento.
  // CODGEN {{R}}
  abstract GetReadLancamento(idlancamento: number): Observable<LancamentoBean>;
  // #endregion

  // #region #crud#removelancamento.
  // CODGEN {{R}}
  abstract RemoveLancamento(idlancamento: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region metodo#get-chart-data.
  // CODGEN {{R}}
  abstract GetChartData(parametro: RequestChartDataBean): Observable<GraphicDataContainer>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
