import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Subject } from 'rxjs';

@Injectable()
export class PtBrMatPaginatorIntl extends MatPaginatorIntl {
  changes = new Subject<void>();

  itemsPerPageLabel: string = 'Items por página:';
  nextPageLabel: string = 'Próximo';
  previousPageLabel: string = 'Anterior';
  firstPageLabel: string = 'Início';
  lastPageLabel: string = 'Fim';

  getRangeLabel = function (page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
  };
}
