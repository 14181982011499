/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LRUCache } from 'lru-cache';
import { Observable, Subject, Subscription, tap } from 'rxjs';
import { HttpHelper } from '../helpers/http-helper';
import { SelectOptionList } from '../models/SelectOptionList';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export interface SelectOptionNode {
  code?: number;
  expireAt?: number;
  lista?: SelectOptionList;
}

export interface SelectOptionNodeCache {
  code?: number;
  key?: string;
  expireAt?: number;
  lista?: SelectOptionList;
}

export interface SelectOptionEvent {
  host?: string;
  par?: string;
  opcao?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SelectOptionService implements OnInit, OnDestroy {
  private static options = {
    max: 500,
    ttl: 1000 * 60 * 5,
    allowStale: false,
    updateAgeOnGet: false,
    updateAgeOnHas: false,
  };
  private modalOpenedSubscription: Subscription;
  private subject = new Subject<SelectOptionEvent>();
  private cache = new LRUCache<string, SelectOptionNodeCache>(SelectOptionService.options);

  constructor(
    private http: HttpClient,
    private helper: HttpHelper,
    private modalService: NgbModal,
  ) {
    //console.log('########1>');
    this.modalOpenedSubscription = this.modalService.activeInstances.subscribe((modals: NgbModalRef[]) => {
      if (modals.length > 0) {
        //console.log('Modal foi aberto');
        this.onModalOpened(); // Chame o método ou gere um evento
      } else {
        //console.log('Todos os modais foram fechados');
        this.onModalClosed(); // Aciona quando todos os modais forem fechados
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    //console.log('######## destroi>');
    if (this.modalOpenedSubscription) {
      this.modalOpenedSubscription.unsubscribe();
    }
  }

  // Método executado quando um modal é aberto
  onModalOpened(): void {
    // Coloque a lógica aqui para quando um modal for aberto
    //console.log('Evento de abertura de modal acionado!');
    this.Clear();
  }

  // Método executado quando todos os modais são fechados
  onModalClosed(): void {
    // Coloque a lógica aqui para quando todos os modais forem fechados
    // console.log('Evento de fechamento de modal acionado!');
    //this.Clear();
  }

  openModal(content: any): void {
    this.modalService.open(content);
  }

  getEvents(): Observable<SelectOptionEvent> {
    return this.subject.asObservable();
  }

  /**
   * limpa o cache a cada inclusão. e avisa que teve alteração.
   */
  publish(message: SelectOptionEvent): void {
    this.ClearKey(message.host, message.par);
    this.subject.next(message);
  }

  public ClearKey(host: string, par: string): void {
    const key = SelectOptionService.ToKey(host, par, '');
    this.deleteItemsWithPartialKey(key);
  }

  private deleteItemsWithPartialKey(partialKey: string) {
    this.cache.forEach((value, key) => {
      if (key.includes(partialKey)) {
        this.cache.delete(key);
      }
    });
  }

  public Clear(): void {
    console.log('this.cache.clear();');
    this.cache.clear();
  }

  /**
   * Busca no cache se possível
   * Exemplo:
   *       this.optionService.GetSelectOptions('/financeiro/categoria', 'XXXXXXX', '').subscribe({
   *           next: (data) => {
   *             this.idsubcategoriadeList = data.items;
   *             this.FiltraListaCategoriasPai(this.f.tipo.value);
   *           },
   *           error: (error) => {
   *             this.alertService.error(error);
   *           },
   *         });
   *
   * Combinando multiplos getters:
   *
   *          const op: Observable<SelectOptionList>[] = [];
   *           op.push(this.OnInvokeSelectOptionsCategoria());
   *           op.push(this.OnInvokeSelectOptionsCentrocusto());
   *           op.push(this.OnInvokeSelectOptionsContato());
   *           op.push(this.OnInvokeSelectOptionsProjeto());
   *           op.push(this.OnInvokeSelectOptionsTaglist());
   *           combineLatest(op).subscribe({
   *             next: () => {
   *               this.OnLoadSelectedRows();
   *             },
   *             error: (error) => {
   *               this.alertService.error(error);
   *             },
   *           });
   *
   *           OnInvokeSelectOptionsCategoria(): Observable<SelectOptionList> {
   *           const host = '/financeiro/categoria';
   *           return this.optionService.GetSelectOptions(host, 'XXXXXXXXX', '').pipe(
   *             tap((data) => {
   *               this.categoriaList = [...SelectOptionUtils.TransformToNumericId(data.items)];
   *             }),
   *           );
   *         }
   *
   * @param host - '/admin/user'
   * @param par  - 'XXXXXXXXXX'
   * @param opcao
   */
  GetSelectOptions(host: string, par: string, opcao: string): Observable<SelectOptionList> {
    const key = SelectOptionService.ToKey(host, par, opcao);
    let vobservable: Observable<SelectOptionList> = null;
    if (this.cache.has(key)) {
      const node: SelectOptionNode = this.cache.get(key);
      if (node && node.lista) {
        vobservable = new Observable<SelectOptionList>((subscriber) => {
          subscriber.next(node.lista);
          subscriber.complete();
        });
      }
    }
    if (!vobservable) {
      vobservable = this.GetSelectOptionsNoCache(host, par, opcao).pipe(
        tap((data) => {
          const node: SelectOptionNodeCache = { code: 0, key: key, expireAt: this.ToExpireAt(), lista: data };
          this.cache.set(key, node);
        }),
      );
    }
    return vobservable;
  }

  private GetSelectOptionsNoCache(host: string, par: string, opcao: string): Observable<SelectOptionList> {
    const url = this.helper.ToURL(host.endsWith('/') ? `${host}selectoptions` : `${host}/selectoptions`);
    let params = new HttpParams();
    params = params.append('par', par ? par : '');
    params = params.append('opcao', opcao ? opcao : '');
    const header = this.helper.GetHeaders(params);
    return this.http.get<SelectOptionList>(url, header);
  }

  public static ToKey(host: string, par: string, opcao: string): string {
    const key = `${host}|${par}|${opcao ? opcao : ''}`;
    if (typeof opcao === 'object' && opcao !== null) {
      console.error('TO KEY OBJETO:', par, opcao, key);
    }
    //console.log('TO KEY:', par, opcao, key);
    return key;
  }

  private ToExpireAt(): number {
    return 60000 + Date.now();
  }
}
