import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { BagParameterObject } from 'src/app/core/models/BagParameter';
import { ModalCallbackFunction } from 'src/app/core/models/CloseModalResult';
import { LocalDateType } from 'src/app/core/models/local-date-type';
import { Utilities } from 'src/app/core/services/utilities';
import { LancamentoActionAddComponent } from '../components/lancamentos/lancamento-action/lancamento-action-add.component';
import { LancamentoBean } from '../models/LancamentoBean';
import { TipoLancamentoEnumRepo } from '../models/TipoLancamentoEnum';
import { TipoRepeticaoEnum } from '../models/TipoRepeticaoEnum';

export class LancamentoUtilitiesService {
  public static buildLancamentoBean({
    cmd,
    idconta,
    data = format(new Date(), 'yyyy-MM-dd'),
    valor = 0.0,
  }: {
    cmd: 'DESPESA' | 'RECEITA' | 'TRANSFERENCIA' | 'LAN_DETALHADO';
    idconta: number;
    data?: LocalDateType;
    valor?: number;
  }): LancamentoBean {
    const registro: LancamentoBean = {};
    registro.idconta = idconta;
    registro.tipolancamento = TipoLancamentoEnumRepo.stringToEnum(cmd);
    registro.datacompetencia = data;
    registro.data = data;
    registro.valor = valor;
    registro.repeticao = { reptiporepeticao: TipoRepeticaoEnum.UNICA };
    return registro;
  }

  public static doOpenModalLancamento(
    modalService: NgbModal,
    registro: LancamentoBean,
    callback: ModalCallbackFunction,
  ): void {
    const modalRef = Utilities.OpenNewModal(modalService, LancamentoActionAddComponent, 'tpx-custom-modal');
    const view: LancamentoActionAddComponent = modalRef.componentInstance;
    view.viewType = ViewTypeEnum.ADD;
    view.parameters = new BagParameterObject();
    view.parameters.setTextById('registro-inicial', JSON.stringify(registro));
    view.parameters.setTextById('cmd', 'ADD');
    modalRef.result.then((result) => {
      callback(result);
    });
  }
}
