<!-- {{GENERATE}} -->
<div class="modal-content">
  <!-- #I-#tpx-crud-header/R -->
  <div class="modal-header">
    <div class="modal-title t-header-title" l10nTranslate>HistoricoActionGrid.HEADERTITLE</div>
    <button type="button" class="btn-close" aria-label="Close" (click)="OnCloseModal(false)"></button>
  </div>
  <!-- #E-#tpx-crud-header -->
  <div class="modal-body">
    <div class="tpx-crud-body">
      <!-- #I-#row-app-search-box/R -->
      <div class="row control-box d-flex align-items-center">
        <div class="col-sm-8">
          <div class="mb-3">
            <app-search-box
              (searchChange)="OnSearchChanged($event)"
              placeholder="{{ 'HistoricoActionGrid.SEARCH' | translate: locale.language }}"
            ></app-search-box>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="navbar action-box p-0">
            <ul class="nav navbar-nav navbar-right p-0"></ul>
          </div>
        </div>
      </div>
      <!-- #E-#row-app-search-box -->
      <div class="table-wrapper">
        <!-- #I-#datatable-grid-start/R -->
        <mat-table [dataSource]="rows" class="mt-3" matSort>
          <!-- #E-#datatable-grid-start -->
          <!-- #I-#field-column-id/R -->
          <!-- id Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate>HistoricoActionGrid.id</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">{{ 'HistoricoActionGrid.id' | translate: locale.language }} :</span>
              <div class="tpx-cel-grid">{{ DisplayField('id', row.id, row) }}</div>
            </mat-cell>
          </ng-container>
          <!-- #E-#field-column-id -->
          <!-- #I-#field-column-moment/R -->
          <!-- moment Column -->
          <ng-container matColumnDef="moment">
            <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate
              >HistoricoActionGrid.moment</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">{{ 'HistoricoActionGrid.moment' | translate: locale.language }} :</span>
              <div class="tpx-cel-grid">{{ DisplayField('moment', row.moment, row) }}</div>
            </mat-cell>
          </ng-container>
          <!-- #E-#field-column-moment -->
          <!-- #I-#field-column-function/R -->
          <!-- function Column -->
          <ng-container matColumnDef="function">
            <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate
              >HistoricoActionGrid.function</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">{{ 'HistoricoActionGrid.function' | translate: locale.language }} :</span>
              <div class="tpx-cel-grid">{{ DisplayField('function', row.function, row) }}</div>
            </mat-cell>
          </ng-container>
          <!-- #E-#field-column-function -->
          <!-- #I-#field-column-access/R -->
          <!-- access Column -->
          <ng-container matColumnDef="access">
            <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate
              >HistoricoActionGrid.access</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">{{ 'HistoricoActionGrid.access' | translate: locale.language }} :</span>
              <div class="tpx-cel-grid">{{ DisplayField('access', row.access, row) }}</div>
            </mat-cell>
          </ng-container>
          <!-- #E-#field-column-access -->
          <!-- #I-#field-column-detail/R -->
          <!-- detail Column -->
          <ng-container matColumnDef="detail">
            <mat-header-cell *matHeaderCellDef mat-sort-header l10nTranslate
              >HistoricoActionGrid.detail</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              <span class="mobile-label">{{ 'HistoricoActionGrid.detail' | translate: locale.language }} :</span>
              <div class="tpx-cel-grid">{{ DisplayField('detail', row.detail, row) }}</div>
            </mat-cell>
          </ng-container>
          <!-- #E-#field-column-detail -->
          <!-- #I-#field-column-action/R -->
          <!-- action Column -->
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef aria-label="row actions" l10nTranslate
              >HistoricoActionGrid.action</mat-header-cell
            >
            <mat-cell *matCellDef="let row"> </mat-cell>
          </ng-container>
          <!-- #E-#field-column-action -->
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
        </mat-table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 50]"
          showFirstLastButtons
          aria-label="Select page of users"
        ></mat-paginator>
      </div>
    </div>
  </div>
  <!-- #I-#tpx-crud-footer/R -->
  <div class="modal-footer"></div>
  <!-- #E-#tpx-crud-footer -->
</div>
