<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>SaldoFinanceiroBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-valorsaldo -->
    <div class="col-sm-12 col-md-6">
      <label [for]="fieldIdTable['valorsaldo']" class="form-label" l10nTranslate>{{ Title }}</label>
      <input
        type="text"
        [id]="fieldIdTable['valorsaldo']"
        formControlName="valorsaldo"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valorsaldo.invalid && (f.valorsaldo.dirty || f.valorsaldo.touched) }"
        kzMaskCurrency
        maxlength="25"
      />
      @if (f.valorsaldo.errors) {
        <div class="invalid-feedback">
          @if (f.valorsaldo.errors.maxlength) {
            <div l10nTranslate>{{ Title }}-MAXLENGTH</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valorsaldo -->
    <!-- #I-#field-indicadorsaldo -->
    <div class="col-sm-12 col-md-6 not-required">
      @for (item of indicadorsaldoRList; track item) {
        <div class="form-check form-check-inline mt-4">
          <input
            type="radio"
            formControlName="indicadorsaldo"
            class="form-check-input"
            [id]="fieldIdTable['ck-indicadorsaldo']"
            [value]="item.value"
          />
          <label class="form-check-label" [for]="fieldIdTable['ck-indicadorsaldo']" l10nTranslate
            >SaldoFinanceiroBeanFragment.indicadorsaldo-{{ item.value }}</label
          >
        </div>
      }
    </div>
    <!-- #E-#field-indicadorsaldo -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
