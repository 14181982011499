import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';
import { SelectOptionItem } from 'src/app/core/models/SelectOptionItem';
import { AlertService } from 'src/app/core/services/alert.service';
import { SelectOptionService } from 'src/app/core/services/select-option.service';

@Injectable({
  providedIn: 'root',
})
export class TypeaheadRegraPreenchimentoService {
  private items: SelectOptionItem[] = [];
  private current_version: number = 1;
  private modify_version: number = -1;

  constructor(
    private optionService: SelectOptionService,
    private alertService: AlertService,
  ) {}

  /**
   * Marca o cache como invalido.
   */
  public setModify(): void {
    this.modify_version++;
  }

  public search(term: string): Observable<SelectOptionItem[]> {
    if (this.modify_version != this.current_version) {
      return this.optionService.GetSelectOptions('/financeiro/categoria', 'REGRAPREENCHIMENTOBEAN', '').pipe(
        debounceTime(300),
        switchMap((data) => {
          this.current_version++;
          this.modify_version = this.current_version;
          this.items = [...data.items];
          return of(this.filterItems(term));
        }),
        catchError((error) => {
          this.alertService.error(error);
          return of([]);
        }),
      );
    } else {
      // Se os itens já estão carregados em memória, filtra localmente
      return of(this.filterItems(term)).pipe(debounceTime(300));
    }
  }

  private filterItems(term: string): SelectOptionItem[] {
    return this.items.filter((item) => item.name.toLowerCase().includes(term.toLowerCase()));
  }
}
