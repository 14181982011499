import { Injectable } from '@angular/core';
import { User } from './User';

export interface Avatar {
  name?: string;
  image?: string;
  size?: number;
  timestamp: number;
}

@Injectable()
export class AvatarDados {
  public static fromUser(user: User, sz: number = 42): Avatar {
    let avatar;
    if (user) {
      const tm = new Date().getTime() / 10000;
      const userName = user.name;
      const userImage = `/image/AVATAR/${user.id}/${tm}`;
      avatar = { name: userName, image: userImage, size: sz, timestamp: tm };
    } else {
      avatar = { name: '', image: '', size: sz, timestamp: 0 };
    }
    //console.log('AVATAR:', avatar);
    return avatar;
  }
}
