// {{NOTGENERATE}}
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { NgxMaskDirective } from 'ngx-mask';

import { ImageCropperComponent, ImageTransform, LoadedImage } from 'ngx-image-cropper';
import { Constants } from 'src/app/core/helpers/constants';
import { HttpHelper } from 'src/app/core/helpers/http-helper';
import { AlertService } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { StoreUserAvatarRequest } from '../../../models/StoreUserAvatarRequest';
import { AdminUserActionService } from '../../../services/admin-user-action.service';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-editar-foto-perfil-form',
  templateUrl: './editar-foto-perfil-form.component.html',
  styleUrls: ['./editar-foto-perfil-form.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    NgxMaskDirective,
    ImageCropperComponent,
  ],
})
export class EditarFotoPerfilFormComponent implements OnInit {
  // #region #properties#.
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  transform: ImageTransform = {};
  // #endregion

  masks = Constants;
  private instanceId = Constants.GetId();
  isModal: boolean = false;

  // #region #constructor#cmp#.
  // CODGEN
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public activeModal: NgbActiveModal,
    private crudService: AdminUserActionService,
    private alertService: AlertService,
    private helper: HttpHelper,
  ) {
    //
  }
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {}
  // #endregion

  // #region #onprocessar#method.
  // CODGEN
  OnProcessar(): void {
    if (!this.helper.credentials || !this.helper.credentials.user) {
      return;
    }
    const arr = this.croppedImage.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = arr[1];
    //
    const registro: StoreUserAvatarRequest = {
      userid: this.helper.credentials.user.id,
      filetype: mime,
      base64image: bstr,
    };
    this.crudService.StoreUserAvatar(registro).subscribe({
      next: () => {
        this.helper.refreshUser();
        this.OnCloseModal(true);
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  // #endregion

  GetId(id: number | string): string {
    return id + '-' + this.instanceId;
  }

  // #region #onclosemodal.
  // CODGEN {{R}}
  OnCloseModal(status = true, repeat = false): void {
    this.activeModal.close({
      ok: status,
      repeat: repeat,
    });
  }
  // #endregion

  // #region #crudformtoregistro.
  // CODGEN {{R}}
  CrudFormToRegistro() {}
  // #endregion

  // #region #opennewmodal.
  // CODGEN {{R}}
  static OpenNewModal(modalService: NgbModal): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, EditarFotoPerfilFormComponent, 'tpx-custom-modal-500');
    const view: EditarFotoPerfilFormComponent = modalRef.componentInstance;
    view.isModal = true;
    return modalRef;
  }
  // #endregion

  // #region #routine-definitions#.

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: any): void {
    //this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage): void {
    // show cropper
  }

  cropperReady(): void {
    // cropper ready
  }

  loadImageFailed(): void {
    // show message
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  // #endregion
}
