import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { PersonalizarHomeComponent } from 'src/app/modules/admin/components/user/personalizar-home/personalizar-home.component';
import { UserPainelConfigBean } from 'src/app/modules/admin/models/UserPainelConfigBean';
import { ResourcesFinanceirosEnum } from 'src/app/modules/financeiro/models/ResourcesFinanceirosEnum';
import { CategoriaActionService } from 'src/app/modules/financeiro/services/categoria-action.service';
import { PainelVisaoGeralService } from 'src/app/modules/financeiro/services/painel-visao-geral.service';
import { MonthYearPickerComponent } from '../../accessors/month-year-picker.component';
import { AdPainelDescription } from '../../models/AdPainelDescription';
import { RegVisaoGeral, UserHrefBeanUtilities } from '../../models/UserHrefBean';
import { AlertService } from '../../services/alert.service';
import { AdDadosElement } from '../painel-ad/ad-dados_element';
import { AdItem } from '../painel-ad/ad-item';
import { AdService } from '../painel-ad/ad.service';
import { PainelAdComponent } from '../painel-ad/painel-ad.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [NgSelectModule, PainelAdComponent, MonthYearPickerComponent, NgbTooltipModule],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  /** AD */
  adsList: AdItem[] = [];
  descricaoPainel: AdPainelDescription;
  private readonly szrow = 50;
  /** Paineis */
  registro: UserPainelConfigBean;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private painelService: PainelVisaoGeralService,
    private modalService: NgbModal,
    private crudService: CategoriaActionService,
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit() {
    this.subscriptions.push(
      this.painelService.getSubject().subscribe({
        next: (evt) => {
          if (evt) {
            if (evt.cmd === 'CONFIG') {
              this.OnInitInvokeService();
            }
          }
        },
      }),
    );
    this.OnInitInvokeService();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private carregaCards(): void {
    if (this.registro && this.registro.parametros) {
      this.carregaAdsPainel(
        UserHrefBeanUtilities.toRegVisaoGeralList(this.registro.parametros, UserHrefBeanUtilities.VISAO_GERAL),
      );
    }
  }

  private carregaAdsPainel(nodes?: RegVisaoGeral[]): void {
    this.descricaoPainel = { href: 'home', cards: [] };
    nodes.forEach((node) => {
      if (node.status) {
        this.descricaoPainel.cards.push({ name: node.name, key: '', painel: JSON.stringify(node) });
      }
    });
    this.adsList = [];
    const ads = AdService.getAdsPainel(this.descricaoPainel);
    if (ads && ads.length > 0) {
      ads.forEach((element) => {
        this.adsList.push(element);
      });
      console.log('CARREGOU:', ads.length);
    } else {
      console.log('NÃO CARREGOU');
    }
  }

  onPersonalizarHome(): void {
    const ref: NgbModalRef = PersonalizarHomeComponent.OpenNewModal(this.modalService);
  }

  OnEventChange(event: string): void {
    console.log('LocalDateType:', event);
    this.painelService.setPeriodo(event);
  }

  OnInitInvokeService() {
    this.adsList = [];
    this.crudService.GetPainelConfig(ResourcesFinanceirosEnum.FINANCEIRO_PAINEL).subscribe({
      next: (data) => {
        try {
          console.log('GetPainelConfig');
          this.registro = data;
          this.carregaCards();
        } catch (error) {
          this.alertService.error('Erro no carregamento dos dados.');
        }
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  onAddChange(event: AdDadosElement): void {
    // console.log('EVENTO:', event, 'SPAN', this.getSpanData(event, this.szrow));
  }

  getSpan(item: AdItem): number {
    if (item.data && item.data.itemHeight) {
      return this.getSpanData(item.data, this.szrow);
    }
    return 1;
  }

  getSpanData(data: AdDadosElement, col: number): number {
    if (data && data.itemHeight) {
      const sz = Number(data.itemHeight);
      const span = sz < col ? 1 : Math.floor(sz / col);
      return span;
    }
    return 1;
  }

  getSpanStyle(data: AdDadosElement) {
    const sz = this.getSpanData(data, this.szrow);
    return { 'grid-row': 'span ' + sz };
  }
  /*
  onDraggableMoved(event: DragEvent, id: number, card: AdPainelCardModel) {}

  onDrop(event: DndDropEvent) {
    if (this.adsList && event && event.data && event.index !== undefined) {
      const o_index = this.adsList.findIndex((x) => x.data.id === event.data);
      let n_index = event.index;
      if (o_index < n_index) {
        n_index--;
      }
      // console.log('Move:', o_index, 'to', n_index);
      if (o_index >= 0 && n_index >= 0 && n_index <= this.adsList.length) {
        // Remove o elemento do array
        const [element] = this.adsList.splice(o_index, 1);
        // Insere o elemento na nova posição
        this.adsList.splice(n_index, 0, element);
      } else {
        console.error('Elemento não encontrado ou índice inválido.');
      }
    }
  }
    */
}
