<!-- {{NOTGENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-tipolancamento -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['tipolancamento']">
      <label [for]="fieldIdTable['tipolancamento']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.tipolancamento</label
      >
      <ng-select
        [attr.id]="fieldIdTable['tipolancamento']"
        #tipolancamento
        formControlName="tipolancamento"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipolancamentoList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoLancamentoEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipolancamento -->
    <!-- #I-#field-tipofinanceiro -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['tipofinanceiro']">
      <label [for]="fieldIdTable['tipofinanceiro']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.tipofinanceiro</label
      >
      <ng-select
        [attr.id]="fieldIdTable['tipofinanceiro']"
        #tipofinanceiro
        formControlName="tipofinanceiro"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of tipofinanceiroList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>TipoFinanceiroEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-tipofinanceiro -->
    <!-- #I-#field-valor -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['valor']">
      <label [for]="fieldIdTable['valor']" class="form-label">{{ fieldState.label.valor }}</label>
      <input
        type="text"
        [attr.id]="fieldIdTable['valor']"
        formControlName="valor"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valor.invalid && (f.valor.dirty || f.valor.touched) }"
        kzMaskCurrency
        appAutofocus
        maxlength="22"
      />
      @if (f.valor.errors) {
        <div class="invalid-feedback">
          @if (f.valor.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.valor-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valor -->
    <!-- #I-#field-valorprevisto -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['valorprevisto']">
      <label [for]="fieldIdTable['valorprevisto']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.valorprevisto</label
      >
      <input
        type="text"
        [attr.id]="fieldIdTable['valorprevisto']"
        formControlName="valorprevisto"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valorprevisto.invalid && (f.valorprevisto.dirty || f.valorprevisto.touched) }"
        kzMaskCurrency
        maxlength="22"
      />
      @if (f.valorprevisto.errors) {
        <div class="invalid-feedback">
          @if (f.valorprevisto.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.valorprevisto-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valorprevisto -->
    <!-- #I-#field-valorefetivo -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['valorefetivo']">
      <label [for]="fieldIdTable['valorefetivo']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.valorefetivo</label
      >
      <input
        type="text"
        [attr.id]="fieldIdTable['valorefetivo']"
        formControlName="valorefetivo"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.valorefetivo.invalid && (f.valorefetivo.dirty || f.valorefetivo.touched) }"
        kzMaskCurrency
        maxlength="22"
      />
      @if (f.valorefetivo.errors) {
        <div class="invalid-feedback">
          @if (f.valorefetivo.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.valorefetivo-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-valorefetivo -->
    <!-- #I-#field-data -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['data']">
      <label [for]="fieldIdTable['data']" class="form-label">{{ fieldState.label.data }}</label>
      <div class="input-group">
        <input
          type="text"
          [attr.id]="fieldIdTable['data']"
          formControlName="data"
          ngbDatepicker
          #ddata="ngbDatepicker"
          class="form-control"
          placeholder="dd/mm/aaaa"
          appMaskDate
          [ngClass]="{ 'is-invalid': f.data.invalid && (f.data.dirty || f.data.touched) }"
        />
        <button class="btn btn-outline-secondary" (click)="ddata.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
      @if (f.data.errors) {
        <div class="invalid-feedback">
          @if (f.data.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.data-REQUIRED</div>
          }
          @if (f.data.errors.validateoptionaldate) {
            <div l10nTranslate>LancamentoBeanFragment.data-VALIDATEOPTIONALDATE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-data -->
    <!-- #I-#field-dataprevista -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['dataprevista']">
      <label [for]="fieldIdTable['dataprevista']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.dataprevista</label
      >
      <div class="input-group">
        <input
          type="text"
          [attr.id]="fieldIdTable['dataprevista']"
          formControlName="dataprevista"
          ngbDatepicker
          #ddataprevista="ngbDatepicker"
          class="form-control"
          placeholder="dd/mm/aaaa"
          appMaskDate
          [ngClass]="{ 'is-invalid': f.dataprevista.invalid && (f.dataprevista.dirty || f.dataprevista.touched) }"
        />
        <button class="btn btn-outline-secondary" (click)="ddataprevista.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
      @if (f.dataprevista.errors) {
        <div class="invalid-feedback">
          @if (f.dataprevista.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.dataprevista-REQUIRED</div>
          }
          @if (f.dataprevista.errors.validateoptionaldate) {
            <div l10nTranslate>LancamentoBeanFragment.dataprevista-VALIDATEOPTIONALDATE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-dataprevista -->
    <!-- #I-#field-dataefetiva -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['dataefetiva']">
      <label [for]="fieldIdTable['dataefetiva']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.dataefetiva</label
      >
      <div class="input-group">
        <input
          type="text"
          [attr.id]="fieldIdTable['dataefetiva']"
          formControlName="dataefetiva"
          ngbDatepicker
          #ddataefetiva="ngbDatepicker"
          class="form-control"
          placeholder="dd/mm/aaaa"
          appMaskDate
          [ngClass]="{ 'is-invalid': f.dataefetiva.invalid && (f.dataefetiva.dirty || f.dataefetiva.touched) }"
        />
        <button class="btn btn-outline-secondary" (click)="ddataefetiva.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
      @if (f.dataefetiva.errors) {
        <div class="invalid-feedback">
          @if (f.dataefetiva.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.dataefetiva-REQUIRED</div>
          }
          @if (f.dataefetiva.errors.validateoptionaldate) {
            <div l10nTranslate>LancamentoBeanFragment.dataefetiva-VALIDATEOPTIONALDATE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-dataefetiva -->
    <!-- #I-#field-datacompetencia -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['datacompetencia']">
      <label [for]="fieldIdTable['datacompetencia']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.datacompetencia</label
      >
      <div class="input-group">
        <input
          type="text"
          [attr.id]="fieldIdTable['datacompetencia']"
          formControlName="datacompetencia"
          ngbDatepicker
          #ddatacompetencia="ngbDatepicker"
          class="form-control"
          placeholder="dd/mm/aaaa"
          appMaskDate
          [ngClass]="{
            'is-invalid': f.datacompetencia.invalid && (f.datacompetencia.dirty || f.datacompetencia.touched),
          }"
        />
        <button class="btn btn-outline-secondary" (click)="ddatacompetencia.toggle()" type="button">
          <i class="fa fa-calendar" aria-hidden="true"></i>
        </button>
      </div>
      @if (f.datacompetencia.errors) {
        <div class="invalid-feedback">
          @if (f.datacompetencia.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.datacompetencia-REQUIRED</div>
          }
          @if (f.datacompetencia.errors.validateoptionaldate) {
            <div l10nTranslate>LancamentoBeanFragment.datacompetencia-VALIDATEOPTIONALDATE</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-datacompetencia -->
    <!-- #I-#field-descricao -->
    <div class="col-sm-12 col-md-6 mb-3" [class.d-none]="!mostraField['descricao']">
      <label [for]="fieldIdTable['descricao']" class="form-label" l10nTranslate>LancamentoBeanFragment.descricao</label>
      <input
        type="text"
        [attr.id]="fieldIdTable['descricao']"
        formControlName="descricao"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.descricao.invalid && (f.descricao.dirty || f.descricao.touched) }"
        maxlength="200"
        [ngbTypeahead]="search"
        [resultFormatter]="formatter"
        [inputFormatter]="inputFormatter"
        (selectItem)="onItemDescricaoSelect($event)"
      />
      @if (f.descricao.errors) {
        <div class="invalid-feedback">
          @if (f.descricao.errors.required) {
            <div l10nTranslate>LancamentoBeanFragment.descricao-REQUIRED</div>
          }
        </div>
      }
    </div>
    <!-- #E-#field-descricao -->
    <!-- #I-#field-idconta -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['idconta']">
      <label [for]="fieldIdTable['idconta']" class="form-label" l10nTranslate>LancamentoBeanFragment.idconta</label>
      <app-select-value
        [attr.id]="fieldIdTable['idconta']"
        formControlName="idconta"
        host="/financeiro/categoria"
        par="CONTABEAN"
        opcao=""
        [isRequired]="true"
        (onAddNewItem)="OnAddNewItem($event, 'idconta')"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idconta -->
    <!-- #I-#field-idcontadestino -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3" [class.d-none]="!mostraField['idcontadestino']">
      <label [for]="fieldIdTable['idcontadestino']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.idcontadestino</label
      >
      <app-select-value
        [attr.id]="fieldIdTable['idcontadestino']"
        formControlName="idcontadestino"
        host="/financeiro/categoria"
        par="CONTABEAN"
        opcao=""
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'idcontadestino')"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcontadestino -->
    <!-- #I-#field-idformapagamento -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3" [class.d-none]="!mostraField['idformapagamento']">
      <label [for]="fieldIdTable['idformapagamento']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.idformapagamento</label
      >
      <app-select-value
        [attr.id]="fieldIdTable['idformapagamento']"
        formControlName="idformapagamento"
        host="/financeiro/categoria"
        par="FORMAPAGAMENTOBEAN"
        opcao=""
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'idformapagamento')"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idformapagamento -->
    <!-- #I-#field-idcategoria -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 required" [class.d-none]="!mostraField['idcategoria']">
      <label [for]="fieldIdTable['idcategoria']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.idcategoria</label
      >
      <app-select-value
        #vappselectvalue_cat
        [attr.id]="fieldIdTable['idcategoria']"
        formControlName="idcategoria"
        host="/financeiro/categoria"
        par="CATEGORIABEAN"
        [opcao]="f.tipofinanceiro.value"
        [isRequired]="true"
        (onAddNewItem)="OnAddNewItem($event, 'idcategoria')"
        (onLoadRows)="onLoadRows($event, 'idcategoria', vappselectvalue_cat)"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcategoria -->
    <!-- #I-#field-listadetalhes -->
    <div class="w-100" [class.d-none]="!mostraField['listadetalhes']">
      <div class="form-group">
        <app-lancamento-detalhe-bean-list-fragment
          formControlName="listadetalhes"
          [TipoLancamento]="f.tipolancamento.value"
          [ValorTotal]="f.valor.value"
          [RegraPreenchimento]="regraPreenchimento"
          [NomeMoeda]="nomeMoeda"
          class="form-control"
          [ngClass]="{ 'is-invalid': f.listadetalhes.invalid && (f.listadetalhes.dirty || f.listadetalhes.touched) }"
        ></app-lancamento-detalhe-bean-list-fragment>
      </div>
    </div>
    <!-- #E-#field-listadetalhes -->
    <!-- #I-#field-idcontato -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3" [class.d-none]="!mostraField['idcontato']">
      <label [for]="fieldIdTable['idcontato']" class="form-label" l10nTranslate>LancamentoBeanFragment.idcontato</label>
      <app-select-value
        [attr.id]="fieldIdTable['idcontato']"
        formControlName="idcontato"
        host="/admin/user"
        par="CONTATOBEAN"
        opcao=""
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'idcontato')"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcontato -->
    <!-- #I-#field-observacoes -->
    <div class="col-sm-12 mb-3" [class.d-none]="!mostraField['observacoes']">
      <label [for]="fieldIdTable['observacoes']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.observacoes</label
      >
      <input
        type="text"
        [attr.id]="fieldIdTable['observacoes']"
        formControlName="observacoes"
        class="form-control"
        maxlength="400"
        [ngClass]="{ 'is-invalid': f.observacoes.invalid && (f.observacoes.dirty || f.observacoes.touched) }"
      />
    </div>
    <!-- #E-#field-observacoes -->
    <!-- #I-#field-idcentrocusto -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3" [class.d-none]="!mostraField['idcentrocusto']">
      <label [for]="fieldIdTable['idcentrocusto']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.idcentrocusto</label
      >
      <app-select-value
        [attr.id]="fieldIdTable['idcentrocusto']"
        formControlName="idcentrocusto"
        host="/financeiro/categoria"
        par="CENTROCUSTOBEAN"
        opcao=""
        [isRequired]="false"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idcentrocusto -->
    <!-- #I-#field-idprojeto -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3" [class.d-none]="!mostraField['idprojeto']">
      <label [for]="fieldIdTable['idprojeto']" class="form-label" l10nTranslate>LancamentoBeanFragment.idprojeto</label>
      <app-select-value
        [attr.id]="fieldIdTable['idprojeto']"
        formControlName="idprojeto"
        host="/financeiro/categoria"
        par="PROJETOBEAN"
        opcao=""
        [isRequired]="false"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-idprojeto -->
    <!-- #I-#field-listatags -->
    <div class="col-sm-12 mb-3" [class.d-none]="!mostraField['listatags']">
      <label [for]="fieldIdTable['listatags']" class="form-label" l10nTranslate>LancamentoBeanFragment.listatags</label>
      <app-select-value
        [attr.id]="fieldIdTable['listatags']"
        formControlName="listatags"
        host="/financeiro/categoria"
        par="TAGBEAN"
        opcao=""
        [multiple]="true"
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'listatags')"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-listatags -->
    <!-- #I-#field-listaarquivos -->
    <div class="col-sm-12 mb-3" [class.d-none]="!mostraField['listaarquivos']">
      <label [for]="fieldIdTable['listaarquivos']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.listaarquivos</label
      >
      <app-select-value
        [attr.id]="fieldIdTable['listaarquivos']"
        formControlName="listaarquivos"
        host="/documentos/action"
        par="TBDOCUMENTBEAN"
        opcao=""
        [multiple]="true"
        [isRequired]="false"
        (onAddNewItem)="OnAddNewItem($event, 'listaarquivos')"
      >
      </app-select-value>
    </div>
    <!-- #E-#field-listaarquivos -->
    <!-- #I-#field-criarnovaregra -->
    <div class="col-sm-12 mb-3" [class.d-none]="!mostraField['criarnovaregra']">
      <div class="form-check form-switch">
        <input
          type="checkbox"
          formControlName="criarnovaregra"
          class="form-check-input"
          [attr.id]="fieldIdTable['ck-criarnovaregra']"
          (change)="OnCriarnovaregraChange($event)"
        />
        <label class="form-check-label ps-2" [for]="fieldIdTable['ck-criarnovaregra']" l10nTranslate
          >LancamentoBeanFragment.criarnovaregra</label
        >
      </div>
    </div>
    <!-- #E-#field-criarnovaregra -->
    <!-- #I-#field-statuslancamento/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3 d-none">
      <label [for]="fieldIdTable['statuslancamento']" class="form-label" l10nTranslate
        >LancamentoBeanFragment.statuslancamento</label
      >
      <ng-select
        [attr.id]="fieldIdTable['statuslancamento']"
        #statuslancamento
        formControlName="statuslancamento"
        class="w-ctrl"
        [searchable]="false"
        [hideSelected]="true"
      >
        @for (item of statuslancamentoList; track item.id) {
          <ng-option [value]="item.id" l10nTranslate>StatusLancamentoEnum.{{ item.name }}</ng-option>
        }
      </ng-select>
    </div>
    <!-- #E-#field-statuslancamento -->
    <!-- #I-#field-repeticao -->
    <div class="d-none w-100" [class.d-none]="!mostraField['repeticao']">
      <app-lancamento-repeticao-bean-fragment
        #vrepeticao
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('repeticao')"
      ></app-lancamento-repeticao-bean-fragment>
    </div>
    <!-- #E-#field-repeticao -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
<!--
-->
