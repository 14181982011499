// {{NOTGENERATE}}
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { L10N_LOCALE, L10nLocale, L10nTranslateDirective, L10nTranslatePipe } from 'angular-l10n';
import { BagParameter, BagParameterObject } from 'src/app/core/models/BagParameter';
import { CloseModalResult } from 'src/app/core/models/CloseModalResult';
import { Utilities } from 'src/app/core/services/utilities';
import { EntityDescription, EntityDescriptionItem } from '../../../models/EntityDescriptionItem';
import { LancamentoDetalheBean } from '../../../models/LancamentoDetalheBean';
import { RegraPreenchimentoBean } from '../../../models/RegraPreenchimentoBean';
import { TipoFinanceiroEnum } from '../../../models/TipoFinanceiroEnum';
import { TipoLancamentoEnum, TipoLancamentoEnumRepo } from '../../../models/TipoLancamentoEnum';
import { LancamentoDetalheBeanFragmentComponent } from './lancamento-detalhe-bean-fragment.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-lancamento-detalhe-bean-list-fragment',
  templateUrl: './lancamento-detalhe-bean-list-fragment.component.html',
  imports: [ReactiveFormsModule, L10nTranslateDirective, L10nTranslatePipe, CurrencyPipe],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LancamentoDetalheBeanListFragmentComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LancamentoDetalheBeanListFragmentComponent),
      multi: true,
    },
  ],
})
export class LancamentoDetalheBeanListFragmentComponent
  implements ControlValueAccessor, Validator, OnChanges, AfterViewChecked, AfterViewInit, OnInit
{
  @Input() TipoLancamento: TipoLancamentoEnum;
  @Input() ValorTotal: number = 0;
  @Input() RegraPreenchimento: RegraPreenchimentoBean;
  @Input() NomeMoeda: string;

  nomeMoedaString: string;

  bagParameter: BagParameterObject;
  nextId: number = 1; // Mantém um contador de IDs para novos detalhes
  lastValorTotal: number = 0;
  lastDiferenca: number = 0;
  isDisabled: boolean = false;

  detalhes: LancamentoDetalheBean[] = [];
  editingIndex: number | null = null;
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.nomeMoedaString = this.NomeMoeda;
  }

  setBagParameter(bag: BagParameter): void {
    this.bagParameter = new BagParameterObject(bag);
  }

  writeValue(value: LancamentoDetalheBean[]): void {
    if (value) {
      this.detalhes = value;
    } else {
      this.detalhes = [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implementar se necessário
    this.isDisabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['NomeMoeda']) {
      // Faça algo quando o @Input mudar
      this.handleInputChange(changes['NomeMoeda'].currentValue);
      this.onChange(this.detalhes); // Notify the change
    }
    if (changes.ValorTotal) {
      this.updateValorTotal();
      this.onChange(this.detalhes); // Notify the change
    }
    this.cdRef.detectChanges();
  }

  private handleInputChange(newValue: any) {
    // Atualize o valor e outras propriedades, se necessário
    this.nomeMoedaString = newValue;
    //console.log('MOEDA:', newValue);
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    // this.cdRef.detectChanges();
    // Promise.resolve().then(() => this.cdRef.detectChanges());
  }

  getTipoFinanceiro(): TipoFinanceiroEnum | null {
    if (this.TipoLancamento) {
      return TipoLancamentoEnumRepo.toTipoFinanceiroEnum(this.TipoLancamento);
    } else if (this.bagParameter) {
      return this.bagParameter.getTextById('tipofinanceiro', null) as TipoFinanceiroEnum;
    } else {
      return TipoFinanceiroEnum.DESPESA;
    }
  }

  displayField(detalhe: LancamentoDetalheBean, field: string): string {
    switch (field) {
      case 'descricao':
        return detalhe.descricao;
      case 'valor':
        return new DecimalPipe('pt-br').transform(detalhe.valor, '1.2-2');
      default:
        return JSON.stringify(detalhe);
    }
  }

  addDetalhe(): void {
    const tipofinanceiro = this.getTipoFinanceiro();
    const detalhe: LancamentoDetalheBean = {
      idlancamentodetalhe: null,
      tipofinanceiro: tipofinanceiro,
      valor: 0.0,
      descricao: '',
      idcategoria: null,
      idcontato: null,
      idcentrocusto: null,
      idprojeto: null,
      listatags: [],
    };
    if (this.RegraPreenchimento) {
      detalhe.descricao = this.RegraPreenchimento.descricao;
      detalhe.idcategoria = this.RegraPreenchimento.idcategoria;
      detalhe.idcentrocusto = this.RegraPreenchimento.idcentrocusto;
      detalhe.idprojeto = this.RegraPreenchimento.idprojeto;
    }
    this.doEditRow(detalhe, 'Novo Lançamento');
  }

  removeDetalhe(index: number): void {
    this.detalhes.splice(index, 1);
    this.updateValorTotal();
    this.onChange(this.detalhes);
    if (this.editingIndex === index) {
      this.editingIndex = null;
    }
  }

  isDiferencaValor(): boolean {
    return this.lastValorTotal !== Number(this.ValorTotal);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.lastValorTotal !== Number(this.ValorTotal)) {
      return { invalidTotal: 'Total divergente' };
    }
    return null;
  }

  editDetalhe(index: number): void {
    this.editingIndex = index;
    const detalhe: LancamentoDetalheBean = this.detalhes[index];
    this.doEditRow(detalhe, 'Editar ' + detalhe.descricao);
  }

  saveDetalhe(index: number): void {
    this.editingIndex = null;
    this.updateValorTotal();
    this.onChange(this.detalhes);
  }

  cancelEdit(): void {
    this.editingIndex = null;
  }

  private updateValorTotal(): void {
    try {
      if (this.detalhes && this.detalhes.length > 0) {
        this.lastValorTotal = this.detalhes.reduce((acc, detalhe) => acc + Number(detalhe.valor), 0);
        this.lastDiferenca = Number(this.ValorTotal) - this.lastValorTotal;
        console.log('TOTAL:', this.lastValorTotal, 'DIFER:', this.lastDiferenca);
      }
    } catch (error) {
      console.log(error);
    }
  }

  doEditRow(row: LancamentoDetalheBean, titulo: string): void {
    const parameters = new BagParameterObject().setTextById('registro', JSON.stringify(row));
    parameters.setTextById('title', titulo);
    console.log('REGISTRO:', row);
    Utilities.OpenNewLoaderModalWindow(
      this.modalService,
      LancamentoDetalheBeanFragmentComponent,
      (message: CloseModalResult) => {
        if (this.editingIndex !== null) {
          this.detalhes[this.editingIndex] = message.registro;
          this.saveDetalhe(this.editingIndex);
        } else {
          this.detalhes.push(message.registro);
          this.updateValorTotal();
          this.onChange(this.detalhes);
        }
      },
      parameters,
    );
  }

  getButtonStyle(node: EntityDescriptionItem): { [key: string]: string } {
    return EntityDescription.getButtonStyle(node);
  }

  openEntity(node: EntityDescriptionItem): void {
    console.log('NODE:', node.key, node.descricao);
  }

  getContaMoeda(): string {
    if (this.nomeMoedaString) {
      return this.nomeMoedaString;
    }
    return 'USA';
  }
}
